import {Project} from "@co-common-libs/resources";
import {actions, getCurrentUserCanEditProject, getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton} from "@material-ui/core";
import {diffResourceInstanceProperties} from "app-utils";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ProjectCreateEditDialog, ProjectData} from "../create-edit-dialogs";

interface ProjectEditButtonProps {
  project: Project;
}

export const ProjectEditButton = React.memo(function ProjectEditButton(
  props: ProjectEditButtonProps,
): JSX.Element | null {
  const {project} = props;

  const [projectCreateEditDialogOpen, setProjectCreateEditDialogOpen] = useState(false);
  const setProjectEditDialogOpenTrue = useCallWithTrue(setProjectCreateEditDialogOpen);
  const setProjectEditDialogOpenFalse = useCallWithFalse(setProjectCreateEditDialogOpen);

  const dispatch = useDispatch();

  const roleCanEditProjects = useSelector(getCurrentUserCanEditProject);
  const customerSettings = useSelector(getCustomerSettings);

  const handleProjectEditOk = useCallback(
    (data: ProjectData): void => {
      const updatedProperties = diffResourceInstanceProperties(data, project);

      if (updatedProperties) {
        dispatch(actions.update(project.url, updatedProperties));
      }

      setProjectCreateEditDialogOpen(false);
    },
    [dispatch, project],
  );

  return customerSettings.canEditProjects && roleCanEditProjects ? (
    <div>
      <IconButton onClick={setProjectEditDialogOpenTrue}>
        <PencilIcon />
      </IconButton>
      <ProjectCreateEditDialog
        data={project}
        open={projectCreateEditDialogOpen}
        onCancel={setProjectEditDialogOpenFalse}
        onOk={handleProjectEditOk}
      />
    </div>
  ) : null;
});
