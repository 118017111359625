import {Config} from "@co-common-libs/config";
import {ResourceName, Role, urlToId} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import ImmutableDate from "bloody-immutable-date";
import memoizeOne from "memoize-one";

const ARCHIVE_DAYS = 7;

const isActiveCheck: Check = {memberName: "active", type: "memberTruthy"};

const alwaysOkCheck: Check = {type: "alwaysOk"};

const hasRelatedActiveRoutePlanCheck: Check = {
  check: isActiveCheck,
  memberName: "routePlan",
  targetType: "routePlan",
  type: "hasForeignKey",
};

const hasRelatedActiveRoutePlanTaskRoutePlanCheck: Check = {
  check: hasRelatedActiveRoutePlanCheck,
  memberName: "routePlanTask",
  targetType: "routePlanTask",
  type: "hasForeignKey",
};

const getCachedDailyAccumulatedCompensatoryCheck = memoizeOne((day: string): Check => {
  const result: Check = {
    memberName: "date",
    type: "memberGte",
    value: day,
  };
  return result;
});

export const computePersistedQueries = (
  customerSettings: Config,
  role: Role | undefined,
): readonly Query[] => {
  const isJobber = role && role.jobber;
  const isManager = role && role.manager;
  const now = new ImmutableDate();
  const todayString = dateToString(now);
  const yesterday = now.setDate(now.getDate() - 1);
  const yesterdayString = dateToString(yesterday);
  const archiveDate = now.setDate(now.getDate() - ARCHIVE_DAYS).setHours(0, 0, 0, 0);
  const archiveDateString = dateToString(archiveDate);

  const resourcesWithSimpleQueries: ResourceName[] = [
    "settingEntry",
    "user",
    "userPhoto",
    "userFile",
    "role",
    "userProfile",
    "employeeGroup",
    "location",
    "machineUseLog",
    "machineLastUsed",
    "locationUseLog",
    "productUseLog",
    "workType",
    "timer",
    "priceGroup",
    "priceItem",
    "settings",
    "tasksPrintout",
    "hoursAbsence",
    "daysAbsence",
    "unit",
    "calendarWorkHours",
    "journal",
    "expectedAmount",
    "information",
    "reportingSpecification",
    "workshopChecklist",
    "workshopChecklistItem",
    "anniversaryType",
    "reportSetup",
    "archiveFile",
    "archiveLink",
    "orderFile",
    "locationType",
    "crop",
  ];

  if (isManager) {
    resourcesWithSimpleQueries.push("accumulatedCompensatory");
  }

  if (
    Object.values(customerSettings.remunerationGroups).some(
      (remunerationGroup: any) => remunerationGroup.accomodationAllowance,
    )
  ) {
    resourcesWithSimpleQueries.push("accomodationAllowance");
  }

  if (customerSettings.usePunchInOut) {
    resourcesWithSimpleQueries.push("punchInOut");
  }

  if (customerSettings.enableLocationStorage) {
    resourcesWithSimpleQueries.push("locationStorageStatus");
  }

  if (customerSettings.fuelSurcharge === "KR_PER_LITER") {
    resourcesWithSimpleQueries.push(
      "krPerLiterFuelSurchargeSpecification",
      "krPerLiterFuelSurchargeBasis",
      "krPerLiterFuelSurchargeSpecificationEntry",
      "krPerLiterDefaultFuelSurchargeUse",
      "krPerLiterWorkTypeFuelSurchargeUse",
      "krPerLiterMachineFuelSurchargeUse",
    );
  }
  if (customerSettings.fuelSurcharge === "PRICE_PERCENT") {
    resourcesWithSimpleQueries.push(
      "pricePercentFuelSurchargeSpecification",
      "pricePercentFuelSurchargeBasis",
      "pricePercentFuelSurchargeSpecificationEntry",
      "pricePercentDefaultFuelSurchargeUse",
      "pricePercentWorkTypeFuelSurchargeUse",
      "pricePercentMachineFuelSurchargeUse",
    );
  }

  const queries: Query[] = resourcesWithSimpleQueries.map((resourceName) =>
    makeQuery({check: alwaysOkCheck, independentFetch: true, resourceName}),
  );

  const dateTodayCheck: Check = {
    memberName: "date",
    type: "memberEq",
    value: todayString,
  };

  queries.push(
    makeQuery({
      check: dateTodayCheck,
      filter: {date: todayString},
      independentFetch: true,
      resourceName: "dinnerBooking",
    }),
  );
  queries.push(
    makeQuery({
      check: dateTodayCheck,
      filter: {date: todayString},
      independentFetch: true,
      resourceName: "lunchBooking",
    }),
  );

  if (customerSettings.externalTaskCustomer) {
    if (isJobber === false) {
      queries.push(
        makeQuery({
          check: alwaysOkCheck,
          independentFetch: true,
          resourceName: "customer",
        }),
      );
    }
    queries.push(
      makeQuery({
        check: alwaysOkCheck,
        independentFetch: true,
        resourceName: "customerFile",
      }),
    );
    queries.push(
      makeQuery({
        check: alwaysOkCheck,
        independentFetch: true,
        resourceName: "contact",
      }),
    );
  }

  if (customerSettings.externalTaskCulture) {
    queries.push(
      makeQuery({
        check: isActiveCheck,
        filter: {active: ""},
        independentFetch: true,
        resourceName: "culture",
      }),
    );
  }

  if (customerSettings.routesEnabled) {
    queries.push(
      makeQuery({
        check: alwaysOkCheck,
        independentFetch: true,
        resourceName: "routePlan",
      }),
    );
    queries.push(
      makeQuery({
        check: hasRelatedActiveRoutePlanCheck,
        independentFetch: false,
        resourceName: "routePlanTask",
      }),
    );
    queries.push(
      makeQuery({
        check: hasRelatedActiveRoutePlanTaskRoutePlanCheck,
        independentFetch: false,
        resourceName: "routePlanTaskActivityOption",
      }),
    );
    queries.push(
      makeQuery({
        check: hasRelatedActiveRoutePlanTaskRoutePlanCheck,
        independentFetch: false,
        resourceName: "routePlanTaskResult",
      }),
    );
  }

  queries.push(
    makeQuery({
      check: alwaysOkCheck,
      independentFetch: true,
      resourceName: "machine",
    }),
  );
  queries.push(
    makeQuery({
      check: alwaysOkCheck,
      independentFetch: true,
      resourceName: "machineGroup",
    }),
  );

  queries.push(
    makeQuery({
      check: alwaysOkCheck,
      independentFetch: true,
      resourceName: "product",
    }),
  );
  queries.push(
    makeQuery({
      check: alwaysOkCheck,
      independentFetch: true,
      resourceName: "productGroup",
    }),
  );

  if (customerSettings.enableProjects) {
    queries.push(
      makeQuery({
        check: alwaysOkCheck,
        independentFetch: true,
        resourceName: "project",
      }),
    );
  }

  const taskCheck: Check = {
    checks: [
      {memberName: "archivable", type: "memberFalsy"},
      {
        memberName: "workFromTimestamp",
        type: "memberGte",
        value: archiveDate.toISOString(),
      },
    ],
    type: "or",
  };

  const orderCheck: Check = {
    check: taskCheck,
    fromResource: "task",
    memberName: "order",
    type: "targetOfForeignKey",
  };
  const customerCheck: Check = {
    check: orderCheck,
    fromResource: "order",
    memberName: "customer",
    type: "targetOfForeignKey",
  };

  const relatedTaskCheck: Check = {
    check: taskCheck,
    memberName: "task",
    targetType: "task",
    type: "hasForeignKey",
  };
  const relatedTransportLogTaskCheck: Check = {
    check: relatedTaskCheck,
    memberName: "transportlog",
    targetType: "transportLog",
    type: "hasForeignKey",
  };
  const relatedYieldLogTaskCheck: Check = {
    check: relatedTaskCheck,
    memberName: "yieldlog",
    targetType: "yieldLog",
    type: "hasForeignKey",
  };
  const relatedTaskRouteMemberCheck: Check = {
    check: taskCheck,
    memberName: "route",
    targetType: "task",
    type: "hasForeignKey",
  };
  const relatedRouteTaskTaskRouteMemberCheck: Check = {
    check: relatedTaskRouteMemberCheck,
    memberName: "routeTask",
    targetType: "routeTask",
    type: "hasForeignKey",
  };

  // Backend checks profile rather than parameter for "isJobber", but data
  // should be cached separately, both in frontend and backend based on
  // this; ensuring a different URL accomplishes both.
  if (isJobber !== undefined) {
    queries.push(
      makeQuery({
        check: taskCheck,
        filter: isJobber
          ? {archiveDate: archiveDateString, isJobber: ""}
          : {archiveDate: archiveDateString},
        independentFetch: true,
        resourceName: "task",
      }),
    );
  }

  queries.push(
    makeQuery({
      check: orderCheck,
      independentFetch: false,
      resourceName: "order",
    }),
  );

  queries.push(
    makeQuery({
      check: customerCheck,
      independentFetch: false,
      resourceName: "customer",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTaskCheck,
      independentFetch: false,
      resourceName: "timerStart",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTaskCheck,
      independentFetch: false,
      resourceName: "transportLog",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTransportLogTaskCheck,
      independentFetch: false,
      resourceName: "pickupLocation",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTransportLogTaskCheck,
      independentFetch: false,
      resourceName: "deliveryLocation",
    }),
  );

  queries.push(
    makeQuery({
      check: {
        check: relatedTransportLogTaskCheck,
        memberName: "location",
        targetType: "pickupLocation",
        type: "hasForeignKey",
      },
      independentFetch: false,
      resourceName: "pickup",
    }),
  );

  queries.push(
    makeQuery({
      check: {
        check: relatedTransportLogTaskCheck,
        memberName: "location",
        targetType: "deliveryLocation",
        type: "hasForeignKey",
      },
      independentFetch: false,
      resourceName: "delivery",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTransportLogTaskCheck,
      independentFetch: false,
      resourceName: "transportLogReport",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTaskCheck,
      independentFetch: false,
      resourceName: "yieldLog",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedYieldLogTaskCheck,
      independentFetch: false,
      resourceName: "yieldLogReport",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedYieldLogTaskCheck,
      independentFetch: false,
      resourceName: "yieldPickupLocation",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedYieldLogTaskCheck,
      independentFetch: false,
      resourceName: "yieldDeliveryLocation",
    }),
  );

  queries.push(
    makeQuery({
      check: {
        check: relatedYieldLogTaskCheck,
        memberName: "location",
        targetType: "yieldPickupLocation",
        type: "hasForeignKey",
      },
      independentFetch: false,
      resourceName: "yieldPickup",
    }),
  );

  queries.push(
    makeQuery({
      check: {
        check: relatedYieldLogTaskCheck,
        memberName: "location",
        targetType: "yieldDeliveryLocation",
        type: "hasForeignKey",
      },
      independentFetch: false,
      resourceName: "yieldDelivery",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTaskCheck,
      independentFetch: false,
      resourceName: "taskPhoto",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTaskCheck,
      independentFetch: false,
      resourceName: "taskFile",
    }),
  );

  queries.push(
    makeQuery({
      check: relatedTaskCheck,
      independentFetch: false,
      resourceName: "reportingPrintout",
    }),
  );

  if (customerSettings.routesEnabled) {
    queries.push(
      makeQuery({
        check: relatedTaskRouteMemberCheck,
        independentFetch: false,
        resourceName: "routeTask",
      }),
    );

    queries.push(
      makeQuery({
        check: relatedRouteTaskTaskRouteMemberCheck,
        independentFetch: false,
        resourceName: "routeTaskResult",
      }),
    );

    queries.push(
      makeQuery({
        check: relatedRouteTaskTaskRouteMemberCheck,
        independentFetch: false,
        resourceName: "routeTaskActivityOption",
      }),
    );

    queries.push(
      makeQuery({
        check: relatedTaskRouteMemberCheck,
        independentFetch: false,
        resourceName: "routeLogReport",
      }),
    );
  }

  if (
    Object.values(customerSettings.remunerationGroups).some(
      (override: any) => override.accumulateCompensatoryLimit,
    )
  ) {
    if (isManager) {
      queries.push(
        makeQuery({
          check: getCachedDailyAccumulatedCompensatoryCheck(yesterdayString),
          filter: {date: yesterdayString},
          independentFetch: true,
          resourceName: "cachedDailyAccumulatedCompensatory",
        }),
      );
    } else if (role) {
      queries.push(
        makeQuery({
          check: getCachedDailyAccumulatedCompensatoryCheck(yesterdayString),
          filter: {
            date: yesterdayString,
            employeeId: urlToId(role.user),
          },
          independentFetch: true,
          resourceName: "cachedDailyAccumulatedCompensatory",
        }),
      );
    }
  }

  queries.push(
    makeQuery({
      check: {memberName: "hasTasks", type: "memberEq", value: false},
      filter: {noTasks: ""},
      independentFetch: true,
      resourceName: "order",
    }),
  );

  // FIXME: maybe do some filtering
  queries.push(
    makeQuery({
      check: alwaysOkCheck,
      independentFetch: true,
      resourceName: "calendarOrdering",
    }),
  );

  // FIXME: sensible filtering --- current week by default...
  queries.push(
    makeQuery({
      check: alwaysOkCheck,
      independentFetch: true,
      resourceName: "availability",
    }),
  );
  return queries;
};
