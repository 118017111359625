import {Customer, CustomerUrl, Location, LocationUrl} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";

export function getWorkplaceString(workplace: Location): string;
export function getWorkplaceString(
  workplaceUrl: LocationUrl | null | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
): string;
export function getWorkplaceString(
  workplaceOrWorkplaceUrl: Location | LocationUrl | null | undefined,
  locationLookup?: (url: LocationUrl) => Location | undefined,
): string {
  if (!workplaceOrWorkplaceUrl) {
    return "";
  }
  const workplace =
    typeof workplaceOrWorkplaceUrl === "string"
      ? locationLookup !== undefined
        ? locationLookup(workplaceOrWorkplaceUrl)
        : undefined
      : workplaceOrWorkplaceUrl;
  if (!workplace) {
    return "";
  }
  const name = workplace.name || "";
  const address = customerAddress(workplace);
  if (name && address) {
    return `${name}, ${address}`;
  } else {
    return name || address || "";
  }
}

export function getFieldString(
  fieldURL: LocationUrl | null,
  locationLookup: (url: LocationUrl) => Location | undefined,
): string {
  if (!fieldURL) {
    return "";
  }
  const field = locationLookup(fieldURL);
  if (!field) {
    return "";
  }
  return field.fieldNumber;
}

export function getLocationPostalCode(
  locationURL: LocationUrl | null | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
): string {
  if (!locationURL) {
    return "";
  }
  const workplace = locationLookup(locationURL);
  if (!workplace) {
    return "";
  }
  return workplace.postalCode;
}

export function getLocationLongString(
  locationURL: LocationUrl | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
): string {
  if (!locationURL) {
    return "";
  }
  const location = locationLookup(locationURL);
  if (!location) {
    return "";
  }
  const customer = location.customer ? customerLookup(location.customer) : null;
  const address = customerAddress(location);
  return [customer?.name, location.name, address].filter(Boolean).join(", ");
}
