import {Customer, CustomerUrl} from "@co-common-libs/resources";
import {ConnectedCustomerDialog} from "@co-frontend-libs/connected-components";
import {getCurrentUserCanManageCustomers} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {CustomerCreateDialog} from "./customer-dialogs/customer-create-dialog";

interface CustomerDialogOwnProps {
  fullscreen?: boolean;
  onCancel(): void;
  onNone?: () => void;
  onOk(url: CustomerUrl): void;
  open: boolean;
}

export const CustomerSelectCreateDialog = ({
  onOk,
  open,
  ...rest
}: CustomerDialogOwnProps): JSX.Element => {
  const [customerCreateEditDialogOpen, setCustomerCreateEditDialogOpen] = useState(false);
  const setCustomerCreateEditDialogOpenFalse = useCallWithFalse(setCustomerCreateEditDialogOpen);

  const [initialName, setInitialName] = useState("");

  const handleAdd = useCallback((searchString: string) => {
    setInitialName(searchString);
    setCustomerCreateEditDialogOpen(true);
  }, []);

  const currentUserCanManageCustomers = useSelector(getCurrentUserCanManageCustomers);

  const handleCreateOk = useCallback(
    (customerURL: CustomerUrl) => {
      setCustomerCreateEditDialogOpen(false);
      setTimeout(() => onOk(customerURL));
    },
    [onOk],
  );

  const initialData = useMemo((): Partial<Customer> => ({name: initialName}), [initialName]);

  return (
    <>
      <ConnectedCustomerDialog
        open={open && !customerCreateEditDialogOpen}
        onAdd={currentUserCanManageCustomers ? handleAdd : undefined}
        onOk={onOk}
        {...rest}
      />
      <CustomerCreateDialog
        customer={initialData}
        open={customerCreateEditDialogOpen}
        onCancel={setCustomerCreateEditDialogOpenFalse}
        onCreateSuccess={handleCreateOk}
      />
    </>
  );
};
