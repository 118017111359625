import {Command, PriceItemUsesDict, Task, TimerStart} from "@co-common-libs/resources";
import {getTaskTimersWithTime} from "@co-common-libs/resources-utils";
import {notNull, notUndefined, setsSameMembers} from "@co-common-libs/utils";
import {patchFromPriceItemUsesChange, recomputePriceItemUses} from "@co-frontend-libs/utils";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getPriceGroupLookup,
  getPriceItemLookup,
  getTaskLookup,
  getTimerLookup,
  getTimerStartArray,
  getUnitLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

// Timer price item special case: May only be removed on stop immediately
// after start -- if added via timer that we don't have time for here, assume
// that we're missing context.
export function updateTaskPriceItemUsesFromTimerPriceGroups(
  newTimerStart: TimerStart | null,
  _oldTimerStart: TimerStart | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTimerStart) {
    return null;
  }

  const state = middlewareApi.getState();

  const taskURL = newTimerStart.task;

  const taskLookup = getTaskLookup(state);
  const task = taskLookup(taskURL);

  if (!task) {
    return null;
  }

  const timerStarteArray = getTimerStartArray(state);
  const taskTimerStartArray = timerStarteArray.filter((t) => t.task === taskURL);

  const extendedTaskTimerStartArray = taskTimerStartArray.concat([newTimerStart]);

  const timerURLSet = new Set(extendedTaskTimerStartArray.map((t) => t.timer).filter(notNull));
  const timerLookup = getTimerLookup(state);

  const timers = Array.from(timerURLSet).map(timerLookup).filter(notUndefined);

  if (timers.every((timer) => !timer.priceGroup)) {
    // no pricegroups from any relevant timers
    return null;
  }

  const oldTimersWithTime = getTaskTimersWithTime(task, taskTimerStartArray);
  const newTimersWithTime = getTaskTimersWithTime(task, extendedTaskTimerStartArray);

  if (setsSameMembers(oldTimersWithTime, newTimersWithTime)) {
    // same timers as before
    return null;
  }

  if (
    Array.from(oldTimersWithTime).every(
      (t) => newTimersWithTime.has(t) || !timerLookup(t)?.priceGroup,
    ) &&
    Array.from(newTimersWithTime).every(
      (t) => oldTimersWithTime.has(t) || !timerLookup(t)?.priceGroup,
    )
  ) {
    // change only to timers without pricegroups
    return null;
  }

  // allow removal of price items for timers whose time was removed
  // by this change...
  const removedTimers = new Set(
    Array.from(oldTimersWithTime).filter((t) => !newTimersWithTime.has(t)),
  );

  const priceGroupLookup = getPriceGroupLookup(state);
  const priceItemLookup = getPriceItemLookup(state);
  const unitLookup = getUnitLookup(state);

  const customerSettings = getCustomerSettings(state);
  const priceItemUses = recomputePriceItemUses(
    task as Task & {
      readonly priceItemUses: PriceItemUsesDict;
    },
    newTimersWithTime,
    timerLookup,
    priceGroupLookup,
    priceItemLookup,
    unitLookup,
    customerSettings,
    removedTimers,
  );
  if (priceItemUses === task.priceItemUses) {
    return null;
  }
  const patch = patchFromPriceItemUsesChange(task.priceItemUses || {}, priceItemUses);
  if (!patch.length) {
    return null;
  }
  const patchTask: Command = {
    action: "UPDATE",
    patch,
    url: taskURL,
  };
  return {after: [patchTask]};
}
