import {
  MachineAnalysisResults,
  MachineAnalysis as RenderedMachineAnalysis,
} from "@co-common-libs/report-rendering";
import {urlToId} from "@co-common-libs/resources";
import {getCurrentUserProfile, getShareToken} from "@co-frontend-libs/redux";
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {Configuration} from "./configuration";

interface DisplayResultsProps {
  configuration: Configuration;
  fromDate: string;
  results: MachineAnalysisResults;
  toDate: string;
}

export function DisplayResults(props: DisplayResultsProps): JSX.Element {
  const {configuration, fromDate, results, toDate} = props;
  const machineId = urlToId(configuration.machine as string);
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const printedBy = currentUserProfile?.name || currentUserProfile?.alias || "";
  const shareToken = useSelector(getShareToken);
  const pdfBaseURL = `${globalConfig.baseURL}/api/report/machine_analysis/pdf/`;
  const pdfURL = [
    `${pdfBaseURL}?token=${shareToken}&printedBy=${printedBy}`,
    `&fromDate=${fromDate}&toDate=${toDate}`,
    `&machine=${machineId}`,
    `&turnoverInvoiced=${configuration?.turnover.invoiced || 0}`,
    `&directCostsSpareParts=${configuration?.directCosts.spareParts || 0}`,
    `&directCostsForeignWorkshop=${configuration?.directCosts.foreignWorkshop || 0}`,
    `&directCostsOther=${configuration?.directCosts.other || 0}`,
    `&indirectCostsInsurance=${configuration?.indirectCosts.insurance || 0}`,
    `&indirectCostsFinancing=${configuration?.indirectCosts.financing || 0}`,
    `&indirectCostsOther=${configuration?.indirectCosts.other || 0}`,
  ].join("");
  return (
    <Card>
      <CardContent>
        <div style={{border: "1px solid black", padding: "7mm", width: "297mm"}}>
          <RenderedMachineAnalysis {...results} />
        </div>
      </CardContent>
      <CardActions>
        <Button color="primary" href={pdfURL} target="_blank" variant="contained">
          <FormattedMessage defaultMessage="Hent PDF" id="machine-analysis.label.fetch-pdf" />
        </Button>
      </CardActions>
    </Card>
  );
}
