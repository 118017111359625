import {AppbarSearchField} from "@co-frontend-libs/components";
import {actions, getProjectLookup, makePathParameterGetter} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {BackToolbar, PageLayout, ProjectDetails} from "app-components";
import {useEventTargetValueCallback, useQueryParameter, useQueryParameterState} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ProjectLogAppendices} from "./project-log-appendices";
import {ProjectPhotoAppendices} from "./project-photo-appendices";
import {ProjectTaskTable} from "./project-task-table";

export enum ProjectDetailTab {
  Details = "details",
  Tasks = "tasks",
  Photos = "photos",
  Logs = "logs",
}

export const ProjectsDetailPage = React.memo(function ProjectsDetailsPage(): JSX.Element {
  const intl = useIntl();
  const idParameterGetter = makePathParameterGetter("id");

  const id = useSelector(idParameterGetter);
  const projectLookup = useSelector(getProjectLookup);
  const project = projectLookup(instanceURL("project", id));

  const dispatch = useDispatch();
  const activeTab = useQueryParameter<ProjectDetailTab>("tab", ProjectDetailTab.Details);
  const [filter, setFilter] = useQueryParameterState<string>("q", "");
  const handleFilterStringChange = useEventTargetValueCallback(setFilter, [setFilter]);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      dispatch(actions.putQueryKey("tab", value));
    },
    [dispatch],
  );

  if (!project) {
    return <FormattedMessage defaultMessage="Projekt ikke fundet" />;
  }

  let content;
  if (activeTab === ProjectDetailTab.Details) {
    content = (
      <div data-testid="project-details">
        <ProjectDetails project={project} />
      </div>
    );
  } else if (activeTab === ProjectDetailTab.Tasks) {
    content = (
      <div data-testid="project-tasks">
        <ProjectTaskTable filterString={filter} project={project} />
      </div>
    );
  } else if (activeTab === ProjectDetailTab.Photos) {
    content = (
      <div data-testid="project-photos">
        <ProjectPhotoAppendices project={project} />
      </div>
    );
  } else if (activeTab === ProjectDetailTab.Logs) {
    content = (
      <div data-testid="project-logs">
        <ProjectLogAppendices project={project} />
      </div>
    );
  }

  const tabs = (
    <Tabs scrollButtons="auto" value={activeTab} variant="scrollable" onChange={handleTabChange}>
      <Tab
        label={intl.formatMessage({defaultMessage: "Detaljer"})}
        value={ProjectDetailTab.Details}
      />
      <Tab label={intl.formatMessage({defaultMessage: "Opgaver"})} value={ProjectDetailTab.Tasks} />
      <Tab label={intl.formatMessage({defaultMessage: "Fotos"})} value={ProjectDetailTab.Photos} />
      <Tab label={intl.formatMessage({defaultMessage: "Logs"})} value={ProjectDetailTab.Logs} />
    </Tabs>
  );

  return (
    <PageLayout
      withBottomScrollPadding
      withPadding
      tabs={tabs}
      toolbar={
        <BackToolbar
          rightElement={
            activeTab === ProjectDetailTab.Tasks ? (
              <div data-testid="search-field">
                <AppbarSearchField value={filter} onChange={handleFilterStringChange} />
              </div>
            ) : undefined
          }
          title={intl.formatMessage(
            {
              defaultMessage: "{projectNumber}: {name}",
            },
            {
              name: project?.name,
              projectNumber: project?.projectNumber,
            },
          )}
        />
      }
    >
      {content}
    </PageLayout>
  );
});
