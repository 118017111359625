import {Location, LocationUrl} from "@co-common-libs/resources";
import {FieldLabels} from "@co-frontend-libs/components";
import {getCropColorMap, getCustomerSettings} from "@co-frontend-libs/redux";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  useTheme,
} from "@material-ui/core";
import {GoogleMap, useLoadGoogleMaps} from "app-utils";
import bowser from "bowser";
import {SPACING} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {areEqual} from "react-window";
import {FieldPolygons} from "../field-polygons";
import {CurrentLocationButton} from "../geolocation-map/current-location-button";
import {CurrentLocationMarker} from "../geolocation-map/current-location-marker";
import {FieldsInformation} from "../geolocation-map/fields-information";
import {ColorListPopover} from "./color-list";

function getBounds(
  entries: readonly {
    readonly field: Location;
  }[],
): [number, number][] {
  const bounds: [number, number][] = [];
  entries.forEach((entry): void => {
    if (!entry.field.geojson) {
      throw new Error("not a field");
    }
    const {bbox} = entry.field.geojson.geometry;
    if (bbox) {
      const [x1, y1, x2, y2] = bbox;
      bounds.push([y1, x1], [y2, x2]);
    }
  });

  return bounds;
}

const baseMapOptions: google.maps.MapOptions = {
  clickableIcons: false,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ["hybrid", "roadmap", "satellite"],
  },
  streetViewControl: false,
  styles: [
    {featureType: "poi", stylers: [{visibility: "off"}]},
    {featureType: "transit", stylers: [{visibility: "off"}]},
  ],
};

const FULLSCREEN_TOOLBAR_HEIGHT = 56;
const TOOLBAR_HEIGHT = 116;
const FOOTER_HEIGHT = 52.5;
const MARGIN_COMBINED = 66;

// from measuring; menu panel is:
// * 36.5 px when displaying just color code button
// * 40 px when displaying total selected and color code button
// * 58 when displaying checkbox and color code button
// * 82 when displaying total selected, checkbox and color code button
const MENU_PANEL_BASE_HEIGHT = 36.5;
const MENU_PANEL_TOTAL_SELECTED_HEIGHT = 40;
const MENU_PANEL_CHECKBOX_HEIGHT = 58;
const MENU_PANEL_TOTAL_SELECTED_AND_CHECKBOX_HEIGHT = 82;

interface MenuPanelHeightOptions {
  withAllFieldsCheckbox: boolean;
  withTotalSelected: boolean;
}

function getMapHeight(fullscreen: boolean, menuPanelHeightOptions: MenuPanelHeightOptions): number {
  const {withAllFieldsCheckbox, withTotalSelected} = menuPanelHeightOptions;
  const menuPanelHeight =
    withAllFieldsCheckbox && withTotalSelected
      ? MENU_PANEL_TOTAL_SELECTED_AND_CHECKBOX_HEIGHT
      : withAllFieldsCheckbox
        ? MENU_PANEL_CHECKBOX_HEIGHT
        : withTotalSelected
          ? MENU_PANEL_TOTAL_SELECTED_HEIGHT
          : MENU_PANEL_BASE_HEIGHT;
  return fullscreen
    ? window.innerHeight - menuPanelHeight - FULLSCREEN_TOOLBAR_HEIGHT
    : window.innerHeight - TOOLBAR_HEIGHT - menuPanelHeight - FOOTER_HEIGHT - MARGIN_COMBINED;
}

interface SelectionMapProps {
  customerHasFields: boolean;
  displayAllFields: boolean;
  entries: readonly {
    readonly field: Location;
  }[];
  fullscreenLayout: boolean;
  onDisplayAllFieldsChange: ((displayAll: boolean) => void) | undefined;
  onSelect(identifier: LocationUrl, selected: boolean): void;
  readonlySet?: ReadonlySet<LocationUrl> | undefined;
  selected?: ReadonlySet<LocationUrl>;
  showTotalSelected?: boolean;
}

export const SelectionMap = React.memo(function SelectionMap(
  props: SelectionMapProps,
): JSX.Element {
  const {
    customerHasFields,
    displayAllFields,
    entries,
    fullscreenLayout,
    onDisplayAllFieldsChange,
    onSelect,
    readonlySet,
    selected,
    showTotalSelected,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);

  const cropColorMap = useSelector(getCropColorMap);

  const colorCodeButtonRef = useRef<HTMLButtonElement | null>(null);
  const [colorListPopoverOpen, setColorListPopoverOpen] = useState(false);
  const handleColorCodeClick = useCallback(() => {
    setColorListPopoverOpen(true);
  }, []);
  const handleColorListPopoverClose = useCallback(() => {
    setColorListPopoverOpen(false);
  }, []);
  const theme = useTheme();

  const locations = useMemo(() => entries.map(({field}) => field), [entries]);

  const {isLoaded /* loadError */} = useLoadGoogleMaps();

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const mapOptions = useMemo((): google.maps.MapOptions => {
    return {
      ...baseMapOptions,
      center: {
        lat: customerSettings.geolocation.initialPositionLatitude,
        lng: customerSettings.geolocation.initialPositionLongitude,
      },
      keyboardShortcuts: !bowser.mobile && !bowser.tablet,
      zoom: customerSettings.geolocation.initialZoom,
    };
  }, [
    customerSettings.geolocation.initialPositionLatitude,
    customerSettings.geolocation.initialPositionLongitude,
    customerSettings.geolocation.initialZoom,
  ]);

  const handleDisplayAllFieldsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onDisplayAllFieldsChange) {
        const displayAll = !event.target.checked;
        onDisplayAllFieldsChange(displayAll);
      }
    },
    [onDisplayAllFieldsChange],
  );

  const bounds = useMemo(() => {
    if (isLoaded) {
      const boundsArray = getBounds(entries);
      const latLngBounds = new google.maps.LatLngBounds();
      boundsArray.forEach(([lat, lng]) => {
        latLngBounds.extend({lat, lng});
      });
      if (!latLngBounds.isEmpty()) {
        // As string to have equality check for triggering useEffect behave.
        return JSON.stringify(latLngBounds);
      }
    }
    return "";
  }, [entries, isLoaded]);

  const handleLoad = useCallback((map: google.maps.Map) => {
    setMapInstance(map);
  }, []);

  useEffect(() => {
    if (mapInstance && bounds) {
      mapInstance.fitBounds(JSON.parse(bounds));
    }
  }, [bounds, mapInstance]);

  const filteredLocationArray = useMemo(() => entries.map((entry) => entry.field), [entries]);

  const withAllFieldsCheckbox = !!onDisplayAllFieldsChange;
  const withTotalSelected = showTotalSelected || false;

  const [mapHeight, setMapHeight] = useState<number>(
    getMapHeight(fullscreenLayout, {withAllFieldsCheckbox, withTotalSelected}),
  );

  const mapContainerStyle = useMemo<React.CSSProperties>(
    () => ({
      height: mapHeight,
      overflow: "hidden",
      position: "relative",
      width: "100%",
    }),
    [mapHeight],
  );

  useEffect(() => {
    const resizeHandler = (): void => {
      setMapHeight(
        getMapHeight(fullscreenLayout, {
          withAllFieldsCheckbox,
          withTotalSelected,
        }),
      );
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    window.addEventListener("orientationchange", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("orientationchange", resizeHandler);
    };
  }, [fullscreenLayout, withAllFieldsCheckbox, withTotalSelected]);

  const intl = useIntl();
  let totalSelectedArea: JSX.Element | null = null;
  if (showTotalSelected) {
    const selectedArea = entries.reduce((current, instance) => {
      const identifier = instance.field.url;
      const isSelected = selected?.has(identifier);
      if (isSelected && instance.field.fieldAreaHa) {
        return current + instance.field.fieldAreaHa;
      } else {
        return current;
      }
    }, 0);
    const formattedArea = intl.formatNumber(selectedArea, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    totalSelectedArea = (
      <FormattedMessage
        defaultMessage="Total areal valgt: {area} HA."
        values={{area: formattedArea}}
      />
    );
  }

  let mapsBlock;
  if (isLoaded) {
    mapsBlock = (
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        options={mapOptions}
        onLoad={handleLoad}
      >
        {mapInstance ? (
          <>
            <div style={{position: "absolute", right: 0}}>
              <CurrentLocationButton googleMap={mapInstance} />
            </div>
            <CurrentLocationMarker map={mapInstance} />
            <FieldPolygons
              cropColorMap={cropColorMap}
              locations={locations}
              map={mapInstance}
              readonlySet={readonlySet}
              selected={selected}
              onSelect={onSelect}
            />
            {customerSettings.showStorageOnTaskFieldMap ? (
              <FieldsInformation
                fields={filteredLocationArray}
                googleMapsLinks={false}
                map={mapInstance}
              />
            ) : (
              <FieldLabels
                includeArea
                fieldArray={filteredLocationArray}
                mapInstance={mapInstance}
              />
            )}
          </>
        ) : null}
      </GoogleMap>
    );
  } else {
    mapsBlock = (
      <div
        style={{
          ...mapContainerStyle,
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Grid container alignItems="center" direction="row">
        <Grid item style={{padding: theme.spacing(SPACING.XSMALL)}}>
          <Grid container item direction="column">
            {totalSelectedArea}
            {onDisplayAllFieldsChange ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!displayAllFields}
                    disabled={!customerHasFields}
                    name="only display customer fields"
                    onChange={handleDisplayAllFieldsChange}
                  />
                }
                label={intl.formatMessage({
                  defaultMessage: "Vis kun kundens marker",
                })}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid item style={{flexGrow: 1}}>
          <Button
            ref={colorCodeButtonRef}
            style={{float: "right"}}
            variant="text"
            onClick={handleColorCodeClick}
          >
            <FormattedMessage defaultMessage="Farvekoder" id="field-dialog.label.color-codes" />
          </Button>
        </Grid>
      </Grid>
      {mapsBlock}
      <ColorListPopover
        anchorEl={colorCodeButtonRef.current}
        colorMap={cropColorMap}
        open={colorListPopoverOpen}
        onClose={handleColorListPopoverClose}
      />
    </>
  );
}, areEqual);
