import {
  Contact,
  ContactUrl,
  Customer,
  CustomerUrl,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  Location,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
} from "@co-common-libs/resources";
import {
  caseAccentInsensitiveCollator,
  customerAddress,
  formatDateTimeWithoutWeekday,
  identifierComparator,
  notUndefined,
} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  IconLinkButton,
  RowData,
  iconButtonColumnSpecification,
  iconColumnSpecification,
} from "@co-frontend-libs/components";
import {
  getCurrentRole,
  getCustomerSettings,
  getKrPerLiterDefaultFuelSurchargeUseArray,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterMachineFuelSurchargeUseArray,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
  getLocationArray,
  getPricePercentDefaultFuelSurchargeUseArray,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentMachineFuelSurchargeUseArray,
  getPricePercentWorkTypeFuelSurchargeUseArray,
  getSortedActiveAndInactiveCustomerArray,
  getSortedActiveContactArrayPerCustomer,
  getSortedActiveCustomerArray,
} from "@co-frontend-libs/redux";
import {ConnectedTableWithPagination, PaginationPageSize} from "app-components";
import {addDanishCountryPrefix} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import CheckIcon from "mdi-react/CheckIcon";
import EmailIcon from "mdi-react/EmailIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import StarIcon from "mdi-react/StarIcon";
import React, {useCallback, useMemo} from "react";
import {IntlShape, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  account: {
    defaultMessage: "Kontonr.",
  },
  active: {defaultMessage: "Akt."},
  address: {
    defaultMessage: "Addresse",
  },
  alias: {
    defaultMessage: "Søgenavn",
  },
  attention: {
    defaultMessage: "Att.",
  },
  cellphone: {
    defaultMessage: "Mob.",
  },
  email: {
    defaultMessage: "E-mail",
  },
  favorite: {
    defaultMessage: "Fav.",
  },
  fuelSurcharge: {
    defaultMessage: "Brændstoftillæg",
  },
  lastFieldUpdate: {
    defaultMessage: "Markkort",
  },
  name: {
    defaultMessage: "Navn",
  },
  phone: {
    defaultMessage: "Tlf.",
  },
  withoutFuelSurcharge: {
    defaultMessage: "Uden brændstoftillæg",
  },
});

type CustomerTableFieldID =
  | "account"
  | "active"
  | "address"
  | "alias"
  | "attention"
  | "cellphone"
  | "email"
  | "favorite"
  | "fuelSurcharge"
  | "lastFieldUpdate"
  | "lastFieldUpdateRaw"
  | "name"
  | "phone"
  | "projectManagerName";

export type CustomerTableColumnID =
  | "account"
  | "active"
  | "address"
  | "alias"
  | "attention"
  | "cellphone"
  | "cellphoneButton"
  | "email"
  | "emailButton"
  | "favorite"
  | "fuelSurcharge"
  | "lastFieldUpdate"
  | "name"
  | "phone"
  | "phoneButton"
  | "projectManagerName";

export interface CustomerTableDataType
  extends RowData<CustomerTableFieldID, ContactUrl | CustomerUrl> {
  account: string;
  active: boolean;
  address: string;
  alias: string;
  attention: string;
  cellphone: string;
  contact?: boolean | undefined;
  email: string;
  favorite: boolean;
  lastFieldUpdate: string;
  lastFieldUpdateRaw: string;
  name: string;
  phone: string;
  projectManagerName: string | null;
  remoteUrl: string | null;
}

function renderFavorite(data: CustomerTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.favorite ? <StarIcon /> : <></>;
}

function renderCellphoneButton(data: CustomerTableDataType): JSX.Element {
  if (data.cellphone) {
    return (
      <IconLinkButton href={`tel:${addDanishCountryPrefix(data.cellphone)}`} Icon={CellphoneIcon} />
    );
  } else {
    return <IconLinkButton Icon={CellphoneIcon} />;
  }
}

function renderEmailButton(data: CustomerTableDataType): JSX.Element {
  if (data.email) {
    return <IconLinkButton href={`mailto:${data.email}`} Icon={EmailIcon} />;
  } else {
    return <IconLinkButton Icon={EmailIcon} />;
  }
}

function renderPhoneButton(data: CustomerTableDataType): JSX.Element {
  if (data.phone) {
    return <IconLinkButton href={`tel:${addDanishCountryPrefix(data.phone)}`} Icon={PhoneIcon} />;
  } else {
    return <IconLinkButton Icon={PhoneIcon} />;
  }
}

function renderActiveCheck(data: CustomerTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function compareAccounts(a: CustomerTableDataType, b: CustomerTableDataType): number {
  return identifierComparator(a.account, b.account);
}

export function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (customerOrContactURL: ContactUrl | CustomerUrl) => void,
): ColumnSpecifications<
  CustomerTableFieldID,
  CustomerTableColumnID,
  ContactUrl | CustomerUrl,
  CustomerTableDataType
> {
  const phoneNumberColumnWidth = 180;
  return {
    account: {
      comparator: compareAccounts,
      field: "account",
      label: formatMessage(messages.account),
      onClick,
      width: phoneNumberColumnWidth,
    },
    active: iconColumnSpecification({
      disableSorting: false,
      field: "active",
      label: formatMessage(messages.active),
      render: renderActiveCheck,
    }),
    address: {
      field: "address",
      label: formatMessage(messages.address),
      onClick,
    },
    alias: {
      field: "alias",
      label: formatMessage(messages.alias),
      onClick,
    },
    attention: {
      field: "attention",
      label: formatMessage(messages.attention),
      onClick,
    },
    cellphone: {
      field: "cellphone",
      label: formatMessage(messages.cellphone),
      onClick,
      width: phoneNumberColumnWidth,
    },
    cellphoneButton: iconButtonColumnSpecification({
      field: "cellphone",
      render: renderCellphoneButton,
    }),
    email: {
      field: "email",
      label: formatMessage(messages.email),
      onClick,
    },
    emailButton: iconButtonColumnSpecification({
      field: "email",
      render: renderEmailButton,
    }),
    favorite: iconColumnSpecification({
      disableSorting: false,
      field: "favorite",
      label: formatMessage(messages.favorite),
      onClick,
      render: renderFavorite,
    }),
    fuelSurcharge: {
      field: "fuelSurcharge",
      label: formatMessage(messages.fuelSurcharge),
      onClick,
    },
    lastFieldUpdate: {
      field: "lastFieldUpdate",
      label: formatMessage(messages.lastFieldUpdate),
      onClick,
      sortField: "lastFieldUpdateRaw",
    },
    name: {
      field: "name",
      label: formatMessage(messages.name),
      onClick,
    },
    phone: {
      field: "phone",
      label: formatMessage(messages.phone),
      onClick,
      width: phoneNumberColumnWidth,
    },
    phoneButton: iconButtonColumnSpecification({
      field: "phone",
      render: renderPhoneButton,
    }),
    projectManagerName: {
      field: "projectManagerName",
      label: "",
    },
  };
}

const isDefaultContact = (contact: Contact): boolean => contact.defaultContact;

interface BuildRowDataParams {
  contactArrayPerCustomer: ReadonlyMap<string, readonly Contact[]>;
  customerArray: readonly Customer[];
  displayCustomerContacts: boolean;
  fuelSurchargeSpecificationLookup: (
    url: KrPerLiterFuelSurchargeSpecificationUrl | PricePercentFuelSurchargeSpecificationUrl,
  ) => KrPerLiterFuelSurchargeSpecification | PricePercentFuelSurchargeSpecification | undefined;
  fuelSurchargeUses: readonly (
    | KrPerLiterDefaultFuelSurchargeUse
    | KrPerLiterMachineFuelSurchargeUse
    | KrPerLiterWorkTypeFuelSurchargeUse
    | PricePercentDefaultFuelSurchargeUse
    | PricePercentMachineFuelSurchargeUse
    | PricePercentWorkTypeFuelSurchargeUse
  )[];
  intl: IntlShape;
  locationArray: readonly Location[];
}
function buildRowData(params: BuildRowDataParams): readonly (CustomerTableDataType & {
  children: readonly CustomerTableDataType[] | undefined;
})[] {
  const {
    contactArrayPerCustomer,
    customerArray,
    displayCustomerContacts,
    fuelSurchargeSpecificationLookup,
    fuelSurchargeUses,
    intl,
    locationArray,
  } = params;

  const newestCustomerField = new Map<string, string>();
  for (const location of locationArray) {
    if (!location.active || !location.geojson || !location.customer || !location.fieldDataChanged) {
      continue;
    }
    const oldValue = newestCustomerField.get(location.customer);
    if (!oldValue || oldValue < location.fieldDataChanged) {
      newestCustomerField.set(location.customer, location.fieldDataChanged);
    }
  }

  const fuelSurchargesPerCustomer = new Map<string, Set<string>>();
  for (const fuelSurchargeUse of fuelSurchargeUses) {
    const {customer: customerUrl} = fuelSurchargeUse;
    if (!customerUrl) {
      continue;
    }
    const fuelSurchargeText = fuelSurchargeUse.fuelSurcharge
      ? fuelSurchargeSpecificationLookup(fuelSurchargeUse.fuelSurcharge)?.name ?? "?"
      : intl.formatMessage(messages.withoutFuelSurcharge);
    const foundFuelSurchargesForCustomer = fuelSurchargesPerCustomer.get(customerUrl);
    if (foundFuelSurchargesForCustomer) {
      foundFuelSurchargesForCustomer.add(fuelSurchargeText);
    } else {
      fuelSurchargesPerCustomer.set(customerUrl, new Set([fuelSurchargeText]));
    }
  }

  return customerArray.map((customer) => {
    const {
      alias,
      attention,
      c5_account: account,
      name,
      projectManagerName,
      remoteUrl,
      url: customerURL,
    } = customer;
    const address = customerAddress(customer);

    const contacts = contactArrayPerCustomer.get(customerURL);
    let defaultContact: Contact | undefined;
    let children: CustomerTableDataType[] | undefined;
    if (contacts) {
      if (displayCustomerContacts) {
        children = contacts.map((contact) => ({
          account: "",
          active: contact.active,
          address: "",
          alias: "",
          attention: "",
          cellphone: contact.cellphone,
          contact: true,
          email: contact.email,
          favorite: false,
          fuelSurcharge: "",
          key: contact.url,
          lastFieldUpdate: "",
          lastFieldUpdateRaw: "",
          name: `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${contact.name}`,
          phone: contact.phone,
          projectManagerName: "",
          remoteUrl: "",
        }));
      } else {
        defaultContact = contacts.find(isDefaultContact);
      }
    }
    const phone = customer.phone || defaultContact?.phone || "";
    const cellphone = customer.cellphone || defaultContact?.cellphone || "";
    const email = customer.billingEmail || defaultContact?.email || "";
    const lastFieldUpdate = newestCustomerField.get(customer.url);
    const foundFuelSurchargesForCustomer = fuelSurchargesPerCustomer.get(customerURL);
    const fuelSurcharge = foundFuelSurchargesForCustomer
      ? Array.from(foundFuelSurchargesForCustomer)
          .sort(caseAccentInsensitiveCollator.compare)
          .join(", ")
      : "";
    return {
      account: account || "",
      active: customer.active,
      address,
      alias,
      attention,
      cellphone,
      children,
      email,
      favorite: customer.favorite,
      fuelSurcharge,
      key: customerURL,
      lastFieldUpdate: formatDateTimeWithoutWeekday(lastFieldUpdate),
      lastFieldUpdateRaw: lastFieldUpdate || "",
      name,
      phone,
      projectManagerName,
      remoteUrl,
    };
  });
}

export function computeVisibleColumns(
  mobile: boolean,
  tablet: boolean,
  contactsMayHaveCellphone: boolean,
  enableCustomerCellphone: boolean,
  showAccountOnCustomerList: boolean,
  showAttOnCustomerList: boolean,
  showSearchnameOnCustomerList: boolean,
  customerFields: boolean,
  showInactive: boolean,
  fuelSurchargeEnabled: boolean,
): readonly CustomerTableColumnID[] {
  if (mobile) {
    return (
      [
        "name",
        "phoneButton",
        contactsMayHaveCellphone || enableCustomerCellphone ? "cellphoneButton" : undefined,
        "emailButton",
        showInactive ? "active" : undefined,
      ] as const
    ).filter(notUndefined);
  } else if (tablet) {
    return (
      [
        "favorite",
        "name",
        "address",
        showAccountOnCustomerList ? "account" : undefined,
        "phone",
        contactsMayHaveCellphone || enableCustomerCellphone ? "cellphone" : undefined,
        "phoneButton",
        contactsMayHaveCellphone || enableCustomerCellphone ? "cellphoneButton" : undefined,
        "emailButton",
        showInactive ? "active" : undefined,
      ] as const
    ).filter(notUndefined);
  } else {
    return (
      [
        "favorite",
        "name",
        showSearchnameOnCustomerList ? "alias" : undefined,
        "address",
        showAttOnCustomerList ? "attention" : undefined,
        showAccountOnCustomerList ? "account" : undefined,
        "phone",
        contactsMayHaveCellphone || enableCustomerCellphone ? "cellphone" : undefined,
        "email",
        "phoneButton",
        contactsMayHaveCellphone || enableCustomerCellphone ? "cellphoneButton" : undefined,
        "emailButton",
        customerFields ? "lastFieldUpdate" : undefined,
        fuelSurchargeEnabled ? "fuelSurcharge" : undefined,
        showInactive ? "active" : undefined,
      ] as const
    ).filter(notUndefined);
  }
}

interface CustomerTableProps {
  filterString: string;
  onClick: (customerOrContactURL: ContactUrl | CustomerUrl) => void;
  showInactive: boolean;
}

export const CustomerTable = React.memo(function CustomerTable(
  props: CustomerTableProps,
): JSX.Element {
  const {filterString, onClick, showInactive} = props;

  const intl = useIntl();

  const contactArrayPerCustomer = useSelector(getSortedActiveContactArrayPerCustomer);
  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const locationArray = useSelector(getLocationArray);
  const sortedActiveCustomerArray = useSelector(getSortedActiveCustomerArray);
  const sortedActiveAndInactiveCustomerArray = useSelector(getSortedActiveAndInactiveCustomerArray);

  const {
    contactsMayHaveCellphone,
    customerFields,
    displayCustomerContacts,
    enableCustomerCellphone,
    enableCustomerContacts,
    fuelSurcharge,
    showAccountOnCustomerList,
    showAttOnCustomerList,
    showSearchnameOnCustomerList,
  } = customerSettings;

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(intl.formatMessage, onClick),
    [intl.formatMessage, onClick],
  );

  const customerArray = useMemo(() => {
    if (!showInactive) {
      return sortedActiveCustomerArray;
    } else {
      return sortedActiveAndInactiveCustomerArray;
    }
  }, [showInactive, sortedActiveCustomerArray, sortedActiveAndInactiveCustomerArray]);

  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );
  const pricePercentMachineFuelSurchargeUseArray = useSelector(
    getPricePercentMachineFuelSurchargeUseArray,
  );
  const pricePercentWorkTypeFuelSurchargeUseArray = useSelector(
    getPricePercentWorkTypeFuelSurchargeUseArray,
  );
  const pricePercentDefaultFuelSurchargeUseArray = useSelector(
    getPricePercentDefaultFuelSurchargeUseArray,
  );

  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );
  const krPerLiterMachineFuelSurchargeUseArray = useSelector(
    getKrPerLiterMachineFuelSurchargeUseArray,
  );
  const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
    getKrPerLiterWorkTypeFuelSurchargeUseArray,
  );
  const krPerLiterDefaultFuelSurchargeUseArray = useSelector(
    getKrPerLiterDefaultFuelSurchargeUseArray,
  );

  const fuelSurchargeUses = useMemo((): (
    | KrPerLiterDefaultFuelSurchargeUse
    | KrPerLiterMachineFuelSurchargeUse
    | KrPerLiterWorkTypeFuelSurchargeUse
    | PricePercentDefaultFuelSurchargeUse
    | PricePercentMachineFuelSurchargeUse
    | PricePercentWorkTypeFuelSurchargeUse
  )[] => {
    if (fuelSurcharge === "PRICE_PERCENT") {
      const typedEmptyArray: (
        | PricePercentDefaultFuelSurchargeUse
        | PricePercentMachineFuelSurchargeUse
        | PricePercentWorkTypeFuelSurchargeUse
      )[] = [];
      return typedEmptyArray.concat(
        pricePercentMachineFuelSurchargeUseArray,
        pricePercentWorkTypeFuelSurchargeUseArray,
        pricePercentDefaultFuelSurchargeUseArray,
      );
    } else if (fuelSurcharge === "KR_PER_LITER") {
      const typedEmptyArray: (
        | KrPerLiterDefaultFuelSurchargeUse
        | KrPerLiterMachineFuelSurchargeUse
        | KrPerLiterWorkTypeFuelSurchargeUse
      )[] = [];
      return typedEmptyArray.concat(
        krPerLiterMachineFuelSurchargeUseArray,
        krPerLiterWorkTypeFuelSurchargeUseArray,
        krPerLiterDefaultFuelSurchargeUseArray,
      );
    } else {
      return [];
    }
  }, [
    fuelSurcharge,
    krPerLiterDefaultFuelSurchargeUseArray,
    krPerLiterMachineFuelSurchargeUseArray,
    krPerLiterWorkTypeFuelSurchargeUseArray,
    pricePercentDefaultFuelSurchargeUseArray,
    pricePercentMachineFuelSurchargeUseArray,
    pricePercentWorkTypeFuelSurchargeUseArray,
  ]);

  const fuelSurchargeSpecificationLookup = useMemo((): ((
    url: KrPerLiterFuelSurchargeSpecificationUrl | PricePercentFuelSurchargeSpecificationUrl,
  ) =>
    | KrPerLiterFuelSurchargeSpecification
    | PricePercentFuelSurchargeSpecification
    | undefined) => {
    if (fuelSurcharge === "PRICE_PERCENT") {
      return pricePercentFuelSurchargeSpecificationLookup as (
        url: KrPerLiterFuelSurchargeSpecificationUrl | PricePercentFuelSurchargeSpecificationUrl,
      ) => PricePercentFuelSurchargeSpecification | undefined;
    } else if (fuelSurcharge === "KR_PER_LITER") {
      return krPerLiterFuelSurchargeSpecificationLookup as (
        url: KrPerLiterFuelSurchargeSpecificationUrl | PricePercentFuelSurchargeSpecificationUrl,
      ) => KrPerLiterFuelSurchargeSpecification | undefined;
    } else {
      return _.constant(undefined);
    }
  }, [
    fuelSurcharge,
    krPerLiterFuelSurchargeSpecificationLookup,
    pricePercentFuelSurchargeSpecificationLookup,
  ]);

  const entries = useMemo(() => {
    return buildRowData({
      contactArrayPerCustomer,
      customerArray,
      displayCustomerContacts: enableCustomerContacts && displayCustomerContacts,
      fuelSurchargeSpecificationLookup,
      fuelSurchargeUses,
      intl,
      locationArray,
    });
  }, [
    contactArrayPerCustomer,
    customerArray,
    enableCustomerContacts,
    displayCustomerContacts,
    fuelSurchargeSpecificationLookup,
    fuelSurchargeUses,
    intl,
    locationArray,
  ]);
  const visibleColumns = useMemo(
    () =>
      computeVisibleColumns(
        !!bowser.mobile,
        !!bowser.tablet,
        enableCustomerContacts && contactsMayHaveCellphone,
        enableCustomerCellphone,
        showAccountOnCustomerList,
        showAttOnCustomerList,
        showSearchnameOnCustomerList,
        customerFields,
        showInactive,
        !!fuelSurcharge,
      ),
    [
      contactsMayHaveCellphone,
      customerFields,
      enableCustomerCellphone,
      enableCustomerContacts,
      fuelSurcharge,
      showAccountOnCustomerList,
      showAttOnCustomerList,
      showInactive,
      showSearchnameOnCustomerList,
    ],
  );

  const getRowStyle = useCallback(
    (data: CustomerTableDataType) => {
      if (
        customerSettings.economicSync &&
        currentRole?.manager &&
        !data.remoteUrl &&
        !data.contact
      ) {
        return {color: "red"};
      }
      return {};
    },
    [currentRole?.manager, customerSettings.economicSync],
  );

  const filteringData = useMemo(() => ({filterString, showInactive}), [filterString, showInactive]);

  return (
    <ConnectedTableWithPagination
      columns={columnSpecifications}
      defaultRowsPerPage={PaginationPageSize.MEDIUM}
      defaultSortDirection="ASC"
      defaultSortKey="name"
      entries={entries}
      filteringData={filteringData}
      filterString={filterString}
      rowStyle={getRowStyle}
      savePaginationIdentifier="CustomerTable"
      saveSortingIdentifier="CustomerTableAll"
      visibleColumns={visibleColumns}
    />
  );
});
