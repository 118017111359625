import {urlToId} from "@co-common-libs/resources";
import {TaskArchiveData} from "@co-common-libs/resources-utils";
import {DateField, SortDirection, TrimTextField} from "@co-frontend-libs/components";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {PaginationPageSize, TableDefaults, useTableWithPagination} from "app-components";
import {
  useBooleanQueryParameterState,
  useFetchGet,
  useNullableQueryParameterState,
  useQueryParameterState,
} from "app-utils";
import bowser from "bowser";
import clsx from "clsx";
import {resourceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {TaskTableColumnID} from "../task-table";
import {ArchiveTaskTable} from "./archive-task-table";

const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    mobilePadding: {padding: "1em 11px 1em 11px"},
    pcPadding: {padding: "1em"},
  });
});

const mobile = bowser.tablet || bowser.mobile;

function getSortOption(
  sortDirection: "ASC" | "DESC",
  sortKey: TaskTableColumnID,
  enableTaskReferenceNumber: boolean,
): string[] {
  if (sortKey) {
    const desc = sortDirection === "DESC";
    switch (sortKey as TaskTableColumnID) {
      case "created":
      case "createdDate":
      case "createdDateShort":
      case "createdShort":
        return [`${desc ? "-" : ""}version_birth_date`];
      case "createdBy":
      case "createdByShort":
        return [`${desc ? "-" : ""}created_by__login_identifier`];
      case "culture":
        return [`${desc ? "-" : ""}order__culture__name`];
      case "cultureMachine":
        return [
          `${desc ? "-" : ""}order__culture__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
        ];
      case "customer":
        return [`${desc ? "-" : ""}order__customer__name`];
      case "customerCultureMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}order__culture__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
        ];
      case "customerMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
        ];
      case "customerRoute":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}order__route_plan__name`,
        ];
      case "customerRouteCultureMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}order__culture__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
          `${desc ? "-" : ""}order__route_plan__name`,
        ];
      case "customerRouteMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
          `${desc ? "-" : ""}order__route_plan__name`,
        ];
      case "date":
      case "dateShort":
        return [`${desc ? "-" : ""}_combined_work_from_timestamp_and_date`];
      case "deliveryLocation":
        return [`${desc ? "-" : ""}workplace__name`];
      case "department":
        return [`${desc ? "-" : ""}department`, "-date", "-time"];
      case "fields":
        return [`${desc ? "-" : ""}fielduse__related_field__name`];
      case "invoiceNote":
        return [`${desc ? "-" : ""}invoice_note`];
      case "machineOperator":
      case "machineOperatorShort":
        return [`${desc ? "-" : ""}machine_operator__initials`];
      case "machines":
        return [`${desc ? "-" : ""}machineuse__machine__name`];
      case "managerInternalNotes":
        return [`${desc ? "-" : ""}manager_internal_notes`];
      case "notes":
        return [
          `${desc ? "-" : ""}notes_from_manager`,
          `${desc ? "-" : ""}notes_from_machine_operator`,
        ];
      case "pickupLocation":
        return [`${desc ? "-" : ""}pickup_location__name`];
      case "priceGroup":
        return [`${desc ? "-" : ""}price_group__name`];
      case "project":
      case "projectWithAlias":
        return [
          `${desc ? "-" : ""}project__project_number`,
          `${desc ? "-" : ""}project__name`,
          `${desc ? "-" : ""}project__alias`,
        ];
      case "referenceNumber":
        return enableTaskReferenceNumber
          ? [`${desc ? "-" : ""}reference_number`]
          : [`${desc ? "-" : ""}order__reference_number`];

      case "machine":
      case "machineShort":
        return [`${desc ? "-" : ""}machineuse__machine__c5_machine`];
      case "time":
      case "timeShort":
        return [`${desc ? "-" : ""}time`];
      case "workType":
      case "workTypeShort":
        return [`${desc ? "-" : ""}work_type__name`];
      default:
        // eslint-disable-next-line no-console
        console.warn(`specific backend sort not specified for key: ${sortKey}`);
        return [`${desc ? "-" : ""}created`];
    }
  } else {
    return ["-date", "-time"];
  }
}

type SortingState = {
  readonly sortDirection: SortDirection;
  readonly sortKey: TaskTableColumnID | null;
};

const taskTableDefaults: TableDefaults<TaskTableColumnID> = {
  defaultRowsPerPage: PaginationPageSize.SMALL,
  defaultSortDirection: "ASC",
  defaultSortKey: "status",
};

const tableSaveIdentifier = "TaskArchiveTable";

export function TaskArchive({onDataLoaded}: {onDataLoaded: () => void}): JSX.Element {
  const classes = useStyles();

  const intl = useIntl();

  const [fromDate, setFromDate] = useNullableQueryParameterState("archiveFromDate", null);
  const [toDate, setToDate] = useNullableQueryParameterState("archiveToDate", null);

  const [textSearch, setTextSearch] = useQueryParameterState<string>("archiveSearch", "");

  const [searchPerformedWithCurrentCriteria, setSearchPerformedWithCurrentCriteria] =
    useBooleanQueryParameterState("searchPerformedWithCurrentCriteria");

  const [fetchData, fetchDataCancel, data, fetching, fetchError] = useFetchGet<{
    data: TaskArchiveData[];
    total: number;
  }>();

  const [page, rowsPerPage, sortBy, sortDirection, setPage, setRowsPerPage, toggleSortingKey] =
    useTableWithPagination(
      tableSaveIdentifier,
      tableSaveIdentifier,
      taskTableDefaults.defaultSortKey,
      taskTableDefaults.defaultSortDirection,
      taskTableDefaults.defaultRowsPerPage,
      null,
    );

  const sortingState: SortingState = useMemo(() => {
    return {sortDirection, sortKey: sortBy || null};
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (data) {
      setSearchPerformedWithCurrentCriteria(true);
      onDataLoaded();
    }
  }, [data, onDataLoaded, setSearchPerformedWithCurrentCriteria]);

  const customerSettings = useSelector(getCustomerSettings);

  const {enableTaskReferenceNumber} = customerSettings;

  useEffect(() => {
    fetchDataCancel();
    setSearchPerformedWithCurrentCriteria(false);
  }, [fromDate, toDate, textSearch, fetchDataCancel, setSearchPerformedWithCurrentCriteria]);

  const dispatch = useDispatch();

  const doFetch = useCallback(
    (
      fetchFromDate: string | null,
      fetchToDate: string | null,
      fetchSortingState: {
        readonly sortDirection: "ASC" | "DESC";
        readonly sortKey: string | null;
      },
      fetchPage: number,
      fetchRowsPerPage: number,
      fetchTextSearch: string,
    ) => {
      if (!fetchFromDate || !fetchToDate || fetchFromDate <= fetchToDate) {
        const taskListURL = resourceURL("task");
        const sortOptions = getSortOption(
          fetchSortingState.sortDirection,
          fetchSortingState.sortKey as TaskTableColumnID,
          enableTaskReferenceNumber,
        );
        const params: string[] = [
          `limit=${fetchRowsPerPage}`,
          `offset=${fetchPage * fetchRowsPerPage}`,
          ...sortOptions.map((sortOption) => `sortBy[]=${encodeURIComponent(sortOption)}`),
        ];
        if (fetchFromDate) {
          params.push(`archiveFromDate=${fetchFromDate}`);
        }
        if (fetchToDate) {
          params.push(`archiveToDate=${fetchToDate}`);
        }
        if (fetchTextSearch) {
          params.push(`search=${encodeURIComponent(fetchTextSearch)}`);
        }
        const taskArchiveSearchURL = `${taskListURL}archive_search/?${params.join("&")}`;
        fetchData(taskArchiveSearchURL);
      }
    },
    [enableTaskReferenceNumber, fetchData],
  );

  const handleFetchClick = useCallback(() => {
    doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch);
  }, [doFetch, fromDate, toDate, sortingState, page, rowsPerPage, textSearch]);

  const handleSubmitOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleFetchClick();
      }
    },
    [handleFetchClick],
  );

  useEffect(() => {
    if (searchPerformedWithCurrentCriteria) {
      handleFetchClick();
    }
    // trigger on deep linking and table header click
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingState]);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage);
      doFetch(fromDate, toDate, sortingState, newPage, rowsPerPage, textSearch);
    },
    [doFetch, fromDate, rowsPerPage, setPage, sortingState, textSearch, toDate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const rows = parseInt(event.target.value);
      setPage(0);
      setRowsPerPage(rows);
      doFetch(fromDate, toDate, sortingState, 0, rows, textSearch);
    },
    [doFetch, fromDate, setPage, setRowsPerPage, sortingState, textSearch, toDate],
  );

  const handleTaskTableClick = useCallback(
    (taskURL: string): void => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}));
    },
    [dispatch],
  );

  const handleTaskTablePhotoClick = useCallback(
    (taskURL: string): void => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}, {tab: "photos"}));
    },
    [dispatch],
  );

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate, setToDate, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          setFromDate(date);
          setToDate(date);
        } else {
          setFromDate(date);
        }
      }
    },
    [setFromDate, setToDate, toDate],
  );

  const theme = useTheme();

  let errorText: string | undefined;

  if (fetchError) {
    if (fetchError instanceof NetworkError) {
      errorText = intl.formatMessage({
        defaultMessage: "Kunne ikke kontakte server",
      });
    } else if (fetchError instanceof StatusError) {
      errorText = intl.formatMessage({defaultMessage: "Serverfejl"});
    } else {
      errorText = intl.formatMessage({defaultMessage: "Ukendt fejl"});
    }
  }

  return (
    <div
      className={clsx({
        [classes.mobilePadding]: mobile,
        [classes.pcPadding]: !mobile,
      })}
    >
      <Card>
        <CardContent>
          <FormattedMessage defaultMessage="Periode" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                autoFocus={!bowser.ios}
                label={intl.formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                referenceDate={toDate || undefined}
                value={fromDate || null}
                yearSuggestions="DATE_BEFORE"
                onBlur={handleFromDateBlur}
                onChange={setFromDate}
                onKeyDown={handleSubmitOnEnter}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Til"})}
                margin="dense"
                referenceDate={fromDate || undefined}
                value={toDate || null}
                yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
                onChange={setToDate}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
            <Grid item xs={12}>
              <TrimTextField
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fritekst"})}
                margin="dense"
                value={textSearch}
                variant="outlined"
                onChange={setTextSearch}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={(fromDate && toDate && fromDate > toDate) || fetching}
            variant="contained"
            onClick={handleFetchClick}
          >
            <FormattedMessage defaultMessage="Søg" />
          </Button>
        </CardActions>
      </Card>
      {fetching ? (
        <div style={{marginTop: "1em", textAlign: "center"}}>
          <CircularProgress />
        </div>
      ) : null}
      {data ? (
        <div style={{marginTop: "1em"}}>
          <ArchiveTaskTable
            count={data.total}
            data={data.data}
            page={page}
            rowsPerPage={rowsPerPage}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onClick={handleTaskTableClick}
            onHeaderClick={toggleSortingKey}
            onPageChange={handleChangePage}
            onPhotoClick={handleTaskTablePhotoClick}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
      {errorText ? (
        <Card style={{marginTop: "1em"}}>
          <CardContent>
            <div style={{color: theme.palette.error.main}}>{errorText}</div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}
