import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  KrPerLiterFuelSurchargeSpecificationUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PriceGroup,
  PriceGroupUrl,
  PricePercentFuelSurchargeSpecificationUrl,
  Project,
  ProjectUrl,
  RouteTask,
  Task,
  TaskPhoto,
  TaskUrl,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  getMachineString,
  getProjectString,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {
  dateToString,
  formatDateNumeric,
  notNullOrUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  RowData,
  iconButtonColumnSpecification,
} from "@co-frontend-libs/components";
import {
  actions,
  getCommitQueue,
  getCustomerSettings,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import {SetAction} from "@co-frontend-libs/utils";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  SvgIcon,
  TableContainer,
  useTheme,
} from "@material-ui/core";
import {GoToPathButton} from "app-components";
import {
  getReferenceNumberLabel,
  getWorkplaceString,
  useEventTargetCheckedCallback,
} from "app-utils";
import _ from "lodash";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import ClipboardTextIcon from "mdi-react/ClipboardTextIcon";
import CloudUploadIcon from "mdi-react/CloudUploadIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import ImageIcon from "mdi-react/ImageIcon";
import PencilIcon from "mdi-react/PencilIcon";
import TagTextOutlineIcon from "mdi-react/TagTextOutlineIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {TaskFuelSurchargeIssuesDialog} from "../issues/task-fuel-surcharge-issues-dialog";
import {EconomicIssue} from "../issues/types";

const MAX_TASKS_DISPLAYED = 5000;
const TABLE_SORTING_IDENTIFIER = "TaskTable";

type TaskTableColumnID =
  | "bookeepingAction"
  | "customer"
  | "date"
  | "delivery"
  | "endDate"
  | "fields"
  | "fuelSurchargeError"
  | "hasEconomicIssues"
  | "invoiceNote"
  | "machineOperator"
  | "machines"
  | "notes"
  | "orderAction"
  | "photo"
  | "pickup"
  | "priceGroup"
  | "project"
  | "refNumber"
  | "select"
  | "taskAction"
  | "workPlace"
  | "workType";

type TaskTableFieldID =
  | "customer"
  | "date"
  | "delivery"
  | "endDate"
  | "fields"
  | "hasEconomicIssues"
  | "hasFuelSurchargeIssues"
  | "invoiceNote"
  | "key"
  | "machineOperator"
  | "machineOperatorURL"
  | "machines"
  | "notes"
  | "order"
  | "photo"
  | "pickup"
  | "priceGroup"
  | "project"
  | "rawDate"
  | "refNumber"
  | "selected"
  | "selectionDisabled"
  | "workType";

export interface TaskTableDataType extends RowData<TaskTableFieldID, TaskUrl> {
  customer: string;
  date: string;
  delivery: string;
  fields: string;
  hasEconomicIssues: boolean;
  hasFuelSurchargeIssues: boolean;
  invoiceNote: string;
  machineOperator: string;
  machineOperatorURL: string | null;
  machines: string;
  notes: string;
  order: string;
  photo: boolean;
  pickup: string;
  priceGroup: string;
  project: string;
  rawDate: string;
  refNumber: string;
  selected: boolean;
  selectionDisabled: boolean;
  workType: string;
}

interface TaskSelectedCheckboxProps {
  disabled: boolean;
  onChange: (url: TaskUrl, checked: boolean) => void;
  selected: boolean;
  url: TaskUrl;
}

const TaskSelectedCheckbox = function TaskSelectedCheckbox(
  props: TaskSelectedCheckboxProps,
): JSX.Element {
  const {disabled, onChange, selected, url} = props;
  const handleChange = useEventTargetCheckedCallback(
    (checked) => {
      onChange(url, checked);
    },
    [onChange, url],
  );
  return <Checkbox checked={selected} disabled={disabled} onChange={handleChange} />;
};

function renderSelect(
  onChange: (url: TaskUrl, checked: boolean) => void,
  data: TaskTableDataType,
): JSX.Element {
  const {hasEconomicIssues, hasFuelSurchargeIssues, key, selected, selectionDisabled} = data;
  return (
    <TaskSelectedCheckbox
      disabled={selectionDisabled || hasFuelSurchargeIssues || hasEconomicIssues}
      selected={selected}
      url={key}
      onChange={onChange}
    />
  );
}

function renderTaskEditButton(data: TaskTableDataType): JSX.Element {
  return (
    <GoToPathButton
      parameters={{id: urlToId(data.order), taskID: urlToId(data.key)}}
      path="/order/:id/:taskID"
    >
      <PencilIcon />
    </GoToPathButton>
  );
}

function renderOrderButton(data: TaskTableDataType): JSX.Element {
  return (
    <GoToPathButton parameters={{id: urlToId(data.order)}} path="/orderEntry/:id">
      <TagTextOutlineIcon />
    </GoToPathButton>
  );
}

function renderBookeepingButton(data: TaskTableDataType): JSX.Element {
  const date = dateToString(new Date(data.rawDate));

  return (
    <GoToPathButton
      disabled={!data.machineOperatorURL || !data.rawDate}
      parameters={{
        date,
        machineOperatorId: data.machineOperatorURL ? urlToId(data.machineOperatorURL) : "",
      }}
      path="/bookkeepingDay/:date/:machineOperatorId"
    >
      <ClipboardTextIcon />
    </GoToPathButton>
  );
}

const ErrorIconButton = ({id}: {id: string}): JSX.Element => {
  const theme = useTheme();
  return (
    <GoToPathButton parameters={{id}} path="/task/:id">
      <AlertCircleIcon color={theme.palette.error.main} />
    </GoToPathButton>
  );
};

function renderErrorIcon(data: TaskTableDataType): JSX.Element | null {
  if (!data.hasEconomicIssues) {
    return null;
  }
  return <ErrorIconButton id={urlToId(data.key)} />;
}

function renderPhotoButton(
  onPhotoClick: (taskURL: TaskUrl) => void,
): (data: TaskTableDataType) => JSX.Element {
  // eslint-disable-next-line react/display-name
  return (data) => {
    return data.photo ? (
      <IconButton
        color="primary"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          onPhotoClick(data.key);
        }}
      >
        <ImageIcon />
      </IconButton>
    ) : (
      <span />
    );
  };
}

interface FuelSurchargeErrorIconButtonProps {
  onClick: (taskUrl: TaskUrl) => void;
  taskUrl: TaskUrl;
}

function FuelSurchargeErrorIconButton(props: FuelSurchargeErrorIconButtonProps): JSX.Element {
  const {onClick, taskUrl} = props;
  const theme = useTheme();
  const handleClick = useCallback((): void => {
    onClick(taskUrl);
  }, [onClick, taskUrl]);
  return (
    <IconButton onClick={handleClick}>
      <GasStationIcon color={theme.palette.error.main} />
    </IconButton>
  );
}

function renderFuelSurchargeErrorIcon(
  onClick: (taskUrl: TaskUrl) => void,
  data: TaskTableDataType,
): JSX.Element | null {
  if (!data.hasFuelSurchargeIssues) {
    return null;
  }
  return <FuelSurchargeErrorIconButton taskUrl={data.key} onClick={onClick} />;
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  enableOrderReferenceNumber: boolean,
  enableTaskReferenceNumber: boolean,
  orderReferenceNumberLabel: string | null,
  taskReferenceNumberLabel: string | null,
  onClick: (taskURL: TaskUrl) => void,
  onPhotoClick: (taskURL: TaskUrl) => void,
  onFuelSurchargeErrorClick: (taskURL: TaskUrl) => void,
  machineLabelVariant: Config["machineLabelVariant"],
  onToRecordChange: (url: TaskUrl, checked: boolean) => void,
  projectLabelVariant: Config["projectLabelVariant"],
): ColumnSpecifications<TaskTableFieldID, TaskTableColumnID, TaskUrl, TaskTableDataType> {
  return {
    bookeepingAction: iconButtonColumnSpecification({
      disableSorting: true,
      field: "key",
      render: renderBookeepingButton,
      width: 60,
    }),
    customer: {
      field: "customer",
      label: <FormattedMessage defaultMessage="Kunde" id="Task-list.table-header.customer" />,
      onClick,
    },
    date: {
      field: "date",
      label: <FormattedMessage defaultMessage="Dato" id="Task-list.table-header.date" />,
      onClick,
      sortField: "rawDate",
    },
    delivery: {
      field: "delivery",
      label: <FormattedMessage defaultMessage="Levering" />,
      onClick,
    },
    endDate: {
      field: "endDate",
      label: <FormattedMessage defaultMessage="Slut dato" />,
      onClick,
    },
    fields: {
      field: "fields",
      label: (
        <FormattedMessage defaultMessage="Mark. nr." id="Task-list.table-header.field-number" />
      ),
      onClick,
    },
    fuelSurchargeError: iconButtonColumnSpecification({
      field: "hasFuelSurchargeIssues",
      render: renderFuelSurchargeErrorIcon.bind(null, onFuelSurchargeErrorClick),
    }),
    hasEconomicIssues: iconButtonColumnSpecification({
      field: "hasEconomicIssues",
      render: renderErrorIcon,
    }),
    invoiceNote: {
      field: "invoiceNote",
      label: <FormattedMessage defaultMessage="Fak. noter" />,
      onClick,
    },
    machineOperator: {
      field: "machineOperator",
      label: (
        <FormattedMessage defaultMessage="Medarb." id="Task-list.table-header.machineOperator" />
      ),
      onClick,
    },
    machines: {
      field: "machines",
      label:
        machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage={"Maskiner"} />
        ) : (
          <FormattedMessage defaultMessage={"Køretøjer"} />
        ),
      onClick,
    },
    notes: {
      field: "notes",
      label: <FormattedMessage defaultMessage="Noter" id="Task-list.table-header.notes" />,
      onClick,
    },
    orderAction: iconButtonColumnSpecification({
      disableSorting: true,
      field: "key",
      render: renderOrderButton,
      width: 60,
    }),
    photo: iconButtonColumnSpecification({
      disableSorting: false,
      field: "photo",
      render: renderPhotoButton(onPhotoClick),
    }),
    pickup: {
      field: "pickup",
      label: <FormattedMessage defaultMessage="Afhentning" id="Task-list.table-header.pickup" />,
      onClick,
    },
    priceGroup: {
      field: "priceGroup",
      label: <FormattedMessage defaultMessage="Variant" id="Task-list.table-header.price-proup" />,
    },
    project: {
      field: "project",
      label:
        projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Projekt" />
        ) : (
          <FormattedMessage defaultMessage="Sag" />
        ),
      onClick,
    },
    refNumber: {
      field: "refNumber",
      label: getReferenceNumberLabel(
        enableTaskReferenceNumber,
        taskReferenceNumberLabel,
        enableOrderReferenceNumber,
        orderReferenceNumberLabel,
        formatMessage,
      ),
      onClick,
    },
    select: {
      field: "selected",
      label: "",
      render: renderSelect.bind(null, onToRecordChange),
    },
    taskAction: iconButtonColumnSpecification({
      disableSorting: true,
      field: "key",
      render: renderTaskEditButton,
      width: 60,
    }),
    workPlace: {
      field: "delivery",
      label: <FormattedMessage defaultMessage="Arbejdssted" />,
      onClick,
    },
    workType: {
      field: "workType",
      label: <FormattedMessage defaultMessage="Område" id="Task-list.table-header.work-type" />,
      onClick,
    },
  };
}

export function buildRowData(
  taskArray: readonly Task[],
  routeTaskArray: readonly RouteTask[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  orderLookup: (url: OrderUrl) => Order | undefined,
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
  projectLookup: (url: ProjectUrl) => Project | undefined,
  uploading: boolean,
  taskPhotoArray: readonly TaskPhoto[],
  economicTasksErrors: ReadonlyMap<TaskUrl, readonly EconomicIssue[]>,
  toRecord: ReadonlySet<TaskUrl>,
): (Omit<TaskTableDataType, "hasFuelSurchargeIssues"> & {task: Task})[] {
  const tasksWithPhotos = new Set(taskPhotoArray.map((instance) => instance.task));

  return taskArray.map((task) => {
    const economicTaskErrors = economicTasksErrors.get(task.url);

    let pickupLocation = getWorkplaceString(task.relatedPickupLocation, locationLookup);
    let workplace = getWorkplaceString(task.relatedWorkplace, locationLookup);

    const pickupLocations = task.reportingLocations
      ? sortByOrderMember(Object.values(task.reportingLocations)).filter((l) => l.type === "pickup")
      : undefined;
    const pickupLocationNames = pickupLocations?.map((entry) => {
      const location = entry.location ? locationLookup(entry.location) : null;
      return location ? location.name || location.address : null;
    });
    if (pickupLocationNames?.length) {
      pickupLocation = [pickupLocation, ...pickupLocationNames].filter(Boolean).join(", ");
    }

    const deliveryLocations = task.reportingLocations
      ? sortByOrderMember(Object.values(task.reportingLocations)).filter(
          (l) => l.type === "delivery",
        )
      : undefined;
    const deliveryLocationNames = deliveryLocations?.map((entry) => {
      const location = entry.location ? locationLookup(entry.location) : null;
      return location ? location.name || location.address : null;
    });
    if (deliveryLocationNames?.length) {
      workplace = [workplace, ...deliveryLocationNames].filter(Boolean).join(", ");
    }

    const order = task.order ? orderLookup(task.order) : null;
    const workType = task.workType ? workTypeLookup(task.workType) : null;

    let fields = "";
    const fieldUseSet = task.fielduseSet;
    fields = fieldUseSet
      ? fieldUseSet
          .map((fieldUse) => {
            const field = locationLookup(fieldUse.relatedField);
            return field ? field.name : "";
          })
          .sort()
          .join(", ")
      : "";

    let customer = "";
    if (order?.routePlan) {
      const routeTaskList = routeTaskArray.filter((routeTask) => routeTask.route === task.url);
      const completedRouteTaskList = _.sortBy(
        routeTaskList.filter((routeTask) => routeTask.completed),
        (routeTask) => routeTask.completed,
      );
      const customers = completedRouteTaskList
        .map((routeTask) => (routeTask.customer ? customerLookup(routeTask.customer) : null))
        .filter(notNullOrUndefined);
      customer = customers.map((c) => c.name).join(", ");
    } else {
      const customerInstance = order?.customer ? customerLookup(order.customer) : null;
      customer = customerInstance?.name || "";
    }

    let project = "";
    if (task.project) {
      const projectInstance = projectLookup(task.project);
      project = getProjectString(projectInstance);
    }

    const photo = tasksWithPhotos.has(task.url);

    return {
      customer,
      date: formatDateNumeric(task.workFromTimestamp || ""),
      delivery: workplace,
      endDate: formatDateNumeric(task.workToTimestamp),
      fields,
      hasEconomicIssues: !!economicTaskErrors,
      invoiceNote: task.invoiceNote,
      key: task.url,
      machineOperator:
        (task.machineOperator && userUserProfileLookup(task.machineOperator)?.alias) || "",
      machineOperatorURL: task.machineOperator,
      machines: task.machineuseSet
        .map((machineUse) => {
          const machine = machineUse.machine ? machineLookup(machineUse.machine) : undefined;
          return getMachineString(machine);
        })
        .join(", "),
      notes: `${task.notesFromMachineOperator ? `M: ${task.notesFromMachineOperator}\n` : ""}${
        task.notesFromManager ? `A: ${task.notesFromManager}` : ""
      }`.trim(),
      order: task.order || "",
      photo,
      pickup: pickupLocation,
      priceGroup: task.priceGroup ? priceGroupLookup(task.priceGroup)?.name || "" : "",
      project,
      rawDate: task.workFromTimestamp || "",
      refNumber: task.referenceNumber || order?.referenceNumber || "",
      selected: toRecord.has(task.url),
      selectionDisabled: uploading,
      task,
      workType: workType ? getWorkTypeString(workType) : "",
    };
  });
}

interface TaskTableProps {
  dispatchToRecord: (value: SetAction<TaskUrl>) => void;
  filteredRowData: readonly (TaskTableDataType & {task: Task})[];
  krPerLiterTaskProblemSpecifications: ReadonlyMap<
    TaskUrl,
    ReadonlySet<KrPerLiterFuelSurchargeSpecificationUrl>
  > | null;
  onUploadToEconomic: () => void;
  previousUploadEncounteredError: boolean;
  pricePercentTaskProblemSpecifications: ReadonlyMap<
    TaskUrl,
    ReadonlySet<PricePercentFuelSurchargeSpecificationUrl>
  > | null;
  taskProblemMachines: ReadonlyMap<TaskUrl, ReadonlySet<MachineUrl>> | null;
  tasksToTransferCount: number;
  uploading: boolean;
  uploadProgress: number;
}

export function EconomicTaskTable(props: TaskTableProps): JSX.Element {
  const {
    dispatchToRecord,
    filteredRowData,
    krPerLiterTaskProblemSpecifications,
    onUploadToEconomic,
    previousUploadEncounteredError,
    pricePercentTaskProblemSpecifications,
    taskProblemMachines,
    tasksToTransferCount,
    uploading,
    uploadProgress,
  } = props;
  const customerSettings = useSelector(getCustomerSettings);
  const {
    economicSyncListColumns,
    enableOrderReferenceNumber,
    enableTaskReferenceNumber,
    fuelSurcharge,
    machineLabelVariant,
    orderReferenceNumberLabel,
    projectLabelVariant,
    taskReferenceNumberLabel,
  } = customerSettings;

  const [fuelSurchargeIssueDialogOpen, setFuelSurchargeIssueDialogOpen] = useState(false);

  const handleFuelSurchargeIssueDialogClose = useCallback((): void => {
    setFuelSurchargeIssueDialogOpen(false);
  }, []);

  const [fuelSurchargeIssueDialogData, setFuelSurchargeIssueDialogData] = useState<{
    readonly krPerLiterProblemSpecifications: ReadonlySet<KrPerLiterFuelSurchargeSpecificationUrl> | null;
    readonly pricePercentProblemSpecifications: ReadonlySet<PricePercentFuelSurchargeSpecificationUrl> | null;
    readonly problemMachines: ReadonlySet<MachineUrl> | null;
  } | null>(null);

  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const handleClick = useCallback(
    (taskURL: string) => {
      dispatch(actions.go("/task/:id", {id: urlToId(taskURL)}));
    },
    [dispatch],
  );
  const handlePhotoClick = useCallback(
    (taskURL: string) => {
      dispatch(actions.go("/task/:id", {id: urlToId(taskURL)}, {tab: "photos"}));
    },
    [dispatch],
  );
  const handleFuelSurchargeErrorClick = useCallback(
    (taskUrl: TaskUrl): void => {
      setFuelSurchargeIssueDialogOpen(true);
      const krPerLiterProblemSpecifications =
        krPerLiterTaskProblemSpecifications?.get(taskUrl) || null;
      const pricePercentProblemSpecifications =
        pricePercentTaskProblemSpecifications?.get(taskUrl) || null;
      const problemMachines = taskProblemMachines?.get(taskUrl) || null;
      setFuelSurchargeIssueDialogData({
        krPerLiterProblemSpecifications,
        pricePercentProblemSpecifications,
        problemMachines,
      });
    },
    [
      krPerLiterTaskProblemSpecifications,
      pricePercentTaskProblemSpecifications,
      taskProblemMachines,
    ],
  );
  const handleToRecordChange = useCallback(
    (url: TaskUrl, checked: boolean) => {
      if (checked) {
        dispatchToRecord({type: "addOne", value: url});
      } else {
        dispatchToRecord({type: "removeOne", value: url});
      }
    },
    [dispatchToRecord],
  );

  const columnSpecifications = useMemo(
    () =>
      buildColumnSpecifications(
        formatMessage,
        enableOrderReferenceNumber,
        enableTaskReferenceNumber,
        orderReferenceNumberLabel,
        taskReferenceNumberLabel,
        handleClick,
        handlePhotoClick,
        handleFuelSurchargeErrorClick,
        machineLabelVariant,
        handleToRecordChange,
        projectLabelVariant,
      ),
    [
      formatMessage,
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      orderReferenceNumberLabel,
      taskReferenceNumberLabel,
      handleClick,
      handlePhotoClick,
      handleFuelSurchargeErrorClick,
      machineLabelVariant,
      handleToRecordChange,
      projectLabelVariant,
    ],
  );

  const visibleColumns = useMemo(
    () =>
      fuelSurcharge
        ? ([
            "select",
            "hasEconomicIssues",
            "fuelSurchargeError",
            ...economicSyncListColumns,
          ] as TaskTableColumnID[])
        : (["select", "hasEconomicIssues", ...economicSyncListColumns] as TaskTableColumnID[]),
    [economicSyncListColumns, fuelSurcharge],
  );

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: TaskTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  const allSelected = filteredRowData.every((t) => t.selected);
  const someSelected = !allSelected && filteredRowData.some((t) => t.selected);

  const handleAllCheckedChanged = useCallback(() => {
    const allPossibleSelected =
      allSelected ||
      filteredRowData.every(
        ({hasEconomicIssues, hasFuelSurchargeIssues, selected}) =>
          selected || hasFuelSurchargeIssues || hasEconomicIssues,
      );

    if (allPossibleSelected) {
      const toDeselect = filteredRowData.filter(({selected}) => selected).map(({task}) => task.url);
      if (toDeselect.length) {
        dispatchToRecord({type: "removeMultiple", values: new Set(toDeselect)});
      }
    } else {
      const toSelect = filteredRowData
        .filter(
          ({hasEconomicIssues, hasFuelSurchargeIssues, selected}) =>
            !selected && !hasFuelSurchargeIssues && !hasEconomicIssues,
        )
        .map(({task}) => task.url);
      if (toSelect.length) {
        dispatchToRecord({type: "addMultiple", values: new Set(toSelect)});
      }
    }
  }, [allSelected, dispatchToRecord, filteredRowData]);

  const commitQueue = useSelector(getCommitQueue);

  const uploadIcon = uploading ? (
    <CircularProgress size={20} value={uploadProgress} variant="determinate" />
  ) : (
    <SvgIcon>
      <CloudUploadIcon />
    </SvgIcon>
  );

  return (
    <>
      <TaskFuelSurchargeIssuesDialog
        {...fuelSurchargeIssueDialogData}
        open={fuelSurchargeIssueDialogOpen}
        onClose={handleFuelSurchargeIssueDialogClose}
      />
      <Grid container>
        <Grid item lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                disabled={uploading}
                indeterminate={someSelected}
                onChange={handleAllCheckedChanged}
              />
            }
            label={<FormattedMessage defaultMessage="Alle" id="task-table.select-all" />}
            style={{marginLeft: 16}}
          />
        </Grid>
        <Grid item lg={6} style={{textAlign: "right"}}>
          <Button
            color="primary"
            disabled={uploading || !!commitQueue.length || previousUploadEncounteredError}
            startIcon={uploadIcon}
            style={{minWidth: 350, width: 350}}
            variant="contained"
            onClick={onUploadToEconomic}
          >
            <FormattedMessage
              defaultMessage="Overfør {taskCount} opgaver til e-conomic"
              id="econ-upload-button.label.upload-to-economic-count"
              values={{taskCount: tasksToTransferCount}}
            />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <GenericTable
              columns={columnSpecifications}
              entries={filteredRowData}
              maxDisplayed={MAX_TASKS_DISPLAYED}
              sortBy={sortKey as any}
              sortDirection={sortDirection}
              visibleColumns={visibleColumns}
              onHeaderClick={handleHeaderClick}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
