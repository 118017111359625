import {Patch, PatchOperation, User, UserProfile} from "@co-common-libs/resources";
import {
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getEmployeeGroupLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import {UserEditData, UserEditDialog, UserProfileData, userProfileDataFields} from "app-components";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const ProfileCard = React.memo(function ProfileCard({
  user,
  userProfile,
}: {
  user: User;
  userProfile: UserProfile;
}): JSX.Element {
  const currentRole = useSelector(getCurrentRole);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getCustomerSettings);
  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);

  const [userProfileEditDialogOpen, setUserProfileEditDialogOpen] = useState(false);
  const setUserProfileEditDialogOpenTrue = useCallWithTrue(setUserProfileEditDialogOpen);
  const setUserProfileEditDialogOpenFalse = useCallWithFalse(setUserProfileEditDialogOpen);

  const dispatch = useDispatch();
  const handleUserEditDialogOk = useCallback(
    (userData: UserEditData, userProfileData: UserProfileData) => {
      const profilePatch: PatchOperation<UserProfile>[] = userProfileDataFields
        .filter((field) => userProfile[field] !== userProfileData[field])
        .map((field) => ({member: field, value: userProfileData[field]}));
      if (profilePatch.length) {
        dispatch(actions.update(userProfile.url, profilePatch));
      }

      if (user.loginIdentifier !== userData.loginIdentifier) {
        const userPatch: Patch<User> = [
          {member: "loginIdentifier", value: userData.loginIdentifier},
        ];
        dispatch(actions.update(user.url, userPatch));
      }
      setUserProfileEditDialogOpen(false);
    },
    [dispatch, user.loginIdentifier, user.url, userProfile],
  );

  const usesDataloen = useMemo(
    () => Object.values(customerSettings.remunerationGroups).some((group) => group.dataloen),
    [customerSettings.remunerationGroups],
  );

  const usesIntect = useMemo(
    () => Object.values(customerSettings.remunerationGroups).some((group) => group.intect),
    [customerSettings.remunerationGroups],
  );

  const intl = useIntl();

  const userIsManager = !!(currentRole && currentRole.manager && !currentRole.projectManager);
  const userIsOther = userProfile.user !== currentUserUrl;

  const employeeGroup = userProfile.employeeGroup
    ? employeeGroupLookup(userProfile.employeeGroup)
    : null;

  return (
    <>
      <Card>
        <div style={{position: "relative"}}>
          {((customerSettings.canEditAllUserDataOnProfilePage ||
            customerSettings.economicSync ||
            customerSettings.c5Sync) &&
            userIsManager) ||
          !userIsOther ? (
            <IconButton
              style={{
                position: "absolute",
                right: 16,
                top: 16,
              }}
              onClick={setUserProfileEditDialogOpenTrue}
            >
              <PencilIcon color="#000" />
            </IconButton>
          ) : null}
        </div>
        <CardHeader title={intl.formatMessage({defaultMessage: "Profil"})} />
        <CardContent>
          <table>
            <tbody>
              {userIsManager || !userIsOther ? (
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Brugernavn:" />
                  </td>
                  <td>{user.loginIdentifier}</td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <FormattedMessage defaultMessage="Initialer:" />
                </td>
                <td>{userProfile.alias}</td>
              </tr>
              {(!userIsOther || userIsManager) && customerSettings.userProfileEmployeeNumber ? (
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Medarbejdernr.:" />
                  </td>
                  <td>{userProfile.employeeNumber}</td>
                </tr>
              ) : null}

              <tr>
                <td>
                  <FormattedMessage defaultMessage="Navn:" />
                </td>
                <td>{userProfile.name}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage defaultMessage="Adresse:" />
                </td>
                <td>{userProfile.address}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage defaultMessage="Postnr.:" />
                </td>
                <td>{userProfile.postalCode}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage defaultMessage="By:" />
                </td>
                <td>{userProfile.city}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage defaultMessage="E-mail:" />
                </td>
                <td>{userProfile.email}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage defaultMessage="Mobil (Synlig for kolleger):" />
                </td>
                <td>{userProfile.cellphone}</td>
              </tr>
              {!userIsOther || userIsManager ? (
                <>
                  {customerSettings.userCellphoneExtra ? (
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Mobil (Kun synlig for administrationen):" />
                      </td>
                      <td>{userProfile.cellphoneExtra}</td>
                    </tr>
                  ) : null}
                  {usesDataloen ? (
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Dataløn, medarbejdernr.:" />
                      </td>
                      <td>{userProfile.externalDataloenIdentifier}</td>
                    </tr>
                  ) : null}
                  {usesIntect ? (
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Intect, medarbejdernr.:" />
                      </td>
                      <td>{userProfile.externalIntectIdentifier}</td>
                    </tr>
                  ) : null}
                  {customerSettings.enableLessorUpload ||
                  customerSettings.enableMaanssonsSalaryUpload ? (
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Lessor, medarbejdernr.:" />
                      </td>
                      <td>{userProfile.externalLessorIdentifier}</td>
                    </tr>
                  ) : null}
                  {customerSettings.useEmployeeGroups ? (
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Gruppe:" />
                      </td>
                      <td>{employeeGroup && employeeGroup.name}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <FormattedMessage defaultMessage="Note:" />
                    </td>
                    <td>{userProfile.note}</td>
                  </tr>
                </>
              ) : null}
            </tbody>
          </table>
        </CardContent>
      </Card>
      <UserEditDialog
        data={{
          user,
          userProfile,
        }}
        open={userProfileEditDialogOpen}
        onCancel={setUserProfileEditDialogOpenFalse}
        onOk={handleUserEditDialogOk}
      />
    </>
  );
});
