import {UserProfile} from "@co-common-libs/resources";
import {IntegerField, TrimTextField} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {useEventTargetValueUpdater, useFieldUpdater} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {RemunerationGroupSelection} from "./remuneration-group-selection";

export const SalaryCard = React.memo(function SalaryCard({
  userProfile,
}: {
  userProfile: UserProfile;
}): JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  const handleExternalDataloenIdentifierChange = useFieldUpdater<UserProfile, string>(
    userProfile.url,
    "externalDataloenIdentifier",
  );
  const handleExternalLessorIdentifierChange = useFieldUpdater<UserProfile, string>(
    userProfile.url,
    "externalLessorIdentifier",
  );
  const handleExternalEconomicIdentifierChange = useFieldUpdater<UserProfile, string>(
    userProfile.url,
    "externalEconomicIdentifier",
  );
  const handleExternalUnicontaIdentifierChange = useFieldUpdater<UserProfile, string>(
    userProfile.url,
    "externalUnicontaIdentifier",
  );
  const handleExternalIntectIdentifierChange = useFieldUpdater<UserProfile, string>(
    userProfile.url,
    "externalIntectIdentifier",
  );
  const handleNormalTransportKilometersChange = useFieldUpdater<UserProfile, number | null>(
    userProfile.url,
    "normalTransportKilometers",
  );

  const remunerationGroup =
    userProfile.remunerationGroup || customerSettings.remunerationDefaultGroup;

  const usesDataloen = !!customerSettings.remunerationGroups[remunerationGroup]?.dataloen;

  const missingDataloenIdentifier = usesDataloen && !userProfile.externalDataloenIdentifier;

  const usesIntect = !!customerSettings.remunerationGroups[remunerationGroup]?.intect;

  const missingIntectIdentifier = usesIntect && !userProfile.externalIntectIdentifier;

  return (
    <Card>
      <CardHeader title={intl.formatMessage({defaultMessage: "Løn"})} />
      <CardContent>
        <RemunerationGroupSelection
          valueSelected={remunerationGroup}
          onChange={useEventTargetValueUpdater<UserProfile>(userProfile.url, "remunerationGroup")}
        />
        {usesDataloen ? (
          <TrimTextField
            fullWidth
            error={missingDataloenIdentifier}
            helperText={
              missingDataloenIdentifier
                ? intl.formatMessage({defaultMessage: "Skal indtastes"})
                : undefined
            }
            label={intl.formatMessage({
              defaultMessage: "Dataløn, medarbejdernr.",
            })}
            margin="dense"
            value={userProfile.externalDataloenIdentifier}
            variant="outlined"
            onChange={handleExternalDataloenIdentifierChange}
          />
        ) : null}
        {usesIntect ? (
          <TrimTextField
            fullWidth
            error={missingIntectIdentifier}
            helperText={
              missingIntectIdentifier
                ? intl.formatMessage({defaultMessage: "Skal indtastes"})
                : undefined
            }
            label={intl.formatMessage({
              defaultMessage: "Intect, medarbejdernr.",
            })}
            margin="dense"
            value={userProfile.externalIntectIdentifier}
            variant="outlined"
            onChange={handleExternalIntectIdentifierChange}
          />
        ) : null}
        {customerSettings.enableLessorUpload || customerSettings.enableMaanssonsSalaryUpload ? (
          <TrimTextField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Lessor, medarbejdernr.",
            })}
            margin="dense"
            value={userProfile.externalLessorIdentifier}
            variant="outlined"
            onChange={handleExternalLessorIdentifierChange}
          />
        ) : null}
        {customerSettings.economicSync && customerSettings.enableProjects ? (
          <TrimTextField
            fullWidth
            disabled={customerSettings.economicEnableUploadUsersToProjectEmployees}
            label={intl.formatMessage({
              defaultMessage: "e-conomic projekt medarbejdernr.",
            })}
            margin="dense"
            value={userProfile.externalEconomicIdentifier}
            variant="outlined"
            onChange={handleExternalEconomicIdentifierChange}
          />
        ) : null}
        {customerSettings.unicontaSync ? (
          <TrimTextField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Uniconta medarbejdernr.",
            })}
            margin="dense"
            value={userProfile.externalUnicontaIdentifier}
            variant="outlined"
            onChange={handleExternalUnicontaIdentifierChange}
          />
        ) : null}
        {customerSettings.userProfileNormalTransportMinutes ? (
          <IntegerField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Normal transport km til arbejde",
            })}
            margin="dense"
            value={userProfile.normalTransportKilometers}
            onChange={handleNormalTransportKilometersChange}
          />
        ) : null}
      </CardContent>
    </Card>
  );
});
