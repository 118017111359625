import {Role, User, UserProfile, UserUrl} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {getUserId} from "../authentication/selectors";
import {ResourcesAuthenticationAppState} from "../middleware/types";
import {getRoleArray, getUserArray, getUserProfileArray} from "../resources/selectors";

function findUser(userID: string | null, userArray: readonly User[]): User | null {
  for (let i = 0; i < userArray.length; i += 1) {
    const user = userArray[i];
    if (user.id === userID) {
      return user;
    }
  }
  return null;
}

export const getCurrentUser: (state: ResourcesAuthenticationAppState) => User | null =
  createSelector(getUserId, getUserArray, findUser);

export const getCurrentUserURL: (state: ResourcesAuthenticationAppState) => UserUrl | null =
  createSelector(getCurrentUser, (user) => (user ? user.url : null));

function findWithUser<T extends {readonly user: string}>(
  user: User | null,
  instanceArray: readonly T[],
): T | null {
  if (!user) {
    return null;
  }
  const userURL = user.url;
  for (let i = 0; i < instanceArray.length; i += 1) {
    const instance = instanceArray[i];
    if (instance.user === userURL) {
      return instance;
    }
  }
  return null;
}

export const getCurrentUserProfile: (state: ResourcesAuthenticationAppState) => UserProfile | null =
  createSelector(getCurrentUser, getUserProfileArray, findWithUser);

export const getCurrentRole: (state: ResourcesAuthenticationAppState) => Role | null =
  createSelector(getCurrentUser, getRoleArray, findWithUser);

export const getCurrentRoleIsManager: (state: ResourcesAuthenticationAppState) => boolean =
  createSelector(getCurrentRole, (role) => !!role && role.manager && !role.projectManager);

export const getCurrentRoleIsProjectManager: (state: ResourcesAuthenticationAppState) => boolean =
  createSelector(getCurrentRole, (role) => !!role && role.manager && role.projectManager);
