import {Config} from "@co-common-libs/config";
import {User, UserProfile} from "@co-common-libs/resources";
import {DateField, ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  employeeSpecificReport: {
    defaultMessage: "Medarb.: {employees}",
    id: "remuneration-report-list.label.employee-specific-report",
  },
  newTitle: {
    defaultMessage: "Ny rapport",
    id: "remuneration-report-list.dialog-title.new-report",
  },
  periodEnd: {
    defaultMessage: "Til",
    id: "remuneration-report-list.label.period-end",
  },
  periodStart: {
    defaultMessage: "Fra",
    id: "remuneration-report-list.label.period-start",
  },
  reportVariation: {
    defaultMessage: "Løngruppe",
    id: "remuneration-report-list.label.report-variation",
  },
  standardReport: {
    defaultMessage: "Standard",
    id: "remuneration-report-list.label.standard-report",
  },
});

interface NewDialogProps {
  customerSettings: Config;
  onCancel: () => void;
  onOk: (
    fromDate: string,
    toDate: string,
    onlyValidated: boolean,
    title: string,
    remunerationGroup: string,
  ) => void;
  open: boolean;
  userArray: readonly User[];
  userProfileArray: readonly UserProfile[];
}

interface NewDialogState {
  onlyValidated: boolean;
  periodEnd: string | null;
  periodStart: string | null;
  reportVariant: number | null;
}

export class NewDialog extends React.PureComponent<NewDialogProps, NewDialogState> {
  state: NewDialogState = {
    onlyValidated: false,
    periodEnd: null,
    periodStart: null,
    reportVariant: null,
  };
  UNSAFE_componentWillMount(): void {
    const {customerSettings, userArray, userProfileArray} = this.props;
    const {remunerationDefaultGroup, remunerationGroups, remunerationReportPerVariation} =
      customerSettings;
    if (remunerationReportPerVariation) {
      const remunerationGroupAlternatives: {
        employeeURLs: string[];
        label: string;
        remunerationGroup: string;
      }[] = [];
      const employeesPerGroup = new Map<string, UserProfile[]>();
      userProfileArray.forEach((userProfile) => {
        const {remunerationGroup} = userProfile;
        const existing = employeesPerGroup.get(remunerationGroup);
        if (existing) {
          existing.push(userProfile);
        } else {
          employeesPerGroup.set(remunerationGroup, [userProfile]);
        }
      });
      Object.entries(remunerationGroups).forEach(([identifier, remunerationGroup]) => {
        if (identifier !== remunerationDefaultGroup) {
          const userProfiles = employeesPerGroup.get(identifier);
          if (userProfiles && userProfiles.length) {
            const employeeURLs = userProfiles.map((userProfile) => userProfile.user).sort();
            const label = remunerationGroup.reportTitle;
            remunerationGroupAlternatives.push({
              employeeURLs,
              label,
              remunerationGroup: identifier,
            });
          }
        }
      });
      const allGroupUsers = remunerationGroupAlternatives.length
        ? new Set(remunerationGroupAlternatives.map((entry) => entry.employeeURLs).flat())
        : new Set<string>();
      const standardEmployeeURLs = userArray
        .map((user) => user.url)
        .filter((url) => !allGroupUsers.has(url))
        .sort();
      if (standardEmployeeURLs.length) {
        remunerationGroupAlternatives.push({
          employeeURLs: standardEmployeeURLs,
          label: remunerationGroups[remunerationDefaultGroup].reportTitle,
          remunerationGroup: remunerationDefaultGroup,
        });
      }
      this.remunerationGroupAlternatives = _.sortBy(
        remunerationGroupAlternatives,
        ({label}) => label,
      );
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private remunerationGroupAlternatives:
    | {
        employeeURLs: readonly string[];
        label: string;
        remunerationGroup: string;
      }[]
    | null = null;
  @bind
  handleOk(): void {
    const {onlyValidated, periodEnd, periodStart, reportVariant} = this.state;
    if (!periodStart || !periodEnd) {
      return;
    }
    this.setState({
      onlyValidated: false,
      periodEnd: null,
      periodStart: null,
      reportVariant: null,
    });
    let title = "";
    let remunerationGroup = "";
    if (this.remunerationGroupAlternatives && reportVariant != null) {
      ({label: title, remunerationGroup} = this.remunerationGroupAlternatives[reportVariant]);
    }
    this.props.onOk(periodStart, periodEnd, onlyValidated, title, remunerationGroup);
  }
  @bind
  handleRequestClose(): void {
    this.setState({
      onlyValidated: false,
      periodEnd: null,
      periodStart: null,
      reportVariant: null,
    });
    this.props.onCancel();
  }
  @bind
  handlePeriodStartChange(newValue: string | null): void {
    this.setState({periodStart: newValue});
  }
  @bind
  handlePeriodStartBlur(): void {
    if (
      this.state.periodStart &&
      (!this.state.periodEnd || this.state.periodStart > this.state.periodEnd)
    ) {
      this.setState({periodEnd: this.state.periodStart});
    }
  }
  @bind
  handlePeriodStartSelectedInDialog(date: string | null): void {
    if (date) {
      if (!this.state.periodEnd || date > this.state.periodEnd) {
        this.setState({periodEnd: date, periodStart: date});
      } else {
        this.setState({periodStart: date});
      }
    }
  }
  @bind
  handlePeriodEndChange(newValue: string | null): void {
    this.setState({periodEnd: newValue});
  }
  @bind
  handleVariantChange(event: React.ChangeEvent<{value: unknown}>): void {
    this.setState({reportVariant: event.target.value as number});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    let periodErrorBlock;
    if (
      this.state.periodStart &&
      this.state.periodEnd &&
      this.state.periodStart > this.state.periodEnd
    ) {
      periodErrorBlock = (
        <Grid>
          <Cell style={{color: "red"}}>
            <FormattedMessage
              defaultMessage='"Fra" datoen må ikke være senere end "til" datoen"'
              id="remuneration-report-list.label.period-error"
            />
          </Cell>
        </Grid>
      );
    }

    const okDisabled =
      !(this.state.periodStart && this.state.periodEnd) ||
      (this.remunerationGroupAlternatives && this.state.reportVariant == null) ||
      !!periodErrorBlock;
    let reportVariationSelectBlock: JSX.Element | undefined;
    if (this.remunerationGroupAlternatives) {
      reportVariationSelectBlock = (
        <Grid>
          <Cell>
            <FormControl fullWidth>
              <InputLabel id="report-variant-select-label">
                {formatMessage(messages.reportVariation)}
              </InputLabel>
              <Select
                id="report-variant-select"
                labelId="report-variant-select-label"
                value={this.state.reportVariant}
                onChange={this.handleVariantChange}
              >
                {this.remunerationGroupAlternatives.map(({label}, index) => (
                  <MenuItem key={index} value={index}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Cell>
        </Grid>
      );
    }
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <DateField
              autoOk
              fullWidth
              autoFocus={!bowser.mobile}
              label={formatMessage(messages.periodStart)}
              margin="dense"
              referenceDate={this.state.periodEnd || undefined}
              value={this.state.periodStart}
              yearSuggestions="DATE_BEFORE"
              onBlur={this.handlePeriodStartBlur}
              onChange={this.handlePeriodStartChange}
              onSelectedInDialog={this.handlePeriodStartSelectedInDialog}
            />
          </Cell>
          <Cell palm="12/12">
            <DateField
              autoOk
              fullWidth
              label={formatMessage(messages.periodEnd)}
              margin="dense"
              referenceDate={this.state.periodStart || undefined}
              value={this.state.periodEnd}
              yearSuggestions={this.state.periodStart ? "DATE_AFTER" : "DATE_BEFORE"}
              onChange={this.handlePeriodEndChange}
            />
          </Cell>
        </Grid>
        {periodErrorBlock}
        {reportVariationSelectBlock}
      </div>
    );
    return (
      <ResponsiveDialog
        okDisabled={okDisabled}
        open={open}
        title={formatMessage(messages.newTitle)}
        onCancel={this.handleRequestClose}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}
