import {
  JournalDataEntry,
  JournalSummarizedDataByProductEntry,
  JournalSummarizedDataEntry,
} from "@co-common-libs/report-rendering";
import {CircularProgress} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useState} from "react";
import {StorageJournalCard} from "./storage-journal-card";

interface DisplayStorageJournalProps {
  csvURL: string;
  pdfURL: string;
  results:
    | {
        data: readonly [
          readonly JournalDataEntry[],
          readonly JournalSummarizedDataEntry[],
          readonly JournalSummarizedDataByProductEntry[],
        ];
        fromDate: string;
        invoiceCustomers: string[];
        location: string;
        locationCustomers: string[];
        machines: {machine: string; priceGroups: string[]}[];
        products: string[];
        toDate: string;
        workTypes: {priceGroups: string[]; workType: string}[];
      }
    | undefined;
}

export const DisplayStorageJournal = React.memo(function DisplayStorageJournal(
  props: DisplayStorageJournalProps,
): JSX.Element {
  const {csvURL, pdfURL, results} = props;

  const [showCustomerColumn, setShowCustomerColumn] = useState(true);
  const handleShowCustomerColumnCheck = useEventTargetCheckedCallback(setShowCustomerColumn, [
    setShowCustomerColumn,
  ]);

  const [showFiltering, setShowFiltering] = useState(true);
  const handleShowFilteringCheck = useEventTargetCheckedCallback(setShowFiltering, [
    setShowFiltering,
  ]);
  const [data, summarizedData, summarizedByProductData] = results?.data || [[], [], []];
  if (results) {
    return (
      <StorageJournalCard
        csvURL={csvURL}
        data={data}
        fromDate={results.fromDate}
        invoiceCustomers={results.invoiceCustomers}
        location={results.location}
        locationCustomers={results.locationCustomers}
        machines={results.machines}
        pdfURL={pdfURL}
        products={results.products}
        showCustomerColumn={showCustomerColumn}
        showFiltering={showFiltering}
        summarizedByProductData={summarizedByProductData}
        summarizedData={summarizedData}
        toDate={results.toDate}
        workTypes={results.workTypes}
        onShowCustomerColumnCheck={handleShowCustomerColumnCheck}
        onShowFilteringCheck={handleShowFilteringCheck}
      />
    );
  } else {
    return (
      <div style={{textAlign: "center"}}>
        <CircularProgress />
      </div>
    );
  }
});
