import {Config} from "@co-common-libs/config";
import {
  PriceGroup,
  PriceGroupItem,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  PriceItemUseWithOrder,
  PriceItemUsesDict,
  Task,
  Timer,
  TimerUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {
  getUnitCode,
  normaliseName,
  priceItemIsTime,
  priceItemUseSetHasMultipleManualDistribution,
} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {getFrontendSentry} from "../sentry";
import {sortPriceItemUses} from "./sort-price-item-uses";

type PriceItemUseOrigins = Pick<PriceItemUse, "machine" | "priceGroup" | "timer" | "workType">;

const ITEM_TYPE_DIFFERENT = 3;

const isDiff = (priceItem: PriceItem): boolean =>
  priceItem.itemtype === ITEM_TYPE_DIFFERENT && priceItem.defaultCount !== 1;

function priceGroupActiveRepresentativePriceItems(
  priceGroupItemSet: readonly PriceGroupItem[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): PriceItemUrl[] {
  const sortedPriceGroupItemSet = _.sortBy(priceGroupItemSet, ({order}) => order);
  const priceItems = sortedPriceGroupItemSet
    .map(({priceItem}) => priceItemLookup(priceItem))
    .filter(notUndefined)
    .filter((priceItem) => priceItem.active);
  const sortedItemList = _.sortBy(priceItems, (priceItem) => priceItem.c5_recid);

  const [differentiatedItemListBase, normalItemList] = _.partition(sortedItemList, isDiff);

  const differentiatedPerUnitMapping = new Map<string, Readonly<PriceItem>>();
  differentiatedItemListBase.forEach((priceItem) => {
    const unit = getUnitCode(priceItem, unitLookup).toLowerCase();
    if (!differentiatedPerUnitMapping.has(unit)) {
      differentiatedPerUnitMapping.set(unit, priceItem);
    }
  });

  const differentiatedItemList = Array.from(differentiatedPerUnitMapping.values());

  const combinedItemList = normalItemList.concat(differentiatedItemList);
  return combinedItemList.map((priceItem) => priceItem.url);
}

function recomputeUnsortedPriceItemUses(
  task: Task & {
    readonly priceItemUses: PriceItemUsesDict;
  },
  timersWithTime: ReadonlySet<TimerUrl>,
  timerLookup: (url: TimerUrl) => Timer | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  customerSettings: Config,
  timerPriceItemRemovalAllowedForTimers: ReadonlySet<string>,
): PriceItemUsesDict {
  const priceItemPriceItemUseOriginsMap = new Map<PriceItemUrl, PriceItemUseOrigins>();

  if (task.priceGroup) {
    const taskPriceGroup = priceGroupLookup(task.priceGroup);
    if (taskPriceGroup?.priceGroupItemSet) {
      priceGroupActiveRepresentativePriceItems(
        taskPriceGroup.priceGroupItemSet,
        priceItemLookup,
        unitLookup,
      ).forEach((priceItemURL) => {
        priceItemPriceItemUseOriginsMap.set(priceItemURL, {
          machine: null,
          priceGroup: task.priceGroup,
          timer: null,
          workType: task.workType,
        });
      });
    }
  }
  if (task.machineuseSet && task.machineuseSet.length) {
    task.machineuseSet.forEach((machineUse) => {
      if (machineUse.priceGroup) {
        const machinePriceGroup = priceGroupLookup(machineUse.priceGroup);
        if (machinePriceGroup?.priceGroupItemSet) {
          priceGroupActiveRepresentativePriceItems(
            machinePriceGroup.priceGroupItemSet,
            priceItemLookup,
            unitLookup,
          ).forEach((priceItemURL) => {
            if (!priceItemPriceItemUseOriginsMap.has(priceItemURL)) {
              priceItemPriceItemUseOriginsMap.set(priceItemURL, {
                machine: machineUse.machine,
                priceGroup: machineUse.priceGroup,
                timer: null,
                workType: null,
              });
            }
          });
        }
      }
    });
  }
  timersWithTime.forEach((timerURL) => {
    const timer = timerLookup(timerURL);
    if (timer?.priceGroup) {
      const timerPriceGroup = priceGroupLookup(timer.priceGroup);
      if (timerPriceGroup?.priceGroupItemSet) {
        priceGroupActiveRepresentativePriceItems(
          timerPriceGroup.priceGroupItemSet,
          priceItemLookup,
          unitLookup,
        ).forEach((priceItemURL) => {
          if (!priceItemPriceItemUseOriginsMap.has(priceItemURL)) {
            priceItemPriceItemUseOriginsMap.set(priceItemURL, {
              machine: null,
              priceGroup: timer.priceGroup,
              timer: timerURL,
              workType: timer.workType,
            });
          }
        });
      }
    }
  });

  const oldPriceItemUses = task.priceItemUses;

  const resultingEntries: {
    [identifier: string]: PriceItemUseWithOrder & {dangling: false};
  } = {};
  const danglingEntries: {
    [identifier: string]: PriceItemUseWithOrder & {dangling: true};
  } = {};

  Object.entries(oldPriceItemUses).forEach(([identifier, priceItemUse]) => {
    const origin = priceItemPriceItemUseOriginsMap.get(priceItemUse.priceItem);
    if (origin) {
      // some relevant price group still present (and price item active)
      resultingEntries[identifier] = {
        ...priceItemUse,
        ...origin,
        dangling: false,
      };
    } else if (
      priceItemUse.timer &&
      !timerPriceItemRemovalAllowedForTimers.has(priceItemUse.timer)
    ) {
      resultingEntries[identifier] = {
        ...priceItemUse,
        dangling: false,
      };
    } else {
      // not matching any current price group
      danglingEntries[identifier] = {...priceItemUse, dangling: true};
    }
  });

  priceItemPriceItemUseOriginsMap.forEach((origin, priceItemURL) => {
    if (
      Object.values(resultingEntries).some(
        (priceItemUse) => priceItemUse.priceItem === priceItemURL,
      )
    ) {
      // present in resultingEntries
      return;
    }
    const priceItem = priceItemLookup(priceItemURL);
    if (!priceItem) {
      return;
    }
    // price item missing in resultingEntries
    const normalisedName = normaliseName(priceItem.name);
    const matchingDangling = Object.entries(danglingEntries).find(
      ([_danglingIdentifier, danglingPriceItemUse]) => {
        const danglingPriceItem = priceItemLookup(danglingPriceItemUse.priceItem);
        return (
          danglingPriceItem &&
          getUnitCode(danglingPriceItem, unitLookup) === getUnitCode(priceItem, unitLookup) &&
          normaliseName(danglingPriceItem.name) === normalisedName
        );
      },
    );
    if (matchingDangling) {
      // some existing dangling entry has same name and unit;
      // probably switch from/to customer specific or between otherwise
      // similar price groups; transfer data

      const [danglingIdentifier, danglingPriceItemUse] = matchingDangling;
      resultingEntries[danglingIdentifier] = {
        ...danglingPriceItemUse,
        priceItem: priceItemURL,
        ...origin,
        dangling: false,
      };
      delete danglingEntries[danglingIdentifier];
    } else {
      // no existing matching entries, so add a new one
      const newIdentifier = uuid();
      resultingEntries[newIdentifier] = {
        priceItem: priceItemURL,
        ...origin,
        correctedCount: null,
        count: null,
        dangling: false,
        notes: "",
        order: 0, // will be overridden by later sort
      };
    }
  });

  // removing duplicates -- may occur on concurrently setting price group
  // or adding machines from multiple devices...
  // ... also seems to occur in some unexplained circumstances...
  // check is O(n^2), but OK given a reasonably number of price items on tasks
  Object.entries(resultingEntries).forEach(([identifier, priceItemUse]) => {
    for (const [otherIdentifier, otherPriceItemUse] of Object.entries(resultingEntries)) {
      if (otherIdentifier === identifier) {
        continue;
      }
      if (
        priceItemUse.priceItem === otherPriceItemUse.priceItem &&
        priceItemUse.machine === otherPriceItemUse.machine &&
        priceItemUse.priceGroup === otherPriceItemUse.priceGroup &&
        priceItemUse.timer === otherPriceItemUse.timer &&
        priceItemUse.workType === otherPriceItemUse.workType
      ) {
        // same item present for same reason...
        if (
          (priceItemUse.count == null && otherPriceItemUse.count != null) ||
          (!priceItemUse.count && !!otherPriceItemUse.count) ||
          (priceItemUse.count === otherPriceItemUse.count &&
            ((priceItemUse.correctedCount == null && otherPriceItemUse.correctedCount != null) ||
              (!priceItemUse.correctedCount && !!otherPriceItemUse.correctedCount) ||
              (priceItemUse.correctedCount === otherPriceItemUse.correctedCount &&
                (!priceItemUse.notes || priceItemUse.notes === otherPriceItemUse.notes))))
        ) {
          // if count is blank on this but not the other, remove this
          // if count is zero on this but not the other, remove this
          // if count is equal but *correctedCount* differs, same strategy
          // if count and correctedCount equal, keep the one with notes;
          // if notes are also equal, which we keep doesn't matter...
          const sentry = getFrontendSentry();
          sentry.withScope((scope) => {
            scope.setExtra("entry", {identifier, priceItemUse});
            scope.setExtra("otherEntry", {
              identifier: otherIdentifier,
              priceItemUse: otherPriceItemUse,
            });
            sentry.captureMessage("Removing duplicate price item use", "warning");
          });
          delete resultingEntries[identifier];
        }
      }
    }
  });

  // "blank" dangling entries are discarded; potential notes disregarded
  const nonZeroDanglingEntries = Object.fromEntries(
    Object.entries(danglingEntries).filter(
      ([_identifier, priceItemUse]) => priceItemUse.count || priceItemUse.correctedCount,
    ),
  );

  let unsorted: {[identifier: string]: PriceItemUseWithOrder} = {
    ...resultingEntries,
    ...nonZeroDanglingEntries,
  };

  const allowMoreThanTwoMachines =
    customerSettings.alwaysAllowMoreThanTwoMachines ||
    customerSettings.allowMoreThanTwoMachinesForDepartments.includes(task.department);

  if (
    !allowMoreThanTwoMachines ||
    !priceItemUseSetHasMultipleManualDistribution(
      Object.values(unsorted),
      priceItemLookup,
      unitLookup,
    )
  ) {
    unsorted = Object.fromEntries(
      Object.entries(unsorted).map((entry) => {
        const [identifier, priceItemUse] = entry;
        const priceItem = priceItemLookup(priceItemUse.priceItem);
        if (priceItem && priceItemIsTime(unitLookup, priceItem) && priceItemUse.count !== null) {
          return [identifier, {...priceItemUse, count: null}];
        }
        return entry;
      }),
    );
  }

  if (_.isEqual(unsorted, oldPriceItemUses)) {
    return oldPriceItemUses;
  } else {
    return unsorted;
  }
}

export function recomputePriceItemUses(
  task: Task & {
    readonly priceItemUses: PriceItemUsesDict;
  },
  timersWithTime: ReadonlySet<TimerUrl>,
  timerLookup: (url: TimerUrl) => Timer | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  customerSettings: Config,
  timerPriceItemRemovalAllowedForTimers: ReadonlySet<string>,
): PriceItemUsesDict {
  const unsorted = recomputeUnsortedPriceItemUses(
    task,
    timersWithTime,
    timerLookup,
    priceGroupLookup,
    priceItemLookup,
    unitLookup,
    customerSettings,
    timerPriceItemRemovalAllowedForTimers,
  );

  if (unsorted === task.priceItemUses) {
    // no change; don't re-sort
    return unsorted;
  }

  return sortPriceItemUses(
    unsorted,
    customerSettings,
    priceGroupLookup,
    priceItemLookup,
    unitLookup,
  );
}
