import {ResetObjectAction, SetObjectPropertyAction} from "./types";

// Let TS check that all options are exhausted
// eslint-disable-next-line consistent-return
export function simpleObjectReducer<T>(
  state: T,
  action: ResetObjectAction<T> | SetObjectPropertyAction<T>,
): T {
  const {type, value} = action;
  switch (type) {
    case "set":
      return {...state, [action.propertyName]: value};
    case "reset":
      return {...value};
  }
}
