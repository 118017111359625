import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import {AppState} from "@co-frontend-libs/redux";
import {LoadInstanceRelated, useLoadInstance} from "app-utils";
import React, {useCallback} from "react";
import {PendingInstance} from "./pending-instance";

type DoLoadInstanceProps<T extends ResourceInstance, Url extends string> = {
  Component: React.ComponentType<{instance: T}>;
  idSelector?: (state: AppState) => string;
  loadingTitle: JSX.Element | string;
  lookupSelector: (state: AppState) => (url: Url) => T | undefined;
  related?: LoadInstanceRelated;
  resourceName: ResourceName;
};

export function DoLoadInstance<T extends ResourceInstance, Url extends string>(
  props: DoLoadInstanceProps<T, Url>,
): JSX.Element | null {
  const {Component, idSelector, loadingTitle, lookupSelector, related, resourceName, ...others} =
    props;

  const [instance, status, error] = useLoadInstance(
    resourceName,
    lookupSelector,
    idSelector,
    related,
  );

  const handleTryAgain = useCallback(() => {
    // TODO -- retry fetch, somehow...
    // ... copy from LoadInstance :-P
  }, []);

  if (instance) {
    return <Component {...others} instance={instance} />;
  } else if (status) {
    return (
      <PendingInstance status={status} title={loadingTitle} onTryAgainButton={handleTryAgain} />
    );
  } else {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return null;
  }
}
