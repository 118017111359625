import {
  Location,
  LocationUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskActivityOptionUrl,
  RouteTaskResult,
  RouteTaskUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {customerAddress} from "@co-common-libs/utils";
import _ from "lodash";

export function getRouteTaskData(
  routeTask: RouteTask,
  locationLookup: (url: LocationUrl) => Location | undefined,
  routeTaskResultSet: ReadonlySet<RouteTaskResult>,
  routeTaskActivityOptionLookup: (
    url: RouteTaskActivityOptionUrl,
  ) => RouteTaskActivityOption | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  includeAllItems: boolean,
): {
  activity: string;
  address: string;
  completed: string | null;
  notesFromTaskAssignee: string;
  resultLines: {
    quantity: number | null;
    resultDescription: string;
    routeTaskResultURL: string;
    unitString: string;
  }[];
  routeTaskURL: RouteTaskUrl;
  started: string | null;
  workplaceName: string;
} {
  const resultLines: {
    order: number;
    quantity: number | null;
    resultDescription: string;
    routeTaskResultURL: string;
    unitString: string;
  }[] = [];
  routeTaskResultSet.forEach((routeTaskResult) => {
    const activityOption = routeTaskActivityOptionLookup(routeTaskResult.activityOption);
    if (!activityOption || activityOption.activity !== routeTask.activity) {
      return;
    }
    const {quantity} = routeTaskResult;
    const priceItemURL = routeTaskResult.specification;
    const priceItem = priceItemLookup(priceItemURL);
    if (!priceItem) {
      return;
    }
    const relevantForExecution =
      priceItem.relevantForExecution != null ? priceItem.relevantForExecution : true;
    if (!relevantForExecution && !includeAllItems) {
      return;
    }
    const resultDescription = priceItem.name;
    const unitString = getUnitString(priceItem, unitLookup);
    resultLines.push({
      order: routeTaskResult.order,
      quantity,
      resultDescription: resultDescription.trim(),
      routeTaskResultURL: routeTaskResult.url,
      unitString: unitString.trim(),
    });
  });
  const priceGroupURL = routeTask.activity;
  const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : undefined;
  const activity = priceGroup ? priceGroup.name : "";
  const workplaceURL = routeTask.relatedLocation;
  const workplace = locationLookup(workplaceURL);
  const workplaceName = routeTask.description;
  const address = customerAddress(workplace);
  const {completed, started} = routeTask;
  const {notesFromTaskAssignee} = routeTask;
  const result = {
    activity,
    address,
    completed,
    notesFromTaskAssignee,
    resultLines: _.sortBy(resultLines, ["order", "unitString", "resultDescription"]),
    routeTaskURL: routeTask.url,
    started,
    workplaceName,
  };
  return result;
}
