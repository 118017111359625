import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  PatchUnion,
  Role,
  Task,
  UserUrl,
} from "@co-common-libs/resources";
import {allowWorkplaceCreate} from "@co-common-libs/resources-utils";
import {Button, IconButton} from "@material-ui/core";
import {WorkplaceBlock} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "workplace-selection-block.label.task-address",
  },
  selectWorkplaceButton: {
    defaultMessage: "Vælg",
    id: "workplace-selection-block.label.select-workplace",
  },
});

interface WorkPlaceOrAddressSelectionProps {
  currentRole: Role | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  customerURL?: CustomerUrl | undefined;
  editAllowed: boolean;
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  onRequestWorkplaceDialog: (
    callback: (workPlace: Location) => void,
    customerURL: CustomerUrl,
    showGlobalLocations: boolean,
    machineOperator: UserUrl | null,
    ref: HTMLButtonElement | null,
  ) => void;
  task: Task;
  update: (url: string, patch: PatchUnion) => void;
}

export class WorkplaceOrAddressSelection extends PureComponent<WorkPlaceOrAddressSelectionProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleWorkplaceSelectButton(): void {
    const {customerSettings, customerURL, editAllowed, task} = this.props;
    if (!editAllowed || !customerURL) {
      return;
    }
    this.props.onRequestWorkplaceDialog(
      this.handleWorkplaceDialogOk,
      customerURL,
      !customerSettings.economicInvoicePerWorkplace,
      task.machineOperator,
      this.selectWorkplaceButton.current,
    );
  }

  @bind
  handleWorkplaceClearButton(): void {
    const {task, update} = this.props;
    update(task.url, [{member: "relatedWorkplace", value: null}]);
  }

  @bind
  handleWorkplaceDialogOk(workplace: Location): void {
    const {task, update} = this.props;
    const {url} = workplace;
    update(task.url, [{member: "relatedWorkplace", value: url}]);
  }

  @bind
  handlePickupLocationSelectButton(): void {
    const {customerURL, editAllowed, task} = this.props;
    if (!editAllowed || !customerURL) {
      return;
    }
    this.props.onRequestWorkplaceDialog(
      this.handlePickupLocationDialogOk,
      customerURL,
      true,
      task.machineOperator,
      this.pickupLocationButton.current,
    );
  }

  @bind
  handlePickupLocationDialogOk(workplace: Location): void {
    const {task, update} = this.props;
    const {url} = workplace;
    update(task.url, [{member: "relatedPickupLocation", value: url}]);
  }

  @bind
  handlePickupLocationClearButton(): void {
    const {task, update} = this.props;
    update(task.url, [{member: "relatedPickupLocation", value: null}]);
  }

  selectWorkplaceButton = React.createRef<HTMLButtonElement>();
  pickupLocationButton = React.createRef<HTMLButtonElement>();

  render(): JSX.Element | null {
    const {formatMessage} = this.context;
    const {
      currentRole,
      customerLookup,
      customerSettings,
      customerURL,
      editAllowed,
      locationLookup,
      task,
    } = this.props;
    const disabled = !customerURL || !editAllowed;
    let customerWorkplaceList;
    if (customerURL) {
      customerWorkplaceList = _.sortBy(
        this.props.locationArray.filter((w) => w.active && w.customer === customerURL),
        (w) => w.name || w.address || "",
      );
    } else {
      customerWorkplaceList = [];
    }
    const customerHasWorkplaces = !!customerWorkplaceList.length;
    let content: JSX.Element | null = null;
    if (customerHasWorkplaces || allowWorkplaceCreate(customerSettings, currentRole)) {
      if (customerSettings.taskPickupDelivery) {
        const pickupURL = task.relatedPickupLocation;
        const deliveryURL = task.relatedWorkplace;
        const pickupWorkplace = pickupURL && locationLookup(pickupURL);
        const deliveryWorkplace = deliveryURL && locationLookup(deliveryURL);
        content = (
          <>
            <FormattedMessage
              defaultMessage="Afhentningssted"
              id="order-instance.label.workplace-pickup"
              tagName="h4"
            />
            <Button
              ref={this.pickupLocationButton}
              color="secondary"
              disabled={disabled}
              variant="contained"
              onClick={this.handlePickupLocationSelectButton}
            >
              {formatMessage(messages.selectWorkplaceButton)}
            </Button>
            <IconButton
              disabled={disabled || !pickupWorkplace}
              onClick={this.handlePickupLocationClearButton}
            >
              <CloseIcon />
            </IconButton>
            <WorkplaceBlock
              customerLookup={customerLookup}
              location={pickupWorkplace || undefined}
            />
            <FormattedMessage
              defaultMessage="Arbejds-/leveringssted"
              id="order-instance.label.workplace-delivery"
              tagName="h4"
            />
            <Button
              ref={this.selectWorkplaceButton}
              color="secondary"
              disabled={disabled}
              variant="contained"
              onClick={this.handleWorkplaceSelectButton}
            >
              {formatMessage(messages.selectWorkplaceButton)}
            </Button>
            <IconButton
              disabled={disabled || !deliveryWorkplace}
              onClick={this.handleWorkplaceClearButton}
            >
              <CloseIcon />
            </IconButton>
            <WorkplaceBlock
              customerLookup={customerLookup}
              location={deliveryWorkplace || undefined}
            />
          </>
        );
      } else {
        const currentWorkplaceURL = task.relatedWorkplace;
        const workplace = currentWorkplaceURL && this.props.locationLookup(currentWorkplaceURL);
        content = (
          <>
            <FormattedMessage
              defaultMessage="Arbejdssted"
              id="order-instance.label.workplace"
              tagName="h4"
            />
            <Button
              ref={this.selectWorkplaceButton}
              color="secondary"
              disabled={disabled}
              variant="contained"
              onClick={this.handleWorkplaceSelectButton}
            >
              {formatMessage(messages.selectWorkplaceButton)}
            </Button>
            <IconButton disabled={disabled || !workplace} onClick={this.handleWorkplaceClearButton}>
              <CloseIcon />
            </IconButton>
            <WorkplaceBlock customerLookup={customerLookup} location={workplace || undefined} />
          </>
        );
      }
    }
    return content;
  }
}
