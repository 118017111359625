import {Config} from "@co-common-libs/config";
import {
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {identifierComparator} from "@co-common-libs/utils";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {
  PureComponent,
  getPriceGroupsFromMap,
  getPriceItemIdentifier,
  getPriceItemsForSelectedPriceGroup,
  getSortedWorkTypes,
  priceGroupCompare,
  remoteURLContainsData,
} from "app-utils";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {OverviewRow} from "./overview-row";

const messages = defineMessages({
  minimumPrice: {
    defaultMessage: "Minimumspris - antal: {minimumCount}",
    id: "worktype-overview.text.minimum-price",
  },
  volumeDiscount: {
    defaultMessage: "Rabat: {percentage}% - grænse: {threshold}",
    id: "worktype-overview.text.volume-discount",
  },
});
interface WorkTypesOverviewProps {
  customerSettings: Config;
  defaultSurcharge: string | null | undefined;
  includePriceGroupsNotConnectedToAnyWorkType: boolean;
  includePriceItemsNotConnectedToAnyPriceGroups: boolean;
  onAddPriceGroupClick: (url: MachineUrl | WorkTypeUrl) => void;
  onChangeColorClick: (url: MachineUrl | WorkTypeUrl) => void;
  priceGroupArray: readonly PriceGroup[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemArray: readonly PriceItem[];
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  workTypesWithSurcharges: ReadonlyMap<string, string | null> | undefined;
}

export class WorkTypesOverview extends PureComponent<WorkTypesOverviewProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  generatePriceGroupRows(
    pricegroups: PriceGroup[],
    workTypeURL: WorkTypeUrl | null,
    workType: WorkType | null,
    showPriceItemIdentifiers: boolean,
  ): [JSX.Element[], string[], string[]] {
    const {formatMessage, formatNumber} = this.context;
    const rows: JSX.Element[] = [];
    const includedPriceItems: string[] = [];
    const includedPriceGroups: string[] = [];
    const keyWorkTypeURLPart = `work-type-${workTypeURL || "not-connected-pricegroup"}`;

    pricegroups.forEach((priceGroup) => {
      const priceGroupURL = priceGroup.url;
      const priceItems = getPriceItemsForSelectedPriceGroup(
        priceGroup,
        this.props.priceItemLookup,
        this.props.unitLookup,
        this.props.customerSettings,
      );
      includedPriceGroups.push(priceGroupURL);
      priceItems.forEach((priceItem, index) => {
        const priceItemURL = priceItem.url;
        const unit = getUnitString(priceItem, this.props.unitLookup);
        const priceItemIdentifier = getPriceItemIdentifier(priceItem);
        const {price} = priceItem;
        const firstRowForPriceItem = index === 0;
        includedPriceItems.push(priceItemURL);
        const firstRowForWorkType = rows.length === 0;
        const workTypeOrMachineIdentifier = workType
          ? firstRowForWorkType
            ? workType.identifier
            : undefined
          : undefined;
        const workTypeColor = workType
          ? firstRowForWorkType
            ? workType.color
            : undefined
          : undefined;

        const workTypeOrMachineName = workType
          ? firstRowForWorkType
            ? workType.name
            : undefined
          : undefined;
        const workTypeOrMachineURL = firstRowForWorkType ? workTypeURL : undefined;
        const variantIdentifier = firstRowForPriceItem ? priceGroup.identifier : undefined;
        const variantName = firstRowForPriceItem ? priceGroup.name : undefined;
        const variantOnlyForExtraTimers = firstRowForPriceItem
          ? priceGroup.onlyForExtraTimers
          : undefined;
        rows.push(
          <OverviewRow
            key={`${keyWorkTypeURLPart}-${priceGroupURL}-${priceItemURL}`}
            color={workTypeColor}
            defaultFuelSurcharge={
              firstRowForWorkType && workTypeURL ? this.props.defaultSurcharge : undefined
            }
            firstRowForPriceItem={firstRowForPriceItem}
            itemName={priceItem.name}
            price={price != null ? price : undefined}
            priceGroupFuelSurcharge={
              firstRowForPriceItem && workTypeURL
                ? this.props.workTypesWithSurcharges?.get(`${workTypeURL}\0${priceGroupURL}`)
                : undefined
            }
            priceItemIdentifier={priceItemIdentifier != null ? priceItemIdentifier : undefined}
            priceItemURL={priceItemURL}
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            unit={unit}
            variantIdentifier={variantIdentifier}
            variantName={variantName}
            variantOnlyForExtraTimers={variantOnlyForExtraTimers}
            variantURL={priceGroupURL}
            workTypeOrMachineFuelSurcharge={
              firstRowForWorkType && workTypeURL
                ? this.props.workTypesWithSurcharges?.get(workTypeURL)
                : undefined
            }
            workTypeOrMachineIdentifier={workTypeOrMachineIdentifier}
            workTypeOrMachineName={workTypeOrMachineName}
            workTypeOrMachineURL={workTypeOrMachineURL != null ? workTypeOrMachineURL : undefined}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
            onChangeColorClick={this.props.onChangeColorClick}
          />,
        );
        const {minimumCount} = priceItem;
        if (minimumCount) {
          rows.push(
            <OverviewRow
              key={`${keyWorkTypeURLPart}-${priceGroupURL}-${priceItemURL}-minimumPrice`}
              priceItemConfiguration
              itemName={formatMessage(messages.minimumPrice, {
                minimumCount: formatNumber(minimumCount),
              })}
              price={price != null ? minimumCount * price : undefined}
              showPriceItemIdentifiers={showPriceItemIdentifiers}
            />,
          );
        }
        const volumeDiscountList = priceItem.volumeDiscountSet || [];
        volumeDiscountList.forEach((volumeDiscount, discountIndex) => {
          const percentage = volumeDiscount.discountPercentage;
          const {threshold} = volumeDiscount;
          rows.push(
            <OverviewRow
              key={`${keyWorkTypeURLPart}-${priceGroupURL}-${priceItemURL}-volumeDiscount-${discountIndex}`}
              priceItemConfiguration
              itemName={formatMessage(messages.volumeDiscount, {
                percentage: percentage != null ? formatNumber(percentage) : "",
                threshold: threshold != null ? formatNumber(threshold) : "",
              })}
              price={price != null ? (price * (100 - (percentage || 0))) / 100 : undefined}
              showPriceItemIdentifiers={showPriceItemIdentifiers}
              unit={unit}
            />,
          );
        });
      });
      if (!priceItems.length) {
        const firstRowForWorkType = rows.length === 0;
        const workTypeOrMachineIdentifier = workType
          ? firstRowForWorkType
            ? workType.identifier
            : undefined
          : undefined;

        const workTypeColor = workType
          ? firstRowForWorkType
            ? workType.color
            : undefined
          : undefined;

        const workTypeOrMachineName = workType
          ? firstRowForWorkType
            ? workType.name
            : undefined
          : undefined;
        const workTypeOrMachineURL = firstRowForWorkType ? workTypeURL : undefined;
        const variantIdentifier = priceGroup.identifier;
        const variantName = priceGroup.name;
        const variantOnlyForExtraTimers = priceGroup.onlyForExtraTimers;

        rows.push(
          <OverviewRow
            key={`${keyWorkTypeURLPart}-${priceGroupURL}`}
            color={workTypeColor}
            defaultFuelSurcharge={
              firstRowForWorkType && workTypeURL ? this.props.defaultSurcharge : undefined
            }
            priceGroupFuelSurcharge={
              workTypeURL
                ? this.props.workTypesWithSurcharges?.get(`${workTypeURL}\0${priceGroupURL}`)
                : undefined
            }
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            variantIdentifier={variantIdentifier}
            variantName={variantName}
            variantOnlyForExtraTimers={variantOnlyForExtraTimers}
            variantURL={priceGroupURL}
            workTypeOrMachineFuelSurcharge={
              firstRowForWorkType && workTypeURL
                ? this.props.workTypesWithSurcharges?.get(workTypeURL)
                : undefined
            }
            workTypeOrMachineIdentifier={
              workTypeOrMachineIdentifier != null ? workTypeOrMachineIdentifier : undefined
            }
            workTypeOrMachineName={workTypeOrMachineName}
            workTypeOrMachineURL={workTypeOrMachineURL != null ? workTypeOrMachineURL : undefined}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
            onChangeColorClick={this.props.onChangeColorClick}
          />,
        );
      }
    });
    return [rows, includedPriceItems, includedPriceGroups];
  }

  render(): JSX.Element {
    const {formatMessage, formatNumber} = this.context;
    const {priceGroupArray, priceGroupLookup, priceItemArray, workTypeArray, workTypeLookup} =
      this.props;
    const {disabledWorkTypes, overviewShowPrices} = this.props.customerSettings;

    const showPriceItemIdentifiers = remoteURLContainsData(priceItemArray);

    const sortedworkTypes = getSortedWorkTypes(workTypeArray, disabledWorkTypes);
    let rows: JSX.Element[] = [];

    let shownPriceItems: string[] = [];
    let shownPriceGroups: string[] = [];

    sortedworkTypes.forEach((workType) => {
      const workTypeURL = workType.url;
      const pricegroups = getPriceGroupsFromMap(workTypeURL, workTypeLookup, priceGroupLookup);
      const [newRows, includedPriceItems, includedPriceGroups] = this.generatePriceGroupRows(
        pricegroups,
        workTypeURL,
        workType,
        showPriceItemIdentifiers,
      );

      rows = rows.concat(newRows);
      shownPriceItems = shownPriceItems.concat(includedPriceItems);
      shownPriceGroups = shownPriceGroups.concat(includedPriceGroups);

      if (!pricegroups.length) {
        rows.push(
          <OverviewRow
            key={`work-type-${workTypeURL}`}
            color={workType.color}
            defaultFuelSurcharge={workTypeURL ? this.props.defaultSurcharge : undefined}
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            workTypeOrMachineFuelSurcharge={
              workTypeURL ? this.props.workTypesWithSurcharges?.get(workTypeURL) : undefined
            }
            workTypeOrMachineIdentifier={workType.identifier}
            workTypeOrMachineName={workType.name}
            workTypeOrMachineURL={workTypeURL}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
            onChangeColorClick={this.props.onChangeColorClick}
          />,
        );
      }
    });

    const notConnectedPriceGroups = priceGroupArray
      .filter((instance) => instance.active && !shownPriceGroups.includes(instance.url))
      .sort(priceGroupCompare);

    if (notConnectedPriceGroups.length && this.props.includePriceGroupsNotConnectedToAnyWorkType) {
      const [newRows, includedPriceItems, includedPriceGroups] = this.generatePriceGroupRows(
        notConnectedPriceGroups,
        null,
        null,
        showPriceItemIdentifiers,
      );

      rows = rows.concat(newRows);
      shownPriceItems = shownPriceItems.concat(includedPriceItems);
      shownPriceGroups = shownPriceGroups.concat(includedPriceGroups);
    }

    const notConnectedPriceItems = this.props.priceItemArray
      .filter((priceItem) => priceItem.active && !shownPriceItems.includes(priceItem.url))
      .sort((a, b) =>
        identifierComparator(getPriceItemIdentifier(a) || "", getPriceItemIdentifier(b) || ""),
      );
    if (notConnectedPriceItems.length && this.props.includePriceItemsNotConnectedToAnyPriceGroups) {
      notConnectedPriceItems.forEach((priceItem) => {
        const priceItemURL = priceItem.url;
        const unit = getUnitString(priceItem, this.props.unitLookup);
        const priceItemIdentifier = getPriceItemIdentifier(priceItem);
        const {price} = priceItem;
        rows.push(
          <OverviewRow
            key={`work-type-not-connected-price-item-${priceItemURL}`}
            itemName={priceItem.name}
            price={price != null ? price : undefined}
            priceItemIdentifier={priceItemIdentifier != null ? priceItemIdentifier : undefined}
            priceItemURL={priceItemURL}
            showPriceItemIdentifiers={showPriceItemIdentifiers}
            unit={unit}
            onAddPriceGroupClick={this.props.onAddPriceGroupClick}
          />,
        );
        const {minimumCount} = priceItem;
        if (minimumCount) {
          rows.push(
            <OverviewRow
              key={`work-type-not-connected-price-item-${priceItemIdentifier}-minimumPrice`}
              priceItemConfiguration
              itemName={formatMessage(messages.minimumPrice, {
                minimumCount: formatNumber(minimumCount),
              })}
              price={price != null ? minimumCount * price : undefined}
              showPriceItemIdentifiers={showPriceItemIdentifiers}
            />,
          );
        }
        const volumeDiscountList = priceItem.volumeDiscountSet || [];
        volumeDiscountList.forEach((volumeDiscount, discountIndex) => {
          const percentage = volumeDiscount.discountPercentage;
          const {threshold} = volumeDiscount;
          rows.push(
            <OverviewRow
              key={`work-type-not-connected-price-item-${priceItemIdentifier}-volumeDiscount-${discountIndex}`}
              priceItemConfiguration
              itemName={formatMessage(messages.volumeDiscount, {
                percentage: percentage != null ? formatNumber(percentage) : "",
                threshold: threshold != null ? formatNumber(threshold) : "",
              })}
              price={price != null ? (price * (100 - (percentage || 0))) / 100 : undefined}
              showPriceItemIdentifiers={showPriceItemIdentifiers}
              unit={unit}
            />,
          );
        });
      });
    }
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                defaultMessage="Område-ID"
                id="worktype-overview.table-header.worktype-id"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Område-Navn"
                id="worktype-overview.table-header.worktype-name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Variant-ID"
                id="worktype-overview.table-header.pricegroup-id"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Variant-Navn"
                id="worktype-overview.table-header.pricegroup-name"
              />
            </TableCell>
            {showPriceItemIdentifiers ? (
              <TableCell>
                <FormattedMessage
                  defaultMessage="Pris-ID"
                  id="worktype-overview.table-header.price-id"
                />
              </TableCell>
            ) : null}
            <TableCell>
              <FormattedMessage
                defaultMessage="Pris-Navn og Opsætning"
                id="worktype-overview.table-header.price-name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Pris-Enhed"
                id="worktype-overview.table-header.price-unit"
              />
            </TableCell>
            {overviewShowPrices ? (
              <TableCell style={{textAlign: "right"}}>
                <FormattedMessage
                  defaultMessage="Pris (kr)"
                  id="worktype-overview.table-header.price"
                />
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }
}
