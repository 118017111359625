import {Culture, Order, Task} from "@co-common-libs/resources";
import {WEEK_DAYS, dateFromString, dateToString} from "@co-common-libs/utils";
import {DateField} from "@co-frontend-libs/components";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  QueryQueryStateStruct,
  actions,
  getOrderArray,
  getPathName,
  getSyncedState,
  getTaskArray,
  getToken,
} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {Card, CardContent, CardHeader, CircularProgress} from "@material-ui/core";
import {WorkTypeSumList} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {endOfDay, startOfDay, subDays} from "date-fns";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  consumptionForPeriod: {
    defaultMessage: "Forbrug i periode",
    id: "culture-instance.card-title.orders-for-period",
  },
  fromDate: {
    defaultMessage: "Fra",
    id: "culture-instance.label.from-date",
  },
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "culture-instance.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "culture-instance.label.archive-waiting",
  },
  toDate: {
    defaultMessage: "Til",
    id: "culture-instance.label.to-date",
  },
});

interface CultureStatisticsCardStateProps {
  orderArray: readonly Order[];
  pathName: string;
  taskArray: readonly Task[];
  taskSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  token: string | null;
}

interface CultureStatisticsCardDispatchProps {
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface CultureStatisticsCardOwnProps {
  culture: Culture;
  fromDate: string | null;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  toDate: string | null;
}

type CultureStatisticsCardProps = CultureStatisticsCardDispatchProps &
  CultureStatisticsCardOwnProps &
  CultureStatisticsCardStateProps;

interface CultureStatisticsCardState {
  query: Query | null;
}

const TEMPORARY_QUERIES_KEY = "CultureStatisticsCard";

class CultureStatisticsCard extends PureComponent<
  CultureStatisticsCardProps,
  CultureStatisticsCardState
> {
  state: CultureStatisticsCardState = {
    query: null,
  };
  UNSAFE_componentWillMount(): void {
    const {fromDate, toDate} = this.props;
    this.fetchFromArchive(fromDate, toDate);
  }
  UNSAFE_componentWillReceiveProps(nextProps: CultureStatisticsCardProps): void {
    if (nextProps.fromDate !== this.props.fromDate || nextProps.toDate !== this.props.toDate) {
      this.fetchFromArchive(nextProps.fromDate, nextProps.toDate);
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  fetchFromArchive(fromDate: string | null, toDate: string | null): void {
    const {culture, pathName} = this.props;
    if (fromDate && toDate && toDate >= fromDate) {
      const rangeStartString = startOfDay(dateFromString(fromDate) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(toDate) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                memberName: "workToTimestamp",
                type: "memberGt",
                value: rangeStartString,
              },
              {
                memberName: "workFromTimestamp",
                type: "memberLt",
                value: rangeEndString,
              },
              {memberName: "completed", type: "memberTruthy"},
            ],
            type: "and",
          },
          {
            checks: [
              {
                memberName: "date",
                type: "memberGte",
                value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
              },
              {
                memberName: "date",
                type: "memberLte",
                value: toDate,
              },
            ],
            type: "and",
          },
          {memberName: "completed", type: "memberFalsy"},
        ],
        type: "or",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const taskQuery = makeQuery({
        check: taskCheck,
        filter: {
          cultureID: culture.id as string,
          fromDate,
          toDate,
        },
        independentFetch: true,
        resourceName: "task",
      });
      const orderQuery = makeQuery({
        check: orderCheck,
        independentFetch: false,
        resourceName: "order",
      });
      this.props.temporaryQueriesRequestedForPath(
        [taskQuery, orderQuery],
        pathName,
        TEMPORARY_QUERIES_KEY,
      );
      this.setState({query: taskQuery});
    }
  }
  @bind
  handleFromFieldChange(value: string | null): void {
    this.props.putQueryKey("fromDate", value || "");
  }
  @bind
  handleToFieldChange(value: string | null): void {
    this.props.putQueryKey("toDate", value || "");
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {culture, fromDate, orderArray, taskArray, taskSyncedState, toDate} = this.props;
    let message;
    let showSpinner = false;
    {
      const {query} = this.state;
      if (query) {
        const querySyncedState = taskSyncedState.get(query.keyString);
        if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
          message = formatMessage(messages.onlineArchiveWaiting);
          showSpinner = true;
        } else {
          const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp;
          const {lastErrorTimestamp} = querySyncedState.queryState;
          if (lastErrorTimestamp && lastTimestamp && lastErrorTimestamp > lastTimestamp) {
            message = formatMessage(messages.onlineArchiveError);
          }
        }
      }
    }
    let content;
    if (message) {
      let spinner;
      if (showSpinner) {
        spinner = <CircularProgress />;
      }
      content = (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>
      );
    } else if (fromDate && toDate && toDate >= fromDate) {
      const cultureURL = culture.url;
      const cultureOrderURLSet = new Set(
        orderArray.filter((order) => order.culture === cultureURL).map((order) => order.url),
      );
      const taskList = taskArray.filter((task) => {
        if (!task.completed || !task.order || !cultureOrderURLSet.has(task.order)) {
          return false;
        }
        const {date} = task;
        return date && fromDate <= date && date <= toDate;
      });
      if (!taskList.length) {
        content = (
          <div style={{padding: 8, textAlign: "center"}}>
            <FormattedMessage
              defaultMessage="Ingen opgaver i den valgte periode for denne kultur."
              id="culture-instance.text.no-tasks-found"
            />
          </div>
        );
      } else {
        content = (
          <WorkTypeSumList
            customerName={culture.name}
            fromDate={fromDate}
            taskList={taskList}
            toDate={toDate}
          />
        );
      }
    }
    return (
      <Card>
        <CardHeader title={formatMessage(messages.consumptionForPeriod)} />
        <CardContent>
          <Grid>
            <Cell palm="12/12">
              <DateField
                autoFocus
                autoOk
                fullWidth
                label={formatMessage(messages.fromDate)}
                margin="dense"
                value={this.props.fromDate}
                onChange={this.handleFromFieldChange}
              />
            </Cell>
            <Cell palm="12/12">
              <DateField
                autoOk
                fullWidth
                label={formatMessage(messages.toDate)}
                margin="dense"
                value={this.props.toDate}
                onChange={this.handleToFieldChange}
              />
            </Cell>
          </Grid>
        </CardContent>
        {content}
      </Card>
    );
  }
}

const ConnectedCultureStatisticsCard: React.ComponentType<CultureStatisticsCardOwnProps> = connect<
  CultureStatisticsCardStateProps,
  CultureStatisticsCardDispatchProps,
  CultureStatisticsCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CultureStatisticsCardStateProps>({
    orderArray: getOrderArray,
    pathName: getPathName,
    taskArray: getTaskArray,
    taskSyncedState: getSyncedState.bind(null, "task"),
    token: getToken,
  }),
  {
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
  },
)(CultureStatisticsCard);

export {ConnectedCultureStatisticsCard as CultureStatisticsCard};
