import {
  Order,
  Patch,
  PatchOperation,
  ReportingData,
  ReportingLog,
  ReportingSpecification,
  Task,
  TimerUrl,
} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getLocationLookup,
  getPriceItemLookup,
  getTaskArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Card, CardContent, Checkbox, FormControlLabel} from "@material-ui/core";
import {
  LocationDialog,
  LogTable,
  PickupDeliveryLocationsBlock,
  ReportingDialog,
  TaskDataTable,
  TransportTotalsTable,
  WorkplaceLocationsBlock,
  WorkplaceTotalsTable,
} from "app-components";
import {getFieldNotesPerLocation, skippedLogChange, useEventTargetCheckedCallback} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AdministrationNotesCard} from "../administration-notes-card";
import {LogEntryDialog} from "./log-entry-dialog";
import {MaterialWarningDialog} from "./material-warning-dialog";
import {ReportingPrintoutCard} from "./reporting-printout-card";

interface LogCardProps {
  logSpecification: ReportingSpecification;
  onRequestBuildReports: () => void;
  order: Order;
  readonly: boolean;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
}

export const LogCard = React.memo(function LogCard(props: LogCardProps): JSX.Element {
  const {logSpecification, onRequestBuildReports, order, readonly, task, timerMinutesMap} = props;

  const locationLookup = useSelector(getLocationLookup);
  const taskArray = useSelector(getTaskArray);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);

  const dispatch = useDispatch();

  const intl = useIntl();

  const {taskData, workplaceRegistration} = logSpecification;

  const fieldNotesPerLocation = useMemo(
    () => getFieldNotesPerLocation(logSpecification, task, order, locationLookup),
    [locationLookup, logSpecification, order, task],
  );

  const observedLocationCounts = new Map<string, number>();
  if (task.reportingLog) {
    Object.values(task.reportingLog).forEach((data) => {
      const locationID = data.location;
      observedLocationCounts.set(locationID, (observedLocationCounts.get(locationID) || 0) + 1);
    });
  }
  const observedLocations = new Set(observedLocationCounts.keys());

  const [swappingLocations, setSwappingLocations] = useState(false);
  const handleSwapButtonClick = useCallback(() => {
    setSwappingLocations(!swappingLocations);
  }, [swappingLocations]);
  const handleReorderLocations = useCallback(
    (fromIdentifier: string, toIdentifier: string): void => {
      setSwappingLocations(false);
      const orderedReportingLocations = _.sortBy(
        Object.entries(task.reportingLocations || {}),
        ([_identifier, reportingLocation]) => reportingLocation.order,
      );
      const fromIndex = orderedReportingLocations.findIndex(
        ([identifier, _reportingLocation]) => identifier === fromIdentifier,
      );
      const toIndex = orderedReportingLocations.findIndex(
        ([identifier, _reportingLocation]) => identifier === toIdentifier,
      );
      if (fromIndex === -1 || toIndex === -1) {
        return;
      }
      const patch: PatchOperation<Task>[] = [];
      const entry = orderedReportingLocations[fromIndex];
      orderedReportingLocations.splice(fromIndex, 1);
      orderedReportingLocations.splice(toIndex, 0, entry);
      let nextOrderValueMin = 0;
      orderedReportingLocations.forEach(([identifier, reportingLocation]) => {
        if (reportingLocation.order < nextOrderValueMin) {
          patch.push({
            path: ["reportingLocations", identifier, "order"],
            value: nextOrderValueMin,
          });
          nextOrderValueMin += 1;
        } else {
          nextOrderValueMin = reportingLocation.order + 1;
        }
      });
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
    },
    [dispatch, task.reportingLocations, task.url],
  );

  const [addEditWorkplaceLocationDialogOpen, setAddEditWorkplaceLocationDialogOpen] =
    useState(false);

  const [editingWorkplaceIdentifier, setEditingWorkplaceIdentifier] = useState<string | null>(null);

  const [addEditPickupLocationDialogOpen, setAddEditPickupLocationDialogOpen] = useState(false);

  const [editingPickupLocationIdentifier, setEditingPickupLocationIdentifier] = useState<
    string | null
  >(null);

  const [addEditDeliveryLocationDialogOpen, setAddEditDeliveryLocationDialogOpen] = useState(false);

  const [editingDeliveryLocationIdentifier, setEditingDeliveryLocationIdentifier] = useState<
    string | null
  >(null);

  const [editingLogEntryIdentifier, setEditingLogEntryIdentifier] = useState<string | null>(null);

  const [deleteEntryRequest, setDeleteEntryRequest] = useState<string | null>(null);

  const handleRequestEditEntryLocationButton = useCallback(
    (
      locationEntryType: "delivery" | "pickup" | "workplace",
      locationEntryIdentifier: string,
    ): void => {
      if (locationEntryType === "workplace") {
        setEditingWorkplaceIdentifier(locationEntryIdentifier);
        setAddEditWorkplaceLocationDialogOpen(true);
      } else if (locationEntryType === "pickup") {
        setEditingPickupLocationIdentifier(locationEntryIdentifier);
        setAddEditPickupLocationDialogOpen(true);
      } else if (locationEntryType === "delivery") {
        setEditingDeliveryLocationIdentifier(locationEntryIdentifier);
        setAddEditDeliveryLocationDialogOpen(true);
      }
    },
    [],
  );

  const handleAddWorkplaceLocation = useCallback(() => {
    setEditingWorkplaceIdentifier(null);
    setAddEditWorkplaceLocationDialogOpen(true);
  }, []);
  const handleAddWorkplaceLocationClose = useCallback(() => {
    setAddEditWorkplaceLocationDialogOpen(false);
  }, []);

  const handleRequestAddPickupLocation = useCallback(() => {
    setEditingPickupLocationIdentifier(null);
    setAddEditPickupLocationDialogOpen(true);
  }, []);
  const handleAddPickupLocationClose = useCallback(() => {
    setAddEditPickupLocationDialogOpen(false);
  }, []);

  const handleRequestAddDeliveryLocation = useCallback(() => {
    setEditingDeliveryLocationIdentifier(null);
    setAddEditDeliveryLocationDialogOpen(true);
  }, []);
  const handleAddDeliveryLocationClose = useCallback(() => {
    setAddEditDeliveryLocationDialogOpen(false);
  }, []);

  const [entryLocationIdentifier, setEntryLocationIdentifier] = useState<string | null>(null);
  const [logEntryDialogOpen, setLogEntryDialogOpen] = useState(false);
  const [logEntryType, setLogEntryType] = useState<"delivery" | "pickup" | "workplace" | null>(
    null,
  );

  const handleLocationButtonClick = useCallback(
    (
      locationEntryType: "delivery" | "pickup" | "workplace",
      locationEntryIdentifier: string,
    ): void => {
      setLogEntryType(locationEntryType);
      setEntryLocationIdentifier(locationEntryIdentifier);
      setEditingLogEntryIdentifier(null);
      setLogEntryDialogOpen(true);
    },
    [],
  );

  const handleRequestEditEntry = useCallback((entryIdentifier: string): void => {
    setLogEntryType(null);
    setEntryLocationIdentifier(null);
    setEditingLogEntryIdentifier(entryIdentifier);
    setLogEntryDialogOpen(true);
  }, []);

  const handleLogEntryDialogClose = useCallback(() => {
    setLogEntryDialogOpen(false);
  }, []);

  const handleRequestEditEntryLocation = useCallback(() => {
    if (entryLocationIdentifier && logEntryType) {
      // from new
      handleRequestEditEntryLocationButton(logEntryType, entryLocationIdentifier);
    } else if (editingLogEntryIdentifier) {
      // from edit
      const editingReportingEntry = task.reportingLog?.[editingLogEntryIdentifier];
      if (editingReportingEntry) {
        handleRequestEditEntryLocationButton(
          editingReportingEntry.type,
          editingReportingEntry.location,
        );
      }
    }
  }, [
    editingLogEntryIdentifier,
    entryLocationIdentifier,
    handleRequestEditEntryLocationButton,
    logEntryType,
    task.reportingLog,
  ]);

  const handleRequestDeleteEntry = useCallback((): void => {
    setLogEntryDialogOpen(false);
    setDeleteEntryRequest(editingLogEntryIdentifier);
  }, [editingLogEntryIdentifier]);

  const handleDeleteEntryCancel = useCallback((): void => {
    setDeleteEntryRequest(null);
  }, []);

  const handleDeleteEntryOk = useCallback((): void => {
    setDeleteEntryRequest(null);
    if (deleteEntryRequest) {
      const patch: Patch<Task> = [{path: ["reportingLog", deleteEntryRequest], value: undefined}];
      dispatch(actions.update(task.url, patch));
    }
  }, [deleteEntryRequest, dispatch, task.url]);

  const WORKPLACE_REGISTRATION_WORKPLACE = 1;
  const WORKPLACE_REGISTRATION_TRANSPORT = 2;

  let locationsBlock: JSX.Element | undefined;
  const addLocationDialogs: JSX.Element[] = [];

  let totalsCard: JSX.Element | undefined;

  if (workplaceRegistration === WORKPLACE_REGISTRATION_WORKPLACE) {
    const workplaceLogEntryAllowedCount = logSpecification.workplaceData.workplace?.logEntries;
    const completedWorkplaceLocations =
      workplaceLogEntryAllowedCount === 1 ? observedLocations : new Set<string>();
    locationsBlock = (
      <WorkplaceLocationsBlock
        completedWorkplaceLocations={completedWorkplaceLocations}
        fieldNotesPerLocation={fieldNotesPerLocation || undefined}
        locationCounts={observedLocationCounts}
        logSpecification={logSpecification}
        readonly={readonly}
        reportingLocations={task.reportingLocations || {}}
        reportingLog={task.reportingLog || {}}
        swappingLocations={swappingLocations}
        task={task}
        onEditLocationButtonClick={handleRequestEditEntryLocationButton}
        onLocationButtonClick={handleLocationButtonClick}
        onReorderWorkplaceLocations={handleReorderLocations}
        onRequestAddWorkplaceLocation={handleAddWorkplaceLocation}
        onSwapButtonClick={handleSwapButtonClick}
      />
    );

    const workplaceDeleteDisabled =
      !editingWorkplaceIdentifier ||
      (!_.isEmpty(task.reportingLog) &&
        Object.values(task.reportingLog as ReportingLog).some(
          (entry) => entry.location === editingWorkplaceIdentifier && entry.type === "workplace",
        ));
    addLocationDialogs.push(
      <LocationDialog
        key="workplace-location-dialog"
        deleteDisabled={workplaceDeleteDisabled}
        editingIdentifier={editingWorkplaceIdentifier || null}
        logSpecification={logSpecification}
        open={addEditWorkplaceLocationDialogOpen}
        orderCustomerUrl={order.customer || null}
        task={task}
        title={intl.formatMessage({defaultMessage: "Arbejdssted"})}
        type="workplace"
        onClose={handleAddWorkplaceLocationClose}
      />,
    );

    if (
      logSpecification.allowTotalsTable !== false &&
      task.reportingLog &&
      Object.keys(task.reportingLog).length
    ) {
      totalsCard = (
        <WorkplaceTotalsTable
          logSpecification={logSpecification}
          showLoadCounts={logSpecification.showLoadCounts}
          task={task}
        />
      );
    }
  } else if (workplaceRegistration === WORKPLACE_REGISTRATION_TRANSPORT) {
    const pickupLogEntryAllowedCount = logSpecification.workplaceData.pickup?.logEntries;
    const deliveryLogEntryAllowedCount = logSpecification.workplaceData.delivery?.logEntries;
    const completedPickupLocations =
      pickupLogEntryAllowedCount === 1 ? observedLocations : new Set<string>();
    const completedDeliveryLocations =
      deliveryLogEntryAllowedCount === 1 ? observedLocations : new Set<string>();
    locationsBlock = (
      <PickupDeliveryLocationsBlock
        completedDeliveryLocations={completedDeliveryLocations}
        completedPickupLocations={completedPickupLocations}
        fieldNotesPerLocation={fieldNotesPerLocation || undefined}
        locationCounts={observedLocationCounts}
        logSpecification={logSpecification}
        readonly={readonly}
        reportingLocations={task.reportingLocations || {}}
        reportingLog={task.reportingLog || {}}
        swappingLocations={swappingLocations}
        task={task}
        onEditLocationButtonClick={handleRequestEditEntryLocationButton}
        onLocationButtonClick={handleLocationButtonClick}
        onReorderDeliveryLocations={handleReorderLocations}
        onReorderPickupLocations={handleReorderLocations}
        onRequestAddDeliveryLocation={handleRequestAddDeliveryLocation}
        onRequestAddPickupLocation={handleRequestAddPickupLocation}
        onSwapButtonClick={handleSwapButtonClick}
      />
    );

    const pickupDeleteDisabled =
      !editingPickupLocationIdentifier ||
      (!_.isEmpty(task.reportingLog) &&
        Object.values(task.reportingLog as ReportingLog).some(
          (entry) => entry.location === editingPickupLocationIdentifier && entry.type === "pickup",
        ));
    addLocationDialogs.push(
      <LocationDialog
        key="pickup-location-dialog"
        deleteDisabled={pickupDeleteDisabled}
        editingIdentifier={editingPickupLocationIdentifier || null}
        logSpecification={logSpecification}
        open={addEditPickupLocationDialogOpen}
        orderCustomerUrl={order.customer || null}
        task={task}
        title={intl.formatMessage({defaultMessage: "Afhentningssted"})}
        type="pickup"
        onClose={handleAddPickupLocationClose}
      />,
    );

    const deliveryDeleteDisabled =
      !editingDeliveryLocationIdentifier ||
      (!_.isEmpty(task.reportingLog) &&
        Object.values(task.reportingLog as ReportingLog).some(
          (entry) =>
            entry.location === editingDeliveryLocationIdentifier && entry.type === "delivery",
        ));
    addLocationDialogs.push(
      <LocationDialog
        key="delivery-location-dialog"
        deleteDisabled={deliveryDeleteDisabled}
        editingIdentifier={editingDeliveryLocationIdentifier || null}
        logSpecification={logSpecification}
        open={addEditDeliveryLocationDialogOpen}
        orderCustomerUrl={order.customer || null}
        task={task}
        title={intl.formatMessage({defaultMessage: "Leveringssted"})}
        type="delivery"
        onClose={handleAddDeliveryLocationClose}
      />,
    );

    if (
      logSpecification.allowTotalsTable !== false &&
      logSpecification.shared &&
      task.reportingLog &&
      Object.keys(task.reportingLog).length
    ) {
      totalsCard = (
        <TransportTotalsTable
          otherTasks={taskArray.filter((t) => t.order === task.order && t.url !== task.url)}
          showLoadCounts={logSpecification.showLoadCounts}
          task={task}
        />
      );
    }
  }

  const [taskDataInputDialogOpen, setTaskDataInputDialogOpen] = useState(false);
  const setTaskDataInputDialogOpenTrue = useCallWithTrue(setTaskDataInputDialogOpen);
  const setTaskDataInputDialogOpenFalse = useCallWithFalse(setTaskDataInputDialogOpen);

  const handleTaskDataInputDialogOk = useCallback(
    (data: ReportingData): void => {
      const patch: PatchOperation<Task>[] = [];
      if (task.reportingSpecification !== logSpecification.url) {
        patch.push({
          member: "reportingSpecification",
          value: logSpecification.url,
        });
      }
      if (task.reportingData) {
        for (const [identifier, value] of Object.entries(data)) {
          if (value !== undefined && value !== task.reportingData[identifier]) {
            patch.push({path: ["reportingData", identifier], value});
          }
        }
        for (const identifier of Object.keys(task.reportingData)) {
          if (data[identifier] === undefined) {
            patch.push({path: ["reportingData", identifier], value: undefined});
          }
        }
      } else {
        patch.push({member: "reportingData", value: data});
      }
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
      setTaskDataInputDialogOpen(false);
    },
    [dispatch, logSpecification.url, task.reportingData, task.reportingSpecification, task.url],
  );

  let taskDataBlock: JSX.Element | undefined;
  if (taskData.inputs && taskData.inputs.length) {
    taskDataBlock = (
      <Card style={{margin: "1em", marginTop: 0}}>
        <CardContent>
          <TaskDataTable inputSpecifications={taskData.inputs} values={task.reportingData || {}} />
          <Button
            color="primary"
            disabled={readonly}
            variant="contained"
            onClick={setTaskDataInputDialogOpenTrue}
          >
            {logSpecification.taskData.enterBaseDataLabel ??
              intl.formatMessage({defaultMessage: "Udfyld stamdata"})}
          </Button>
        </CardContent>
      </Card>
    );
  }

  let logCard: JSX.Element | undefined;
  if (task.reportingLog && Object.keys(task.reportingLog).length) {
    logCard = (
      <LogTable
        logSpecification={logSpecification}
        readonly={readonly}
        task={task}
        onRequestEditEntry={handleRequestEditEntry}
      />
    );
  }

  let printoutsCard: JSX.Element | undefined;
  if (task.completed) {
    printoutsCard = (
      <ReportingPrintoutCard
        logTitle={logSpecification.name}
        task={task}
        onRequestBuildReports={onRequestBuildReports}
      />
    );
  }

  const [materialWarningDialogOpen, setMaterialWarningDialogOpen] = useState(false);

  const handleLogSkippedChange = useEventTargetCheckedCallback(
    (checked: boolean): void => {
      skippedLogChange(
        logSpecification,
        checked,
        task,
        priceItemLookup,
        unitLookup,
        setMaterialWarningDialogOpen,
        dispatch,
      );
    },
    [dispatch, logSpecification, priceItemLookup, task, unitLookup],
  );

  const handleMaterialWarningDialogClose = useCallback((): void => {
    setMaterialWarningDialogOpen(false);
  }, []);

  const customerSettings = useSelector(getCustomerSettings);
  return (
    <div>
      {logSpecification.allowSkip ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={task.logSkipped}
              disabled={readonly || !!(task.reportingLog && Object.keys(task.reportingLog).length)}
              onChange={handleLogSkippedChange}
            />
          }
          label={intl.formatMessage({defaultMessage: "Ingen log"})}
          style={{paddingLeft: "1em", paddingRight: "1em"}}
        />
      ) : null}
      {customerSettings.showTaskInfoManagerNotesAboveLogButtons ? (
        <AdministrationNotesCard order={order} task={task} />
      ) : null}
      {!task.logSkipped ? (
        <>
          {locationsBlock}
          {taskDataBlock}
          {totalsCard}
          {logCard}
          {printoutsCard}
          {logSpecification.taskData?.inputs?.length ? (
            <ReportingDialog
              logSpecification={logSpecification}
              open={taskDataInputDialogOpen}
              task={task}
              title={intl.formatMessage({defaultMessage: "Stamdata"})}
              onCancel={setTaskDataInputDialogOpenFalse}
              onOk={handleTaskDataInputDialogOk}
            />
          ) : null}
          <LogEntryDialog
            editingIdentifier={editingLogEntryIdentifier}
            fieldNotesPerLocation={fieldNotesPerLocation}
            locationIdentifier={entryLocationIdentifier}
            logSpecification={logSpecification}
            open={logEntryDialogOpen}
            task={task}
            timerMinutesMap={timerMinutesMap}
            type={logEntryType}
            onClose={handleLogEntryDialogClose}
            onRequestDeleteEntry={handleRequestDeleteEntry}
            onRequestEditEntryLocation={handleRequestEditEntryLocation}
          />
          {addLocationDialogs}
          <DeleteDialog
            open={deleteEntryRequest != null}
            onCancel={handleDeleteEntryCancel}
            onOk={handleDeleteEntryOk}
          >
            <FormattedMessage defaultMessage="Slet postering?" />
          </DeleteDialog>
        </>
      ) : null}
      <MaterialWarningDialog
        open={materialWarningDialogOpen}
        onClose={handleMaterialWarningDialogClose}
      />
    </div>
  );
});
