import {createTheme} from "@material-ui/core";
import {
  blueGrey,
  common as commonColors,
  green,
  grey,
  lightBlue,
  orange,
} from "@material-ui/core/colors";
import bowser from "bowser";

declare module "@material-ui/core/styles/createPalette" {
  export interface Palette {
    consultant: Palette["primary"];
  }
  export interface PaletteOptions {
    consultant: PaletteOptions["primary"];
  }
}

export enum SPACING {
  NONE = 0,
  XSMALL = 1,
  SMALL = 2,
  MEDIUM = 4,
  LARGE = 6,
  XLARGE = 8,
}

export const theme = createTheme({
  overrides: {
    MuiButton: {
      contained: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "&:disabled": {
          color: commonColors.black,
        },
      },
      text: (window as any).GUEST_APP
        ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "&:disabled": {
              color: grey[500],
            },
          }
        : {},
    },
    MuiCard: {
      root: {
        minWidth: "fit-content",
      },
    },
    MuiIconButton: {
      root: {
        color: commonColors.black,
      },
    },
    MuiListItemIcon: {
      root: {
        color: commonColors.black,
      },
    },
    MuiTable: {
      root: {
        backgroundColor: commonColors.white,
      },
    },
    MuiTableCell:
      bowser.mobile || bowser.tablet
        ? {
            root: {
              padding: 8,
            },
            sizeSmall: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "&:last-child": {
                paddingRight: 8,
              },
              padding: "6px 8px 6px 8px",
            },
          }
        : {},
  },
  palette: {
    consultant: {
      dark: orange[300],
      light: orange[100],
      main: orange[200],
    },
    primary: {
      contrastText: commonColors.white,
      main: (window as any).GUEST_APP ? blueGrey[900] : lightBlue[500],
    },
    secondary: {
      main: green[700],
    },
    text: {
      primary: commonColors.black,
    },
  },
  props: {
    MuiFab: {
      color: "primary",
    },
    MuiIconButton: {
      color: "primary",
    },
  },
});
