import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import React, {useCallback} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  registerAccommodation: {
    defaultMessage: "Registrer diæt/udetillæg",
    id: "accommodation-allowance-checkbox.register-accommodation",
  },
  registerAccommodationOverride: {
    defaultMessage: "Registrer {label}",
    id: "accommodation-allowance-checkbox.register-accommodation-override",
  },
});

interface AccommodationAllowanceCheckboxProps {
  /** checkbox checked and read-only */
  accommodationAllowanceRegistratedOnTaskDate: boolean;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  onRegisterAccommodationChanged: (
    registerAccommodation: boolean,
    remunerationGroup: string,
  ) => void;
  /** checkbox checked; accommodationAllowanceRegistratedOnTaskDate overrides */
  registerAccommodation: boolean;
  remunerationGroup: string;
}

export const AccommodationAllowanceCheckbox = React.memo(function AccommodationAllowanceCheckbox(
  props: AccommodationAllowanceCheckboxProps,
): JSX.Element {
  const {
    accommodationAllowanceRegistratedOnTaskDate,
    labelPlacement = "end",
    onRegisterAccommodationChanged,
    registerAccommodation,
    remunerationGroup,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);

  const {formatMessage} = useIntl();

  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onRegisterAccommodationChanged(checked, remunerationGroup);
    },
    [onRegisterAccommodationChanged, remunerationGroup],
  );

  const remunerationGroupAccomodationAllowanceLabel =
    customerSettings.remunerationGroups[remunerationGroup]?.accomodationAllowanceLabel;

  const label = remunerationGroupAccomodationAllowanceLabel
    ? formatMessage(messages.registerAccommodationOverride, {
        label: remunerationGroupAccomodationAllowanceLabel,
      })
    : formatMessage(messages.registerAccommodation);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={accommodationAllowanceRegistratedOnTaskDate || registerAccommodation}
          disabled={accommodationAllowanceRegistratedOnTaskDate}
          onChange={handleChange}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
});
