import {Config} from "@co-common-libs/config";
import {
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskResult,
  RoutePlanTaskUrl,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskResult,
  RouteTaskUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {priceItemIsTime} from "@co-common-libs/resources-utils";
import {HOUR_MINUTES, customerAddress, notUndefined} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  getCustomerSettings,
  getLocationLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getRoutePlanTaskActivityOptionArray,
  getRoutePlanTaskLookup,
  getRoutePlanTaskResultArray,
  getRouteTaskActivityOptionArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {adjustMinutes} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {PriceGroupSelectionPart} from "./price-group-selection-part";
import {RouteTaskResultBlock} from "./route-task-result-block";

function getPriceItemValues(
  selectedPriceGroup: string | null,
  filteredRoutePlanTaskActivityOptionArray: Readonly<RoutePlanTaskActivityOption>[],
  filteredRoutePlanTaskResultArray: Readonly<RoutePlanTaskResult>[],
  filteredRouteTaskActivityOptionArray: Readonly<RouteTaskActivityOption>[],
  filteredRouteTaskResultArray: Readonly<RouteTaskResult>[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  primaryMinutes: number,
  customerSettings: Config,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Map<PriceItemUrl, number | null> {
  const selectedRoutePlanTaskActivityOption = selectedPriceGroup
    ? filteredRoutePlanTaskActivityOptionArray.find(
        (activityOption) => activityOption.activity === selectedPriceGroup,
      )
    : undefined;
  const selectedRoutePlanTaskResultArray = selectedRoutePlanTaskActivityOption
    ? _.sortBy(
        filteredRoutePlanTaskResultArray.filter(
          (result) => result.activityOption === selectedRoutePlanTaskActivityOption.url,
        ),
        (result) => result.order,
      )
    : [];
  const selectedRouteTaskActivityOption = selectedPriceGroup
    ? filteredRouteTaskActivityOptionArray.find(
        (activityOption) => activityOption.activity === selectedPriceGroup,
      )
    : undefined;
  const selectedRouteTaskResultArray = selectedRouteTaskActivityOption
    ? _.sortBy(
        filteredRouteTaskResultArray.filter(
          (result) => result.activityOption === selectedRouteTaskActivityOption.url,
        ),
        (result) => result.order,
      )
    : [];
  const result = new Map<PriceItemUrl, number | null>();
  const handledPriceItems = new Set<string>();
  selectedRoutePlanTaskResultArray.forEach((routePlanTaskResult) => {
    const priceItemUrl = routePlanTaskResult.specification;
    handledPriceItems.add(priceItemUrl);
    const routeTaskResult = selectedRouteTaskResultArray.find(
      (r) => r.specification === priceItemUrl,
    );
    let quantity =
      routeTaskResult?.quantity ?? routeTaskResult?.quoted ?? routePlanTaskResult.quoted ?? null;
    if (quantity == null) {
      const priceItem = priceItemLookup(priceItemUrl);
      if (priceItem && priceItemIsTime(unitLookup, priceItem)) {
        const minutes = adjustMinutes(customerSettings.adjustBilledMinutes, primaryMinutes);
        const DECIMAL_PLACES = 2;
        const hoursValue = _.round(minutes / HOUR_MINUTES, DECIMAL_PLACES);
        quantity = hoursValue;
      }
    }
    result.set(priceItemUrl, quantity);
  });
  selectedRouteTaskResultArray.forEach((routeTaskResult) => {
    const priceItemUrl = routeTaskResult.specification;
    if (handledPriceItems.has(priceItemUrl)) {
      return;
    }
    let quantity = routeTaskResult?.quantity ?? routeTaskResult?.quoted ?? null;
    if (quantity == null) {
      const priceItem = priceItemLookup(priceItemUrl);
      if (priceItem && priceItemIsTime(unitLookup, priceItem)) {
        const minutes = adjustMinutes(customerSettings.adjustBilledMinutes, primaryMinutes);
        const DECIMAL_PLACES = 2;
        const hoursValue = _.round(minutes / HOUR_MINUTES, DECIMAL_PLACES);
        quantity = hoursValue;
      }
    }
    result.set(priceItemUrl, quantity);
  });
  return result;
}

interface RouteTaskCompletedDialogContentProps {
  address: string;
  editing: boolean;
  onCancel: () => void;
  onDelete?: (() => void) | undefined;
  onOk: (
    selectedPriceGroup: PriceGroupUrl,
    priceItemValues: Map<PriceItemUrl, number | null>,
    notes: string,
  ) => void;
  open: boolean;
  primaryMinutes: number;
  routePlanTask: RoutePlanTask;
  routeTask?: RouteTask | undefined;
  workplaceName: string;
}

const RouteTaskCompletedDialogContent = React.memo(function RouteTaskCompletedDialogContent(
  props: RouteTaskCompletedDialogContentProps,
): JSX.Element {
  const {
    address,
    editing,
    onCancel,
    onDelete,
    onOk,
    open,
    primaryMinutes,
    routePlanTask,
    routeTask,
    workplaceName,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const routeTaskActivityOptionArray = useSelector(getRouteTaskActivityOptionArray);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);

  const routePlanTaskActivityOptionArray = useSelector(getRoutePlanTaskActivityOptionArray);
  const routePlanTaskResultArray = useSelector(getRoutePlanTaskResultArray);

  const routePlanTaskUrl = routePlanTask?.url;
  const routeTaskUrl = routeTask?.url;

  // options/results from route plan
  const filteredRoutePlanTaskActivityOptionArray = useMemo(
    () =>
      routePlanTaskUrl
        ? routePlanTaskActivityOptionArray.filter(
            (activityOption) => activityOption.routePlanTask === routePlanTaskUrl,
          )
        : [],
    [routePlanTaskActivityOptionArray, routePlanTaskUrl],
  );
  const filteredRoutePlanTaskResultArray = useMemo(
    () =>
      routePlanTaskUrl
        ? routePlanTaskResultArray.filter((result) => result.routePlanTask === routePlanTaskUrl)
        : [],
    [routePlanTaskResultArray, routePlanTaskUrl],
  );

  // options/results from route
  const filteredRouteTaskActivityOptionArray = useMemo(
    () =>
      routeTaskUrl
        ? routeTaskActivityOptionArray.filter(
            (activityOption) => activityOption.routeTask === routeTaskUrl,
          )
        : [],
    [routeTaskActivityOptionArray, routeTaskUrl],
  );
  const filteredRouteTaskResultArray = useMemo(
    () =>
      routeTaskUrl
        ? routeTaskResultArray.filter((result) => result.routeTask === routeTaskUrl)
        : [],
    [routeTaskResultArray, routeTaskUrl],
  );

  const [selectedPriceGroup, setSelectedPriceGroup] = useState(routeTask?.activity ?? null);
  const [priceItemValues, setPriceItemValues] = useState(() =>
    getPriceItemValues(
      selectedPriceGroup,
      filteredRoutePlanTaskActivityOptionArray,
      filteredRoutePlanTaskResultArray,
      filteredRouteTaskActivityOptionArray,
      filteredRouteTaskResultArray,
      priceItemLookup,
      primaryMinutes,
      customerSettings,
      unitLookup,
    ),
  );
  const [notes, setNotes] = useState(routeTask?.notesFromTaskAssignee || "");

  const priceGroupList = useMemo(() => {
    const priceGroupListFromActivityOptions = _.sortBy(
      filteredRoutePlanTaskActivityOptionArray
        .map((activityOption) => priceGroupLookup(activityOption.activity))
        .filter(notUndefined),
      [(priceGroup) => priceGroup.identifier, (priceGroup) => priceGroup.name],
    );
    if (
      selectedPriceGroup &&
      priceGroupListFromActivityOptions.every((priceGroup) => priceGroup.url !== selectedPriceGroup)
    ) {
      const priceGroup = priceGroupLookup(selectedPriceGroup);
      if (priceGroup) {
        return priceGroupListFromActivityOptions.concat([priceGroup]);
      }
    }
    return priceGroupListFromActivityOptions;
  }, [filteredRoutePlanTaskActivityOptionArray, priceGroupLookup, selectedPriceGroup]);

  const intl = useIntl();

  const handleSelectPriceGroup = useCallback(
    (value: PriceGroupUrl): void => {
      setSelectedPriceGroup(value);
      setPriceItemValues(
        getPriceItemValues(
          value,
          filteredRoutePlanTaskActivityOptionArray,
          filteredRoutePlanTaskResultArray,
          filteredRouteTaskActivityOptionArray,
          filteredRouteTaskResultArray,
          priceItemLookup,
          primaryMinutes,
          customerSettings,
          unitLookup,
        ),
      );
    },
    [
      customerSettings,
      filteredRoutePlanTaskActivityOptionArray,
      filteredRoutePlanTaskResultArray,
      filteredRouteTaskActivityOptionArray,
      filteredRouteTaskResultArray,
      priceItemLookup,
      primaryMinutes,
      unitLookup,
    ],
  );

  const handleUpdateValue = useCallback(
    (priceItemURL: PriceItemUrl, value: number | null): void => {
      const newPriceItemValues = new Map(priceItemValues);
      newPriceItemValues.set(priceItemURL, value);
      setPriceItemValues(newPriceItemValues);
    },
    [priceItemValues],
  );

  const handleOk = useCallback((): void => {
    if (selectedPriceGroup) {
      onOk(selectedPriceGroup, priceItemValues, notes);
    }
  }, [notes, onOk, priceItemValues, selectedPriceGroup]);

  const okDisabled =
    !selectedPriceGroup ||
    Array.from(priceItemValues).some(([priceItemUrl, value]) => {
      const priceItem = priceItemLookup(priceItemUrl);
      if (!priceItem) {
        return false;
      }
      const relevantForExecution =
        priceItem.relevantForExecution != null ? priceItem.relevantForExecution : true;
      if (!relevantForExecution) {
        return false;
      }
      return value == null;
    });
  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      open={open}
      title={
        editing
          ? intl.formatMessage({defaultMessage: "Ret"})
          : intl.formatMessage({defaultMessage: "Færdig ved"})
      }
      onCancel={onCancel}
      onDelete={editing ? onDelete : undefined}
      onOk={handleOk}
    >
      <DialogContent>
        <div style={{paddingBottom: "1em"}}>
          <h3>
            {workplaceName}
            <br />
            {address}
          </h3>
        </div>
        <PriceGroupSelectionPart
          priceGroupList={priceGroupList}
          selected={selectedPriceGroup || undefined}
          onSelect={handleSelectPriceGroup}
        />
        <RouteTaskResultBlock
          customerSettings={customerSettings}
          editing={editing}
          priceItemLookup={priceItemLookup}
          priceItemValues={priceItemValues}
          unitLookup={unitLookup}
          onUpdateValue={handleUpdateValue}
        />
        <TrimTextField
          fullWidth
          multiline
          label={intl.formatMessage({defaultMessage: "Noter"})}
          margin="dense"
          maxRows={30}
          minRows={2}
          value={notes}
          variant="outlined"
          onChange={setNotes}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});

interface RouteTaskCompletedDialogContainerProps {
  editing: boolean;
  onCancel: () => void;
  onDelete?: () => void;
  onOk: (
    selectedPriceGroup: PriceGroupUrl,
    priceItemValues: Map<PriceItemUrl, number | null>,
    notes: string,
  ) => void;
  open: boolean;
  primaryMinutes: number;
  routePlanTaskUrl?: RoutePlanTaskUrl | undefined;
  routeTaskUrl?: RouteTaskUrl | undefined;
}

const RouteTaskCompletedDialogContainer = React.memo(function RouteTaskCompletedDialogContainer(
  props: RouteTaskCompletedDialogContainerProps,
): JSX.Element | null {
  const {editing, onCancel, onDelete, onOk, open, primaryMinutes, routePlanTaskUrl, routeTaskUrl} =
    props;

  const routePlanTaskLookup = useSelector(getRoutePlanTaskLookup);
  const routeTaskLookup = useSelector(getRouteTaskLookup);
  const locationLookup = useSelector(getLocationLookup);

  if (!routePlanTaskUrl || !open) {
    return null;
  }
  const routePlanTask = routePlanTaskLookup(routePlanTaskUrl);
  if (!routePlanTask) {
    return null;
  }
  const routeTask = routeTaskUrl ? routeTaskLookup(routeTaskUrl) : undefined;

  const workplaceURL = routeTask?.relatedLocation || routePlanTask.relatedLocation;
  const workplace = locationLookup(workplaceURL);
  const address = customerAddress(workplace);

  return (
    <RouteTaskCompletedDialogContent
      address={address}
      editing={editing}
      open={open}
      primaryMinutes={primaryMinutes}
      routePlanTask={routePlanTask}
      routeTask={routeTask}
      workplaceName={routeTask?.description || routePlanTask.description}
      onCancel={onCancel}
      onDelete={onDelete}
      onOk={onOk}
    />
  );
});

export {RouteTaskCompletedDialogContainer as RouteTaskCompletedDialog};
