import {Config} from "@co-common-libs/config";
import {
  Customer,
  Machine,
  MachineUrl,
  Order,
  Task,
  Timer,
  User,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {formatDateNumeric} from "@co-common-libs/utils";
import {DateField} from "@co-frontend-libs/components";
import {ConnectedMultipleExternalWorkTypesDialog} from "@co-frontend-libs/connected-components";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {PathTemplate, QueryQueryStateStruct} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Button, Card, CardContent, CardHeader, CircularProgress} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import memoizeOne from "memoize-one";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {moveDate, setDate} from "./log-tab";
import OrderList from "./order-list";

export const ENTRIES_BEFORE_ELLIPSIS = 3;

const messages = defineMessages({
  fromDate: {defaultMessage: "Fra", id: "customer-instance.label.from-date"},
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "customer-instance.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "customer-instance.label.archive-waiting",
  },
  ordersForPeriod: {
    defaultMessage: "Ordre i periode",
    id: "customer-instance.card-title.orders-for-period",
  },
  toDate: {defaultMessage: "Til", id: "customer-instance.label.to-date"},
});

interface PeriodOrdersCardProps {
  configPutRequest: (data: {key: string; value: any}) => void;
  customer: Customer;
  customerInstanceDateFields?: {fromDate: string; toDate: string} | undefined;
  customerSettings: Config;
  fromDate?: string;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderArray: readonly Order[];
  orderSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  pathName: string;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  taskArray: readonly Task[];
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  timerArray: readonly Timer[];
  toDate?: string;
  userLookup: (url: UserUrl) => User | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  workTypes: string;
}

interface PeriodOrdersCardState {
  fromDateMoved: boolean;
  toDateMoved: boolean;
  workTypeDialogOpen: boolean;
}

const TEMPORARY_QUERIES_KEY = "PeriodOrdersCard";

class PeriodOrdersCard extends PureComponent<PeriodOrdersCardProps, PeriodOrdersCardState> {
  state: PeriodOrdersCardState = {
    fromDateMoved: false,
    toDateMoved: false,
    workTypeDialogOpen: false,
  };
  UNSAFE_componentWillMount(): void {
    const {customerSettings, fromDate, toDate} = this.props;
    const {reportDataIsValidFrom} = customerSettings;
    const [fromDateMoved, newFromDate] = moveDate(reportDataIsValidFrom, fromDate);
    const [toDateMoved, newToDate] = moveDate(reportDataIsValidFrom, toDate);
    this.fetchFromArchive(newFromDate, newToDate);
    this.setState({fromDateMoved, toDateMoved});
  }
  UNSAFE_componentWillReceiveProps(nextProps: PeriodOrdersCardProps): void {
    if (nextProps.fromDate !== this.props.fromDate || nextProps.toDate !== this.props.toDate) {
      const {customerSettings, fromDate, toDate} = nextProps;
      const {reportDataIsValidFrom} = customerSettings;
      const [fromDateMoved, newFromDate] = moveDate(reportDataIsValidFrom, fromDate);
      const [toDateMoved, newToDate] = moveDate(reportDataIsValidFrom, toDate);
      this.fetchFromArchive(newFromDate, newToDate);
      this.setState({fromDateMoved, toDateMoved});
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  buildQueries(fromDate?: string | null, toDate?: string | null): Query[] {
    const {customer} = this.props;
    if (fromDate && toDate && toDate >= fromDate) {
      // FIXME: server-side check considers various fields on order, *and*
      // dates on tasks
      const orderCheck: Check = {
        type: "alwaysOk",
      };
      const taskCheck: Check = {
        check: orderCheck,
        memberName: "order",
        targetType: "order",
        type: "hasForeignKey",
      };
      const queries = [
        makeQuery({
          check: orderCheck,
          filter: {
            customerID: customer.id as string,
            fromDate,
            toDate,
          },
          independentFetch: true,
          resourceName: "order",
        }),
        makeQuery({
          check: taskCheck,
          independentFetch: false,
          resourceName: "task",
        }),
      ];
      return queries;
    } else {
      return [];
    }
  }
  fetchFromArchive(fromDate?: string | null, toDate?: string | null): void {
    const queries = this.buildQueries(fromDate, toDate);
    if (queries.length) {
      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY);
    }
  }

  @bind
  handleFromFieldChange(value: string | null): void {
    const {configPutRequest, customerInstanceDateFields, customerSettings, putQueryKey} =
      this.props;
    this.setState({
      fromDateMoved: setDate(
        {
          configPutRequest,
          customerInstanceDateFields,
          customerSettings,
          dateField: "fromDate",
          putQueryKey,
        },
        value,
      ),
    });
  }
  @bind
  handleToFieldChange(value: string | null): void {
    const {configPutRequest, customerInstanceDateFields, customerSettings, putQueryKey} =
      this.props;
    this.setState({
      toDateMoved: setDate(
        {
          configPutRequest,
          customerInstanceDateFields,
          customerSettings,
          dateField: "toDate",
          putQueryKey,
        },
        value,
      ),
    });
  }

  @bind
  handleWorkTypeSelectButton(): void {
    this.setState({workTypeDialogOpen: true});
  }

  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({workTypeDialogOpen: false});
  }
  @bind
  handleWorkTypeDialogOk(urls: ReadonlySet<string>): void {
    this.setState({workTypeDialogOpen: false});
    this.props.putQueryKey("workTypes", Array.from(urls).map(urlToId).join(","));
  }

  buildWorkTypesSet = memoizeOne((workTypeArray: WorkTypeUrl[]) => new Set(workTypeArray));

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customer, fromDate, orderArray, orderSyncedState, toDate, workTypes} = this.props;
    let message;
    let showSpinner = false;
    {
      const queries = this.buildQueries(fromDate, toDate);
      if (queries.length) {
        const query = queries[0];
        const querySyncedState = orderSyncedState.get(query.keyString);
        if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
          message = formatMessage(messages.onlineArchiveWaiting);
          showSpinner = true;
        } else {
          const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp;
          const {lastErrorTimestamp} = querySyncedState.queryState;
          if (lastErrorTimestamp && lastTimestamp && lastErrorTimestamp > lastTimestamp) {
            message = formatMessage(messages.onlineArchiveError);
          }
        }
      }
    }
    let workTypesCell;
    let workTypeArray: WorkTypeUrl[] = [];
    if (!this.props.customerSettings.noExternalTaskWorkType) {
      workTypeArray = workTypes
        ? workTypes.split(",").map((id) => instanceURL("workType", id))
        : [];
      const workTypesSet: ReadonlySet<WorkTypeUrl> = this.buildWorkTypesSet(workTypeArray);
      workTypesCell = (
        <div>
          <ConnectedMultipleExternalWorkTypesDialog
            open={this.state.workTypeDialogOpen}
            selected={workTypesSet}
            onCancel={this.handleWorkTypeDialogCancel}
            onOk={this.handleWorkTypeDialogOk}
          />
          <Button
            color="secondary"
            style={{marginTop: 2}}
            variant="contained"
            onClick={this.handleWorkTypeSelectButton}
          >
            <FormattedMessage
              defaultMessage="Vælg arbejdsområder"
              id="period-order-card.select-worktypes"
            />
          </Button>
          <div style={{whiteSpace: "pre-line"}}>
            {!workTypesSet.size ? (
              <FormattedMessage
                defaultMessage="Ingen filtrering"
                id="period-order-card.no-filter"
              />
            ) : (
              workTypeArray
                .map((workTypeURL) => getWorkTypeString(this.props.workTypeLookup(workTypeURL)))
                .sort()
                .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                .concat(workTypesSet.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                .join(",\n")
            )}
          </div>
        </div>
      );
    }
    let content;
    if (message) {
      let spinner;
      if (showSpinner) {
        spinner = <CircularProgress />;
      }
      content = (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>
      );
    } else if (fromDate && toDate && toDate >= fromDate) {
      const customerURL = customer.url;
      const customerOrderArray = orderArray.filter((order) => order.customer === customerURL);
      const customerOrderURLSet = new Set(customerOrderArray.map((order) => order.url));

      let filteredTaskArray = this.props.taskArray;
      if (workTypeArray.length) {
        filteredTaskArray = filteredTaskArray.filter(
          (task) => task.workType && workTypeArray.includes(task.workType),
        );
      }
      const tasksInPeriodOrderURLs = new Set(
        filteredTaskArray
          .filter((task) => task.order && customerOrderURLSet.has(task.order))
          .filter((task) => {
            const {date} = task;
            return date && fromDate <= date && date <= toDate;
          })
          .map((task) => task.order),
      );
      const orderList = _.sortBy(
        customerOrderArray.filter((order) => {
          const {date} = order;
          return (
            tasksInPeriodOrderURLs.has(order.url) ||
            (!workTypeArray.length && date && fromDate <= date && date <= toDate)
          );
        }),
        (order) => order.date,
      );
      if (this.props.customerSettings.customerOrderListNewestFirst) {
        orderList.reverse();
      }
      if (!orderList.length) {
        content = (
          <div style={{padding: 8, textAlign: "center"}}>
            {workTypeArray.length ? (
              <FormattedMessage
                defaultMessage="Kunden har ingen ordre, på de valgte områder, i perioden."
                id="order-instance.text.no-orders-found-with-filter"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Kunden har ingen ordre i den valgte periode."
                id="order-instance.text.no-orders-found"
              />
            )}
          </div>
        );
      } else {
        content = <OrderList orderList={orderList} taskArray={filteredTaskArray} />;
      }
    }

    return (
      <Card>
        <CardHeader title={formatMessage(messages.ordersForPeriod)} />
        <CardContent>
          <Grid>
            <Cell palm="12/12">
              <DateField
                autoFocus
                autoOk
                fullWidth
                label={formatMessage(messages.fromDate)}
                margin="dense"
                value={this.props.fromDate || null}
                onChange={this.handleFromFieldChange}
              />
            </Cell>
            <Cell palm="12/12">
              <DateField
                autoOk
                fullWidth
                label={formatMessage(messages.toDate)}
                margin="dense"
                value={this.props.toDate || null}
                onChange={this.handleToFieldChange}
              />
            </Cell>
          </Grid>
          {workTypesCell}
          <div style={{color: "red"}}>
            {this.state.fromDateMoved ? (
              <div>
                <FormattedMessage
                  defaultMessage="Den valgte fra dato lå før den første valide start dato: {date}, så datoen er flyttet frem"
                  id="customer-instance.label.from-date-error"
                  values={{date: formatDateNumeric(this.props.fromDate)}}
                />
              </div>
            ) : null}
            {this.state.toDateMoved ? (
              <div>
                <FormattedMessage
                  defaultMessage="Den valgte til dato lå før den første valide start dato: {date}, så datoen er flyttet frem"
                  id="customer-instance.label.to-date-error"
                  values={{date: formatDateNumeric(this.props.fromDate)}}
                />
              </div>
            ) : null}
          </div>
        </CardContent>
        {content}
      </Card>
    );
  }
}

export default PeriodOrdersCard;
