import {ProjectAccess, ProjectAccessChoices, ProjectUrl, urlToId} from "@co-common-libs/resources";
import {DebouncedAppbarSearchField} from "@co-frontend-libs/components";
import {
  PathTemplate,
  actions,
  getCurrentRoleIsManager,
  getCurrentRoleIsProjectManager,
  getCustomerSettings,
} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {MenuToolbar, PageLayout, ProjectsList} from "app-components";
import {useQueryParameter, useQueryParameterState} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface ProjectsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

function tabIsProjectAccess(tab: string): tab is ProjectAccess {
  return ProjectAccessChoices.includes(tab as ProjectAccess);
}

export type ProjectDetailUrls = Extract<PathTemplate, "/projects/:id" | "/settings/projects/:id">;

export function getProjectListPageWithDetailsUrl(
  projectDetailsUrl: ProjectDetailUrls,
): React.FunctionComponent<ProjectsPageProps> {
  return React.memo(function ProjectListPage(props: ProjectsPageProps): JSX.Element {
    const intl = useIntl();
    const {projectLabelVariant} = useSelector(getCustomerSettings);
    const [filter, setFilter] = useQueryParameterState<string>("q", "");
    const isManager = useSelector(getCurrentRoleIsManager);
    const isProjectManager = useSelector(getCurrentRoleIsProjectManager);

    const showAccessTabs = isManager || isProjectManager;

    const dispatch = useDispatch();

    const activeTab = useQueryParameter("tab", ProjectAccess.Open);

    const handleProjectClick = useCallback(
      (projectUrlURL: ProjectUrl) => {
        const id = urlToId(projectUrlURL);
        dispatch(actions.go(projectDetailsUrl, {id}));
      },
      [dispatch],
    );

    const handleTabChange = useCallback(
      (_event: React.ChangeEvent<unknown>, value: string): void => {
        dispatch(actions.putQueryKey("tab", value));
      },
      [dispatch],
    );

    const tabs = (
      <Tabs scrollButtons="auto" value={activeTab} variant="scrollable" onChange={handleTabChange}>
        <Tab label={intl.formatMessage({defaultMessage: "Åben"})} value={ProjectAccess.Open} />
        <Tab label={intl.formatMessage({defaultMessage: "Skjult"})} value={ProjectAccess.Hidden} />
        <Tab
          label={intl.formatMessage({defaultMessage: "Afsluttet"})}
          value={ProjectAccess.Closed}
        />
        <Tab label={intl.formatMessage({defaultMessage: "Spærret"})} value={ProjectAccess.Barred} />
      </Tabs>
    );

    return (
      <PageLayout
        withBottomScrollPadding
        withPadding
        tabs={showAccessTabs ? tabs : undefined}
        toolbar={
          <MenuToolbar
            rightElement={
              <div data-testid="search-field">
                <DebouncedAppbarSearchField
                  debounceTimeout={200}
                  value={filter}
                  onChange={setFilter}
                />
              </div>
            }
            title={
              projectLabelVariant === "PROJECT"
                ? intl.formatMessage({defaultMessage: "Projekter"})
                : intl.formatMessage({defaultMessage: "Sager"})
            }
            onMenuButton={props.onMenuButton}
          />
        }
      >
        <div data-testid="project-list">
          <ProjectsList
            filterByAccess={
              showAccessTabs && tabIsProjectAccess(activeTab) ? activeTab : ProjectAccess.Open
            }
            showAccessColumn={false}
            onClick={handleProjectClick}
          />
        </div>
      </PageLayout>
    );
  });
}
