import {Task} from "@co-common-libs/resources";
import {getCustomerSettings, getOrderLookup, getTaskArray} from "@co-frontend-libs/redux";
import {useOrdersWithValidationRequired} from "integration-hooks";
import {useMemo} from "react";
import {useSelector} from "react-redux";

export function useEconomicSyncTasks({
  includeReportApproved,
}: {
  includeReportApproved: boolean;
}): readonly Task[] {
  const taskArray = useSelector(getTaskArray);
  const orderLookup = useSelector(getOrderLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const {orderValidation} = customerSettings;

  const requiresValidationOrders = useOrdersWithValidationRequired();

  return useMemo(
    () =>
      taskArray.filter((task) => {
        const taskApproved =
          (includeReportApproved && task.reportApproved) || task.validatedAndRecorded;
        if (!taskApproved || task.recordedInC5 || task.archivable) {
          return false;
        }
        if (task.order && (orderValidation || requiresValidationOrders.has(task.order))) {
          const order = orderLookup(task.order);
          return !!order?.routePlan || !!order?.validatedAndRecorded;
        }
        return true;
      }),
    [taskArray, includeReportApproved, orderValidation, requiresValidationOrders, orderLookup],
  );
}
