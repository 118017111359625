import {Project} from "@co-common-libs/resources";
import {getCustomerLookup, getCustomerSettings} from "@co-frontend-libs/redux";
import {Box, Card, CardContent, CardHeader, Grid, makeStyles} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ProjectDeleteButton} from "./project-delete-button";
import {ProjectEditButton} from "./project-edit-button";

const useStyles = makeStyles({
  root: {
    "& table": {
      "& > td": {
        width: "14ex",
      },
      width: "100%",
    },
  },
});

interface ProjectDetailsProps {
  project: Project;
}

export const ProjectDetails = React.memo(function ProjectDetails(
  props: ProjectDetailsProps,
): React.JSX.Element | string {
  const {project} = props;
  const intl = useIntl();
  const classes = useStyles();

  const customerLookup = useSelector(getCustomerLookup);
  const customer = customerLookup(project.customer);

  const {projectLabelVariant} = useSelector(getCustomerSettings);

  const translateProjectAccessChoice = useMemo(() => {
    switch (project.access) {
      case "open":
        return intl.formatMessage({defaultMessage: "Åben"});
      case "hidden":
        return intl.formatMessage({defaultMessage: "Skjult"});
      case "closed":
        return intl.formatMessage({defaultMessage: "Afsluttet"});
      case "barred":
        return intl.formatMessage({defaultMessage: "Spærret"});
      default:
        return project.access;
    }
  }, [project, intl]);

  return project && customer ? (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={6}>
        <Card>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <CardHeader data-testid="project-name" title={project.name} />
            <Box alignItems="right" display="flex" justifyContent="space-between">
              <ProjectEditButton project={project} />
              <ProjectDeleteButton project={project} />
            </Box>
          </Box>
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Projektnr." />
                  </td>
                  <td>{project.projectNumber}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Kunde" />
                  </td>
                  <td>{customer?.name}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Adgang" />
                  </td>
                  <td>{translateProjectAccessChoice}</td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  ) : projectLabelVariant === "PROJECT" ? (
    <FormattedMessage defaultMessage="Projekt ikke fundet på serveren" />
  ) : (
    <FormattedMessage defaultMessage="Sag ikke fundet på serveren" />
  );
});
