import {LocationTypeUrl, LocationUrl, ProductUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator, customerAddress} from "@co-common-libs/utils";
import {
  MultiLocationDialog,
  MultipleLocationTypeDialog,
  MultipleProductDialog,
} from "@co-frontend-libs/components";
import {
  getCustomerLookup,
  getCustomerSettings,
  getLocationArray,
  getLocationTypeArray,
  getProductArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {useFalseCallback, useTrueCallback} from "app-utils";
import clsx from "clsx";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  productSearchPlaceholder: {
    defaultMessage: "Søg varer",
    id: "location-storage.placeholder.search-products",
  },
});

const useStyles = makeStyles((theme) => ({
  expand: {
    marginLeft: "auto",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

interface LocationStorageFilterCardProps {
  filterToConfiguredLocations: boolean;
  onFilterToConfiguredLocationsChange: (checked: boolean) => void;
  onSelectedLocationsChange: (selectedLocations: ReadonlySet<LocationUrl>) => void;
  onSelectedLocationTypesChange: (selectedLocationTypes: ReadonlySet<LocationTypeUrl>) => void;
  onSelectedProductsChange: (selectedProducts: ReadonlySet<ProductUrl>) => void;
  onShowZeroAmountLocationsChange: (checked: boolean) => void;
  selectedLocations: ReadonlySet<LocationUrl>;
  selectedLocationTypes: ReadonlySet<LocationTypeUrl>;
  selectedProducts: ReadonlySet<ProductUrl>;
  showZeroAmountLocations: boolean;
}

export function LocationStorageFilterCard(props: LocationStorageFilterCardProps): JSX.Element {
  const {
    filterToConfiguredLocations,
    onFilterToConfiguredLocationsChange,
    onSelectedLocationsChange,
    onSelectedLocationTypesChange,
    onSelectedProductsChange,
    onShowZeroAmountLocationsChange,
    selectedLocations,
    selectedLocationTypes,
    selectedProducts,
    showZeroAmountLocations,
  } = props;
  const {formatMessage} = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const unitLookup = useSelector(getUnitLookup);
  const storageLocationTypeArray = useMemo(
    () => locationTypeArray.filter((locationType) => locationType.products.length),
    [locationTypeArray],
  );
  const locationArray = useSelector(getLocationArray);
  const productArray = useSelector(getProductArray);
  const storageProductURLs: ReadonlySet<string> = useMemo(
    () => new Set(storageLocationTypeArray.flatMap((locationType) => locationType.products)),
    [storageLocationTypeArray],
  );
  const storageProductArray = useMemo(
    () => productArray.filter((product) => storageProductURLs.has(product.url)),
    [productArray, storageProductURLs],
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const expandButton = (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={handleExpandClick}
    >
      <ChevronDownIcon />
    </IconButton>
  );

  const handleFilterToConfiguredLocationsCheckboxChange = useCallback(
    (_event: unknown, checked: boolean) => {
      onFilterToConfiguredLocationsChange(checked);
    },
    [onFilterToConfiguredLocationsChange],
  );

  const handleShowZeroAmountLocationsCheckboxChange = useCallback(
    (_event: unknown, checked: boolean) => {
      onShowZeroAmountLocationsChange(checked);
    },
    [onShowZeroAmountLocationsChange],
  );

  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const handleProductDialogOk = useCallback(
    (urls: ReadonlySet<ProductUrl>) => {
      setProductDialogOpen(false);
      onSelectedProductsChange(urls);
    },
    [onSelectedProductsChange],
  );
  const handleClearSelectedProductsClick = useCallback(() => {
    onSelectedProductsChange(new Set<ProductUrl>());
  }, [onSelectedProductsChange]);
  const sortedSelectedProducts = storageProductArray
    .filter((product) => selectedProducts.has(product.url))
    .sort((a, b) => caseAccentInsensitiveCollator.compare(a.catalogNumber, b.catalogNumber));

  const [locationTypeDialogOpen, setLocationTypeDialogOpen] = useState(false);
  const handleLocationTypeDialogOk = useCallback(
    (urls: ReadonlySet<LocationTypeUrl>) => {
      setLocationTypeDialogOpen(false);
      onSelectedLocationTypesChange(urls);
    },
    [onSelectedLocationTypesChange],
  );
  const handleClearSelectedLocationTypesClick = useCallback(() => {
    onSelectedLocationTypesChange(new Set<LocationTypeUrl>());
  }, [onSelectedLocationTypesChange]);
  const sortedSelectedLocationTypes = storageLocationTypeArray
    .filter((locationType) => selectedLocationTypes.has(locationType.url))
    .sort((a, b) =>
      caseAccentInsensitiveCollator.compare(a.name || a.identifier, b.name || b.identifier),
    );

  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const handleLocationDialogOk = useCallback(
    (urls: ReadonlySet<LocationUrl>) => {
      setLocationDialogOpen(false);
      onSelectedLocationsChange(urls);
    },
    [onSelectedLocationsChange],
  );
  const handleClearSelectedLocationsClick = useCallback(() => {
    onSelectedLocationsChange(new Set<LocationUrl>());
  }, [onSelectedLocationsChange]);
  const sortedSelectedLocations = locationArray
    .filter((location) => selectedLocations.has(location.url))
    .sort((a, b) =>
      caseAccentInsensitiveCollator.compare(
        a.name || customerAddress(a),
        b.name || customerAddress(b),
      ),
    );

  return (
    <>
      <Card style={{margin: 8}}>
        <CardHeader
          action={expandButton}
          title={
            <FormattedMessage defaultMessage="Filtrering" id="location-storage.card-title.filter" />
          }
        />
        <Collapse unmountOnExit in={expanded} timeout="auto">
          <CardContent style={{alignItems: "flex-start", display: "flex"}}>
            <div style={{marginRight: 8, whiteSpace: "nowrap", width: 250}}>
              <Button
                color="primary"
                variant="contained"
                onClick={useTrueCallback(setLocationTypeDialogOpen, [setLocationTypeDialogOpen])}
              >
                <FormattedMessage
                  defaultMessage="Filtrér type"
                  id="location-storage.button-text.filter-location-type"
                />
              </Button>
              <IconButton
                disabled={!selectedLocationTypes.size}
                onClick={handleClearSelectedLocationTypesClick}
              >
                <CloseIcon />
              </IconButton>
              {sortedSelectedLocationTypes.map((locationType) => (
                <div key={locationType.url}>{locationType.name || locationType.identifier}</div>
              ))}
            </div>
            <div style={{marginRight: 8, whiteSpace: "nowrap", width: 250}}>
              <Button
                color="primary"
                variant="contained"
                onClick={useTrueCallback(setLocationDialogOpen, [setLocationDialogOpen])}
              >
                <FormattedMessage
                  defaultMessage="Filtrér sted"
                  id="location-storage.button-text.filter-location"
                />
              </Button>
              <IconButton
                disabled={!selectedLocations.size}
                onClick={handleClearSelectedLocationsClick}
              >
                <CloseIcon />
              </IconButton>
              {sortedSelectedLocations.map((location) => (
                <div key={location.url}>{location.name || customerAddress(location)}</div>
              ))}
            </div>
            <div style={{marginRight: 8, whiteSpace: "nowrap", width: 250}}>
              <Button
                color="primary"
                variant="contained"
                onClick={useTrueCallback(setProductDialogOpen, [setProductDialogOpen])}
              >
                <FormattedMessage
                  defaultMessage="Filtrér varer"
                  id="location-storage.button-text.filter-products"
                />
              </Button>
              <IconButton
                disabled={!selectedProducts.size}
                onClick={handleClearSelectedProductsClick}
              >
                <CloseIcon />
              </IconButton>
              {sortedSelectedProducts.map((product) => (
                <div key={product.url}>
                  {product.catalogNumber}: {product.name}
                </div>
              ))}
            </div>
            <div style={{flexGrow: 1}}>
              <div style={{display: "inline-block", float: "right"}}>
                <FormControlLabel
                  checked={filterToConfiguredLocations}
                  control={<Checkbox />}
                  disabled={!!selectedLocationTypes.size}
                  label={
                    <FormattedMessage
                      defaultMessage="Begræns til opsatte lagre"
                      id="location-storage.label.filter-to-configured-locations"
                    />
                  }
                  onChange={handleFilterToConfiguredLocationsCheckboxChange}
                />
                <FormControlLabel
                  checked={showZeroAmountLocations}
                  control={<Checkbox />}
                  label={
                    <FormattedMessage
                      defaultMessage="Vis opsatte lagre med 0"
                      id="location-storage.label.show-zero-amount-locations"
                    />
                  }
                  onChange={handleShowZeroAmountLocationsCheckboxChange}
                />
              </div>
            </div>
          </CardContent>
        </Collapse>
      </Card>
      <MultipleProductDialog
        materialUseAlternativeText={customerSettings.materialUseAlternativeText}
        open={productDialogOpen}
        productArray={storageProductArray}
        searchTitle={formatMessage(messages.productSearchPlaceholder)}
        selected={selectedProducts}
        title={
          <FormattedMessage
            defaultMessage="Vælg varer"
            id="location-storage.dialog-title.select-products"
          />
        }
        unitLookup={unitLookup}
        onCancel={useFalseCallback(setProductDialogOpen, [setProductDialogOpen])}
        onOk={handleProductDialogOk}
      />
      <MultipleLocationTypeDialog
        defaultFieldType={customerSettings.fieldDefaultLocationType || ""}
        includeSelectAll={false}
        locationTypeArray={storageLocationTypeArray}
        open={locationTypeDialogOpen}
        selected={selectedLocationTypes}
        showFieldType={customerSettings.customerFields}
        onCancel={useFalseCallback(setLocationTypeDialogOpen, [setLocationTypeDialogOpen])}
        onOk={handleLocationTypeDialogOk}
      />
      <MultiLocationDialog
        includeLogOnlyLocations
        includeWorkplaceOnlyLocations
        locationCrossCustomerSelectionEnabled
        customerLookup={customerLookup}
        customerURL={null}
        includeSelectAll={false}
        locationArray={locationArray}
        locationFavoritesEnabled={customerSettings.locationFavoritesEnabled}
        onlyActive={false}
        open={locationDialogOpen}
        selected={selectedLocations}
        titleVariant="LOCATION"
        onCancel={useFalseCallback(setLocationDialogOpen, [setLocationDialogOpen])}
        onOk={handleLocationDialogOk}
      />
    </>
  );
}
