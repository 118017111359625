import {
  Location,
  LocationType,
  LocationUrl,
  ProductUrl,
  TaskUrl,
  urlToId,
} from "@co-common-libs/resources";
import {allowLocationEdit, allowWorkplaceEdit} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {actions, getCurrentRole, getCustomerSettings, getTaskLookup} from "@co-frontend-libs/redux";
import {useMapContainer} from "@co-frontend-libs/utils";
import {Divider, Menu, MenuItem} from "@material-ui/core";
import {getGoogleMapsUrl} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import ReactDOM from "react-dom";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {VisibleLocationMarkers} from "../location-markers/visible-location-markers";
import {TaskMenuItem} from "./task-menu-item";

const LABEL_VISIBILITY_THRESHOLD = 12;
const AREA_AND_CUSTOMER_NAME_VISIBILITY_THRESHOLD = 14;
const CROP_VISIBILITY_THRESHOLD = 15;

interface LocationTypeMarkersWithMenuProps {
  filteredLocationArray: readonly Location[];
  locationProductCounts?: ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>> | undefined;
  locationTaskUrlsMap?: ReadonlyMap<LocationUrl, ReadonlySet<TaskUrl>> | undefined;
  locationType: LocationType;
  map: google.maps.Map;
  onRequestCreateTask: (location: Location) => void;
  onRequestEditLocation: (location: Location) => void;
  showAsActive?: boolean | undefined;
}

export function LocationTypeMarkersWithMenu(props: LocationTypeMarkersWithMenuProps): JSX.Element {
  const {
    filteredLocationArray,
    locationProductCounts,
    locationTaskUrlsMap,
    locationType,
    map,
    onRequestCreateTask,
    onRequestEditLocation,
    showAsActive,
  } = props;
  const role = useSelector(getCurrentRole);
  const isManager = role?.manager;
  const locationTypeURL = locationType.url;
  const typeFilteredLocationArray = useMemo(() => {
    return filteredLocationArray.filter((location) => location.locationType === locationTypeURL);
  }, [filteredLocationArray, locationTypeURL]);

  const [containerElement, overlayView] = useMapContainer(map);
  const [popoverRef, setPopoverRef] = useState<HTMLSpanElement>();
  const [popoverLocation, setPopoverLocation] = useState<Location>();

  const popoverLocationTaskUrls = popoverLocation
    ? locationTaskUrlsMap?.get(popoverLocation?.url)
    : undefined;

  const customerSettings = useSelector(getCustomerSettings);
  const handleClose = useCallback(() => {
    setPopoverRef(undefined);
  }, []);

  let googleMapsURL: string | undefined;
  if (popoverLocation) {
    googleMapsURL = getGoogleMapsUrl(popoverLocation);
  }

  const handleLocationClick = useCallback((ref: HTMLSpanElement, location: Location) => {
    setPopoverLocation(location);
    setPopoverRef(ref);
  }, []);

  const dispatch = useDispatch();
  const handleGoToStorageJournal = useCallback(() => {
    if (popoverLocation) {
      const locationId = urlToId(popoverLocation.url);
      dispatch(actions.go("/storageJournal/:locationId", {locationId}));
    }
    handleClose();
  }, [dispatch, handleClose, popoverLocation]);
  const handleGoToStorage = useCallback(() => {
    if (popoverLocation) {
      const id = urlToId(popoverLocation.url);
      dispatch(actions.go("/locationStorage", undefined, {id}));
    }
    handleClose();
  }, [dispatch, handleClose, popoverLocation]);
  const handleEditLocation = useCallback(() => {
    if (popoverLocation) {
      onRequestEditLocation(popoverLocation);
    }
    handleClose();
  }, [handleClose, onRequestEditLocation, popoverLocation]);

  const {enableLocationStorage, locationStoragePageVisibleToEveryone} =
    useSelector(getCustomerSettings);

  const handleCreateTaskClick = useCallback(() => {
    if (popoverLocation) {
      onRequestCreateTask(popoverLocation);
    }
    handleClose();
  }, [handleClose, onRequestCreateTask, popoverLocation]);
  const taskLookup = useSelector(getTaskLookup);

  const zoomLevel = map.getZoom();
  if (containerElement && overlayView && zoomLevel) {
    const mapCanvasProjection = overlayView.getProjection();
    const bounds = map.getBounds() as google.maps.LatLngBounds;

    const labelsVisible = zoomLevel > LABEL_VISIBILITY_THRESHOLD;
    const fieldHaAndCustomerNameVisible = zoomLevel > AREA_AND_CUSTOMER_NAME_VISIBILITY_THRESHOLD;
    const cropVisible = zoomLevel > CROP_VISIBILITY_THRESHOLD;

    const popoverLocationIsWorkplace = !popoverLocation?.logOnlyLocation;

    return ReactDOM.createPortal(
      <>
        <VisibleLocationMarkers
          bounds={bounds}
          cropVisible={cropVisible}
          fieldHaAndCustomerNameVisible={fieldHaAndCustomerNameVisible}
          labelsVisible={labelsVisible}
          locationProductCounts={locationProductCounts}
          locations={typeFilteredLocationArray}
          mapCanvasProjection={mapCanvasProjection}
          showAsActive={!!showAsActive}
          onClickOrGoogleMapsUrl={handleLocationClick}
        />
        <Menu
          anchorEl={popoverRef || null}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={Boolean(popoverRef)}
          transformOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          onClose={handleClose}
        >
          {popoverLocationTaskUrls
            ? _.sortBy(
                [...popoverLocationTaskUrls].map(taskLookup).filter(notUndefined),
                (task) => task.created,
              ).map((task) => {
                return <TaskMenuItem key={task.url} task={task} />;
              })
            : null}
          {popoverLocationTaskUrls?.size ? <Divider /> : null}
          <MenuItem
            component="a"
            href={googleMapsURL || ""}
            target={window.cordova && bowser.ios ? "_system" : "_blank"}
          >
            <FormattedMessage defaultMessage="Find vej" />
          </MenuItem>
          {popoverLocation?.geojson &&
          customerSettings.adminCanCreateCustomerTask &&
          (customerSettings.machineOperatorCanCreateCustomerTask || isManager) ? (
            <MenuItem onClick={handleCreateTaskClick}>
              <FormattedMessage defaultMessage="Ny opgave" />
            </MenuItem>
          ) : null}
          {enableLocationStorage && (isManager || locationStoragePageVisibleToEveryone) ? (
            <MenuItem onClick={handleGoToStorageJournal}>
              <FormattedMessage defaultMessage="Vis lagerbevægelser" />
            </MenuItem>
          ) : null}
          {enableLocationStorage && (isManager || locationStoragePageVisibleToEveryone) ? (
            <MenuItem onClick={handleGoToStorage}>
              <FormattedMessage defaultMessage="Åben lager" />
            </MenuItem>
          ) : null}
          {isManager &&
          allowLocationEdit(customerSettings, role) &&
          (allowWorkplaceEdit(customerSettings, role) ||
            !popoverLocationIsWorkplace ||
            !popoverLocation?.remoteUrl) &&
          !popoverLocation?.geojson &&
          customerSettings ? (
            <MenuItem onClick={handleEditLocation}>
              <FormattedMessage defaultMessage="Ret sted" />
            </MenuItem>
          ) : null}
        </Menu>
      </>,
      containerElement,
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
}
