import {Config, SettingID} from "@co-common-libs/config";
import {SettingEntry} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {
  ActiveIntegrationKind,
  integrationCustomerSettings,
} from "shared-integration-customer-settings";
import {getCustomerSettings, getSettingEntryArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {getCurrentRoleIsManager} from "./current-user";

function buildObjectByIdentifier(settingEntryArray: readonly Readonly<SettingEntry>[]): {
  readonly [key in SettingID]?: Readonly<SettingEntry>;
} {
  const result: {[key in SettingID]?: SettingEntry} = {};
  settingEntryArray.forEach((entry) => {
    result[entry.key as SettingID] = entry;
  });
  return result;
}

const getSettingsEntryMappingByIdentifier: (state: AppState) => {
  readonly [key in SettingID]?: Readonly<SettingEntry>;
} = createSelector(getSettingEntryArray, buildObjectByIdentifier);

function entryLookupByIdentifier(settingEntryMapping: {
  readonly [key in SettingID]?: Readonly<SettingEntry>;
}): (identifier: SettingID) => SettingEntry | undefined {
  return (identifier: SettingID) => settingEntryMapping[identifier];
}

export const getSettingsEntryLookupByIdentifier: (
  state: AppState,
) => (identifier: SettingID) => Readonly<SettingEntry> | undefined = createSelector(
  getSettingsEntryMappingByIdentifier,
  entryLookupByIdentifier,
);

const DATE_STRING_LENGTH = "YYYY-MM-DD".length;
const YEAR_AND_SEPARATOR_LENGTH = "YYYY-".length;

function extraHolidaysFromCustomerSettings(
  customerSettings: Config,
  remunerationGroupId: string,
  date: string,
): string | undefined {
  const {remunerationGroups} = customerSettings;
  const remunerationGroup = remunerationGroups[remunerationGroupId];
  if (!remunerationGroup) {
    return undefined;
  }
  const {extraHolidays} = remunerationGroup;
  if (!extraHolidays) {
    return undefined;
  }
  const exactMatch: string | undefined = extraHolidays[date];
  if (exactMatch) {
    return exactMatch;
  }
  console.assert(date.length === DATE_STRING_LENGTH);
  const monthDayString = date.substring(YEAR_AND_SEPARATOR_LENGTH);
  const monthDayMatch: string | undefined = extraHolidays[monthDayString];
  return monthDayMatch;
}

export const getExtraHolidaysPerRemunerationGroup: (
  state: AppState,
) => (remunerationGroup: string, date: string) => string | undefined = createSelector(
  getCustomerSettings,
  (customerSettings) => extraHolidaysFromCustomerSettings.bind(null, customerSettings),
);

function extraHalfHolidaysFromCustomerSettings(
  customerSettings: Config,
  remunerationGroupId: string,
  date: string,
): string | undefined {
  const {remunerationGroups} = customerSettings;
  const remunerationGroup = remunerationGroups[remunerationGroupId];
  if (!remunerationGroup) {
    return undefined;
  }
  const {extraHalfHolidays} = remunerationGroup;
  if (!extraHalfHolidays) {
    return undefined;
  }
  const exactMatch: string | undefined = extraHalfHolidays[date];
  if (exactMatch) {
    return exactMatch;
  }
  console.assert(date.length === DATE_STRING_LENGTH);
  const monthDayString = date.substring(YEAR_AND_SEPARATOR_LENGTH);
  const monthDayMatch: string | undefined = extraHalfHolidays[monthDayString];
  return monthDayMatch;
}

export const getExtraHalfHolidaysPerRemunerationGroup: (
  state: AppState,
) => (remunerationGroup: string, date: string) => string | undefined = createSelector(
  getCustomerSettings,
  (customerSettings) => extraHalfHolidaysFromCustomerSettings.bind(null, customerSettings),
);

export function getCustomerSettingsMember<T extends keyof Config>(
  member: T,
): (state: AppState) => Config[T] {
  return createSelector(getCustomerSettings, (config) => config[member]);
}

export const getCurrentUserCanManageCustomers: (state: AppState) => boolean = createSelector(
  getCustomerSettingsMember("createCustomer"),
  getCurrentRoleIsManager,
  (createCustomer: Config["createCustomer"], isManager: boolean) =>
    createCustomer === "all" || (createCustomer === "manager" && isManager),
);

export const getCurrentUserCanManageContacts: (state: AppState) => boolean = createSelector(
  getCurrentUserCanManageCustomers,
  getCustomerSettings,
  (canManageCustomers: boolean, customerSettings: Config) => {
    const {activeIntegrationKind} = integrationCustomerSettings(customerSettings);
    return (
      canManageCustomers &&
      (activeIntegrationKind === ActiveIntegrationKind.NONE ||
        activeIntegrationKind === ActiveIntegrationKind.LIVE_SYNC)
    );
  },
);

export const getContactsMayHaveFax: (state: AppState) => boolean = createSelector(
  getCustomerSettingsMember("economicSync"),
  (economicSync: Config["economicSync"]) => !economicSync,
);
