import {Task} from "@co-common-libs/resources";
import {getCustomerSettings, getOrderLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import _ from "lodash";
import {useCallback} from "react";
import {useSelector} from "react-redux";

export function useOrderValidationRequiredForTask(): (
  task: Pick<Task, "order" | "url" | "workType">,
) => boolean {
  const {orderValidation, orderValidationForWorkTypes} = useSelector(getCustomerSettings);

  const orderLookup = useSelector(getOrderLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  return useCallback(
    (task: Pick<Task, "order" | "url" | "workType">): boolean => {
      if (orderValidation) {
        return true;
      } else if (!_.isEmpty(orderValidationForWorkTypes)) {
        if (!task.order || !task.workType) {
          return false;
        }

        const order = orderLookup(task.order);
        const workType = workTypeLookup(task.workType);

        return (
          !order?.routePlan &&
          !!(workType && orderValidationForWorkTypes.includes(workType.identifier))
        );
      } else {
        return false;
      }
    },
    [orderLookup, orderValidation, orderValidationForWorkTypes, workTypeLookup],
  );
}
