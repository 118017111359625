import {ComputedTime, PatchUnion, Task} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {
  actions,
  getCustomerSettings,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getPunchInOutPeriodsPerEmployee,
  getReportingSpecificationLookup,
  getSprayLogArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getWorkTypeLookup,
  getYieldLogArray,
} from "@co-frontend-libs/redux";
import {
  computeIntervalSums,
  computeIntervalsNormalisedInputTruncated,
  getGenericEffectiveTimer,
  mergeIntervals,
  normaliseTimerStarts,
  updateTaskPriceGroupsPriceItemUses,
  updateTaskReportingSpecification,
} from "app-utils";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {InternalTask} from "./internal-task";
import {Route} from "./route";
import {TaskInstance} from "./task-instance";

interface TaskInstanceContainerProps {
  instance: Task;
}

export function TaskInstanceContainer(props: TaskInstanceContainerProps): JSX.Element | null {
  const task = props.instance;
  const customerSettings = useSelector(getCustomerSettings);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const timerLookup = useSelector(getTimerLookup);
  const timerStartArray = useSelector(getTimerStartArray);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const timerArray = useSelector(getTimerArray);
  const punchInOutPeriodsPerEmployee = useSelector(getPunchInOutPeriodsPerEmployee);
  const transportLogArray = useSelector(getTransportLogArray);
  const sprayLogArray = useSelector(getSprayLogArray);
  const yieldLogArray = useSelector(getYieldLogArray);

  const dispatch = useDispatch();

  const boundUpdate = useCallback(
    (url: string, patch: PatchUnion): void => {
      dispatch(actions.update(url, patch));
    },
    [dispatch],
  );

  useEffect(() => {
    updateTaskPriceGroupsPriceItemUses(
      task,
      workTypeLookup,
      machineLookup,
      priceGroupLookup,
      priceItemLookup,
      orderLookup,
      timerLookup,
      unitLookup,
      timerStartArray,
      customerSettings,
      boundUpdate,
    );
    updateTaskReportingSpecification(
      task,
      {
        machineLookup,
        orderLookup,
        priceGroupLookup,
        reportingSpecificationLookup,
        sprayLogArray,
        transportLogArray,
        workTypeLookup,
        yieldLogArray,
      },
      boundUpdate,
    );
    // recompute on opening, switching open task and on "price list" changes;
    // but not on *all* task, timerstart or order changes...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    task.url,
    workTypeLookup,
    machineLookup,
    priceGroupLookup,
    priceItemLookup,
    timerLookup,
    unitLookup,
    customerSettings,
  ]);

  const [, setNow] = useState(new Date());

  useEffect(() => {
    const uiIntervalId = window.setInterval(() => {
      setNow(new Date());
    }, SECOND_MILLISECONDS);
    return () => {
      window.clearInterval(uiIntervalId);
    };
  }, []);

  const now = new Date();
  now.setUTCMilliseconds(0);
  const timestamp = now.toISOString();
  const nowMinute = new Date(now);
  nowMinute.setUTCSeconds(0, 0);

  let computedIntervals: readonly ComputedTime[];
  let activeTimer = null;
  if (task.recordedInC5) {
    computedIntervals = task.computedTimeSet;
  } else {
    const taskURL = task.url;
    const taskTimerStartList = normaliseTimerStarts(
      timerStartArray.filter((instance) => instance.task === taskURL),
    );
    const lastTimerStart = taskTimerStartList[taskTimerStartList.length - 1];
    activeTimer = lastTimerStart && lastTimerStart.timer;
    computedIntervals = computeIntervalsNormalisedInputTruncated(
      taskTimerStartList,
      nowMinute.toISOString(),
    );
  }
  const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
  const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
  const intervals = mergeIntervals(
    computedIntervals,
    correctionIntervals,
    managerCorrectionIntervals,
    timestamp,
  );

  const timerMinutesMap = computeIntervalSums(intervals, now);
  const genericPrimaryTimer = getGenericEffectiveTimer(timerArray);
  if (!genericPrimaryTimer) {
    return null;
  }

  const orderURL = task.order;
  const order = orderURL ? orderLookup(orderURL) : null;

  if (order && order.routePlan) {
    return (
      <Route
        activeTimer={activeTimer || undefined}
        computedIntervals={computedIntervals}
        genericPrimaryTimer={genericPrimaryTimer}
        intervals={intervals}
        legalIntervals={
          customerSettings.usePunchInOut && task.machineOperator
            ? punchInOutPeriodsPerEmployee.get(task.machineOperator)
            : undefined
        }
        now={now}
        task={task}
        timerMinutesMap={timerMinutesMap}
      />
    );
  } else if (orderURL) {
    return (
      <TaskInstance
        activeTimer={activeTimer || undefined}
        computedIntervals={computedIntervals}
        genericPrimaryTimer={genericPrimaryTimer}
        intervals={intervals}
        now={now}
        task={task}
        timerMinutesMap={timerMinutesMap}
      />
    );
  } else {
    return (
      <InternalTask
        activeTimer={activeTimer || undefined}
        computedIntervals={computedIntervals}
        genericPrimaryTimer={genericPrimaryTimer}
        intervals={intervals}
        legalIntervals={
          customerSettings.usePunchInOut && task.machineOperator
            ? punchInOutPeriodsPerEmployee.get(task.machineOperator)
            : undefined
        }
        now={now}
        task={task}
        timerMinutesMap={timerMinutesMap}
      />
    );
  }
}
