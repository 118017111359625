import {Role} from "@co-common-libs/resources";
import {ActiveIntegrationKind, getActiveIntegrationKind} from "./get-integration-kind";
import {RelevantConfigPart} from "./relevant-config-part";

export interface EconomySystemIntegrationCustomerSettings {
  activeIntegrationKind: ActiveIntegrationKind;
  canCreateWorkTypes: boolean;
  canImportMachines: boolean;
  canImportProductGroups: boolean;
  canImportProducts: boolean;
  canImportWorkTypes: boolean;
  csvImportExportSettings: {
    canExport: (role: Role | null) => boolean;
    canImport: (role: Role | null) => boolean;
  };
  hasActiveIntegration: boolean;
  priceItemSettings: {
    addTimeAfterMinutesSetup: (role: Role | null) => boolean;
    allowManualPriceGroupPriceItemOrdering: boolean;
    canImportPriceItems: boolean;
  };
  sendTaskOnApprove: boolean;
}

export function integrationCustomerSettings(
  config: RelevantConfigPart,
): EconomySystemIntegrationCustomerSettings {
  const {
    canEditProductGroups,
    canEditProducts,
    createCostLines,
    createSalesLines,
    economicEnableProjectActivitiesImport,
    economicEnableWorkTypeFromProductGroupImport,
    economicSync,
    importExternalWorkTypesFromProductGroups,
    importInternalWorkTypesFromProducts,
    navSync,
    navSyncProfile,
    unicontaMachineDimension,
    unicontaSync,
  } = config;

  const integrationKind = getActiveIntegrationKind(config);
  const isImportOrLiveSyncIntegration =
    integrationKind === ActiveIntegrationKind.IMPORT_ONLY ||
    integrationKind === ActiveIntegrationKind.LIVE_SYNC;

  // TODO(mr): make `importExternalWorkTypesFromProductGroups` replace `economicEnableWorkTypeFromProductGroupImport`
  const syncWorkTypes =
    (economicSync && economicEnableWorkTypeFromProductGroupImport) ||
    (unicontaSync &&
      (importInternalWorkTypesFromProducts || importExternalWorkTypesFromProductGroups));

  const hasMachineDimension = economicSync || (unicontaSync && unicontaMachineDimension !== null);

  return {
    activeIntegrationKind: integrationKind,
    canCreateWorkTypes: economicSync,
    // TODO(mr): canEditMachines was not required for machine import from econ - bug?
    canImportMachines: isImportOrLiveSyncIntegration && hasMachineDimension,
    canImportProductGroups: isImportOrLiveSyncIntegration && canEditProductGroups,
    canImportProducts: isImportOrLiveSyncIntegration && canEditProducts,
    canImportWorkTypes: isImportOrLiveSyncIntegration && syncWorkTypes,
    csvImportExportSettings: {
      canExport: (role) => Boolean(role?.consultant),
      canImport: (role) =>
        Boolean(
          role?.consultant && (integrationKind === ActiveIntegrationKind.NONE || economicSync),
        ),
    },
    hasActiveIntegration: integrationKind !== ActiveIntegrationKind.NONE,
    priceItemSettings: {
      addTimeAfterMinutesSetup: (role) =>
        Boolean(
          role?.consultant && (integrationKind === ActiveIntegrationKind.NONE || economicSync),
        ),
      allowManualPriceGroupPriceItemOrdering:
        integrationKind === ActiveIntegrationKind.NONE ||
        isImportOrLiveSyncIntegration ||
        (navSync &&
          (navSyncProfile === "vredodanmark" ||
            navSyncProfile === "hvt" ||
            navSyncProfile === "rosgaard")),
      canImportPriceItems: isImportOrLiveSyncIntegration && !economicEnableProjectActivitiesImport,
    },
    sendTaskOnApprove: unicontaSync && (createSalesLines || createCostLines),
  };
}
