import {Project, urlToId} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {QueryParameters} from "@co-frontend-libs/routing-sync-history";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {TaskTable} from "../task-list/task-table";

interface ProjectTaskTableProps {
  filterString: string;
  project: Pick<Project, "url">;
}

export const ProjectTaskTable = React.memo(function ProjectTaskTable(
  props: ProjectTaskTableProps,
): JSX.Element {
  const {filterString, project} = props;

  const dispatch = useDispatch();

  const navigateToTask = useCallback(
    (taskURL: string, queryParameters?: QueryParameters) => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}, queryParameters));
    },
    [dispatch],
  );

  const navigateToTaskPhotoTab = useCallback(
    (taskURL: string) => {
      navigateToTask(taskURL, {tab: "photos"});
    },
    [navigateToTask],
  );

  return (
    <TaskTable
      batchOperationActive={false}
      filterByProject={project}
      filterString={filterString.trim()}
      tab="archive"
      onClick={navigateToTask}
      onPhotoClick={navigateToTaskPhotoTab}
    />
  );
});
