import {
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {identifierComparator, notNull} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  RowData,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  getCustomerSettings,
  getProductArray,
  getProductGroupLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {
  ConnectedTableWithPagination,
  PaginationPageSize,
  ProductCreateEditDialog,
} from "app-components";
import {useQueryParameter} from "app-utils";
import {ImportProductsFab} from "feat-import-resources";
import CheckIcon from "mdi-react/CheckIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedNumber, IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {integrationCustomerSettings} from "shared-integration-customer-settings";

type ProductTableFieldID = "active" | "identifier" | "name" | "price" | "productGroup" | "unit";

type ProductTableColumnID = "active" | "identifier" | "name" | "price" | "productGroup" | "unit";

interface ProductTableDataType extends RowData<ProductTableFieldID, ProductUrl> {
  identifier: string;
  name: string;
  price: number | null;
  productGroup: string;
  unit: string;
}
function renderActive(data: ProductTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function renderPrice(data: ProductTableDataType): JSX.Element | null {
  return data.price ? (
    <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={data.price} />
  ) : null;
}

function compareID(a: ProductTableDataType, b: ProductTableDataType): number {
  return identifierComparator(a.identifier, b.identifier);
}
function compareProductGroup(a: ProductTableDataType, b: ProductTableDataType): number {
  return identifierComparator(a.productGroup, b.productGroup);
}

function buildColumnSpecifications(
  intl: IntlShape,
  onClick?: (productURL: ProductUrl) => void,
): ColumnSpecifications<
  ProductTableFieldID,
  ProductTableColumnID,
  ProductUrl,
  ProductTableDataType
> {
  return {
    active: {
      field: "active",
      label: intl.formatMessage({defaultMessage: "Aktiv"}),
      onClick,
      render: renderActive,
    },
    identifier: {
      comparator: compareID,
      field: "identifier",
      label: intl.formatMessage({defaultMessage: "ID"}),
      onClick,
      width: 180,
    },
    name: {
      field: "name",
      label: intl.formatMessage({defaultMessage: "Navn"}),
      onClick,
    },
    price: {
      field: "price",
      label: intl.formatMessage({defaultMessage: "Pris"}),
      onClick,
      render: renderPrice,
      style: {textAlign: "right"},
    },
    productGroup: {
      comparator: compareProductGroup,
      field: "productGroup",
      label: intl.formatMessage({defaultMessage: "Varegruppe"}),
      onClick,
    },
    unit: {
      field: "unit",
      label: intl.formatMessage({defaultMessage: "Enhed"}),
      onClick,
    },
  };
}

function buildRowData(
  productArray: readonly Product[],
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): ProductTableDataType[] {
  return productArray.map((product) => {
    const productGroup = product.group ? productGroupLookup(product.group) : null;
    return {
      active: product.active,
      identifier: product.catalogNumber,
      key: product.url,
      name: product.name,
      price: product.price,
      productGroup: productGroup ? `${productGroup.identifier}: ${productGroup.name}` : "",
      unit: getUnitString(product, unitLookup),
    };
  });
}

export function ProductsList(): JSX.Element {
  const intl = useIntl();
  const productArray = useSelector(getProductArray);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const unitLookup = useSelector(getUnitLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const {canEditProducts, overviewShowPrices} = customerSettings;
  const {canImportProducts} = integrationCustomerSettings(customerSettings);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const setProductDialogOpenFalse = useCallWithFalse(setProductDialogOpen);

  const productLookup = useSelector(getProductLookup);
  const [product, setProduct] = useState<Product | undefined>();

  const handleEditProduct = useCallback(
    (productURL: ProductUrl) => {
      setProduct(productLookup(productURL));
      setProductDialogOpen(true);
    },
    [productLookup],
  );
  const handleCreateProduct = useCallback(() => {
    setProduct(undefined);
    setProductDialogOpen(true);
  }, []);

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(intl, canEditProducts ? handleEditProduct : undefined),
    [canEditProducts, intl, handleEditProduct],
  );

  const rowData = useMemo(
    () =>
      buildRowData(
        customerSettings.economicSync ? productArray.filter(({active}) => active) : productArray,
        productGroupLookup,
        unitLookup,
      ),
    [customerSettings.economicSync, productArray, productGroupLookup, unitLookup],
  );

  const filterString = useQueryParameter("q", "");

  const visibleColumns = useMemo(
    () =>
      (
        [
          "identifier",
          "name",
          "productGroup",
          overviewShowPrices ? "price" : null,
          "unit",
          !customerSettings.economicSync ? "active" : null,
        ] as (ProductTableColumnID | null)[]
      ).filter(notNull),
    [customerSettings.economicSync, overviewShowPrices],
  );

  const filteringData = useMemo(() => ({filterString}), [filterString]);

  return (
    <>
      <ConnectedTableWithPagination
        columns={columnSpecifications}
        defaultRowsPerPage={PaginationPageSize.SMALL}
        defaultSortDirection="ASC"
        defaultSortKey="name"
        entries={rowData}
        filteringData={filteringData}
        filterString={filterString}
        savePaginationIdentifier="ProductTable"
        saveSortingIdentifier="ProductTable"
        visibleColumns={visibleColumns}
      />

      {canImportProducts ? <ImportProductsFab stackIndex={1} /> : null}
      {canEditProducts ? (
        <VerticalStackingFloatingActionButton stackIndex={0} onClick={handleCreateProduct}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      ) : null}
      <ProductCreateEditDialog
        open={productDialogOpen}
        product={product}
        onCancel={setProductDialogOpenFalse}
        onOk={setProductDialogOpenFalse}
      />
    </>
  );
}
