import {FloatingActionButtonData} from "app-components";
import {BatchOperation} from "app-utils";
import CheckIcon from "mdi-react/CheckIcon";
import FormatListChecksIcon from "mdi-react/FormatListChecksIcon";
import UndoIcon from "mdi-react/UndoIcon";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {BatchOperationState} from "./use-batch-operation-state";

export const useBatchOperationFloatingActionButtons = <T,>(
  batchOperations: readonly BatchOperation<T>[],
  {
    acceptCurrentBatchOperation,
    openSelectBatchOperationMenu,
    setCurrentBatchOperation,
    stopBatchOperation,
  }: BatchOperationState<T>,
): [
  start: FloatingActionButtonData,
  stop: FloatingActionButtonData,
  accept: FloatingActionButtonData,
  operations: readonly FloatingActionButtonData[],
] => {
  const {formatMessage} = useIntl();

  const startBatchOperationButton = {
    buttonIcon: <FormatListChecksIcon />,
    name: "batch",
    onClick: openSelectBatchOperationMenu,
    tooltipTitle: formatMessage({defaultMessage: "Masse-handling"}),
  };

  const stopBatchOperationButton = {
    buttonIcon: <UndoIcon />,
    name: "cancel",
    onClick: stopBatchOperation,
    tooltipTitle: formatMessage({defaultMessage: "Fortryd"}),
  };

  const acceptBatchOperationButton = {
    buttonIcon: <CheckIcon />,
    name: "accept",
    onClick: acceptCurrentBatchOperation,
    tooltipTitle: formatMessage({defaultMessage: "Godkend"}),
  };

  const batchOperationButtons = useMemo(
    () =>
      batchOperations.map((batchOperation) => ({
        buttonIcon: batchOperation.icon,
        name: batchOperation.name,
        onClick: () => setCurrentBatchOperation(batchOperation),
        tooltipTitle: batchOperation.label,
      })),
    [batchOperations, setCurrentBatchOperation],
  );

  return [
    startBatchOperationButton,
    stopBatchOperationButton,
    acceptBatchOperationButton,
    batchOperationButtons,
  ];
};
