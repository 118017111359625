import {Config} from "@co-common-libs/config";
import {
  Contact,
  ContactUrl,
  CultureUrl,
  Customer,
  CustomerUrl,
  DaysAbsence,
  DeliveryLocation,
  EmployeeGroup,
  EmployeeGroupUrl,
  HoursAbsence,
  Location,
  LocationTypeUrl,
  LocationUrl,
  Machine,
  MachineGroup,
  MachineGroupUrl,
  MachineUrl,
  MachineUse,
  Order,
  OrderFieldUse,
  OrderUrl,
  Patch,
  PatchOperation,
  PatchUnion,
  PickupLocation,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceTypeUnion,
  Role,
  SprayLocation,
  SprayLog,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  TransportLog,
  Unit,
  UnitUrl,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  YieldDeliveryLocation,
  YieldLog,
  YieldPickupLocation,
  emptyOrder,
  emptyTask,
} from "@co-common-libs/resources";
import {
  ITEM_TYPE_MINIMUM,
  ITEM_TYPE_TIMER,
  allowWorkplaceCreate,
  formatDuration,
  getNormalisedDeviceTimestamp,
  getUnitString,
  getUpdatedFieldUseList,
  priceItemIsManualDistributionTime,
  priceItemIsTime,
  workTypePotentialPriceGroups,
} from "@co-common-libs/resources-utils";
import {
  HOUR_MINUTES,
  customerAddress,
  formatDateTimeShort,
  identifierComparator,
  notUndefined,
  simpleComparator,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  DateField,
  DeleteDialog,
  ErrorColorButton,
  FilePdfIcon,
  IntegerField,
  MinutesField,
  ResponsiveDialog,
  ThrottledTextField,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedDepartmentDialog,
  ConnectedExternalWorkTypeDialog,
  ConnectedLocationDialog,
  ConnectedMachineOperatorDialog,
  ConnectedPriceGroupDialog,
  ConnectedProjectDialog,
} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  actions,
  getContactArray,
  getContactLookup,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserCanManageContacts,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDaysAbsenceArray,
  getDeliveryLocationArray,
  getEmployeeGroupLookup,
  getHoursAbsenceArray,
  getLocationArray,
  getLocationLookup,
  getMachineGroupLookup,
  getMachineLookup,
  getOrderArray,
  getOrderLookup,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectLookup,
  getReportingSpecificationLookup,
  getShareToken,
  getSprayLocationArray,
  getSprayLogArray,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getToken,
  getTransportLogArray,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldPickupLocationArray,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withTheme,
} from "@material-ui/core";
import {
  AddOrderBDReference,
  AjaxDownloadButton,
  ContactSelectCreateDialog,
  CustomerFileListCard,
  CustomerSelectCreateDialog,
  DoLoadInstance,
  FieldMultiSelectionDialog,
  FieldUseWithField,
  FieldsCard,
  LocationCreateEditDialog,
  MachinePriceGroupWizard,
  OrderFilesCard,
  RemoveOrderBDReference,
  WorkTypeChangeBlockedDialog,
  getOtherTaskCount,
  getUsedFieldUrlSet,
} from "app-components";
import {
  InlinedTask,
  LoadInstanceRelated,
  PureComponent,
  WorkTypeChangeBlockedReason,
  adjustMinutes,
  changeCustomerCulture,
  checkUserAbsence,
  computeDepartment,
  computeIntervalSums,
  computeIntervalsTruncated,
  computeTime,
  copyTaskWithLogs,
  copyTaskWithLogsLocations,
  createLocation,
  getBreakTimer,
  getDepartmentName,
  getGenericEffectiveTimer,
  getGenericPrimaryTimer,
  getTaskSecondaryTimerList,
  getWorkplaceString,
  hasMultipleManualDistributionPriceItemUses,
  machineAlreadySelected,
  mergeIntervals,
  removeUnicodeCf,
  willTaskBeRecorded,
} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {globalConfig, instanceURL} from "frontend-global-config";
import _ from "lodash";
import CalendarMultipleIcon from "mdi-react/CalendarMultipleIcon";
import ClockIcon from "mdi-react/ClockIcon";
import CloseIcon from "mdi-react/CloseIcon";
import MapIcon from "mdi-react/MapIcon";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {Dispatch, bindActionCreators} from "redux";
import {createStructuredSelector} from "reselect";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {ApproveOrderDialog} from "./approve-order-dialog";
import {ApproveTasksDialog} from "./approve-tasks-dialog";
import {MissingMachineOperatorDialog} from "./missing-machineoperator-dialog";
import OrderMergeDialog from "./order-merge-dialog";
import ResultRow from "./result-row";
import {TaskAndData, TaskTable} from "./task-table";

const MOBILE = bowser.mobile || bowser.tablet;

const INITIAL = Math.pow(2, 28);

const INCREMENT = Math.pow(2, 14);

const THRESHOLD = Math.pow(2, -14);

const focusButton = (buttonRef: React.RefObject<HTMLButtonElement>): void => {
  if (MOBILE || !buttonRef) {
    return;
  }
  window.setTimeout(() => {
    const {current} = buttonRef;
    if (current) {
      current.focus();
    }
  });
};

const focusIconButton = (button: HTMLButtonElement | null): void => {
  if (MOBILE || !button) {
    return;
  }
  window.setTimeout(() => {
    button.focus();
  });
};

const fieldListDiff = (
  oldList: readonly OrderFieldUse[],
  newList: readonly OrderFieldUse[],
): {
  add: OrderFieldUse[];
  change: OrderFieldUse[];
  remove: string[];
} => {
  const result: {
    add: OrderFieldUse[];
    change: OrderFieldUse[];
    remove: string[];
  } = {
    add: [],
    change: [],
    remove: [],
  };

  //Map giving wierd undefined result when empty list expected. Using forEach instead.
  oldList.forEach((oldFieldUse) => {
    if (!newList.some((fieldUse) => fieldUse.relatedField === oldFieldUse.relatedField)) {
      result.remove.push(oldFieldUse.relatedField);
    }
  });

  newList.forEach((fieldUse) => {
    const oldFieldUse = oldList.find((f) => f.relatedField === fieldUse.relatedField);
    if (!oldFieldUse) {
      result.add.push(fieldUse);
    }

    if (oldFieldUse && oldFieldUse.notes !== fieldUse.notes) {
      result.change.push(fieldUse);
    }
  });
  return result;
};

function computeDuration(
  taskList: readonly Task[],
  enableOrderEntryTaskTime: boolean,
): number | undefined {
  if (enableOrderEntryTaskTime) {
    return taskList.map((t) => t.minutesExpectedTotalTaskDuration).find(Boolean) || undefined;
  }
  return undefined;
}

function recalculateAllPriorities(
  taskList: readonly Task[],
  insertNewAfter: Task,
): {
  changedTaskPriorities: ReadonlyMap<string, number>;
  newTaskPriority: number;
} {
  const newOrder: (TaskUrl | "NEW_TASK")[] = taskList.map((task) => task.url);
  const newTargetIndex = newOrder.indexOf(insertNewAfter.url);
  newOrder.splice(newTargetIndex + 1, 0, "NEW_TASK");
  const changedTaskPriorities = new Map<string, number>();
  let newTaskPriority = 0;
  for (let i = newOrder.length - 1; i >= 0; i -= 1) {
    const url = newOrder[i];
    const priority = INITIAL + i * INCREMENT;
    if (url === "NEW_TASK") {
      newTaskPriority = priority;
    } else {
      changedTaskPriorities.set(url, priority);
    }
  }
  return {changedTaskPriorities, newTaskPriority};
}

function calculatePriorities(
  taskList: readonly Task[],
  insertNewAfter: Task,
): {
  changedTaskPriorities?: ReadonlyMap<string, number>;
  newTaskPriority: number;
} {
  if (taskList.every((task) => task.priority != null)) {
    const targetPriority = insertNewAfter.priority as number;
    const targetIndex = taskList.findIndex((t) => t.url === insertNewAfter.url);
    let newPriority: number;
    const afterTarget = taskList[targetIndex + 1];
    if (afterTarget) {
      console.assert(afterTarget.priority != null);

      newPriority = (targetPriority + (afterTarget.priority as number)) / 2;
    } else {
      newPriority = targetPriority + INCREMENT;
    }

    if (Math.abs(targetPriority - newPriority) > THRESHOLD) {
      return {newTaskPriority: newPriority};
    } else {
      return recalculateAllPriorities(taskList, insertNewAfter);
    }
  } else {
    return recalculateAllPriorities(taskList, insertNewAfter);
  }
}

interface OrderEntryProps {
  backSkip: (skip: PathTemplate[], fallback?: PathTemplate) => void;
  contactArray: readonly Contact[];
  contactLookup: (url: ContactUrl) => Contact | undefined;
  create: (instance: ResourceTypeUnion) => void;
  createOrUpdate: (instance: ResourceTypeUnion) => void;
  currentRole: Role | null;
  currentUser: User | null;
  currentUserCanManageContacts: boolean;
  currentUserProfile: UserProfile | null;
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  deliveryLocationArray: readonly DeliveryLocation[];
  dispatch: Dispatch;
  employeeGroupLookup: (url: EmployeeGroupUrl) => EmployeeGroup | undefined;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  hoursAbsenceArray: readonly HoursAbsence[];
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineGroupLookup: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  order: Order;
  orderArray: readonly Order[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  orderMergeCandidateList: readonly (Order & {taskSet: readonly Task[]})[];
  pickupLocationArray: readonly PickupLocation[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  remove: (url: string) => void;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  shareToken: string | null;
  sprayLocationArray: readonly SprayLocation[];
  sprayLogArray: readonly SprayLog[];
  taskArray: readonly Task[];
  taskList: readonly Task[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  theme: Theme;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  token: string | null;
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface OrderEntryState {
  approveAllDialogOpen: boolean;
  contactDialogOpen: boolean;
  customerDialogOpen: boolean;
  deleteDialogOpen: boolean;
  departmentDialogOpen: boolean;
  departmentDialogTask: string | null;
  estimatedTimeDialogButton: HTMLButtonElement | null;
  estimatedTimeDialogOpenFor: string | null;
  estimatedTimeDialogOpenMinutes: number | null;
  fieldDialogOpen: boolean;
  locationCreateEditDialogInitialAddress: string;
  machineDialogButton: HTMLButtonElement | null;
  machineDialogOpen: boolean;
  machineDialogTask: TaskUrl | null;
  machineOperatorDialogButton: HTMLButtonElement | null;
  machineOperatorDialogOpenFor: string | null;
  minutesExpectedTotalTaskDuration: number | null;
  orderMergeDialogOpen: boolean;
  priceGroupDialogOpenFor: TaskUrl | null;
  projectDialogButton: HTMLButtonElement | null;
  projectDialogOpenFor: string | null;
  propagateWorkplaceDialogOpen: boolean;
  taskMissingMachineOperatorErrorDialogOpen: boolean;
  timeFieldFocused: boolean;
  validateOrderDialogOpen: boolean;
  workplaceAddDialogOpen: boolean;
  workplaceDialogButton: HTMLButtonElement | null;
  workplaceDialogOpenForOrder: boolean;
  workplaceDialogOpenForTask: TaskUrl | null;
  workTypeChangeBlockedDialogOpen: boolean;
  workTypeChangeBlockedReason: WorkTypeChangeBlockedReason | null;
  workTypeDialogOpen: boolean;
}

class OrderEntry extends PureComponent<OrderEntryProps, OrderEntryState> {
  state: OrderEntryState = {
    approveAllDialogOpen: false,
    contactDialogOpen: false,
    customerDialogOpen: false,
    deleteDialogOpen: false,
    departmentDialogOpen: false,
    departmentDialogTask: null,
    estimatedTimeDialogButton: null,
    estimatedTimeDialogOpenFor: null,
    estimatedTimeDialogOpenMinutes: null,
    fieldDialogOpen: false,
    locationCreateEditDialogInitialAddress: "",
    machineDialogButton: null,
    machineDialogOpen: false,
    machineDialogTask: null,
    machineOperatorDialogButton: null,
    machineOperatorDialogOpenFor: null,
    minutesExpectedTotalTaskDuration:
      computeDuration(this.props.taskList, this.props.customerSettings.orderEntryTaskTime) || null,
    orderMergeDialogOpen: false,
    priceGroupDialogOpenFor: null,
    projectDialogButton: null,
    projectDialogOpenFor: null,
    propagateWorkplaceDialogOpen: false,
    taskMissingMachineOperatorErrorDialogOpen: false,
    timeFieldFocused: false,
    validateOrderDialogOpen: false,
    workplaceAddDialogOpen: false,
    workplaceDialogButton: null,
    workplaceDialogOpenForOrder: false,
    workplaceDialogOpenForTask: null,
    workTypeChangeBlockedDialogOpen: false,
    workTypeChangeBlockedReason: null,
    workTypeDialogOpen: false,
  };
  componentDidMount(): void {
    focusButton(this._selectCustomerButton);
  }
  UNSAFE_componentWillReceiveProps(nextProps: OrderEntryProps): void {
    if (this.props.customerSettings.orderEntryTaskTime) {
      if (this.state.minutesExpectedTotalTaskDuration == null) {
        const oldDuration = computeDuration(this.props.taskList, true);
        const newDuration = computeDuration(nextProps.taskList, true);
        if (newDuration && newDuration !== oldDuration) {
          this.setState({minutesExpectedTotalTaskDuration: newDuration});
        }
      }
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  _computeDepartment(
    taskList: readonly Task[],
    enableExternalTaskDepartmentField: boolean,
  ): string | undefined {
    if (!this.props.currentUser || !this.props.currentUserProfile) {
      return undefined;
    }
    if (enableExternalTaskDepartmentField) {
      const departmentSet = new Set(taskList.map((task) => task.department));
      if (departmentSet.size === 1) {
        return Array.from(departmentSet)[0];
      } else if (!taskList.length) {
        const employeeGroupURL = this.props.currentUserProfile.employeeGroup;
        const employeeGroup =
          (employeeGroupURL && this.props.employeeGroupLookup(employeeGroupURL)) || null;

        return computeDepartment(
          this.props.currentUserProfile,
          employeeGroup,
          this.props.customerSettings,
        );
      }
    }
    return undefined;
  }

  _selectDepartmentButton = React.createRef<HTMLButtonElement>();
  _orderMergeButton = React.createRef<HTMLButtonElement>();
  _selectCustomerButton = React.createRef<HTMLButtonElement>();
  _selectContactButton = React.createRef<HTMLButtonElement>();
  _selectWorkplaceButton = React.createRef<HTMLButtonElement>();
  _addTaskButton = React.createRef<HTMLButtonElement>();

  @bind
  handleCreateAnother(): void {
    const {order} = this.props;
    const {currentUserURL} = this.props;
    const id = uuid();
    const url = instanceURL("order", id);
    const jsObj: Writable<Order> = {
      ...emptyOrder,
      createdBy: currentUserURL,
      draft: this.props.customerSettings.orderDrafts,
      id,
      url,
    };
    const customerURL = order.customer;
    if (customerURL) {
      const contactURL = order.contact;
      jsObj.customer = customerURL;
      jsObj.contact = contactURL;
    }
    this.props.create(jsObj);
    this.props.go("/orderEntry/:id", {id});
  }
  @bind
  handleConvertToOrder(): void {
    const {customerSettings, order, taskArray, update} = this.props;
    if (
      customerSettings.requireEmployeeAssignedToTasksWhenConvertingOrderDrafts &&
      taskArray.filter((task) => task.order === order.url).some((task) => !task.machineOperator)
    ) {
      this.setState({taskMissingMachineOperatorErrorDialogOpen: true});
    } else {
      update(order.url, [{member: "draft", value: false}]);
    }
  }

  @bind
  handleMissingMachineOperatorDialogOk(): void {
    this.setState({taskMissingMachineOperatorErrorDialogOpen: false});
  }

  @bind
  handleDeleteButton(): void {
    this.setState({deleteDialogOpen: true});
  }
  @bind
  handleDeleteDialogOk(): void {
    this.setState({deleteDialogOpen: false});
    const {order, taskList} = this.props;
    for (const task of taskList) {
      this.props.remove(task.url);
    }
    this.props.remove(order.url);
    window.setTimeout(() => {
      this.props.backSkip([
        "/order/:id",
        "/order/:id/:taskID",
        "/orderEntry/:id",
        "/task/:id",
        "/taskDetails/:id",
        "/taskEdit/:id",
        "/internalTask/:id",
      ]);
    });
  }
  @bind
  handleDeleteDialogCancel(): void {
    this.setState({deleteDialogOpen: false});
  }
  @bind
  handleCustomerSelectButton(): void {
    this.setState({customerDialogOpen: true});
  }
  @bind
  handleCustomerClearButton(): void {
    const {order} = this.props;
    if (order.customer) {
      this.changeCustomerCulture(null, null);
    }
  }
  @bind
  handleCustomerDialogOk(url: CustomerUrl): void {
    const {order} = this.props;
    const oldURL = order.customer;
    if (url !== oldURL) {
      this.changeCustomerCulture(url, null);
    }
    this.setState({customerDialogOpen: false});
    focusButton(this._selectCustomerButton);
  }
  changeCustomerCulture(customerUrl: CustomerUrl | null, cultureUrl: CultureUrl | null): void {
    const {contactArray, customerLookup, order, taskList, unitLookup, update} = this.props;
    changeCustomerCulture(
      order,
      customerUrl,
      cultureUrl,
      {
        contactArray,
        customerLookup,
        taskList,
        unitLookup,
      },
      this.props.customerSettings,
      update,
    );
  }
  @bind
  handleCustomerDialogCancel(): void {
    this.setState({
      customerDialogOpen: false,
    });
    focusButton(this._selectCustomerButton);
  }
  @bind
  handleContactSelectButton(): void {
    this.setState({contactDialogOpen: true});
  }
  @bind
  handleContactClearButton(): void {
    const {order} = this.props;
    this.props.update(order.url, [{member: "contact", value: null}]);
  }
  @bind
  handleContactDialogOk(url: ContactUrl | null = null): void {
    const {order} = this.props;
    if (url !== order.contact) {
      this.props.update(order.url, [{member: "contact", value: url}]);
    }
    this.setState({contactDialogOpen: false});
    focusButton(this._selectContactButton);
  }
  @bind
  handleContactDialogCancel(): void {
    this.setState({contactDialogOpen: false});
    focusButton(this._selectContactButton);
  }
  @bind
  handleWorkTypeSelectButton(): void {
    this.setState({workTypeDialogOpen: true});
  }
  @bind
  handleWorkTypeDialogOk(url: WorkTypeUrl): void {
    this.addTaskWithWorkType(url);
    focusButton(this._addTaskButton);
    this.setState({
      workTypeDialogOpen: false,
    });
  }
  @bind
  handleWorkTypeDialogCancel(): void {
    focusButton(this._addTaskButton);
    this.setState({
      workTypeDialogOpen: false,
    });
  }
  @bind
  handleWorkplaceSelectButton(): void {
    this.setState({workplaceDialogOpenForOrder: true});
  }

  @bind
  handleWorkplaceDialogOk(newRelatedWorkplace: LocationUrl): void {
    const {order, taskArray, taskLookup, update} = this.props;

    if (this.state.workplaceDialogOpenForOrder) {
      if (order.relatedWorkplace !== newRelatedWorkplace) {
        update(order.url, [
          {
            member: "relatedWorkplace",
            value: newRelatedWorkplace,
          },
        ]);
      }

      if (
        taskArray.some(
          (task) =>
            task.order === order.url &&
            !task.validatedAndRecorded &&
            task.relatedWorkplace !== newRelatedWorkplace,
        )
      ) {
        this.setState({propagateWorkplaceDialogOpen: true});
      }
    } else if (this.state.workplaceDialogOpenForTask) {
      const task = taskLookup(this.state.workplaceDialogOpenForTask);
      if (task?.relatedWorkplace !== newRelatedWorkplace) {
        update(this.state.workplaceDialogOpenForTask, [
          {
            member: "relatedWorkplace",
            value: newRelatedWorkplace,
          },
        ]);
      }
    }

    this.setState({
      workplaceDialogOpenForOrder: false,
      workplaceDialogOpenForTask: null,
    });
    focusButton(this._selectWorkplaceButton);
  }

  @bind
  handlePropagateWorkplaceDialogOk(): void {
    const {order, taskArray, update} = this.props;

    taskArray
      .filter(
        (task) =>
          task.order === order.url &&
          !task.validatedAndRecorded &&
          task.relatedWorkplace !== order.relatedWorkplace,
      )
      .forEach((task) => {
        update(task.url, [
          {
            member: "relatedWorkplace",
            value: order.relatedWorkplace,
          },
        ]);
      });

    this.setState({propagateWorkplaceDialogOpen: false});
    focusButton(this._selectWorkplaceButton);
  }

  @bind
  handleWorkplaceDialogCancel(): void {
    if (this.state.workplaceDialogButton) {
      focusIconButton(this.state.workplaceDialogButton);
    } else {
      focusButton(this._selectWorkplaceButton);
    }
    this.setState({
      propagateWorkplaceDialogOpen: false,
      workplaceDialogButton: null,
      workplaceDialogOpenForOrder: false,
      workplaceDialogOpenForTask: null,
    });
  }

  @bind
  handleWorkplaceDialogAdd(address: string): void {
    this.setState({
      locationCreateEditDialogInitialAddress: address,
      workplaceAddDialogOpen: true,
    });
  }

  @bind
  handleWorkplaceAddDialogCancel(): void {
    focusButton(this._selectWorkplaceButton);
    this.setState({
      workplaceAddDialogOpen: false,
    });
  }

  @bind
  handleWorkplaceAddDialogOk({
    address,
    attention,
    city,
    coordinatesFromAddress,
    favorite,
    latitude,
    locationType,
    logOnlyLocation,
    longitude,
    name,
    phone,
    postalCode,
    workplaceOnlyLocation,
  }: {
    address: string;
    attention: string;
    city: string;
    coordinatesFromAddress: boolean;
    favorite: boolean;
    latitude: number | null;
    locationType: LocationTypeUrl | null;
    logOnlyLocation: boolean;
    longitude: number | null;
    name: string;
    phone: string;
    postalCode: string;
    workplaceOnlyLocation: boolean;
  }): void {
    focusButton(this._selectWorkplaceButton);
    const {order} = this.props;
    const customerURL = order.customer;
    const instance: Location = createLocation({
      address,
      attention,
      city,
      coordinatesFromAddress,
      customer: customerURL,
      favorite,
      latitude,
      locationType,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      workplaceOnlyLocation,
    });
    this.props.create(instance);
    this.handleWorkplaceDialogOk(instance.url);
    this.setState({
      workplaceAddDialogOpen: false,
    });
  }

  @bind
  handleAddTaskButton(): void {
    const {noExternalTaskWorkType} = this.props.customerSettings;
    if (!noExternalTaskWorkType) {
      this.setState({workTypeDialogOpen: true});
    } else {
      this.addTask(null);
    }
  }
  addTask(workTypeURL: WorkTypeUrl | null): void {
    const {currentUserURL, customerSettings} = this.props;
    const {order, priceGroupLookup, taskList, workTypeLookup} = this.props;
    const orderURL = order.url;
    const workType = workTypeURL && this.props.workTypeLookup(workTypeURL);

    let workTypePriceGroups: readonly PriceGroupUrl[] = [];
    if (workType) {
      workTypePriceGroups = workType.pricegroups.filter(
        (priceGroupUrl) => priceGroupLookup(priceGroupUrl)?.active,
      );
      this.props.update(orderURL, [{member: "workType", value: workTypeURL}]);
    }
    const priceGroupAlternatives = new Set(workTypePriceGroups);
    let priceGroup: PriceGroupUrl | null = null;
    if (priceGroupAlternatives.size === 1) {
      priceGroup = Array.from(priceGroupAlternatives)[0];
    }
    let newLastPriority: number;
    const oldLast = taskList[taskList.length - 1];
    if (taskList.some((task) => task.priority == null)) {
      const {changedTaskPriorities, newTaskPriority} = recalculateAllPriorities(taskList, oldLast);
      newLastPriority = newTaskPriority;
      if (changedTaskPriorities) {
        changedTaskPriorities.forEach((priority, url) => {
          this.props.update(url, [{member: "priority", value: priority}]);
        });
      }
    } else {
      newLastPriority = oldLast ? (oldLast.priority as number) + INCREMENT : INITIAL;
    }

    const id = uuid();
    const url = instanceURL("task", id);
    const task: Writable<Task> = {
      ...emptyTask,
      archivable: false,
      completed: false,
      computedTimeSet: [],
      // for local ordering until set by server
      created: new Date().toISOString(),
      createdBy: currentUserURL,
      date: customerSettings.autoInsertOrderDateAsNewTaskDate ? order.date : null,
      department: order.department,
      fielduseSet: order.orderfielduseSet,
      id,
      machineGroup: null,
      machineOperator: customerSettings.defaultTaskEmployee
        ? instanceURL("user", customerSettings.defaultTaskEmployee)
        : null,
      machineOperatorTimeCorrectionSet: [],
      machineuseSet: [],
      managerTimeCorrectionSet: [],
      order: orderURL,
      priceGroup,
      priority: newLastPriority,
      recordedInC5: null,
      relatedWorkplace: order.relatedWorkplace,
      url,
      validatedAndRecorded: false,
      workType: workTypeURL,
    };
    if (order.time) {
      task.time = order.time;
    }
    if (this.state.minutesExpectedTotalTaskDuration) {
      task.minutesExpectedTotalTaskDuration = this.state.minutesExpectedTotalTaskDuration;
    }
    this.props.create(task);
    if (
      customerSettings.askForPriceGroupSelectionAfterWorkTypeSelection &&
      workTypeURL &&
      workTypePotentialPriceGroups(workTypeURL, order.customer, workTypeLookup, priceGroupLookup)
        .length > 1
    ) {
      this.setState({
        priceGroupDialogOpenFor: url,
      });
      return;
    }
    focusButton(this._addTaskButton);
  }
  addTaskWithWorkType(workTypeURL: WorkTypeUrl): void {
    this.addTask(workTypeURL);
  }
  @bind
  handlePriceGroupDialogOk(priceGroupURL: PriceGroupUrl): void {
    const taskURL = this.state.priceGroupDialogOpenFor;
    if (!taskURL) {
      return;
    }
    focusButton(this._addTaskButton);
    this.setState({priceGroupDialogOpenFor: null});
    const patch: Patch<Task> = [{member: "priceGroup", value: priceGroupURL}];
    this.props.update(taskURL, patch);
  }
  @bind
  handlePriceGroupDialogCancel(): void {
    focusButton(this._addTaskButton);
    this.setState({priceGroupDialogOpenFor: null});
  }

  @bind
  handleRequestWorkplaceEdit(taskURL: TaskUrl, ref: HTMLButtonElement | null): void {
    this.setState({
      workplaceDialogButton: ref,
      workplaceDialogOpenForTask: taskURL,
    });
  }
  @bind
  handleRequestMachineOperatorEdit(taskURL: string, ref: HTMLButtonElement | null): void {
    this.setState({
      machineOperatorDialogButton: ref,
      machineOperatorDialogOpenFor: taskURL,
    });
  }
  @bind
  handleMachineOperatorDialogOk(url: UserUrl): void {
    const taskURL = this.state.machineOperatorDialogOpenFor;
    if (!taskURL) {
      return;
    }
    focusIconButton(this.state.machineOperatorDialogButton);
    this.setState({
      machineOperatorDialogButton: null,
      machineOperatorDialogOpenFor: null,
    });
    this.props.update(taskURL, [{member: "machineOperator", value: url}]);
  }
  @bind
  handleMachineOperatorDialogCancel(): void {
    focusIconButton(this.state.machineOperatorDialogButton);
    this.setState({
      machineOperatorDialogButton: null,
      machineOperatorDialogOpenFor: null,
    });
  }
  @bind
  handleRequestProjectEdit(taskURL: string, ref: HTMLButtonElement | null): void {
    this.setState({
      projectDialogButton: ref,
      projectDialogOpenFor: taskURL,
    });
  }

  @bind
  handleProjectDialogOk(url: ProjectUrl): void {
    const taskURL = this.state.projectDialogOpenFor;
    if (!taskURL) {
      return;
    }
    focusIconButton(this.state.projectDialogButton);
    this.setState({
      projectDialogButton: null,
      projectDialogOpenFor: null,
    });
    this.props.update(taskURL, [{member: "project", value: url}]);
  }

  @bind
  handleProjectDialogCancel(): void {
    focusIconButton(this.state.projectDialogButton);
    this.setState({
      projectDialogButton: null,
      projectDialogOpenFor: null,
    });
  }

  @bind
  handleRequestEstimatedTimeEdit(taskURL: TaskUrl, ref: HTMLButtonElement | null): void {
    const task = this.props.taskLookup(taskURL);
    if (task) {
      this.setState({
        estimatedTimeDialogButton: ref,
        estimatedTimeDialogOpenFor: taskURL,
        estimatedTimeDialogOpenMinutes: task.minutesExpectedTotalTaskDuration,
      });
    }
  }
  @bind
  handleEstimatedTimeDialogOk(): void {
    const taskURL = this.state.estimatedTimeDialogOpenFor;
    if (!taskURL) {
      return;
    }
    focusIconButton(this.state.estimatedTimeDialogButton);
    const minutes = this.state.estimatedTimeDialogOpenMinutes;
    this.setState({
      estimatedTimeDialogButton: null,
      estimatedTimeDialogOpenFor: null,
      estimatedTimeDialogOpenMinutes: null,
    });
    this.props.update(taskURL, [
      {
        member: "minutesExpectedTotalTaskDuration",
        value: minutes,
      },
    ]);
  }
  @bind
  handleEstimatedTimeDialogCancel(): void {
    focusIconButton(this.state.estimatedTimeDialogButton);
    this.setState({
      estimatedTimeDialogButton: null,
      estimatedTimeDialogOpenFor: null,
      estimatedTimeDialogOpenMinutes: null,
    });
  }
  @bind
  handleRequestMachineAdd(taskURL: TaskUrl, ref: HTMLButtonElement | null): void {
    this.setState({
      machineDialogButton: ref,
      machineDialogOpen: true,
      machineDialogTask: taskURL,
    });
  }
  @bind
  handleMachineDialogOk(machineURL: MachineUrl, priceGroupURL: PriceGroupUrl | null): void {
    const {update} = this.props;
    const taskURL = this.state.machineDialogTask;
    if (!taskURL) {
      return;
    }
    this.setState({machineDialogButton: null, machineDialogOpen: false});
    const task = this.props.taskLookup(taskURL);
    if (!task) {
      return;
    }
    const oldMachines = task.machineuseSet || [];
    if (machineAlreadySelected(task, machineURL)) {
      return;
    }
    const newEntry: MachineUse = {
      machine: machineURL,
      priceGroup: priceGroupURL,
      transporter: false,
    };
    const newMachines = [...oldMachines, newEntry];
    const patch: Patch<Task> = [
      {
        member: "machineuseSet",
        value: newMachines,
      },
    ];
    update(task.url, patch);
    const workType = task.workType ? this.props.workTypeLookup(task.workType) : undefined;
    if (workType?.department) {
      return;
    }
    const machine = this.props.machineLookup(machineURL);
    if (!machine?.department) {
      return;
    }
    const oldMachineDepartments = new Set(
      oldMachines
        .map((machineUse) => this.props.machineLookup(machineUse.machine))
        .filter(notUndefined)
        .map((oldMachine) => oldMachine.department)
        .filter(Boolean),
    );
    if (oldMachineDepartments.size && !oldMachineDepartments.has(machine.department)) {
      this.setState({
        departmentDialogOpen: true,
        departmentDialogTask: taskURL,
      });
    }
  }
  @bind
  handleMachineDialogCancel(): void {
    focusIconButton(this.state.machineDialogButton);
    this.setState({machineDialogButton: null, machineDialogOpen: false});
  }
  @bind
  handleWorkTypeChangeBlocked(reason: WorkTypeChangeBlockedReason): void {
    this.setState({
      workTypeChangeBlockedDialogOpen: true,
      workTypeChangeBlockedReason: reason,
    });
  }
  @bind
  handleWorkTypeChangeBlockedDialogClose(): void {
    this.setState({workTypeChangeBlockedDialogOpen: false});
  }

  @bind
  handleEstimatedTimeDialogOpenMinutesChange(newValue: number | null): void {
    this.setState({estimatedTimeDialogOpenMinutes: newValue});
  }
  @bind
  handleDepartmentSelectButton(): void {
    this.setState({departmentDialogOpen: true});
  }
  @bind
  handleDepartmentDialogOk(selected: string): void {
    const {departmentDialogTask} = this.state;
    if (departmentDialogTask) {
      this.props.update(departmentDialogTask, [{member: "department", value: selected}]);
      this.setState({departmentDialogOpen: false, departmentDialogTask: null});
    } else {
      const {order, taskList} = this.props;
      taskList.forEach((task) => {
        this.props.update(task.url, [{member: "department", value: selected}]);
      });
      this.props.update(order.url, [{member: "department", value: selected}]);
      this.setState({departmentDialogOpen: false});
      focusButton(this._selectDepartmentButton);
    }
  }
  @bind
  handleDepartmentDialogCancel(): void {
    focusButton(this._selectDepartmentButton);
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleOrderMergeButton(): void {
    this.setState({orderMergeDialogOpen: true});
  }
  @bind
  handleOrderMergeDialogCancel(): void {
    // eslint-disable-next-line react/no-string-refs
    focusButton(this._orderMergeButton);
    this.setState({orderMergeDialogOpen: false});
  }
  @bind
  handleOrderMergeDialogOk(
    selectedOrderList: readonly (Order & {taskSet: readonly Task[]})[],
  ): void {
    const currentOrderURL = this.props.order.url;
    // eslint-disable-next-line react/no-string-refs
    focusButton(this._orderMergeButton);
    this.setState({orderMergeDialogOpen: false});
    const taskURLSet = new Set<string>();
    const orderURLSet = new Set<string>();
    selectedOrderList.forEach((order) => {
      orderURLSet.add(order.url);
      order.taskSet.forEach((task) => {
        taskURLSet.add(task.url);
      });
    });
    if (this.props.order.orderfielduseSet && this.props.order.orderfielduseSet.length) {
      this.props.update(currentOrderURL, [{member: "orderfielduseSet", value: []}]);
    }
    taskURLSet.forEach((taskURL) => {
      this.props.update(taskURL, [{member: "order", value: currentOrderURL}]);
    });
    orderURLSet.forEach((orderURL) => {
      this.props.remove(orderURL);
    });
  }
  @bind
  handleMinutesExpectedTotalTaskDuration(newValue: number | null): void {
    this.setState({minutesExpectedTotalTaskDuration: newValue});
  }
  @bind
  handleSelectFieldsClick(): void {
    this.setState({
      fieldDialogOpen: true,
    });
  }

  updateTaskFields(oldList: readonly OrderFieldUse[], newList: readonly OrderFieldUse[]): void {
    const {taskList, update} = this.props;

    let tempStore = taskList;

    const diffList = fieldListDiff(oldList, newList);

    // Handle deleted
    tempStore = tempStore.map((task) => {
      const usedFieldsUrls =
        task.reportingLocations && task.reportingLog
          ? getUsedFieldUrlSet(task.reportingLocations, task.reportingLog)
          : undefined;

      const filteredList = task.fielduseSet.filter(
        (fieldUse) =>
          usedFieldsUrls?.has(fieldUse.relatedField) ||
          !diffList.remove.includes(fieldUse.relatedField),
      );
      return {...task, fielduseSet: filteredList};
    });
    // Handle added
    diffList.add.forEach((fieldAdded) => {
      tempStore = tempStore.map((task) => {
        const fieldUses = task.fielduseSet;
        const alreadyPresent = fieldUses.some(
          (fieldUse) => fieldUse.relatedField === fieldAdded.relatedField,
        );
        if (alreadyPresent) {
          return task;
        } else {
          const newFieldUses = [...fieldUses, {...fieldAdded, notes: ""}];
          return {...task, fielduseSet: newFieldUses};
        }
      });
    });
    tempStore.forEach((task) => {
      if (!task.completed) {
        update(task.url, [{member: "fielduseSet", value: task.fielduseSet}]);
      }
    });
  }

  @bind
  handleFieldDialogOk(fieldURLSet: ReadonlySet<LocationUrl>): void {
    const {locationLookup, order} = this.props;
    const oldSelected = this.getFieldUseList();
    const fieldUseList = getUpdatedFieldUseList(fieldURLSet, oldSelected, locationLookup);

    this.props.update(order.url, [{member: "orderfielduseSet", value: fieldUseList}]);
    this.setState({fieldDialogOpen: false});

    this.updateTaskFields(oldSelected, fieldUseList);
  }

  getFieldUseList(): readonly OrderFieldUse[] {
    const {order, taskList} = this.props;

    let orderFieldUseList: readonly OrderFieldUse[];
    if (order.orderfielduseSet) {
      orderFieldUseList = order.orderfielduseSet;
    } else {
      orderFieldUseList = [];
    }

    let fieldUseList = orderFieldUseList;

    taskList.forEach((task) => {
      const taskFieldUseList = task.fielduseSet;
      if (taskFieldUseList && taskFieldUseList.length) {
        taskFieldUseList.forEach((taskFieldUse) => {
          const fieldURL = taskFieldUse.relatedField;
          if (!fieldUseList.some((f) => f.relatedField === fieldURL)) {
            fieldUseList = [...fieldUseList, {...taskFieldUse, notes: ""}];
          }
        });
      }
    });
    return fieldUseList;
  }

  @bind
  handleNotesChange(fieldNotes: FieldUseWithField): void {
    const {order} = this.props;
    const fieldUseList = this.getFieldUseList();
    const newfieldUseList = fieldUseList.slice();
    const idx = fieldUseList.findIndex((field) => field.relatedField === fieldNotes.field.url);
    newfieldUseList[idx] = {...newfieldUseList[idx], notes: fieldNotes.notes};
    this.props.update(order.url, [{member: "orderfielduseSet", value: newfieldUseList}]);
    this.updateTaskFields(fieldUseList, newfieldUseList);
  }

  @bind
  handleRearrange(sourceField: FieldUseWithField, targetField: FieldUseWithField): void {
    const {order} = this.props;
    const fieldUseList = this.getFieldUseList();
    const sourceFieldUse: OrderFieldUse = {
      fieldAreaHa: sourceField.field.fieldAreaHa,
      fieldCrop: sourceField.field.fieldCrop,
      geojson: sourceField.field.geojson,
      notes: sourceField.notes,
      relatedField: sourceField.field.url,
    };
    const sourceIndex = fieldUseList.findIndex(
      (field) => field.relatedField === sourceFieldUse.relatedField,
    );
    const targetIndex = fieldUseList.findIndex(
      (field) => field.relatedField === targetField.field.url,
    );
    const newfieldUseList = fieldUseList.slice();
    newfieldUseList.splice(sourceIndex, 1);
    newfieldUseList.splice(targetIndex, 0, sourceFieldUse);
    this.props.update(order.url, [{member: "orderfielduseSet", value: newfieldUseList}]);
    this.updateTaskFields(fieldUseList, newfieldUseList);
  }

  @bind
  handleFieldDialogCancel(): void {
    this.setState({fieldDialogOpen: false});
  }

  @bind
  handleApproveAll(): void {
    this.setState({approveAllDialogOpen: true});
  }
  @bind
  handleApproveAllDialogOk(taskList: readonly InlinedTask[]): void {
    const {customerSettings, order, timerArray, timerStartArray, update: updateFn} = this.props;
    const breakTimer = getBreakTimer(timerArray);
    this.setState({approveAllDialogOpen: false});

    taskList.forEach((task) => {
      const patch: PatchOperation<Task>[] = [];
      if (customerSettings.useApproveReport) {
        patch.push({member: "reportApproved", value: true});
      } else {
        patch.push({member: "validatedAndRecorded", value: true});
      }
      if (
        !willTaskBeRecorded(
          task,
          order,
          this.props.customerSettings,
          timerStartArray,
          breakTimer?.url,
        )
      ) {
        if (customerSettings.useApproveReport) {
          console.assert(!task.validatedAndRecorded);
          patch.push({member: "validatedAndRecorded", value: true});
        }
        patch.push({member: "archivable", value: true});
      }
      updateFn(task.url, patch);
    });

    if (!customerSettings.orderValidation) {
      setTimeout(() => {
        this.props.go("/order", {}, {tab: "validation"});
      }, 300);
    }
  }
  @bind
  handleApproveAllDialogCancel(): void {
    this.setState({approveAllDialogOpen: false});
  }

  @bind
  handleValidateOrder(): void {
    this.setState({validateOrderDialogOpen: true});
  }
  @bind
  handleValidateOrderDialogOk(): void {
    const {order, taskArray, timerArray, timerStartArray, update: updateFn} = this.props;
    const breakTimer = getBreakTimer(timerArray);
    this.setState({validateOrderDialogOpen: false});
    const orderURL = order.url;
    updateFn(orderURL, [{member: "validatedAndRecorded", value: true}]);
    if (this.props.customerSettings.useApproveReport) {
      taskArray
        .filter(
          (task) => task.order === orderURL && task.reportApproved && !task.validatedAndRecorded,
        )
        .forEach((task) => {
          // Really bad "feature". Setting validatedAndRecorded on all tasks
          // here is only usefull for econSync customers that also has
          // customerSettings.useApproveReport set as validatedAndRecorded
          // is used to mark which tasks will be transferred to e-conomic next
          // time the transfer button is clicked.
          const patch: PatchOperation<Task>[] = [{member: "validatedAndRecorded", value: true}];
          if (
            !willTaskBeRecorded(
              task,
              order,
              this.props.customerSettings,
              timerStartArray,
              breakTimer?.url,
            )
          ) {
            patch.push({member: "archivable", value: true});
          }
          updateFn(task.url, patch);
        });
    }
    setTimeout(() => {
      this.props.go("/order", {}, {tab: "validation"});
    }, 300);
  }
  @bind
  handleValidateOrderDialogCancel(): void {
    this.setState({validateOrderDialogOpen: false});
  }

  @bind
  handleCopyDate(): void {
    const {order, taskArray} = this.props;
    const orderURL = order.url;
    taskArray
      .filter(
        (task) =>
          task.order === orderURL &&
          !task.completed &&
          !this.props.timerStartArray.some((instance) => instance.task === task.url),
      )
      .forEach((task) => {
        this.props.update(task.url, [{member: "date", value: order.date}]);
      });
  }

  @bind
  handleTransferTime(): void {
    const {order, taskArray} = this.props;
    const orderURL = order.url;
    taskArray
      .filter(
        (task) =>
          task.order === orderURL &&
          !task.completed &&
          !this.props.timerStartArray.some((instance) => instance.task === task.url),
      )
      .forEach((task) => {
        this.props.update(task.url, [{member: "time", value: order.time}]);
      });
  }

  handleCreateCopy = (): void => {
    const {
      currentRole,
      currentUserURL,
      customerSettings,
      locationLookup,
      machineLookup,
      order,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLogArray,
      taskList,
      transportLogArray,
      workTypeLookup,
      yieldLogArray,
    } = this.props;
    const orderCopyID = uuid();
    const orderCopyURL = instanceURL("order", orderCopyID);
    const newOrder: Writable<Order> = {
      ...emptyOrder,
      createdBy: currentUserURL,
      id: orderCopyID,
      url: orderCopyURL,
    };
    customerSettings.orderCopyFields.forEach((fieldName) => {
      const value = order[fieldName as keyof Order];
      if (value !== undefined) {
        (newOrder as any)[fieldName] = value;
      }
    });

    if (customerSettings.orderDrafts && customerSettings.orderDraftsAllwaysCreateDraft) {
      newOrder.draft = true;
    }

    this.props.create(newOrder);

    const overrides: Partial<Writable<Task>> = {
      createdBy: currentUserURL,
      date: null,
      order: orderCopyURL,
    };
    if (
      !currentRole ||
      !currentRole.manager ||
      customerSettings.taskCopyAlwaysOverridemachineOperator
    ) {
      overrides.machineOperator = currentUserURL;
    }

    taskList.forEach((task, index) => {
      const copyResult = copyTaskWithLogs(
        task,
        customerSettings.taskCopyFields,
        overrides,
        {
          locationLookup,
          machineLookup,
          priceGroupLookup,
          productLookup,
          projectLookup,
          reportingSpecificationLookup,
          sprayLogArray,
          transportLogArray,
          workTypeLookup,
          yieldLogArray,
        },
        this.props.dispatch,
        this.context,
        customerSettings,
      );
      const taskCopy = copyResult[0] as Task;

      this.props.create({
        ...taskCopy,
        priority: INITIAL + index * INCREMENT,
        url: taskCopy.url,
      });
      // skipping the task instance; it is handled otherwise; only save logs/other...
      for (let i = 1; i < copyResult.length; i += 1) {
        const instance = copyResult[i];
        this.props.createOrUpdate(instance);
      }
    });
    window.setTimeout(() => {
      this.props.go("/orderEntry/:id", {id: orderCopyID}, {}, "REPLACE");
    }, 0);
  };

  @bind
  handleTimeFocus(): void {
    this.setState({timeFieldFocused: true});
  }

  @bind
  handleTimeBlur(): void {
    this.setState({timeFieldFocused: false});
  }

  @bind
  handleTaskCopy(taskURL: TaskUrl): void {
    const {taskList, taskLookup} = this.props;
    const task = taskLookup(taskURL);

    if (!task) {
      return;
    }

    const {
      currentUserURL,
      customerSettings,
      deliveryLocationArray,
      locationLookup,
      machineLookup,
      pickupLocationArray,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLocationArray,
      sprayLogArray,
      transportLogArray,
      workTypeLookup,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldPickupLocationArray,
    } = this.props;

    if (!currentUserURL) {
      return;
    }

    const {changedTaskPriorities, newTaskPriority} = calculatePriorities(taskList, task);

    const overrides = {
      createdBy: currentUserURL,
      order: task.order,
      priority: newTaskPriority,
    };

    const copyResult = copyTaskWithLogsLocations(
      task,
      customerSettings.taskCopyFields,
      overrides,
      {
        deliveryLocationArray,
        locationLookup,
        machineLookup,
        pickupLocationArray,
        priceGroupLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        sprayLocationArray,
        sprayLogArray,
        transportLogArray,
        workTypeLookup,
        yieldDeliveryLocationArray,
        yieldLogArray,
        yieldPickupLocationArray,
      },
      this.props.dispatch,
      this.context,
      customerSettings,
    );

    copyResult.forEach((instance) => {
      this.props.createOrUpdate(instance);
    });

    if (changedTaskPriorities) {
      changedTaskPriorities.forEach((priority, url) => {
        this.props.update(url, [{member: "priority", value: priority}]);
      });
    }
  }

  @bind
  handleReferenceNumberChange(value: string): void {
    const {order, update} = this.props;
    update(order.url, [{member: "referenceNumber", value}]);
  }

  @bind
  handleTimeChange(value: string | null): void {
    const {order, update} = this.props;
    update(order.url, [{member: "time", value}]);
  }

  @bind
  handleEarliestDateChange(value: string | null): void {
    const {order, update} = this.props;
    const patch: PatchOperation<Order>[] = [{member: "earliestDate", value}];
    if (!order.latestDate) {
      patch.push({member: "latestDate", value});
    }
    update(order.url, patch);
  }

  @bind
  handleLatestDateChange(value: string | null): void {
    const {order, update} = this.props;
    update(order.url, [{member: "latestDate", value}]);
  }

  @bind
  handleDateChange(value: string | null): void {
    const {order, update} = this.props;
    update(order.url, [{member: "date", value}]);
  }

  @bind
  handleDurationDaysChange(value: number | null): void {
    const {order, update} = this.props;
    update(order.url, [{member: "durationDays", value}]);
  }

  @bind
  handleOrderNotesChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {order, update} = this.props;
    update(order.url, [{member: "notes", value: filteredValue}]);
  }

  @bind
  handleManagerInternalNotesChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {order, update} = this.props;
    update(order.url, [{member: "managerInternalNotes", value: filteredValue}]);
  }

  @bind
  handleWorkplaceClearButton(): void {
    const {order, update} = this.props;
    if (order.relatedWorkplace) {
      update(order.url, [{member: "relatedWorkplace", value: null}]);
    }
  }

  @bind
  getResultsData(): {
    effectiveMinutes: number;
    resultRowData: {
      [key: string]: {
        correctedTotal: number;
        name: string;
        total: number;
        unit: string;
        workplace: string;
      };
    };
    startprisCounts: {[priceItemURL: string]: number | undefined};
    workTypeEffectiveMinutesMap: Map<WorkTypeUrl, number>;
  } {
    const {
      customerSettings,
      locationLookup,
      machineLookup,
      priceGroupLookup,
      taskList,
      timerArray,
      timerLookup,
      workTypeLookup,
    } = this.props;
    const intl = this.context;
    const resultRowData: {
      [key: string]: {
        correctedTotal: number;
        name: string;
        total: number;
        unit: string;
        workplace: string;
      };
    } = {};
    const startprisCounts: {[priceItemURL: string]: number | undefined} = {};
    let effectiveMinutes = 0;
    const workTypeEffectiveMinutesMap = new Map<WorkTypeUrl, number>();
    taskList.forEach((task) => {
      const secondaryTimerList = getTaskSecondaryTimerList(task, customerSettings, {
        machineLookup,
        priceGroupLookup,
        timerArray,
        workTypeLookup,
      });
      let computedIntervals;
      if (task.archivable) {
        computedIntervals = task.computedTimeSet;
      } else {
        const taskURL = task.url;
        const taskTimerStartSeq = _.sortBy(
          this.props.timerStartArray.filter((instance) => instance.task === taskURL),
          getNormalisedDeviceTimestamp,
        );
        computedIntervals = computeIntervalsTruncated(taskTimerStartSeq);
      }
      const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
      const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
      const intervals = mergeIntervals(
        computedIntervals,
        correctionIntervals,
        managerCorrectionIntervals,
      );
      const now = new Date();
      now.setUTCMilliseconds(0);
      const timerMinutesMap = computeIntervalSums(intervals, now);
      const genericPrimaryTimer = getGenericEffectiveTimer(this.props.timerArray) as Timer;
      console.assert(genericPrimaryTimer);
      const primaryTimerURL = genericPrimaryTimer.url;
      if (customerSettings.orderEntryResultsEffective && task.workType) {
        const minutes = timerMinutesMap.get(primaryTimerURL) || 0;
        effectiveMinutes += minutes;
        workTypeEffectiveMinutesMap.set(
          task.workType,
          (workTypeEffectiveMinutesMap.get(task.workType) || 0) + minutes,
        );
      }
      const workplaceURL = task.relatedWorkplace;
      const workplace = workplaceURL ? locationLookup(workplaceURL) : null;
      let workplaceString = "";
      if (customerSettings.orderEntryResultsWorkplace) {
        workplaceString = workplace
          ? customerAddress(workplace)
          : (task.address || "").split("\n").join(", ");
      }
      const priceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {}));
      let usesDistributionTable = false;
      const {department} = task;
      const allowMoreThanTwoMachines =
        customerSettings.alwaysAllowMoreThanTwoMachines ||
        customerSettings.allowMoreThanTwoMachinesForDepartments.includes(department);
      if (allowMoreThanTwoMachines) {
        usesDistributionTable = hasMultipleManualDistributionPriceItemUses(
          task,
          this.props.priceItemLookup,
          this.props.unitLookup,
        );
      }

      priceItemUseList.forEach((priceItemUse) => {
        const priceItemURL = priceItemUse.priceItem;
        const priceItem = this.props.priceItemLookup(priceItemURL);
        if (!priceItem) {
          return;
        }
        const {itemtype} = priceItem;
        if (
          !customerSettings.orderEntryResultsEffective &&
          (itemtype === ITEM_TYPE_MINIMUM ||
            itemtype === ITEM_TYPE_TIMER ||
            priceItem.genericEffectiveTimerTarget ||
            priceItem.minimumCount)
        ) {
          // Skipping item types MINIMUM and TIMER
          return;
        }
        if (itemtype === 1) {
          // Recording, then skipping item type STARTPRIS
          startprisCounts[priceItemURL] = (startprisCounts[priceItemURL] || 0) + 1;
          return;
        }
        const {name} = priceItem;
        const unit = getUnitString(priceItem, this.props.unitLookup);
        const taskPriceGroupURL = task.priceGroup;
        const taskPriceGroup = taskPriceGroupURL ? priceGroupLookup(taskPriceGroupURL) : null;
        const taskPriceGroupItemSet = taskPriceGroup ? taskPriceGroup.priceGroupItemSet : null;
        const isPrimaryTimer = taskPriceGroupItemSet
          ? taskPriceGroupItemSet.some(
              (priceGroupItem) => priceGroupItem.priceItem === priceItemURL,
            )
          : false;
        let breakHours = 0;
        if (customerSettings.billedBreaks && isPrimaryTimer) {
          const breakTimer = getBreakTimer(timerArray);
          console.assert(breakTimer);
          const breakTimerURL = breakTimer?.url;
          breakHours = ((breakTimerURL && timerMinutesMap.get(breakTimerURL)) || 0) / HOUR_MINUTES;
        }
        let amount = priceItemUse.count;
        let correctedAmount = priceItemUse.correctedCount;

        const isDistributionTime = priceItemIsManualDistributionTime(
          this.props.unitLookup,
          priceItem,
        );
        if (usesDistributionTable && isDistributionTime) {
          if (amount == null) {
            // blank in distribution table should count as zero...
            amount = 0;
          }
          const minutes = amount;
          const adjustedMinutes = adjustMinutes(
            customerSettings.distributionTableAdjustTimeMethod,
            minutes,
          );
          amount = adjustedMinutes / HOUR_MINUTES;
        } else if (amount == null && priceItemIsTime(this.props.unitLookup, priceItem)) {
          let priceItemTimer;
          if (isPrimaryTimer) {
            priceItemTimer = timerLookup(primaryTimerURL);
          } else {
            priceItemTimer = secondaryTimerList.find((timer) => {
              const priceGroupURL = timer.priceGroup;
              const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : null;
              if (!priceGroup) {
                return false;
              }
              return priceGroup.priceGroupItemSet.some(
                (priceGroupItem) => priceGroupItem.priceItem === priceItemURL,
              );
            });
          }
          if (priceItemTimer) {
            const minutes = timerMinutesMap.get(priceItemTimer.url) || 0;
            amount = minutes / HOUR_MINUTES + breakHours;
            if (correctedAmount == null) {
              const correctedMinutes = adjustMinutes(
                customerSettings.adjustBilledMinutes,
                isPrimaryTimer ? minutes + breakHours * HOUR_MINUTES : minutes,
              );
              correctedAmount = correctedMinutes / HOUR_MINUTES;
            }
          }
        }
        if (amount == null) {
          amount = 0;
        }
        if (correctedAmount == null) {
          correctedAmount = amount;
        }
        const key = [name.toLowerCase(), unit.toLowerCase(), workplaceString.toLowerCase()].join(
          "-",
        );
        if (resultRowData[key]) {
          const oldTotal = resultRowData[key].total;
          resultRowData[key].total = oldTotal + amount;
          const oldCorrectedTotal = resultRowData[key].correctedTotal;
          resultRowData[key].correctedTotal = oldCorrectedTotal + correctedAmount;
        } else {
          resultRowData[key] = {
            correctedTotal: correctedAmount,
            name,
            total: amount,
            unit,
            workplace: workplaceString,
          };
        }
      });
      const productUseList = sortByOrderMember(Object.values(task.productUses || {}));
      productUseList.forEach((productUse) => {
        const productURL = productUse.product;
        const product = this.props.productLookup(productURL);
        if (!product) {
          return;
        }
        const {name} = product;
        const unit = getUnitString(product, this.props.unitLookup);
        const {ours} = productUse;
        const amount = productUse.count || 0;
        let correctedAmount = productUse.correctedCount;
        if (correctedAmount == null) {
          correctedAmount = amount;
        }
        const key = [
          name.toLowerCase(),
          unit.toLowerCase(),
          workplaceString.toLowerCase(),
          `${ours}`,
        ].join("-");
        if (resultRowData[key]) {
          const oldTotal = resultRowData[key].total;
          resultRowData[key].total = oldTotal + amount;
          const oldCorrectedTotal = resultRowData[key].correctedTotal;
          resultRowData[key].correctedTotal = oldCorrectedTotal + correctedAmount;
        } else {
          resultRowData[key] = {
            correctedTotal: correctedAmount,
            name: ours ? name : intl.formatMessage({defaultMessage: "{name} (Deres)"}, {name}),
            total: amount,
            unit,
            workplace: workplaceString,
          };
        }
      });
    });
    return {
      effectiveMinutes,
      resultRowData,
      startprisCounts,
      workTypeEffectiveMinutesMap,
    };
  }
  @bind
  getPDFData(): {
    address: string;
    contactEmail: string;
    contactMobile: string;
    contactName: string;
    contactPhone: string;
    customerName: string;
    decimals: number;
    deviceTimestamp: string;
    notes: string;
    orderWorkplaceLabel: string;
    orderWorkplaceString: string;
    reference: string;
    referenceLabel: string | null;
    showInvoiceNoteOnOrderPdf: boolean;
    showRealTimeColumnOnOrderPDF: boolean;
    tasks: {
      date: string | null;
      employeeName: string;
      invoiceNote: string | null;
      locationDescription: string;
      notes: string | null;
      results: {
        minutes: number | null;
        text: string;
        unit: string;
        value: number;
      }[];
      workDescription: string;
    }[];
    totals: {
      correctedTotal: number;
      name: string;
      total: number;
      unit: string;
      workplace: string;
    }[];
  } {
    const {
      contactLookup,
      customerLookup,
      customerSettings,
      locationLookup,
      machineLookup,
      order,
      priceGroupLookup,
      priceItemLookup,
      productLookup,
      taskList,
      timerArray,
      unitLookup,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;

    const intl = this.context;
    const customerURL = order.customer;
    const customer = customerURL ? customerLookup(customerURL) : null;
    let customerName = "";
    let address = "";
    if (customer) {
      customerName = customer.name;
      address = customerAddress(customer);
    }
    const contactURL = order.contact;
    const contact = contactURL ? contactLookup(contactURL) : null;
    let contactName = "";
    let contactPhone = "";
    let contactMobile = "";
    let contactEmail = "";
    if (contact) {
      contactName = contact.name;
      contactPhone = contact.phone;
      contactMobile = contact.cellphone;
      contactEmail = contact.email;
    } else if (customer) {
      contactPhone = customer.phone;
      contactMobile = customer.cellphone;
      contactEmail = customer.billingEmail || customer.logEmail;
    }
    const tasks: {
      date: string | null;
      employeeName: string;
      invoiceNote: string | null;
      locationDescription: string;
      notes: string | null;
      results: {
        minutes: number | null;
        text: string;
        unit: string;
        value: number;
      }[];
      workDescription: string;
    }[] = [];
    taskList.forEach((task) => {
      if (!task.completed) {
        return;
      }
      const taskNotes = customerSettings.showTaskNotesOnOrderEntryPage
        ? task.notesFromMachineOperator
        : null;
      const invoiceNote = customerSettings.showInvoiceNoteOnOrderPdf ? task.invoiceNote : null;
      let workDescription: string;
      const workTypeURL = task.workType;
      const workType = workTypeURL ? workTypeLookup(workTypeURL) : null;
      if (workType) {
        workDescription = `${workType.identifier}: ${workType.name}`;
        const priceGroupURL = task.priceGroup;
        const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : null;
        if (priceGroup) {
          workDescription += `, ${priceGroup.name}`;
        }
      } else {
        workDescription = task.machineuseSet
          .filter((machineuse) => machineuse.priceGroup)
          .flatMap((machineuse) => {
            const priceGroup = priceGroupLookup(machineuse.priceGroup as PriceGroupUrl);
            if (priceGroup) {
              if (priceGroup.identifier) {
                return [`${priceGroup.identifier}: ${priceGroup.name}`];
              } else {
                return [priceGroup.name];
              }
            }
            return [];
          })
          .join(", ");
      }
      let locationDescription = "";
      const pickupLocationURL = task.relatedPickupLocation;
      const pickupLocation = pickupLocationURL ? locationLookup(pickupLocationURL) : null;
      if (pickupLocation) {
        locationDescription = getWorkplaceString(pickupLocation);
      }
      const workplaceURL = task.relatedWorkplace;
      const workplace = workplaceURL ? locationLookup(workplaceURL) : null;
      if (workplace) {
        if (locationDescription) {
          locationDescription += `, ${getWorkplaceString(workplace)}`;
        } else {
          locationDescription = getWorkplaceString(workplace);
        }
      }
      const userURL = task.machineOperator;
      const userProfile = userURL ? userUserProfileLookup(userURL) : null;
      const employeeName = userProfile ? userProfile.name : "";
      const results: {
        minutes: number | null;
        text: string;
        unit: string;
        value: number;
      }[] = [];
      let usesDistributionTable = false;
      const {department} = task;
      const allowMoreThanTwoMachines =
        customerSettings.alwaysAllowMoreThanTwoMachines ||
        customerSettings.allowMoreThanTwoMachinesForDepartments.includes(department);
      if (allowMoreThanTwoMachines) {
        usesDistributionTable = hasMultipleManualDistributionPriceItemUses(
          task,
          priceItemLookup,
          unitLookup,
        );
      }
      const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);
      const genericPrimaryTimerURL = genericPrimaryTimer ? genericPrimaryTimer.url : null;
      const breakTimer = getBreakTimer(timerArray);
      const breakTimerURL = breakTimer ? breakTimer.url : null;
      const computedIntervals = task.computedTimeSet;
      const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
      const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
      const intervals = mergeIntervals(
        computedIntervals,
        correctionIntervals,
        managerCorrectionIntervals,
      );
      const timerMinutes = computeIntervalSums(intervals);
      const secondaryTimerList = getTaskSecondaryTimerList(task, customerSettings, {
        machineLookup,
        priceGroupLookup,
        timerArray,
        workTypeLookup,
      });

      sortByOrderMember(Object.values(task.priceItemUses || {})).forEach((priceitemuse) => {
        const priceItemURL = priceitemuse.priceItem;
        const priceItem = priceItemURL ? priceItemLookup(priceItemURL) : null;
        if (!priceItem || priceItem.onlyVisibleOnOverview) {
          return;
        }
        const {name: text} = priceItem;
        const unit = getUnitString(priceItem, unitLookup);
        const {correctedCount, count} = priceitemuse;
        let minutes: number | null = null;
        let value: number | null = null;

        const isTime = priceItemIsTime(this.props.unitLookup, priceItem);
        const isDistributionTime = priceItemIsManualDistributionTime(
          this.props.unitLookup,
          priceItem,
        );
        if (usesDistributionTable && isDistributionTime) {
          value = count == null ? 0 : count;
          if (count == null) {
            // blank in distribution table should count as zero...
            value = 0;
          }
          minutes = value;
          const adjustedMinutes = adjustMinutes(
            customerSettings.distributionTableAdjustTimeMethod,
            minutes,
          );
          value = adjustedMinutes / HOUR_MINUTES;
        } else if (priceItem.relevantForExecution !== false && count == null && !isTime) {
          // Not filled in, so presumably hidden for some *other* reason than relevantForExecution; C5 minimum/startpris?  Keep it hidden.
          return;
        } else if (count == null && isTime) {
          const priceGroups = priceItem.priceGroups
            ? priceItem.priceGroups
            : priceItem.priceGroup
              ? [priceItem.priceGroup]
              : [];
          secondaryTimerList.forEach((timer) => {
            const timerPriceGroupURL = timer.priceGroup;
            if (timerPriceGroupURL && priceGroups.includes(timerPriceGroupURL)) {
              minutes = timerMinutes.get(timer.url) || 0;
            }
          });
          if (minutes == null) {
            minutes = (genericPrimaryTimerURL && timerMinutes.get(genericPrimaryTimerURL)) || 0;
            if (customerSettings.billedBreaks) {
              minutes += (breakTimerURL && timerMinutes.get(breakTimerURL)) || 0;
            }
          }
          value =
            adjustMinutes(this.props.customerSettings.adjustBilledMinutes, minutes) / HOUR_MINUTES;
        } else if (isTime) {
          minutes = count;
          value = (minutes || 0) / HOUR_MINUTES;
        } else {
          value = correctedCount ?? count;
        }

        if (correctedCount !== null) {
          value = correctedCount;
        }
        if (
          (!value && !(customerSettings.showRealTimeColumnOnOrderPDF && minutes)) ||
          value === null
        ) {
          return;
        }
        results.push({
          minutes,
          text,
          unit,
          value,
        });
      });
      sortByOrderMember(Object.values(task.productUses || {})).forEach((productuse) => {
        if (!productuse.ours) {
          return;
        }
        const value = productuse.correctedCount ?? productuse.count;
        if (!value) {
          return;
        }
        const productURL = productuse.product;
        const product = productURL ? productLookup(productURL) : null;
        if (!product) {
          return;
        }
        const {name: text} = product;
        const unit = getUnitString(product, unitLookup);
        if (!value) {
          return;
        }
        results.push({
          minutes: null,
          text,
          unit,
          value,
        });
      });
      tasks.push({
        date: task.date,
        employeeName,
        invoiceNote,
        locationDescription,
        notes: taskNotes,
        results,
        workDescription,
      });
    });
    const {resultRowData} = this.getResultsData();
    const totals = Array.from(Object.values(resultRowData).filter((entry) => entry.correctedTotal));

    const notes = customerSettings.showManagerInternalNotesOnOrderPDF
      ? `${order.notes || ""}\n\n${order.managerInternalNotes || ""}`.trim()
      : order.notes || "";

    const orderWorkplaceLabel = intl.formatMessage({defaultMessage: "Arbejdssted"});
    let orderWorkplaceString = "";
    if (customerSettings.showOrderWorkplaceOnOrderPdf) {
      const orderWorkplaceUrl = order.relatedWorkplace;
      const orderWorkplace = orderWorkplaceUrl ? locationLookup(orderWorkplaceUrl) : null;
      orderWorkplaceString = orderWorkplace ? getWorkplaceString(orderWorkplace) : "";
    }

    return {
      address,
      contactEmail,
      contactMobile,
      contactName,
      contactPhone,
      customerName,
      decimals: customerSettings.materialDecimals,
      deviceTimestamp: new Date().toISOString(),
      notes,
      orderWorkplaceLabel,
      orderWorkplaceString,
      reference: order.referenceNumber,
      referenceLabel:
        customerSettings.orderReferenceNumberLabel ||
        intl.formatMessage({
          defaultMessage: "Referencenummer",
        }),
      showInvoiceNoteOnOrderPdf: customerSettings.showInvoiceNoteOnOrderPdf,
      showRealTimeColumnOnOrderPDF: customerSettings.showRealTimeColumnOnOrderPDF,
      tasks,
      totals,
    };
  }

  @bind
  handleValidatedCheckedChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {order, update} = this.props;
    update(order.url, [{member: "validatedAndRecorded", value: checked}]);
  }

  render(): JSX.Element {
    const intl = this.context;
    const {
      contactArray,
      contactLookup,
      currentUserCanManageContacts,
      customerSettings,
      locationLookup,
      order,
      orderMergeCandidateList,
      taskList,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const {currentRole, currentUserURL} = this.props;
    const userURL = order.createdBy || currentUserURL;
    const userProfile = userURL && userUserProfileLookup(userURL);
    const userInitials = userProfile && userProfile.alias;
    const customerURL = order.customer;
    const userIsOnlyMachineOperator = currentRole && !currentRole.manager;
    const customerDialog = (
      <CustomerSelectCreateDialog
        key="customer-dialog"
        open={this.state.customerDialogOpen}
        onCancel={this.handleCustomerDialogCancel}
        onOk={this.handleCustomerDialogOk}
      />
    );
    const contactDialog = customerURL ? (
      <ContactSelectCreateDialog
        key="contact-dialog"
        customerUrl={customerURL}
        open={this.state.contactDialogOpen}
        onCancel={this.handleContactDialogCancel}
        onOk={this.handleContactDialogOk}
      />
    ) : null;
    let customer;
    const customerData = customerURL ? this.props.customerLookup(customerURL) : undefined;
    if (customerData) {
      console.assert(customerData);
      let address = null;
      if (customerData) {
        address = customerAddress(customerData);
      }
      let mapsURL = null;
      if (address) {
        const query = encodeURIComponent(address);
        mapsURL = `http://maps.google.com/?q=${query}`;
      }
      let mapButton = null;
      if (mapsURL) {
        mapButton = (
          <IconButton color="primary" href={mapsURL} target="_blank">
            <MapIcon />
          </IconButton>
        );
      }

      let cellphoneRow;
      if (customerData.cellphone) {
        cellphoneRow = (
          <tr>
            <td>
              <FormattedMessage defaultMessage="Mobil:" />
            </td>
            <td>{customerData.cellphone}</td>
          </tr>
        );
      }

      const labelColumnStyle = {width: "7em"};
      customer = (
        <table>
          <tbody>
            <tr>
              <td style={labelColumnStyle}>
                <FormattedMessage defaultMessage="Navn:" />
              </td>
              <td>{customerData.name}</td>
            </tr>
            <tr>
              <td style={labelColumnStyle}>
                <FormattedMessage defaultMessage="Kontonr:" />
              </td>
              <td>{customerData.c5_account}</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage defaultMessage="Adresse:" />
              </td>
              <td>
                {address} {mapButton}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage defaultMessage="Tlf.:" />
              </td>
              <td>{customerData.phone}</td>
            </tr>
            {cellphoneRow}
            <tr>
              <td>
                <FormattedMessage defaultMessage="E-mail:" />
              </td>
              <td>{customerData.billingEmail}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    let contact = null;
    const contactURL = order.contact;
    const customerHasContacts =
      customerURL && contactArray.some((c) => c.active && c.customer === customerURL);

    if (contactURL) {
      const contactData = contactLookup(contactURL);
      if (contactData) {
        const labelColumnStyle = {width: "7em"};
        const {name} = contactData;
        const {phone} = contactData;
        const {cellphone} = contactData;
        const {email} = contactData;
        const {fax} = contactData;
        contact = (
          <table>
            <tbody>
              <tr>
                <td style={labelColumnStyle}>
                  <FormattedMessage defaultMessage="Navn:" />
                </td>
                <td>{name}</td>
              </tr>
              {phone ? (
                <tr>
                  <td style={labelColumnStyle}>
                    <FormattedMessage defaultMessage="Telefon:" />
                  </td>
                  <td>{phone}</td>
                </tr>
              ) : null}
              {cellphone ? (
                <tr>
                  <td style={labelColumnStyle}>
                    <FormattedMessage defaultMessage="Mobil:" />
                  </td>
                  <td>{cellphone}</td>
                </tr>
              ) : null}
              {email ? (
                <tr>
                  <td style={labelColumnStyle}>
                    <FormattedMessage defaultMessage="E-mail:" />
                  </td>
                  <td>{email}</td>
                </tr>
              ) : null}
              {fax ? (
                <tr>
                  <td style={labelColumnStyle}>
                    <FormattedMessage defaultMessage="Fax:" />
                  </td>
                  <td>{fax}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        );
      }
    }
    const workTypeDialog = (
      <ConnectedExternalWorkTypeDialog
        key="work-type-dialog"
        open={!!this.state.workTypeDialogOpen}
        onCancel={this.handleWorkTypeDialogCancel}
        onOk={this.handleWorkTypeDialogOk}
      />
    );
    let customerWorkplaceList: Location[];
    if (customerURL) {
      customerWorkplaceList = _.sortBy(
        this.props.locationArray.filter((w) => w.active && w.customer === customerURL),
        (w) => w.name,
      );
    } else {
      customerWorkplaceList = [];
    }
    const showAddWorkPlace = allowWorkplaceCreate(customerSettings, this.props.currentRole);
    const workplaceDialog = (
      <ConnectedLocationDialog
        key="workplace-dialog"
        hideFieldLocations
        includeWorkplaceOnlyLocations
        customerURL={customerURL}
        includeLogOnlyLocations={false}
        open={
          this.state.workplaceDialogOpenForOrder || this.state.workplaceDialogOpenForTask !== null
        }
        titleVariant="WORKPLACE"
        onAdd={showAddWorkPlace ? this.handleWorkplaceDialogAdd : undefined}
        onCancel={this.handleWorkplaceDialogCancel}
        onOk={this.handleWorkplaceDialogOk}
      />
    );

    const confirmPropagateWorkplaceDialog = (
      <ResponsiveDialog
        key="confirm-propagate-workplace-dialog"
        autoFocusCancel
        cancelLabel={intl.formatMessage({defaultMessage: "Nej"})}
        fullscreen={bowser.mobile || bowser.tablet}
        okLabel={intl.formatMessage({defaultMessage: "Ja"})}
        open={this.state.propagateWorkplaceDialogOpen}
        title={intl.formatMessage({defaultMessage: "Nyt arbejdssted"})}
        onCancel={this.handleWorkplaceDialogCancel}
        onOk={this.handlePropagateWorkplaceDialogOk}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Skal ændringen af arbejdssted ske på alle ordrens ikke-godkendte opgaver?" />
        </DialogContent>
      </ResponsiveDialog>
    );
    const workplaceAddDialog = (
      <LocationCreateEditDialog
        key="workplace-add-dialog"
        customerSettings={customerSettings}
        initialSearch={this.state.locationCreateEditDialogInitialAddress}
        locationFavoritesEnabled={customerSettings.locationFavoritesEnabled}
        logOnlyLocation={false}
        open={this.state.workplaceAddDialogOpen}
        workplaceOnlyLocation={customerSettings.setWorkplaceOnlyLocationOnCreate}
        onCancel={this.handleWorkplaceAddDialogCancel}
        onOk={this.handleWorkplaceAddDialogOk}
      />
    );

    const inlinedTaskList: TaskAndData[] = taskList.map((task) => {
      const machineOperatorURL = task.machineOperator;
      const machineOperatorProfile =
        (machineOperatorURL && this.props.userUserProfileLookup(machineOperatorURL)) || undefined;
      const machineList = (task.machineuseSet || [])
        .map((machineUse) => {
          const machineURL = machineUse.machine;
          return this.props.machineLookup(machineURL);
        })
        .filter(notUndefined);
      const priceGroupURL = task.priceGroup;
      const priceGroup = (priceGroupURL && this.props.priceGroupLookup(priceGroupURL)) || undefined;
      const machinePriceGroups = (task.machineuseSet || [])
        .map((machineUse) => {
          return (
            (machineUse.priceGroup && this.props.priceGroupLookup(machineUse.priceGroup)) ||
            undefined
          );
        })
        .filter(notUndefined);
      const taskWorkTypeURL = task.workType;
      const taskWorkType =
        (taskWorkTypeURL && this.props.workTypeLookup(taskWorkTypeURL)) || undefined;
      const project = (task.project && this.props.projectLookup(task.project)) || undefined;
      const result: TaskAndData = {
        machineList,
        machineOperatorProfile,
        machinePriceGroups,
        priceGroup,
        project,
        task,
        workType: taskWorkType,
      };
      return result;
    });
    const role = this.props.currentRole;
    const anyRecorded = taskList.some((t) => t.validatedAndRecorded);
    const anyRecordedInC5 = taskList.some((t) => t.recordedInC5);
    const orderValidated = order.validatedAndRecorded;
    let orderIsPossibleToDelete = false;
    if (taskList.length) {
      orderIsPossibleToDelete = taskList.every((task) => {
        if (task.completed) {
          return false;
        } else {
          const taskURL = task.url;
          const computedIntervals = computeIntervalsTruncated(
            this.props.timerStartArray.filter((instance) => instance.task === taskURL),
          );
          const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
          const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
          const intervals = mergeIntervals(
            computedIntervals,
            correctionIntervals,
            managerCorrectionIntervals,
          );
          return !intervals.length;
        }
      });
    } else {
      orderIsPossibleToDelete = true;
    }
    const userIsManager = role && role.manager;
    const userIsMachineOperator = role && role.machineOperator;
    const orderIsCreatedByUser = order.createdBy === currentUserURL;
    const userHasDeletePermission =
      userIsManager || (userIsMachineOperator && orderIsCreatedByUser);
    const canDeleteOrder = orderIsPossibleToDelete && userHasDeletePermission;
    const machineOperatorDialog = (
      <ConnectedMachineOperatorDialog
        key="machine-operator-dialog"
        open={!!this.state.machineOperatorDialogOpenFor}
        onCancel={this.handleMachineOperatorDialogCancel}
        onOk={this.handleMachineOperatorDialogOk}
      />
    );

    const taskEstimateDialog = (
      <ResponsiveDialog
        key="task-estimate-dialog"
        open={!!this.state.estimatedTimeDialogOpenFor}
        title={intl.formatMessage({
          defaultMessage: "Estimeret varighed",
        })}
        onCancel={this.handleEstimatedTimeDialogCancel}
        onOk={this.handleEstimatedTimeDialogOk}
      >
        <DialogContent>
          <MinutesField
            autoFocus
            fullWidth
            minQuarter
            label={intl.formatMessage({
              defaultMessage: "Estimeret varighed",
            })}
            margin="dense"
            value={this.state.estimatedTimeDialogOpenMinutes}
            onChange={this.handleEstimatedTimeDialogOpenMinutesChange}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
    let machineDialog: JSX.Element | undefined;
    if (this.state.machineDialogTask) {
      const task = this.props.taskLookup(this.state.machineDialogTask);
      if (task) {
        machineDialog = (
          <MachinePriceGroupWizard
            key="machine-dialog"
            open={this.state.machineDialogOpen}
            task={task}
            onCancel={this.handleMachineDialogCancel}
            onOk={this.handleMachineDialogOk}
          />
        );
      }
    }

    const deleteDialog = (
      <DeleteDialog
        key="delete-dialog"
        open={this.state.deleteDialogOpen}
        onCancel={this.handleDeleteDialogCancel}
        onOk={this.handleDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet ordre?" />
      </DeleteDialog>
    );

    const priceGroupDialogTaskWorkType = this.state.priceGroupDialogOpenFor
      ? this.props.taskLookup(this.state.priceGroupDialogOpenFor)?.workType
      : undefined;

    const priceGroupDialog = (
      <ConnectedPriceGroupDialog
        key="price-group-dialog"
        customerUrl={customerURL || undefined}
        open={!!this.state.priceGroupDialogOpenFor}
        workTypeURL={priceGroupDialogTaskWorkType || undefined}
        onCancel={this.handlePriceGroupDialogCancel}
        onOk={this.handlePriceGroupDialogOk}
      />
    );
    const orderMergeDialog = (
      <OrderMergeDialog
        key="order-merge-dialog"
        customer={customerData}
        open={this.state.orderMergeDialogOpen}
        order={order}
        orderMergeCandidateList={orderMergeCandidateList}
        onCancel={this.handleOrderMergeDialogCancel}
        onOk={this.handleOrderMergeDialogOk}
      />
    );

    const projectDialog = (
      <ConnectedProjectDialog
        key="project-dialog"
        suggestRecentlyUsed
        customerURL={this.props.order.customer}
        open={!!this.state.projectDialogOpenFor}
        onCancel={this.handleProjectDialogCancel}
        onOk={this.handleProjectDialogOk}
      />
    );

    const missingMachineOperatorDialog = (
      <MissingMachineOperatorDialog
        key="missing-machine-operator-dialog"
        open={this.state.taskMissingMachineOperatorErrorDialogOpen}
        onOk={this.handleMissingMachineOperatorDialogOk}
      />
    );

    const workTypeChangeBlockedDialog = (
      <WorkTypeChangeBlockedDialog
        key="work-type-change-blocked-dialog"
        blockedReason={this.state.workTypeChangeBlockedReason}
        open={this.state.workTypeChangeBlockedDialogOpen}
        onClose={this.handleWorkTypeChangeBlockedDialogClose}
      />
    );

    const dialogs = [
      priceGroupDialog,
      deleteDialog,
      machineDialog,
      taskEstimateDialog,
      customerDialog,
      contactDialog,
      workTypeDialog,
      workplaceDialog,
      confirmPropagateWorkplaceDialog,
      machineOperatorDialog,
      orderMergeDialog,
      workplaceAddDialog,
      projectDialog,
      missingMachineOperatorDialog,
      workTypeChangeBlockedDialog,
    ];
    let usesOrderValidation = customerSettings.orderValidation;
    if (!usesOrderValidation && customerSettings.orderValidationForWorkTypes.length) {
      const {orderValidationForWorkTypes} = customerSettings;

      usesOrderValidation = taskList.some((task) => {
        const taskWorkTypeURL = task.workType;
        const xtaskWorkType = taskWorkTypeURL && workTypeLookup(taskWorkTypeURL);
        return xtaskWorkType && orderValidationForWorkTypes.includes(xtaskWorkType.identifier);
      });
    }
    if (customerSettings.enableExternalTaskDepartmentField) {
      const departmentDialog = (
        <ConnectedDepartmentDialog
          key="department-dialog"
          open={this.state.departmentDialogOpen}
          onCancel={this.handleDepartmentDialogCancel}
          onOk={this.handleDepartmentDialogOk}
        />
      );
      dialogs.push(departmentDialog);
    }

    let departmentSelection;
    if (customerSettings.enableExternalTaskDepartmentField) {
      const departmentID = order.department;
      const department = <div>{getDepartmentName(departmentID, this.props.customerSettings)}</div>;
      departmentSelection = (
        <div>
          <FormattedMessage defaultMessage="Afdeling" tagName="h4" />
          <Button
            ref={this._selectDepartmentButton}
            color="secondary"
            disabled={
              anyRecorded ||
              !!(customerSettings.onlyAdminCanChangeDepartment && userIsOnlyMachineOperator)
            }
            variant="contained"
            onClick={this.handleDepartmentSelectButton}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          {department}
        </div>
      );
    }
    let orderMergeButton: JSX.Element | undefined;
    if (customerSettings.enableOrderMerge) {
      orderMergeButton = (
        <Button
          ref={this._orderMergeButton}
          color="secondary"
          disabled={orderValidated || !orderMergeCandidateList.length}
          style={{marginTop: 12}}
          variant="contained"
          onClick={this.handleOrderMergeButton}
        >
          <FormattedMessage defaultMessage="Sammenflet ordre" />
        </Button>
      );
    }
    let referenceNumberBlock: JSX.Element | undefined;
    let referenceNumberEditable: boolean;
    if (customerSettings.alwaysAllowEditingOrderReferenceNumber) {
      referenceNumberEditable = usesOrderValidation ? !order.validatedAndRecorded : true;
    } else {
      referenceNumberEditable = !anyRecordedInC5 && customerSettings.allowOrderReferenceNumberEdit;
    }
    if (customerSettings.enableOrderReferenceNumber) {
      referenceNumberBlock = (
        <TrimTextField
          disabled={!referenceNumberEditable}
          inputProps={{maxLength: 255}}
          label={
            customerSettings.orderReferenceNumberLabel ||
            intl.formatMessage({
              defaultMessage: "Referencenummer",
            })
          }
          margin="dense"
          value={order.referenceNumber || ""}
          onChange={this.handleReferenceNumberChange}
        />
      );
    }
    let brugerdataReference: JSX.Element | undefined;
    if (customerSettings.showBrugerdataOrdernumber) {
      brugerdataReference = (
        <>
          <FormattedMessage
            defaultMessage="Brugerdata-ordre: {number}"
            values={{number: order.remoteUrl}}
          />
          {userIsOnlyMachineOperator ? null : <RemoveOrderBDReference order={order} />}
          {customerSettings.brugerdataCreateOrderButton ? (
            <AddOrderBDReference order={order} />
          ) : null}
        </>
      );
    }
    const customerFileListCard =
      customerSettings.orderShowCustomerFiles && customerURL ? (
        <CustomerFileListCard
          customerURL={customerURL}
          title={intl.formatMessage({
            defaultMessage: "Filer, kunder",
          })}
        />
      ) : null;
    const customerBlock = (
      <div>
        <FormattedMessage defaultMessage="Kunde" tagName="h4" />
        {customerSettings.enableCustomerSwitch ? (
          <>
            <Button
              ref={this._selectCustomerButton}
              color="secondary"
              disabled={anyRecorded}
              variant="contained"
              onClick={this.handleCustomerSelectButton}
            >
              <FormattedMessage defaultMessage="Vælg" />
            </Button>
            <IconButton
              disabled={!customerURL || anyRecorded}
              onClick={this.handleCustomerClearButton}
            >
              <CloseIcon />
            </IconButton>
          </>
        ) : null}
        {customer}
      </div>
    );
    let contactBlock;
    if (customerSettings.enableCustomerContacts && customerSettings.orderEntryShowContact) {
      contactBlock = (
        <div>
          <FormattedMessage defaultMessage="Kontaktperson" tagName="h4" />
          <Button
            ref={this._selectContactButton}
            color="secondary"
            disabled={!customerURL || (!currentUserCanManageContacts && !customerHasContacts)}
            variant="contained"
            onClick={this.handleContactSelectButton}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton
            disabled={!customerURL || !contactURL}
            onClick={this.handleContactClearButton}
          >
            <CloseIcon />
          </IconButton>
          {contact}
        </div>
      );
    }
    let workplaceBlock;
    let workplaceEditable: boolean;
    if (customerSettings.alwaysAllowEditingOrderWorkplace) {
      workplaceEditable = usesOrderValidation ? !order.validatedAndRecorded : true;
    } else {
      workplaceEditable = !anyRecordedInC5;
    }
    if (customerSettings.orderEntryShowWorkPlace) {
      const location = order.relatedWorkplace
        ? this.props.locationLookup(order.relatedWorkplace)
        : null;
      const locationString = location?.name || location?.address || "";
      const workplaceButtonDisabled =
        !customerURL ||
        (customerWorkplaceList.every((l) => l.logOnlyLocation) && !showAddWorkPlace) ||
        !workplaceEditable;
      const workplaceCustomerMismatchWarning =
        customerSettings.economicInvoiceShowOrderWorkplaceNameAsOtherRef &&
        userIsManager &&
        customerURL &&
        location &&
        customerURL !== location.customer ? (
          <div style={{color: this.props.theme.palette.warning.main}}>
            Arbejdsstedet tilhører ikke den valgte kunde
          </div>
        ) : null;
      workplaceBlock = (
        <div>
          <Button
            ref={this._selectWorkplaceButton}
            color="secondary"
            disabled={workplaceButtonDisabled}
            variant="contained"
            onClick={this.handleWorkplaceSelectButton}
          >
            <FormattedMessage defaultMessage="Vælg arbejdssted" />
          </Button>
          <IconButton
            disabled={!order.relatedWorkplace || !workplaceEditable}
            onClick={this.handleWorkplaceClearButton}
          >
            <CloseIcon />
          </IconButton>
          <div>{locationString}</div>
          {workplaceCustomerMismatchWarning}
        </div>
      );
    }
    let resultsCard;
    if (customerSettings.orderEntryShowResults) {
      const {effectiveMinutes, resultRowData, workTypeEffectiveMinutesMap} = this.getResultsData();
      const resultRowList = Object.keys(resultRowData)
        .sort()
        .map((key) => {
          const data = resultRowData[key];
          return (
            <ResultRow
              key={key}
              correctedTotal={data.correctedTotal}
              customerSettings={this.props.customerSettings}
              name={data.name}
              total={data.total}
              unit={data.unit}
              workplace={data.workplace}
            />
          );
        });
      const resultsWorkplaceHeader = customerSettings.orderEntryResultsWorkplace ? (
        <TableCell>
          <FormattedMessage defaultMessage="Arbejdssted" />
        </TableCell>
      ) : null;
      let effectiveTimeResultRows;
      if (customerSettings.orderEntryResultsEffective) {
        const effectiveWorkplaceCell = customerSettings.orderEntryResultsWorkplace ? (
          <TableCell />
        ) : null;
        effectiveTimeResultRows = [
          <TableRow key="-total-effective-">
            <TableCell>
              <strong>
                <FormattedMessage defaultMessage="Samlet effektiv tid" />
              </strong>
            </TableCell>
            {effectiveWorkplaceCell}
            <TableCell>
              <strong>
                ({formatDuration(customerSettings.durationFormat, effectiveMinutes)}){" "}
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={effectiveMinutes / HOUR_MINUTES}
                />
              </strong>
            </TableCell>
            <TableCell />
          </TableRow>,
        ];
        _.sortBy(
          Array.from(workTypeEffectiveMinutesMap.entries()).map(([resultWorkTypeURL, minutes]) => {
            const resultWorkType = this.props.workTypeLookup(resultWorkTypeURL);
            const label = resultWorkType
              ? `${resultWorkType.identifier}: ${resultWorkType.name}`
              : "";
            return [resultWorkTypeURL, label, minutes] as const;
          }),
          ([_workTypeURL, label, _minutes]) => label,
        ).forEach(([resultWorkTypeURL, label, minutes]) => {
          effectiveTimeResultRows.push(
            <TableRow key={resultWorkTypeURL}>
              <TableCell>{label}</TableCell>
              {effectiveWorkplaceCell}
              <TableCell>
                ({formatDuration(customerSettings.durationFormat, minutes)}){" "}
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={minutes / HOUR_MINUTES}
                />
              </TableCell>
              <TableCell />
            </TableRow>,
          );
        });
      }
      resultsCard = (
        <Card style={{marginTop: 8}}>
          <CardHeader
            title={intl.formatMessage({
              defaultMessage: "Resultater",
            })}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Beskrivelse" />
                </TableCell>
                {resultsWorkplaceHeader}
                <TableCell>
                  <FormattedMessage defaultMessage="Total" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Korrigeret total" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Enhed" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultRowList}
              {effectiveTimeResultRows}
            </TableBody>
          </Table>
        </Card>
      );
    }
    let orderTaskStartDurationBlock;
    if (customerSettings.orderEntryTaskTime) {
      const orderTime = order.time;
      const time =
        orderTime || this.state.timeFieldFocused ? orderTime : computeTime(this.props.taskList);
      orderTaskStartDurationBlock = (
        <div>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TimeField
                fullWidth
                label={intl.formatMessage({
                  defaultMessage: "Klokkeslæt",
                })}
                margin="dense"
                value={time || undefined}
                onBlur={this.handleTimeBlur}
                onChange={this.handleTimeChange}
                onFocus={this.handleTimeFocus}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <MinutesField
                fullWidth
                label={intl.formatMessage({
                  defaultMessage: "Forventet varighed",
                })}
                margin="dense"
                value={this.state.minutesExpectedTotalTaskDuration}
                onChange={this.handleMinutesExpectedTotalTaskDuration}
              />
            </Grid>
          </Grid>
          {this.props.customerSettings.showTimeColumnOnOrderEntryTaskList ? (
            <Button
              disabled={!time}
              startIcon={<ClockIcon />}
              variant="contained"
              onClick={this.handleTransferTime}
            >
              <FormattedMessage defaultMessage="Overfør klokkeslæt" />
            </Button>
          ) : null}
        </div>
      );
    }

    const fieldList = this.props.locationArray.filter(
      (field) => field.active && !!field.geojson && field.customer === customerURL,
    );

    const orderFieldUseList = order.orderfielduseSet || [];
    if (!anyRecorded) {
      const fieldDialog = (
        <FieldMultiSelectionDialog
          key="field-dialog"
          customerURL={customerURL || undefined}
          open={this.state.fieldDialogOpen}
          selected={orderFieldUseList}
          onCancel={this.handleFieldDialogCancel}
          onOk={this.handleFieldDialogOk}
        />
      );
      dialogs.push(fieldDialog);
    }
    let fieldUseList = orderFieldUseList;
    const fieldTaskSet: {[fieldURL: string]: number[]} = {};
    const tasksWithAbsence: TaskUrl[] = [];
    const tasksWhereMachineOperatorHasMultipleTasks = new Set<TaskUrl>();
    taskList.forEach((task, index) => {
      const n = index + 1;
      const taskFieldUseList = task.fielduseSet;
      if (taskFieldUseList && taskFieldUseList.length) {
        taskFieldUseList.forEach((fieldUse) => {
          const fieldURL = fieldUse.relatedField;
          if (!fieldUseList.some((f) => f.relatedField === fieldURL)) {
            fieldUseList = [...fieldUseList, {...fieldUse, notes: ""}];
          }
          if (fieldTaskSet[fieldURL]) {
            fieldTaskSet[fieldURL].push(n);
          } else {
            fieldTaskSet[fieldURL] = [n];
          }
        });
      }

      const machineOperatorAbsent =
        task.machineOperator &&
        checkUserAbsence(
          task.machineOperator,
          task,
          this.props.orderLookup,
          this.props.daysAbsenceArray,
          this.props.hoursAbsenceArray,
          this.props.customerSettings.absenceWarningDisabledFor,
        );
      if (machineOperatorAbsent) {
        tasksWithAbsence.push(task.url);
      }

      const machineOperatorOtherTaskCount = getOtherTaskCount(task, this.props.taskArray);

      if (machineOperatorOtherTaskCount > 0) {
        tasksWhereMachineOperatorHasMultipleTasks.add(task.url);
      }
    });
    const extraFieldLabels = new Map<string, string>();
    Object.keys(fieldTaskSet).forEach((fieldURL) => {
      const taskIndexArray = fieldTaskSet[fieldURL];
      extraFieldLabels.set(fieldURL, `Opg.: ${taskIndexArray.join(", ")}`);
    });

    const tasksReadyForApproval = taskList.filter(
      (t) =>
        (customerSettings.useApproveReport ? !t.reportApproved : !t.validatedAndRecorded) &&
        (t.completed || t.completedAsInternal || t.cancelled),
    );
    const approveAllBlock = (
      <Grid key="approve-all-block" item xs>
        <Button
          color="primary"
          disabled={!tasksReadyForApproval.length}
          style={{width: "100%"}}
          variant="contained"
          onClick={this.handleApproveAll}
        >
          <FormattedMessage defaultMessage="Godkend opgaver" />
        </Button>
      </Grid>
    );

    dialogs.push(
      <ApproveTasksDialog
        key="order-approve-all-dialog"
        open={this.state.approveAllDialogOpen}
        tasksReadyForApproval={tasksReadyForApproval}
        onCancel={this.handleApproveAllDialogCancel}
        onOk={this.handleApproveAllDialogOk}
      />,
    );

    let orderValidationBlock;
    if (usesOrderValidation) {
      const orderReadyForValidation =
        !order.validatedAndRecorded &&
        !!taskList.length &&
        (customerSettings.useApproveReport
          ? taskList.every((t) => t.reportApproved)
          : taskList.every((t) => t.validatedAndRecorded));
      orderValidationBlock = (
        <Grid item sm xs={12}>
          <Button
            color="primary"
            disabled={!orderReadyForValidation}
            style={{width: "100%"}}
            variant="contained"
            onClick={this.handleValidateOrder}
          >
            <FormattedMessage defaultMessage="Godkend ordre" />
          </Button>
        </Grid>
      );

      dialogs.push(
        <ApproveOrderDialog
          open={this.state.validateOrderDialogOpen}
          order={this.props.order}
          onCancel={this.handleValidateOrderDialogCancel}
          onOk={this.handleValidateOrderDialogOk}
        />,
      );
    }
    let convertCell;
    if (order.draft) {
      convertCell = (
        <Grid item sm xs={12}>
          <Button
            color="secondary"
            style={{width: "100%"}}
            variant="contained"
            onClick={this.handleConvertToOrder}
          >
            <FormattedMessage defaultMessage="Konverter til ordre" />
          </Button>
        </Grid>
      );
    }

    let machineOperatorAbsentBlock;
    if (tasksWithAbsence.length > 0) {
      machineOperatorAbsentBlock = (
        <div style={{color: "red"}}>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Maskinfører markeret med rødt har registreret fravær på den valgte dato"
              tagName="h3"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage
              defaultMessage="Medarbejder markeret med rødt har registreret fravær på den valgte dato"
              tagName="h3"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Chauffør markeret med rødt har registreret fravær på den valgte dato"
              tagName="h3"
            />
          )}
        </div>
      );
    }

    let machineOperatorHasMultipleTasksBlock;
    if (tasksWhereMachineOperatorHasMultipleTasks.size > 0) {
      machineOperatorHasMultipleTasksBlock = (
        <div style={{color: "orange"}}>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Maskinfører markeret med orange har flere opgaver på den valgte dato"
              tagName="h3"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage
              defaultMessage="Medarbejder markeret med orange har flere opgaver på den valgte dato"
              tagName="h3"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Chauffør markeret med orange har flere opgaver på den valgte dato"
              tagName="h3"
            />
          )}
        </div>
      );
    }

    let subtitle;
    if (this.props.customerSettings.showOrderCreatedDateTime) {
      subtitle = intl.formatMessage(
        {
          defaultMessage: "Ordre oprettet af {userInitials} d. {datetime}",
        },
        {
          datetime: formatDateTimeShort(order.created),
          userInitials,
        },
      );
    } else {
      subtitle = intl.formatMessage(
        {
          defaultMessage: "Ordre oprettet af {userInitials}",
        },
        {userInitials},
      );
    }

    const pdfURL = `${globalConfig.baseURL}/download/order/pdf`;
    let pdfFilename;
    /* eslint-disable @typescript-eslint/naming-convention */
    if (customerData?.c5_account || order.date) {
      pdfFilename = `ordre-${[customerData?.c5_account, order.date]
        .filter((x) => x)
        .join("-")}.pdf`;
    } else {
      pdfFilename = "ordre.pdf";
    }
    /* eslint-enable @typescript-eslint/naming-convention */
    return (
      <>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Card>
              <CardHeader
                subheader={subtitle}
                title={intl.formatMessage(
                  {
                    defaultMessage:
                      "{isDraft, select, true {Overordnet — kladde} other {Overordnet}}",
                  },
                  {
                    isDraft: order.draft,
                  },
                )}
              />
              <CardContent>
                {referenceNumberBlock}
                {brugerdataReference}
                {customerBlock}
                {contactBlock}
                {workplaceBlock}
                {departmentSelection}
                {orderMergeButton}
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card style={{marginBottom: 22}}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <DateField
                      autoOk
                      fullWidth
                      disabled={anyRecorded}
                      label={intl.formatMessage({
                        defaultMessage: "Ønskes udført tidligst",
                      })}
                      margin="dense"
                      value={order.earliestDate}
                      onChange={this.handleEarliestDateChange}
                    />
                  </Grid>
                  <Grid item xs>
                    <DateField
                      autoOk
                      fullWidth
                      calendarStartDate={order.earliestDate || undefined}
                      disabled={anyRecorded}
                      label={intl.formatMessage({
                        defaultMessage: "Ønskes udført senest",
                      })}
                      margin="dense"
                      value={order.latestDate}
                      onChange={this.handleLatestDateChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <DateField
                      autoOk
                      fullWidth
                      disabled={anyRecorded}
                      label={intl.formatMessage({
                        defaultMessage: "Planlagt dato",
                      })}
                      margin="dense"
                      value={order.date}
                      onChange={this.handleDateChange}
                    />
                  </Grid>
                  <Grid item xs>
                    <IntegerField
                      fullWidth
                      disabled={orderValidated}
                      label={intl.formatMessage({
                        defaultMessage: "Planlagt varighed, dage",
                      })}
                      margin="dense"
                      value={order.durationDays}
                      onChange={this.handleDurationDaysChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  disabled={!order.date}
                  startIcon={<CalendarMultipleIcon />}
                  variant="contained"
                  onClick={this.handleCopyDate}
                >
                  <FormattedMessage defaultMessage="Overfør dato" />
                </Button>
                {orderTaskStartDurationBlock}
                <ThrottledTextField
                  fullWidth
                  multiline
                  disabled={
                    anyRecorded && !customerSettings.alwaysAllowManagerTaskAndOrderNotesEdit
                  }
                  label={intl.formatMessage({
                    defaultMessage: "Interne noter",
                  })}
                  maxRows={30}
                  minRows={2}
                  style={{marginTop: 16}}
                  value={order.managerInternalNotes || ""}
                  variant="outlined"
                  onChange={this.handleManagerInternalNotesChange}
                />

                <ThrottledTextField
                  fullWidth
                  multiline
                  disabled={
                    anyRecorded && !customerSettings.alwaysAllowManagerTaskAndOrderNotesEdit
                  }
                  label={intl.formatMessage({
                    defaultMessage: "Noter til medarbejdere",
                  })}
                  margin="dense"
                  maxRows={30}
                  minRows={2}
                  style={{marginTop: 16}}
                  value={order.notes || ""}
                  variant="outlined"
                  onChange={this.handleOrderNotesChange}
                />
                {customerSettings.orderValidation ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={order.validatedAndRecorded}
                        name="noBreak"
                        onChange={this.handleValidatedCheckedChanged}
                      />
                    }
                    disabled={!order.validatedAndRecorded}
                    label={<FormattedMessage defaultMessage="Godkendt" />}
                  />
                ) : null}
              </CardContent>
            </Card>
            {customerSettings.orderShowOrderFiles ? (
              <div style={{marginBottom: 20}}>
                <OrderFilesCard editable orderURL={order.url} />
              </div>
            ) : null}
            {customerFileListCard}
          </Grid>
        </Grid>
        <div>
          <AjaxDownloadButton
            data={this.getPDFData}
            disabled={!taskList.length}
            downloadURL={pdfURL}
            filename={pdfFilename}
            Icon={FilePdfIcon}
            label={intl.formatMessage({
              defaultMessage: "Download PDF",
            })}
            token={this.props.token}
          />
        </div>
        <Card>
          <CardHeader
            title={intl.formatMessage({
              defaultMessage: "Opgaver",
            })}
          />
          <CardContent>
            {machineOperatorAbsentBlock} {machineOperatorHasMultipleTasksBlock}
          </CardContent>
          <TaskTable
            order={order}
            taskAndDataArray={inlinedTaskList}
            tasksWhereMachineOperatorHasMultipleTasks={tasksWhereMachineOperatorHasMultipleTasks}
            tasksWithAbsence={new Set(tasksWithAbsence)}
            usesOrderValidation={usesOrderValidation}
            onRequestEstimatedTimeEdit={this.handleRequestEstimatedTimeEdit}
            onRequestMachineAdd={this.handleRequestMachineAdd}
            onRequestMachineOperatorEdit={this.handleRequestMachineOperatorEdit}
            onRequestProjectEdit={this.handleRequestProjectEdit}
            onRequestWorkplaceEdit={this.handleRequestWorkplaceEdit}
            onTaskCopy={this.handleTaskCopy}
            onWorkTypeChangeBlocked={this.handleWorkTypeChangeBlocked}
          />
          {customerSettings.adminCanCreateCustomerTask ? (
            <CardActions>
              <Button
                ref={this._addTaskButton}
                color="secondary"
                disabled={orderValidated}
                variant="contained"
                onClick={this.handleAddTaskButton}
              >
                <FormattedMessage defaultMessage="Tilføj opgave" />
              </Button>
            </CardActions>
          ) : null}
        </Card>
        <div style={{paddingTop: 8}}>
          <FieldsCard
            customerActiveFieldList={fieldList}
            customerURL={customerURL}
            extraLabels={extraFieldLabels}
            fieldUseList={fieldUseList}
            locationLookup={locationLookup}
            withMap={!!fieldUseList.length}
            onNotesChange={this.handleNotesChange}
            onRearrange={this.handleRearrange}
            onSelectFieldsClick={this.handleSelectFieldsClick}
          />
        </div>
        {resultsCard}
        <Grid container spacing={3} style={{paddingTop: 8}}>
          <Grid item sm xs={12}>
            <ErrorColorButton
              disabled={!canDeleteOrder}
              style={{width: "100%"}}
              variant="contained"
              onClick={this.handleDeleteButton}
            >
              <FormattedMessage
                defaultMessage="{isDraft, select, true {Slet ordrekladde} other {Slet ordre}}"
                values={{
                  isDraft: order.draft,
                }}
              />
            </ErrorColorButton>
          </Grid>
          {convertCell}
          {customerSettings.adminCanCreateCustomerTask ? (
            <Grid item sm xs={12}>
              <Button
                color="primary"
                style={{width: "100%"}}
                variant="contained"
                onClick={this.handleCreateAnother}
              >
                <FormattedMessage
                  defaultMessage="{createDraft, select, true {Opret ny ordrekladde} other {Opret endnu en ordre}}"
                  values={{
                    createDraft:
                      customerSettings.orderDrafts &&
                      (customerSettings.orderDraftsAllwaysCreateDraft || order.draft),
                  }}
                />
              </Button>
            </Grid>
          ) : null}
          {approveAllBlock}
          {orderValidationBlock}
          {customerSettings.adminCanCreateCustomerTask ? (
            <Grid item sm xs={12}>
              <Button
                color="primary"
                style={{width: "100%"}}
                variant="contained"
                onClick={this.handleCreateCopy}
              >
                <FormattedMessage defaultMessage="Opret kopi" />
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {dialogs}
      </>
    );
  }
}

const OrderEntryWithTheme = withTheme(OrderEntry);

interface OrderEntryContainerStateProps {
  contactArray: readonly Contact[];
  contactLookup: (url: ContactUrl) => Contact | undefined;
  currentRole: Role | null;
  currentUser: User | null;
  currentUserCanManageContacts: boolean;
  currentUserProfile: UserProfile | null;
  currentUserURL: UserUrl | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  deliveryLocationArray: readonly DeliveryLocation[];
  employeeGroupLookup: (url: EmployeeGroupUrl) => EmployeeGroup | undefined;
  hoursAbsenceArray: readonly HoursAbsence[];
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineGroupLookup: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderArray: readonly Order[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupLocationArray: readonly PickupLocation[];
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  shareToken: string | null;
  sprayLocationArray: readonly SprayLocation[];
  sprayLogArray: readonly SprayLog[];
  taskArray: readonly Task[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  token: string | null;
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface OrderEntryContainerDispatchProps {
  backSkip: (skip: PathTemplate[], fallback?: PathTemplate) => void;
  create: (instance: ResourceTypeUnion) => void;
  createOrUpdate: (instance: ResourceTypeUnion) => void;
  dispatch: Dispatch;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface OrderEntryContainerOwnProps {
  instance: Order;
}

type OrderEntryContainerProps = OrderEntryContainerDispatchProps &
  OrderEntryContainerOwnProps &
  OrderEntryContainerStateProps;

class OrderEntryContainer extends PureComponent<OrderEntryContainerProps> {
  render(): JSX.Element {
    const {customerSettings, orderArray, taskArray, workTypeLookup} = this.props;
    const order = this.props.instance;
    const orderURL = order.url;
    const filteredSortedTaskArray = _.sortBy(
      taskArray.filter((task) => task.order === orderURL),
      [(task) => task.priority, (task) => task.created],
    );

    const taskList =
      customerSettings.orderTaskTableSort === "DATE, WORK TYPE"
        ? filteredSortedTaskArray.sort((a, b) => {
            const dateCompare = simpleComparator(a.date, b.date);
            if (dateCompare) {
              return dateCompare;
            }
            const aWorkTypeIdentitfier = a.workType ? workTypeLookup(a.workType)?.identifier : "";
            const bWorkTypeIdentitfier = b.workType ? workTypeLookup(b.workType)?.identifier : "";
            return identifierComparator(aWorkTypeIdentitfier ?? "", bWorkTypeIdentitfier ?? "");
          })
        : filteredSortedTaskArray;
    let orderMergeCandidateList: Order[];
    let inlinedOrderMergeCandidateList: readonly (Order & {
      taskSet: readonly Task[];
    })[] = [];
    if (!customerSettings.enableOrderMerge || !order.customer) {
      orderMergeCandidateList = [];
    } else {
      const customerURL = order.customer;
      orderMergeCandidateList = orderArray.filter(
        (o) => o.customer === customerURL && o.url !== order.url,
      );

      if (customerSettings.enableOrderReferenceNumber) {
        const {referenceNumber} = order;
        orderMergeCandidateList = orderMergeCandidateList.filter(
          (o) => o.referenceNumber === referenceNumber,
        );
      }
      if (customerSettings.orderEntryShowWorkPlace) {
        const {address, relatedWorkplace} = order;
        orderMergeCandidateList = orderMergeCandidateList.filter(
          (o) => o.address === address && o.relatedWorkplace === relatedWorkplace,
        );
      }
      if (customerSettings.enableExternalTaskDepartmentField) {
        const {department} = order;
        orderMergeCandidateList = orderMergeCandidateList.filter(
          (o) => o.department === department,
        );
      }
      if (orderMergeCandidateList.length) {
        const orderURLToTaskArray = new Map<string, Task[]>();
        orderMergeCandidateList.forEach((o) => {
          orderURLToTaskArray.set(o.url, []);
        });
        taskArray.forEach((task) => {
          const taskOrderURL = task.order;
          if (taskOrderURL) {
            const taskArrayForOrder = orderURLToTaskArray.get(taskOrderURL);
            if (taskArrayForOrder) {
              taskArrayForOrder.push(task);
            }
          }
        });
        inlinedOrderMergeCandidateList = orderMergeCandidateList.map((o) => ({
          ...o,
          taskSet: orderURLToTaskArray.get(o.url) || [],
        }));
        // ignore orders without tasks
        // disallow merge on orders with only validatedAndRecorded tasks
        inlinedOrderMergeCandidateList = inlinedOrderMergeCandidateList.filter(
          (o) => o.taskSet.length && !o.taskSet.every((t) => t.recordedInC5),
        );
      }
    }

    return (
      <OrderEntryWithTheme
        order={order}
        orderMergeCandidateList={inlinedOrderMergeCandidateList}
        taskList={taskList}
        {...this.props}
      />
    );
  }
}

const ConnectedOrderEntryContainer = connect<
  OrderEntryContainerStateProps,
  OrderEntryContainerDispatchProps,
  OrderEntryContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OrderEntryContainerStateProps>({
    contactArray: getContactArray,
    contactLookup: getContactLookup,
    currentRole: getCurrentRole,
    currentUser: getCurrentUser,
    currentUserCanManageContacts: getCurrentUserCanManageContacts,
    currentUserProfile: getCurrentUserProfile,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    deliveryLocationArray: getDeliveryLocationArray,
    employeeGroupLookup: getEmployeeGroupLookup,
    hoursAbsenceArray: getHoursAbsenceArray,
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    machineGroupLookup: getMachineGroupLookup,
    machineLookup: getMachineLookup,
    orderArray: getOrderArray,
    orderLookup: getOrderLookup,
    pickupLocationArray: getPickupLocationArray,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    projectLookup: getProjectLookup,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    shareToken: getShareToken,
    sprayLocationArray: getSprayLocationArray,
    sprayLogArray: getSprayLogArray,
    taskArray: getTaskArray,
    taskLookup: getTaskLookup,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    token: getToken,
    transportLogArray: getTransportLogArray,
    unitLookup: getUnitLookup,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
    yieldDeliveryLocationArray: getYieldDeliveryLocationArray,
    yieldLogArray: getYieldLogArray,
    yieldPickupLocationArray: getYieldPickupLocationArray,
  }),
  (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        backSkip: actions.backSkip,
        create: actions.create,
        createOrUpdate: actions.createOrUpdate,
        go: actions.go,
        remove: actions.remove,
        update: actions.update,
      },
      dispatch,
    ),
  }),
)(OrderEntryContainer);

const related: LoadInstanceRelated = [
  {memberName: "order", resourceType: "task", type: "hasForeignKey"},
  {
    memberName: "customer",
    resourceType: "customer",
    type: "targetOfForeignKey",
  },
  {memberName: "order", resourceType: "orderFile", type: "hasForeignKey"},
] as const;

class LoadOrderEntry extends React.Component<object> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const intl = this.context;
    return (
      <DoLoadInstance<Order, OrderUrl>
        Component={ConnectedOrderEntryContainer}
        loadingTitle={intl.formatMessage({
          defaultMessage: "Ordre",
        })}
        lookupSelector={getOrderLookup}
        related={related}
        resourceName="order"
      />
    );
  }
}

export default LoadOrderEntry;
