import {
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {getPricePercentFuelSurchargeSpecificationLookup} from "@co-frontend-libs/redux";
import {DoLoadInstance} from "app-components";
import {LoadInstanceRelated} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";
import {PricePercentFuelSurchargeSpecificationLoaded} from "./price-percent-fuel-surcharge-specification-loaded";

const related: LoadInstanceRelated = [
  {
    memberName: "specification",
    resourceType: "pricePercentFuelSurchargeSpecificationEntry",
    type: "hasForeignKey",
  },
] as const;

export function PricePercentFuelSurchargeSpecificationEdit(): JSX.Element {
  const intl = useIntl();

  return (
    <DoLoadInstance<
      PricePercentFuelSurchargeSpecification,
      PricePercentFuelSurchargeSpecificationUrl
    >
      Component={PricePercentFuelSurchargeSpecificationLoaded}
      loadingTitle={intl.formatMessage({defaultMessage: "Brændstoftillæg"})}
      lookupSelector={getPricePercentFuelSurchargeSpecificationLookup}
      related={related}
      resourceName="pricePercentFuelSurchargeSpecification"
    />
  );
}
