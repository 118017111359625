import {CustomerUrl, Project, ProjectUrl, Task, urlToId} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import {ConnectedProjectDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getContactArray,
  getCurrentUserCanAddProject,
  getCurrentUserSelectableProjectArray,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getOrderLookup,
  getProjectLookup,
  getTaskArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, Chip} from "@material-ui/core";
import {ProjectCreateEditDialog, type ProjectData} from "app-components";
import {taskChangeCustomerCulture} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  selectProject: {
    defaultMessage: "Vælg",
    id: "project-selection.label.select-project",
  },
});

interface ProjectSelectionProps {
  customerUrl?: CustomerUrl | undefined;
  disabled?: boolean;
  onRequestCustomer: () => void;
  onTaskMovedToNewOrder?: (orderId: string, taskId: string) => void;
  task: Task;
}

export const ProjectSelection = React.memo(function ProjectSelection(
  props: ProjectSelectionProps,
): JSX.Element {
  const {
    customerUrl,
    disabled: propsDisabled = false,
    onRequestCustomer,
    onTaskMovedToNewOrder,
    task,
  } = props;

  const [addingProject, setAddingProject] = useState(false);
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const contactArray = useSelector(getContactArray);
  const customerLookup = useSelector(getCustomerLookup);
  const currentUserSelectableProjectArray = useSelector(getCurrentUserSelectableProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const taskArray = useSelector(getTaskArray);
  const orderLookup = useSelector(getOrderLookup);
  const unitLookup = useSelector(getUnitLookup);

  const {canAddProject, onlyEnableProjectsForDepartments, projectLabelVariant} = customerSettings;

  const handleProjectSelectButton = useCallback((): void => {
    setProjectDialogOpen(true);
  }, []);

  const handleOk = useCallback(
    (url: ProjectUrl): void => {
      setProjectDialogOpen(false);
      if (customerUrl) {
        dispatch(actions.update(task.url, [{member: "project", value: url}]));
      } else {
        const project = projectLookup(url);
        const projectCustomerUrl = project ? project.customer : null;
        const orderId = task.order ? urlToId(task.order) : null;
        if (!projectCustomerUrl || !orderId) {
          return;
        }
        const possiblyNewOrderId = taskChangeCustomerCulture(
          projectCustomerUrl,
          null,
          {
            contactArray,
            create: bindActionCreators(actions.create, dispatch),
            customerLookup,
            orderLookup,
            task,
            taskArray,
            unitLookup,
            update: bindActionCreators(actions.update, dispatch),
          },
          customerSettings,
          currentUserUrl,
        );
        const customer = projectCustomerUrl ? customerLookup(projectCustomerUrl) : null;
        const address = customer ? customerAddress(customer) : "";

        dispatch(
          actions.update(task.url, [
            {member: "address", value: address},
            {member: "project", value: url},
          ]),
        );
        if (onTaskMovedToNewOrder && possiblyNewOrderId && possiblyNewOrderId !== orderId) {
          onTaskMovedToNewOrder(possiblyNewOrderId, urlToId(task.url));
        }
      }
    },
    [
      contactArray,
      currentUserUrl,
      customerLookup,
      customerSettings,
      customerUrl,
      dispatch,
      onTaskMovedToNewOrder,
      orderLookup,
      projectLookup,
      task,
      taskArray,
      unitLookup,
    ],
  );

  const handleCancel = useCallback((): void => {
    setAddingProject(false);
    setProjectDialogOpen(false);
  }, []);

  const handleRemoveProject = useCallback((): void => {
    dispatch(actions.update(task.url, [{member: "project", value: null}]));
  }, [dispatch, task.url]);

  const handleAddProject = useCallback((): void => {
    setAddingProject(true);
    setProjectDialogOpen(false);
    if (!customerUrl) {
      onRequestCustomer();
    }
  }, [customerUrl, onRequestCustomer]);

  const handleProjectCreateEditDialogOk = useCallback(
    (data: ProjectData): void => {
      if (!customerUrl) {
        return;
      }
      const id = uuid();
      const url = instanceURL("project", id);
      const instance: Project = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        c5_recid: 0,
        id,
        remoteUrl: "",
        url,
        ...data,
      };
      dispatch(actions.create(instance));
      setAddingProject(false);
      handleOk(url);
    },
    [customerUrl, dispatch, handleOk],
  );

  const {formatMessage} = useIntl();

  const enabledForDepartment =
    !onlyEnableProjectsForDepartments.length ||
    onlyEnableProjectsForDepartments.includes(task.department);
  let noProjects = true;
  if (customerUrl) {
    noProjects = !currentUserSelectableProjectArray.some(
      (project) => project.customer === customerUrl,
    );
  } else {
    noProjects = !currentUserSelectableProjectArray.length;
  }
  let projectBlock;
  const projectURL = task.project;
  const validated = task.validatedAndRecorded || task.reportApproved;
  const currentUserCanAddProject = useSelector(getCurrentUserCanAddProject);
  const disabled =
    propsDisabled ||
    validated ||
    (noProjects && !currentUserCanAddProject) ||
    !enabledForDepartment;
  if (projectURL) {
    const project = projectLookup(projectURL);
    if (project) {
      const {alias, name, projectNumber} = project;
      const nameAndOrAlias =
        customerSettings.showProjectAlias && name && alias ? `${name}, ${alias}` : name || alias;
      projectBlock = (
        <Chip
          disabled={disabled}
          label={`${projectNumber}: ${nameAndOrAlias}`}
          onDelete={handleRemoveProject}
        />
      );
    }
  }
  const projectCreateEditDialogOpen = addingProject && !!customerUrl;
  return (
    <>
      {projectLabelVariant === "PROJECT" ? (
        <FormattedMessage defaultMessage="Projekt" tagName="h4" />
      ) : (
        <FormattedMessage defaultMessage="Sag" tagName="h4" />
      )}
      <Button
        color="secondary"
        disabled={disabled}
        variant="contained"
        onClick={handleProjectSelectButton}
      >
        {formatMessage(messages.selectProject)}
      </Button>
      {projectBlock}
      <ConnectedProjectDialog
        suggestRecentlyUsed
        customerURL={customerUrl}
        open={projectDialogOpen}
        onAdd={canAddProject ? handleAddProject : undefined}
        onCancel={handleCancel}
        onOk={handleOk}
      />
      {customerUrl ? (
        <ProjectCreateEditDialog
          data={customerUrl}
          open={projectCreateEditDialogOpen}
          onCancel={handleCancel}
          onOk={handleProjectCreateEditDialogOk}
        />
      ) : null}
    </>
  );
});
