import {Customer, CustomerUrl, Location} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import {IconButton} from "@material-ui/core";
import {PureComponent, addDanishCountryPrefix, getGoogleMapsUrl} from "app-utils";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import MapIcon from "mdi-react/MapIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import React from "react";
import {FormattedMessage} from "react-intl";

interface WorkplaceBlockProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  location?: Location | undefined;
  style?: React.CSSProperties;
}

export class WorkplaceBlock extends PureComponent<WorkplaceBlockProps> {
  render(): JSX.Element {
    const {customerLookup, location, style = {}} = this.props;
    if (!location) {
      return (
        <div style={style}>
          <FormattedMessage
            defaultMessage="Ikke angivet"
            id="workplaceblock.label.workplace-not-specified"
            tagName="i"
          />
        </div>
      );
    }
    const {attention, cellphone, name, phone} = location;
    const address = customerAddress(location);
    const mapsURL = getGoogleMapsUrl(location);
    let mapButton = null;
    if (mapsURL) {
      mapButton = (
        <IconButton color="primary" href={mapsURL} target="_blank">
          <MapIcon />
        </IconButton>
      );
    }
    const customer = location.customer ? customerLookup(location.customer) : null;
    const customerName = customer?.name;
    return (
      <div style={style}>
        {customerName ? (
          <div>
            <i>{customerName}</i>
          </div>
        ) : null}
        {attention ? (
          <FormattedMessage defaultMessage="Att: {attention}" tagName="div" values={{attention}} />
        ) : null}
        {name ? <div>{name}</div> : null}
        <div>
          {mapButton} {address}
        </div>
        {cellphone ? (
          <div>
            <IconButton color="primary" href={`tel:${addDanishCountryPrefix(cellphone)}`}>
              <CellphoneIcon />
            </IconButton>{" "}
            {cellphone}
          </div>
        ) : null}
        {phone ? (
          <div>
            <IconButton color="primary" href={`tel:${addDanishCountryPrefix(phone)}`}>
              <PhoneIcon />
            </IconButton>{" "}
            {phone}
          </div>
        ) : null}
      </div>
    );
  }
}
