import {formatDateNumeric} from "@co-common-libs/utils";
import React from "react";
import {FormattedMessage} from "react-intl";
import {JournalDataEntry, StorageJournalTable} from "./storage-journal-table";
import {
  JournalSummarizedDataByProductEntry,
  StorageSummarizedByProductJournalTable,
} from "./storage-summarized-by-product";
import {
  JournalSummarizedDataEntry,
  StorageSummarizedByProductPerLocationJournalTable,
} from "./storage-summarized-by-product-per-location";

export {StorageJournalTable} from "./storage-journal-table";

export type {JournalDataEntry} from "./storage-journal-table";
export type {JournalSummarizedDataEntry} from "./storage-summarized-by-product-per-location";
export type {JournalSummarizedDataByProductEntry} from "./storage-summarized-by-product";

export enum ContentSelection {
  EVERYTHING = "everything",
  EVENTS = "events",
  SUMMARY_PRODUCT_PER_LOCATION = "summaryProductPerLocation",
  SUMMARY_BY_PRODUCT = "summaryByProduct",
}

interface StorageJournalProps {
  contentSelection: ContentSelection;
  data: readonly JournalDataEntry[];
  fromDate: string;
  invoiceCustomerNames: readonly string[];
  locationCustomerNames: readonly string[];
  locationName: string;
  machineWithPriceGroupLabels: readonly string[];
  productLabels: readonly string[];
  showCustomerColumn: boolean;
  showFiltering: boolean;
  showStatusColumn: boolean;
  summarizedByProductData: readonly JournalSummarizedDataByProductEntry[];
  summarizedData: readonly JournalSummarizedDataEntry[];
  toDate: string;
  workTypeWithPriceGroupLabels?: readonly string[] | undefined;
}

export function StorageJournalContent(props: StorageJournalProps): JSX.Element {
  const {
    contentSelection,
    data,
    fromDate,
    invoiceCustomerNames,
    locationCustomerNames,
    locationName,
    machineWithPriceGroupLabels,
    productLabels,
    showCustomerColumn,
    showFiltering,
    showStatusColumn,
    summarizedByProductData,
    summarizedData,
    toDate,
    workTypeWithPriceGroupLabels,
  } = props;
  return (
    <div
      style={{
        fontFamily: "Linux Biolinum O, sans-serif",
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <h3>
        <FormattedMessage
          defaultMessage="Sted: {locationName}"
          id="storage-journal.header.location"
          values={{locationName}}
        />
      </h3>
      <div>
        <FormattedMessage
          defaultMessage="Periode: {fromDate} - {toDate}"
          id="storage-journal.title.period"
          values={{
            fromDate: formatDateNumeric(fromDate),
            toDate: formatDateNumeric(toDate),
          }}
        />
      </div>
      {showFiltering ? (
        <>
          {workTypeWithPriceGroupLabels ? (
            <div>
              {workTypeWithPriceGroupLabels.length ? (
                <FormattedMessage
                  defaultMessage="Områder: {workTypes}"
                  id="storage-journal.text.work-types"
                  values={{workTypes: workTypeWithPriceGroupLabels.join(", ")}}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Områder: Alle"
                  id="storage-journal.text.all-work-types"
                />
              )}
            </div>
          ) : null}
          {productLabels ? (
            <div>
              {productLabels.length ? (
                <FormattedMessage
                  defaultMessage="Produkter: {products}"
                  values={{products: productLabels.join(", ")}}
                />
              ) : (
                <FormattedMessage defaultMessage="Produkter: Alle" />
              )}
            </div>
          ) : null}
          <div>
            {machineWithPriceGroupLabels.length ? (
              <FormattedMessage
                defaultMessage="Maskiner: {machines}"
                id="storage-journal.text.machines"
                values={{machines: machineWithPriceGroupLabels.join(", ")}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Maskiner: Alle"
                id="storage-journal.text.all-machines"
              />
            )}
          </div>
          <div>
            {locationCustomerNames.length ? (
              <FormattedMessage
                defaultMessage="Afhentnings-/leveringskunder: {customers}"
                id="storage-journal.text.location-customers"
                values={{customers: locationCustomerNames.join(", ")}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Afhentnings-/leveringskunder: Alle"
                id="storage-journal.text.all-location-customers"
              />
            )}
          </div>
          <div>
            {invoiceCustomerNames.length ? (
              <FormattedMessage
                defaultMessage="Fakturakunder: {customers}"
                id="storage-journal.text.invoice-customers"
                values={{customers: invoiceCustomerNames.join(", ")}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Fakturakunder: Alle"
                id="storage-journal.text.all-invoice-customers"
              />
            )}
          </div>
        </>
      ) : null}
      {contentSelection === ContentSelection.EVERYTHING ||
      contentSelection === ContentSelection.EVENTS ? (
        <StorageJournalTable
          data={data}
          showCustomerColumn={showCustomerColumn}
          showStatusColumn={showStatusColumn}
        />
      ) : null}
      {contentSelection === ContentSelection.EVERYTHING ||
      contentSelection === ContentSelection.SUMMARY_PRODUCT_PER_LOCATION ? (
        <div style={{paddingTop: 20}}>
          <FormattedMessage defaultMessage="Summeret pr. vare/sted" tagName="h3" />
          <StorageSummarizedByProductPerLocationJournalTable data={summarizedData} />
        </div>
      ) : null}
      {contentSelection === ContentSelection.EVERYTHING ||
      contentSelection === ContentSelection.SUMMARY_BY_PRODUCT ? (
        <div style={{paddingTop: 20}}>
          <FormattedMessage defaultMessage="Summeret pr. vare" tagName="h3" />
          <StorageSummarizedByProductJournalTable data={summarizedByProductData} />
        </div>
      ) : null}
    </div>
  );
}
