import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import {
  DecimalField,
  ErrorColorButton,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getLocationLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {LocationSelectCreateDialog} from "./location-dialog";
import {UnitSelect} from "./unit-select";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "delivery-location-dialog.label.task-address",
  },
  areaHa: {
    defaultMessage: "Areal (ha)",
    id: "delivery-location-dialog.title.area",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  delete: {
    defaultMessage: "Slet",
    id: "delivery-location-dialog.label.delete",
  },
  editDeliveryLocation: {
    defaultMessage: "Ret leveringssted",
    id: "delivery-location-dialog.title.edit-delivery-location",
  },
  newDeliveryLocation: {
    defaultMessage: "Nyt leveringssted",
    id: "delivery-location-dialog.title.new-delivery-location",
  },
  note: {
    defaultMessage: "Note",
    id: "delivery-location-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  selectCustomer: {
    defaultMessage: "Vælg",
    id: "delivery-location-dialog.label.select-customer",
  },
  selectDeliveryLocation: {
    defaultMessage: "Leveringssted",
    id: "delivery-location-dialog.title.select-delivery-location",
  },
  selectWorkplace: {
    defaultMessage: "Vælg",
    id: "delivery-location-dialog.label.select-workplace",
  },
  totalAmount: {
    defaultMessage: "Total mængde ({unit})",
    id: "delivery-location-dialog.label.total-amount",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "delivery-location-dialog.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed",
    id: "delivery-location-dialog.label.unit-choice",
  },
});

interface DeliveryLocationDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface DeliveryLocationDialogOwnProps {
  amount?: number | undefined;
  areaHa?: number | undefined;
  canDelete?: boolean;
  customer?: Customer | undefined;
  customerSettings: Config;
  haRequired?: boolean | undefined;
  isNew: boolean;
  location?: Location | undefined;
  note?: string | undefined;
  onCancel: () => void;
  onOk: (data: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }) => void;
  onRequestCustomerDialog: (callback: (customer: Customer) => void) => void;
  onRequestDelete?: () => void;
  open: boolean;
  relatedUnit?: Unit | undefined;
  unit?: string | undefined;
}

interface DeliveryLocationDialogState {
  amount: number | null | undefined;
  areaHa: number | null | undefined;
  customer: Customer | null | undefined;
  customerLocationDialogOpen: boolean;
  haRequired: boolean | undefined;
  location: Location | null | undefined;
  note: string | undefined;
  relatedUnit: Unit | null | undefined;
  unit: string | undefined;
}

type DeliveryLocationDialogProps = DeliveryLocationDialogOwnProps &
  DeliveryLocationDialogStateProps;

class DeliveryLocationDialogImpl extends PureComponent<
  DeliveryLocationDialogProps,
  DeliveryLocationDialogState
> {
  state: DeliveryLocationDialogState = {
    amount: this.props.amount,
    areaHa: this.props.areaHa,
    customer: this.props.customer,
    customerLocationDialogOpen: false,
    haRequired: this.props.haRequired,
    location: this.props.location,
    note: this.props.note,
    relatedUnit: this.props.relatedUnit,
    unit: this.props.unit,
  };
  UNSAFE_componentWillReceiveProps(nextProps: DeliveryLocationDialogProps): void {
    const members = [
      "amount",
      "areaHa",
      "customer",
      "haRequired",
      "note",
      "unit",
      "location",
      "relatedUnit",
    ] as const;
    if (nextProps.open && !this.props.open) {
      const update: Partial<DeliveryLocationDialogState> = {};
      members.forEach((member) => {
        update[member] = nextProps[member] as any;
      });
      this.setState(update as any);
    } else {
      let changed = false;
      const update: Partial<DeliveryLocationDialogState> = {};
      members.forEach((member) => {
        if (nextProps[member] !== this.props[member]) {
          update[member] = nextProps[member] as any;
          changed = true;
        }
      });
      if (changed) {
        this.setState(update as any);
      }
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleCustomerSelectButton(): void {
    this.props.onRequestCustomerDialog(this.handleCustomerSelected);
  }
  @bind
  handleCustomerSelected(customer: Customer): void {
    this.setState({customer, location: null});
  }

  @bind
  handleOk(): void {
    const {amount, areaHa, customer, location, note, relatedUnit, unit} = this.state;
    this.props.onOk({
      amount: amount != null ? amount : null,
      areaHa: areaHa != null ? areaHa : null,
      customer: customer || null,
      location: location || null,
      note: note || "",
      relatedUnit: relatedUnit || null,
      unit: unit || "",
    });
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleAreaHaChange(newValue: number | null): void {
    this.setState({areaHa: newValue});
  }
  @bind
  handleAmountChange(newValue: number | null): void {
    this.setState({amount: newValue});
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleSelectUnitChange(unitURL: UnitUrl): void {
    const unit = this.props.unitLookup(unitURL);
    if (!unit) {
      return;
    }
    this.setState({
      relatedUnit: unit,
      unit: unit.name || unit.symbol,
    });
  }
  @bind
  handleLocationSelectButton(_event: unknown): void {
    this.setState({customerLocationDialogOpen: true});
  }
  @bind
  handleWorkPlaceDialogCancel(): void {
    this.setState({customerLocationDialogOpen: false});
  }
  @bind
  handleWorkPlaceDialogOk(url: LocationUrl): void {
    const location = this.props.locationLookup(url);
    const customer = location?.customer ? this.props.customerLookup(location.customer) : null;
    this.setState({
      customer: customer || null,
      customerLocationDialogOpen: false,
      location,
    });
  }
  render(): JSX.Element {
    const {customerSettings} = this.props;
    const {formatMessage} = this.context;
    const {canDelete, isNew, open} = this.props;

    const disabled =
      customerSettings.sharedTransportLog &&
      customerSettings.transportLogUnitPerLocation &&
      !this.state.relatedUnit;
    let customerBlock;
    if (this.state.customer) {
      const {customer} = this.state;
      customerBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Navn: {name}"
            id="delivery-location-dialog.label.customer-name"
            tagName="div"
            values={{name: customer.name}}
          />
          <FormattedMessage
            defaultMessage="Adresse: {address}"
            id="delivery-location-dialog.label.customer-address"
            tagName="div"
            values={{address: customerAddress(customer)}}
          />
        </div>
      );
    }
    let deleteButton;
    if (!isNew && this.props.onRequestDelete) {
      deleteButton = (
        <div>
          <ErrorColorButton
            disabled={!canDelete}
            variant="contained"
            onClick={this.props.onRequestDelete}
          >
            {formatMessage(messages.delete)}
          </ErrorColorButton>
        </div>
      );
    }
    let locationAddress;
    if (this.state.location) {
      locationAddress = this.state.location.address || this.state.location.name;
    }
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>
              <FormattedMessage
                defaultMessage="Kunde"
                id="pickup-location-dialog.label.customer"
                tagName="h4"
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleCustomerSelectButton}
              >
                {formatMessage(messages.selectCustomer)}
              </Button>
              {customerBlock}
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Arbejdssted"
                id="order-instance.label.workplace"
                tagName="h4"
              />
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleLocationSelectButton}
                >
                  {formatMessage(messages.selectDeliveryLocation)}
                </Button>
                <div>{locationAddress}</div>
              </div>
            </div>
            {this.state.haRequired ? (
              <DecimalField
                fullWidth
                decimalPlaces={customerSettings.transportLogDecimals}
                label={formatMessage(messages.areaHa)}
                margin="dense"
                maxDigits={9}
                value={this.state.areaHa != null ? this.state.areaHa : null}
                onChange={this.handleAreaHaChange}
              />
            ) : null}
            {deleteButton}
          </Cell>
          <Cell palm="12/12">
            {customerSettings.transportLogUnitPerLocation ? (
              <UnitSelect
                relatedUnit={this.state.relatedUnit?.url}
                onSelectUnitChange={this.handleSelectUnitChange}
              />
            ) : null}
            <DecimalField
              fullWidth
              decimalPlaces={customerSettings.transportLogDecimals}
              label={formatMessage(messages.totalAmount, {
                unit: this.state.relatedUnit
                  ? this.state.relatedUnit.symbol || this.state.relatedUnit.name
                  : this.state.unit,
              })}
              margin="dense"
              maxDigits={9}
              value={this.state.amount != null ? this.state.amount : null}
              onChange={this.handleAmountChange}
            />
            <TrimTextField
              fullWidth
              multiline
              label={formatMessage(messages.note)}
              margin="dense"
              maxRows={30}
              minRows={2}
              value={this.state.note}
              variant="outlined"
              onChange={this.handleNoteChange}
            />
          </Cell>
        </Grid>
        {customerSettings.sharedTransportLog && customerSettings.transportLogUnitPerLocation ? (
          <FormattedMessage
            defaultMessage="* Krævet"
            id="delivery-location-dialog.label.required"
          />
        ) : null}
      </div>
    );
    return (
      <div>
        <ResponsiveDialog
          okDisabled={disabled}
          open={open}
          title={
            isNew
              ? formatMessage(messages.newDeliveryLocation)
              : formatMessage(messages.editDeliveryLocation)
          }
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        >
          <DialogContent>{dialogContent}</DialogContent>
        </ResponsiveDialog>
        <LocationSelectCreateDialog
          customerURL={this.state.customer?.url || null}
          includeWorkplaceOnlyLocations={false}
          logOnlyLocation={customerSettings.setLogOnlyLocationOnCreate}
          open={this.state.customerLocationDialogOpen}
          titleVariant="DELIVERY"
          onCancel={this.handleWorkPlaceDialogCancel}
          onOk={this.handleWorkPlaceDialogOk}
        />
      </div>
    );
  }
}

export const DeliveryLocationDialog: React.ComponentType<DeliveryLocationDialogOwnProps> = connect<
  DeliveryLocationDialogStateProps,
  object,
  DeliveryLocationDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DeliveryLocationDialogStateProps>({
    customerLookup: getCustomerLookup,
    locationLookup: getLocationLookup,
    unitLookup: getUnitLookup,
  }),
  {},
)(DeliveryLocationDialogImpl);
