import React, {useCallback, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {ResponsiveDialog} from "../responsive-dialog";

interface ModalPromptComponentProps<T> {
  onCancel: () => void;
  onOk: (result: T) => void;
}

export const useModal = <T, P>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ModalContent: React.ComponentType<P & ModalPromptComponentProps<T>>,
  additionalProps?: Omit<P, keyof ModalPromptComponentProps<T>>,
  title: string = "",
): [modal: JSX.Element, prompt: () => Promise<T>] => {
  const [isOpen, setIsOpen] = useState(false);

  const resolverRef = useRef<((value: T) => void) | null>(null);
  const rejecterRef = useRef<(() => void) | null>(null);

  const prompt = (): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      if (rejecterRef.current) {
        rejecterRef.current();
      }
      resolverRef.current = resolve;
      rejecterRef.current = reject;
      setIsOpen(true);
    });
  };

  const onOk = useCallback((result: T) => {
    if (resolverRef.current) {
      resolverRef.current(result);
    }
    setIsOpen(false);
    resolverRef.current = null;
    rejecterRef.current = null;
  }, []);

  const onCancel = useCallback(() => {
    if (rejecterRef.current) {
      rejecterRef.current();
    }
    setIsOpen(false);
    resolverRef.current = null;
    rejecterRef.current = null;
  }, []);

  const modal = createPortal(
    <ResponsiveDialog open={isOpen} title={title} onCancel={onCancel}>
      <ModalContent onCancel={onCancel} onOk={onOk} {...(additionalProps as P)} />
    </ResponsiveDialog>,
    document.body,
    `modal-prompt-${ModalContent.displayName}`,
  );

  return [modal, prompt];
};
