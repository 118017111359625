import {TransportReport as RenderedTransportReport} from "@co-common-libs/report-rendering";
import {notUndefined} from "@co-common-libs/utils";
import {
  getCurrentUserProfile,
  getCustomerLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getShareToken,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import {globalConfig, instanceURL} from "frontend-global-config";
import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ResultEntry} from "./types";

function buildPDFURL(options: {
  fromDate: string;
  invoiceCustomers: readonly string[];
  locationCustomers: readonly string[];
  locations: readonly string[];
  machines: readonly {
    readonly machine: string;
    readonly priceGroups: readonly string[];
  }[];
  printedBy: string;
  shareToken: string | null;
  showCustomerColumn: boolean;
  showFiltering: boolean;
  toDate: string;
  workTypes: readonly {
    readonly priceGroups: readonly string[];
    readonly workType: string;
  }[];
}): string {
  const {
    fromDate,
    invoiceCustomers,
    locationCustomers,
    locations,
    machines,
    printedBy,
    shareToken,
    showCustomerColumn,
    showFiltering,
    toDate,
    workTypes,
  } = options;
  const pdfBaseURL = `${globalConfig.baseURL}/api/report/transport_report/pdf/`;

  const invoiceCustomerFilterString = invoiceCustomers
    .map((customerId) => `&invoiceCustomer=${customerId}`)
    .join("");

  const locationCustomerFilterString = locationCustomers
    .map((customerId) => `&locationCustomer=${customerId}`)
    .join("");

  const locationFilterString = locations.map((locationId) => `&location=${locationId}`).join("");

  const workTypeFilterString = workTypes
    .map(({priceGroups: priceGroupIds, workType: workTypeId}) => {
      if (!priceGroupIds.length) {
        return `&workType=${workTypeId},`;
      } else {
        return priceGroupIds
          .map((priceGroupId) => {
            return `&workType=${workTypeId},${priceGroupId}`;
          })
          .join("");
      }
    })
    .join("");

  const machineFilterString = machines
    .map(({machine: machineId, priceGroups: priceGroupIds}) => {
      if (!priceGroupIds.length) {
        return `&machine=${machineId},`;
      } else {
        return priceGroupIds
          .map((priceGroupId) => {
            return `&machine=${machineId},${priceGroupId}`;
          })
          .join("");
      }
    })
    .join("");

  const showCustomerColumnString = showCustomerColumn ? "&showCustomerColumn" : "";

  const showFilteringString = showFiltering ? "&showFiltering" : "";

  const pdfURL = [
    `${pdfBaseURL}?token=${shareToken}&fromDate=${fromDate}&toDate=${toDate}&printedBy=${printedBy}`,
    invoiceCustomerFilterString,
    locationCustomerFilterString,
    locationFilterString,
    workTypeFilterString,
    machineFilterString,
    showCustomerColumnString,
    showFilteringString,
  ].join("");
  return pdfURL;
}

interface DisplayResultsProps {
  data: readonly ResultEntry[];
  fromDate: string;
  invoiceCustomers: readonly string[];
  locationCustomers: readonly string[];
  locations: readonly string[];
  machines: readonly {
    readonly machine: string;
    readonly priceGroups: readonly string[];
  }[];
  toDate: string;
  workTypes: readonly {
    readonly priceGroups: readonly string[];
    readonly workType: string;
  }[];
}

export function DisplayResults(props: DisplayResultsProps): JSX.Element {
  const {
    data,
    fromDate,
    invoiceCustomers,
    locationCustomers,
    locations,
    machines,
    toDate,
    workTypes,
  } = props;

  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const shareToken = useSelector(getShareToken);
  const currentUserProfile = useSelector(getCurrentUserProfile);

  const invoiceCustomerNames = invoiceCustomers
    .map((id) => customerLookup(instanceURL("customer", id)))
    .filter(notUndefined)
    .map((customer) => customer.name)
    .sort();
  const locationCustomerNames = locationCustomers
    .map((id) => customerLookup(instanceURL("customer", id)))
    .filter(notUndefined)
    .map((customer) => customer.name)
    .sort();
  const machineWithPriceGroupLabels = machines
    .map(({machine: machineId, priceGroups}) => {
      const machine = machineLookup(instanceURL("machine", machineId));
      const machineString = `${machine?.c5_machine}: ${machine?.name}` || "";
      if (!priceGroups.length) {
        return machineString;
      } else {
        const priceGroupsString = priceGroups
          .map((id) => priceGroupLookup(instanceURL("priceGroup", id)))
          .filter(notUndefined)
          .map((priceGroup) => priceGroup.name)
          .sort()
          .join(", ");
        return `${machineString} (${priceGroupsString})`;
      }
    })
    .sort();

  const workTypeWithPriceGroupLabels = workTypes
    .map(({priceGroups, workType: workTypeId}) => {
      const workType = workTypeLookup(instanceURL("workType", workTypeId));
      const workTypeString = `${workType?.identifier}: ${workType?.name}` || "";
      if (!priceGroups.length) {
        return workTypeString;
      } else {
        const priceGroupsString = priceGroups
          .map((id) => priceGroupLookup(instanceURL("priceGroup", id)))
          .filter(notUndefined)
          .map((priceGroup) => priceGroup.name)
          .sort()
          .join(", ");
        return `${workTypeString} (${priceGroupsString})`;
      }
    })
    .sort();

  const [showCustomerColumn, setShowCustomerColumn] = useState(true);
  const handleShowCustomerColumnCheck = useEventTargetCheckedCallback(setShowCustomerColumn, [
    setShowCustomerColumn,
  ]);

  const [showFiltering, setShowFiltering] = useState(true);
  const handleShowFilteringCheck = useEventTargetCheckedCallback(setShowFiltering, [
    setShowFiltering,
  ]);

  const pdfURL = buildPDFURL({
    fromDate,
    invoiceCustomers,
    locationCustomers,
    locations,
    machines,
    printedBy: currentUserProfile?.name || currentUserProfile?.alias || "",
    shareToken,
    showCustomerColumn,
    showFiltering,
    toDate,
    workTypes,
  });

  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage defaultMessage="Rapport" id="transport-report.card-title.report" />
        }
      />
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox checked={showCustomerColumn} onChange={handleShowCustomerColumnCheck} />
          }
          label={
            <FormattedMessage
              defaultMessage="Vis sted-kunde-kolonne"
              id="transport-report.label.show-customer-column"
            />
          }
        />
        <FormControlLabel
          control={<Checkbox checked={showFiltering} onChange={handleShowFilteringCheck} />}
          label={
            <FormattedMessage
              defaultMessage="Vis filtreringskriterier"
              id="transport-report.label.show-filtering"
            />
          }
        />
      </CardContent>
      <CardContent>
        <div style={{border: "1px solid black", padding: "7mm", width: "297mm"}}>
          <RenderedTransportReport
            data={data}
            fromDate={fromDate}
            invoiceCustomerNames={invoiceCustomerNames}
            locationCustomerNames={locationCustomerNames}
            machineWithPriceGroupLabels={machineWithPriceGroupLabels}
            showCustomerColumn={showCustomerColumn}
            showFiltering={showFiltering}
            toDate={toDate}
            workTypeWithPriceGroupLabels={workTypeWithPriceGroupLabels}
          />
        </div>
      </CardContent>
      <CardActions>
        <Button color="primary" href={pdfURL} target="_blank" variant="contained">
          <FormattedMessage defaultMessage="Hent PDF" id="transport-report.label.fetch-pdf" />
        </Button>
      </CardActions>
    </Card>
  );
}
