import {
  AccomodationAllowance,
  ComputedTime,
  CustomerUrl,
  FieldUse,
  LocationUrl,
  MachineUrl,
  MachineUse,
  Order,
  Patch,
  PatchOperation,
  PriceGroupUrl,
  ProjectUrl,
  Task,
  TaskPhoto,
  TimeCorrection,
  Timer,
  TimerStart,
  TimerUrl,
  UserPhoto,
  UserUrl,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  getLastTimerStart,
  getMachinesWorkTypeString,
  getNormalisedDeviceTimestamp,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {
  MINUTE_MILLISECONDS,
  customerAddress,
  dateFromString,
  dateToString,
  notUndefined,
} from "@co-common-libs/utils";
import {DeleteDialog, StatusBar} from "@co-frontend-libs/components";
import {
  ConnectedDepartmentDialog,
  ConnectedMachineDialog,
} from "@co-frontend-libs/connected-components";
import {
  PunchWorkPeriod,
  actions,
  getCurrentRole,
  getCurrentUserProfile,
  getCurrentUserSortedTimerStartArray,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getEmployeeGroupLookup,
  getLocationLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getQueryParameters,
  getReportingSpecificationLookup,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
  getWorkshopChecklistArray,
  getWorkshopChecklistItemArray,
} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Tab, Tabs} from "@material-ui/core";
import {
  CustomerTaskCreationWrapper,
  MachineRemovalBlockedDialog,
  MissingBreakDialog,
  NotesDialog,
  PageLayout,
  PhotoDisplayDialog,
  TaskInformation,
  getComputedTime,
} from "app-components";
import {
  ADD_MACHINE_ACTION,
  CHANGE_DEPARTMENT_ACTION,
  EDIT_NOTE_ACTION,
  ErrorAction,
  MachineRemovalBlockedReason,
  computeDepartment,
  computeIntervalSums,
  computeIntervalsTruncated,
  computeWorkFromTo,
  concurrencyAllowedForTask,
  copyTask,
  createOrder,
  getBreakTimer,
  getContinuationTaskTargetDate,
  getEmployeeHolidayCalendars,
  getTaskGenericPrimaryTimerLabel,
  getTaskSecondaryTimerList,
  machineAlreadySelected,
  machineRemovalBlocked,
  mergeIntervals,
  padZero,
  saveTimerStart,
  useBoundActions,
  useQueryParameter,
} from "app-utils";
import bowser from "bowser";
import {differenceInCalendarDays, eachDayOfInterval} from "date-fns";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {batch, useDispatch, useSelector} from "react-redux";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {ActionButtons} from "./action-buttons";
import {AutoCorrectionDialog} from "./auto-correction-dialog";
import CopiedDialog from "./copied-dialog";
import {GeolocationTab} from "./geolocation-tab";
import InternalCompletedDialog from "./internal-completed-dialog";
import InternalInfoCard from "./internal-info-card";
import InternalValidatedDialog from "./internal-validated-dialog";
import MachinesCard from "./machines-card";
import {PhotosTab} from "./photos-tab";
import {TaskWarningDialogs} from "./task-warning-dialogs";
import TimeCard from "./time-cards";
import {AddTimeCorrectionDialog} from "./time-correction/add-time-correction-dialog";
import {EditTimeCorrectionDialog} from "./time-correction/edit-time-correction-dialog";
import {editTimeCorrectionsAllowed} from "./utils";
import {WorkshopChecklist} from "./workshop-checklist";

const messages = defineMessages({
  cancel: {defaultMessage: "Fortryd", id: "dialog.label.cancel"},
  cancelOtherTask: {
    defaultMessage: "Afbryd anden opgave?",
    id: "task-instance.dialog-title.cancel-other-task",
  },
  checklist: {
    defaultMessage: "Tjekliste",
  },
  contractorWork: {
    defaultMessage: "Entreprenørarbejde",
    id: "task-list.contractor-work",
  },
  geolocation: {
    defaultMessage: "GPS",
    id: "task-instance.geolocation",
  },
  goToOldTask: {
    defaultMessage: "Gå til gammel opgave",
    id: "task-instance.label.go-to-old-task",
  },
  ok: {defaultMessage: "OK", id: "dialog.label.ok"},
  oldTaskOpen: {
    defaultMessage: "Gammel opgave ikke afsluttet",
    id: "task-instance.dialog-title.old-task-open",
  },
  photo: {
    defaultMessage: "Foto",
    id: "task-instance.photo",
  },
  taskPlannedOnOtherDate: {
    defaultMessage: "Opgaven er planlagt til anden dato",
    id: "task-instance.dialog-title.planned-other-date",
  },
  taskTitle: {defaultMessage: "Opgave"},
  time: {
    defaultMessage: "Tid",
  },
});

const convertToExternalTime = (
  primaryTimer: TimerUrl,
  breakTimer: TimerUrl,
  timeSet: readonly TimeCorrection[],
): TimeCorrection[] => {
  const newTimeSet = timeSet.map((timeCorrection) => {
    if (timeCorrection.timer !== breakTimer && timeCorrection.timer !== primaryTimer) {
      return {...timeCorrection, timer: primaryTimer};
    } else {
      return timeCorrection;
    }
  });
  return newTimeSet;
};

interface InternalTaskProps {
  activeTimer?: TimerUrl | undefined;
  computedIntervals: readonly ComputedTime[];
  genericPrimaryTimer: Timer;
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[];
  legalIntervals?: readonly PunchWorkPeriod[] | undefined;
  now: Date;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
}

export function InternalTask(props: InternalTaskProps): JSX.Element {
  const {
    activeTimer,
    computedIntervals,
    genericPrimaryTimer,
    intervals,
    legalIntervals,
    now,
    task,
    timerMinutesMap,
  } = props;

  const currentRole = useSelector(getCurrentRole);
  const currentTab = useQueryParameter<string>("tab", "time");
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const currentUserSortedTimerStartArray = useSelector(getCurrentUserSortedTimerStartArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);
  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const queryParameters = useSelector(getQueryParameters);
  const taskArray = useSelector(getTaskArray);
  const taskLookup = useSelector(getTaskLookup);
  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const timerStartArray = useSelector(getTimerStartArray);
  const unitLookup = useSelector(getUnitLookup);
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const workshopChecklistArray = useSelector(getWorkshopChecklistArray);
  const workshopChecklistItemArray = useSelector(getWorkshopChecklistItemArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);

  const dispatch = useDispatch();

  const [notesDialogDismissed, setNotesDialogDismissed] = useState(
    !!(
      queryParameters &&
      queryParameters.notesDisplayed &&
      parseInt(queryParameters.notesDisplayed)
    ),
  );
  const [completedDialogOpen, setCompletedDialogOpen] = useState(false);
  const [machineDialogOpen, setMachineDialogOpen] = useState(false);
  const [validatedDialogOpen, setValidatedDialogOpen] = useState(false);
  const [departmentDialogOpen, setDepartmentDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [completedDialogCancelled, setCompletedDialogCancelled] = useState(false);
  const [completedDialogContinuation, setCompletedDialogContinuation] = useState(false);
  const [missingBreakDialogOpen, setMissingBreakDialogOpen] = useState(false);
  const [nextCustomerDialogOpen, setNextCustomerDialogOpen] = useState(false);
  const [copiedDialogOpen, setCopiedDialogOpen] = useState(false);
  const [performOldTaskCheck, setPerformOldTaskCheck] = useState(false);
  const [autoCorrectionDialog, setAutoCorrectionDialog] = useState<{
    fromTimestamp: string;
    timerURL: TimerUrl;
    toTimestamp: string;
  } | null>(null);
  const [machineOperatorTimeCorrectionDialogOpen, setMachineOperatorTimeCorrectionDialogOpen] =
    useState(false);
  const [managerTimeCorrectionDialogOpen, setManagerTimeCorrectionDialogOpen] = useState(false);
  const [editIntervalEnd, setEditIntervalEnd] = useState<string | null>(null);
  const [editIntervalStart, setEditIntervalStart] = useState<string | null>(null);
  const [editIntervalTimer, setEditIntervalTimer] = useState<Timer | null>(null);
  const [displayedImage, setDisplayedImage] = useState<TaskPhoto | UserPhoto | null>(null);
  const [deletingFile, setDeletingFile] = useState<string | null>(null);
  const [deletingPhoto, setDeletingPhoto] = useState<string | null>(null);
  const [timerButtonClickedFor, setTimerButtonClickedFor] = useState<TimerUrl | null>(null);

  const {boundCreate, boundUpdate} = useBoundActions();

  const boundRegisterTimerStartPosition = useCallback(
    (timerStart: TimerStart): void => {
      dispatch(actions.registerTimerStartPosition(timerStart));
    },
    [dispatch],
  );

  const intl = useIntl();

  const handleCompletedValidatedDialogAction = useCallback((action: ErrorAction): void => {
    if (action === ADD_MACHINE_ACTION) {
      setCompletedDialogOpen(false);
      setMachineDialogOpen(true);
      setValidatedDialogOpen(false);
    } else if (action === CHANGE_DEPARTMENT_ACTION) {
      setCompletedDialogOpen(false);
      setDepartmentDialogOpen(true);
      setValidatedDialogOpen(false);
    } else if (action === EDIT_NOTE_ACTION) {
      setCompletedDialogOpen(false);
      setDepartmentDialogOpen(false);
      setValidatedDialogOpen(false);
    } else {
      const sentry = getFrontendSentry();
      sentry.captureMessage(
        `Unhandled error action "${action}" encountered in InternalTask.handleCompletedValidatedDialogAction`,
        "error",
      );
      setCompletedDialogOpen(false);
      setDepartmentDialogOpen(false);
      setValidatedDialogOpen(false);
    }
  }, []);

  const timerChange = useCallback(
    (timerURL: TimerUrl | null, timestamp: string | null = null): void => {
      const userURL = task.machineOperator;
      const taskURL = task.url;
      if (userURL && taskURL) {
        saveTimerStart(
          boundCreate,
          timerURL,
          taskURL,
          userURL,
          timestamp,
          customerSettings.geolocation.registerPositionOnTimerClick &&
            currentUserURL === task.machineOperator
            ? boundRegisterTimerStartPosition
            : undefined,
        );
      }
    },
    [
      boundCreate,
      boundRegisterTimerStartPosition,
      currentUserURL,
      customerSettings.geolocation.registerPositionOnTimerClick,
      task.machineOperator,
      task.url,
    ],
  );

  const handleDeleteButton = useCallback((): void => {
    setDeleteDialogOpen(true);
    // stop timer, just in case
    timerChange(null);
  }, [timerChange]);

  const handleDeleteDialogOk = useCallback((): void => {
    setDeleteDialogOpen(false);
    const taskURL = task.url;
    dispatch(actions.remove(taskURL));
    window.setTimeout(() => {
      batch(() => {
        dispatch(actions.back());
      });
    });
  }, [dispatch, task.url]);

  const handleDeleteDialogCancel = useCallback((): void => {
    setDeleteDialogOpen(false);
  }, []);

  const handleMachineSelectButton = useCallback((): void => {
    setMachineDialogOpen(true);
  }, []);

  const handleMachineDialogOk = useCallback(
    (machineURL: MachineUrl): void => {
      setMachineDialogOpen(false);
      if (machineAlreadySelected(task, machineURL)) {
        return;
      }
      const oldMachines = task.machineuseSet || [];
      const newEntry: MachineUse = {
        machine: machineURL,
        priceGroup: null,
        transporter: false,
      };
      const newMachines = [...oldMachines, newEntry];
      const patch: Patch<Task> = [{member: "machineuseSet", value: newMachines}];
      dispatch(actions.update(task.url, patch));
    },
    [dispatch, task],
  );

  const handleMachineDialogCancel = useCallback((): void => {
    setMachineDialogOpen(false);
  }, []);

  const handleDepartmentSelectButton = useCallback((): void => {
    setDepartmentDialogOpen(true);
  }, []);

  const handleDepartmentDialogOk = useCallback(
    (selected: string): void => {
      dispatch(actions.update(task.url, [{member: "department", value: selected}]));
      setDepartmentDialogOpen(false);
    },
    [dispatch, task.url],
  );

  const handleDepartmentDialogCancel = useCallback((): void => {
    setDepartmentDialogOpen(false);
  }, []);

  const [machineRemovalBlockedReason, setMachineRemovalBlockedReason] =
    useState<MachineRemovalBlockedReason | null>(null);
  const [machineRemovalBlockedDialogOpen, setMachineRemovalBlockedDialogOpen] = useState(false);

  const handleRemoveMachine = useCallback(
    (index: number): void => {
      const machineUse = task.machineuseSet[index];
      if (!machineUse) {
        return;
      }
      const machineUrl = machineUse.machine;
      const blockedReason = machineRemovalBlocked(
        task,
        machineUrl,
        timerMinutesMap,
        customerSettings,
        {
          machineLookup,
          priceGroupLookup,
          reportingSpecificationLookup,
          timerArray,
          workTypeLookup,
        },
      );
      if (blockedReason) {
        setMachineRemovalBlockedDialogOpen(true);
        setMachineRemovalBlockedReason(blockedReason);
      } else {
        const oldMachines = [...task.machineuseSet];
        oldMachines.splice(index, 1);
        const newMachines = oldMachines;
        dispatch(actions.update(task.url, [{member: "machineuseSet", value: newMachines}]));
      }
    },
    [
      customerSettings,
      dispatch,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
      timerMinutesMap,
      workTypeLookup,
    ],
  );

  const handleMachineRemovalBlockedDialogClose = useCallback((): void => {
    setMachineRemovalBlockedDialogOpen(false);
  }, []);

  const handleCompletedButton = useCallback((): void => {
    // FIXME: handle admins stopping task on behalf of others
    // stop timer, just in case
    timerChange(null);
    let showMissingBreakDialog = false;
    if (customerSettings.askRegardingMissingBreakOnInternalTaskCompletion) {
      const finalSums = computeIntervalSums(intervals, new Date());
      const breakTimer = getBreakTimer(timerArray) as Timer;
      const breakTimerURL = breakTimer.url;
      showMissingBreakDialog = !finalSums.get(breakTimerURL);
    }
    if (showMissingBreakDialog) {
      setCompletedDialogCancelled(false);
      setCompletedDialogContinuation(false);
      setMissingBreakDialogOpen(true);
    } else {
      setCompletedDialogCancelled(false);
      setCompletedDialogContinuation(false);
      setCompletedDialogOpen(true);
    }
  }, [
    customerSettings.askRegardingMissingBreakOnInternalTaskCompletion,
    intervals,
    timerArray,
    timerChange,
  ]);

  const handleMissingBreakDialogOk = useCallback((): void => {
    setCompletedDialogOpen(true);
    setMissingBreakDialogOpen(false);
  }, []);

  const handleMissingBreakDialogCancel = useCallback((): void => {
    setMissingBreakDialogOpen(false);
  }, []);

  const handleIncompleteButton = useCallback((): void => {
    timerChange(null);
    setCompletedDialogCancelled(false);
    setCompletedDialogContinuation(true);
    setCompletedDialogOpen(true);
  }, [timerChange]);

  const handleCompletedDialogCancel = useCallback((): void => {
    setCompletedDialogOpen(false);
    setNextCustomerDialogOpen(false);
  }, []);

  const getTimerStarts = useCallback((): TimerStart[] => {
    const taskURL = task.url;
    return _.sortBy(
      timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }, [task.url, timerStartArray]);

  const holidayCalendars = useMemo(() => {
    const userProfile = task.machineOperator ? userUserProfileLookup(task.machineOperator) : null;
    return getEmployeeHolidayCalendars(customerSettings, userProfile);
  }, [customerSettings, task.machineOperator, userUserProfileLookup]);

  const handleCompletedDialogOk = useCallback(
    (_event: unknown, continuation: boolean, registerAccommodationGroup: string | null): void => {
      // FIXME: sanity-check timer stop logic here
      const cancelled = !!completedDialogCancelled;
      setCompletedDialogOpen(false);
      const computeTimestamp = new Date();
      const timerStarts = getTimerStarts();
      const newComputedIntervals = computeIntervalsTruncated(
        timerStarts,
        computeTimestamp.toISOString(),
      );
      const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
      const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
      const newIntervals = mergeIntervals(
        newComputedIntervals,
        correctionIntervals,
        managerCorrectionIntervals,
        computeTimestamp.toISOString(),
      );
      const {workFromTimestamp, workToTimestamp} = computeWorkFromTo(newIntervals);
      const patch: Patch<Task> = [
        {member: "cancelled", value: cancelled},
        {member: "completed", value: true},
        {member: "computedTimeSet", value: newComputedIntervals},
        {member: "workFromTimestamp", value: workFromTimestamp},
        {member: "workToTimestamp", value: workToTimestamp},
      ];
      dispatch(actions.update(task.url, patch));

      if (registerAccommodationGroup && workFromTimestamp && workToTimestamp) {
        const fromDateTime = new Date(workFromTimestamp);
        const toDateTime = new Date(workToTimestamp);
        if (differenceInCalendarDays(toDateTime, fromDateTime) > 0) {
          const dates = eachDayOfInterval({
            end: toDateTime,
            start: fromDateTime,
          });
          dates.pop();
          dates.forEach((date) => {
            const id = uuid();
            const url = instanceURL("accomodationAllowance", id);
            const instance: AccomodationAllowance = {
              date: dateToString(date),
              employee: task.machineOperator as UserUrl,
              id,
              remunerationGroup: registerAccommodationGroup,
              url,
            };
            dispatch(actions.create(instance));
          });
        } else {
          const id = uuid();
          const url = instanceURL("accomodationAllowance", id);
          const instance: AccomodationAllowance = {
            date: dateToString(fromDateTime),
            employee: task.machineOperator as UserUrl,
            id,
            remunerationGroup: registerAccommodationGroup,
            url,
          };
          dispatch(actions.create(instance));
        }
      }

      if (continuation) {
        const newID = uuid();
        const newURL = instanceURL("task", newID);
        const jsObj = {...task};
        const currentDate = task.workFromTimestamp
          ? new Date(task.workFromTimestamp)
          : task.date
            ? dateFromString(task.date)
            : new Date();
        const dateObj = currentDate
          ? getContinuationTaskTargetDate(
              holidayCalendars,
              currentDate,
              customerSettings.continuationTaskTargetDate,
            )
          : null;
        console.assert(dateObj);
        const dateString = dateToString(dateObj);
        jsObj.date = dateString;
        delete jsObj.created;
        jsObj.id = newID;
        jsObj.url = newURL;
        jsObj.time = null;
        jsObj.notesFromMachineOperator = "";
        jsObj.completed = false;
        if (jsObj.productUses) {
          jsObj.productUses = Object.fromEntries(
            Object.values(jsObj.productUses).map((productUse) => [
              uuid(),
              {...productUse, count: 0},
            ]),
          );
        }
        if (jsObj.priceItemUses) {
          jsObj.priceItemUses = Object.fromEntries(
            Object.values(jsObj.priceItemUses).map((priceItemUse) => [
              uuid(),
              {...priceItemUse, count: 0},
            ]),
          );
        }
        jsObj.computedTimeSet = [];
        jsObj.machineOperatorTimeCorrectionSet = [];
        jsObj.managerTimeCorrectionSet = [];
        jsObj.workFromTimestamp = null;
        jsObj.workToTimestamp = null;
        if (jsObj.minutesExpectedTotalTaskDuration) {
          const timerMinutes = computeIntervalSums(newIntervals, computeTimestamp);
          const primaryTimerURL = genericPrimaryTimer.url;
          const minutes = timerMinutes.get(primaryTimerURL) || 0;
          if (jsObj.minutesExpectedTotalTaskDuration > minutes) {
            jsObj.minutesExpectedTotalTaskDuration -= minutes;
          } else {
            jsObj.minutesExpectedTotalTaskDuration = null;
          }
        }

        dispatch(actions.create(jsObj));
      }
      if (!nextCustomerDialogOpen) {
        window.setTimeout(() => {
          batch(() => {
            dispatch(
              actions.forwardBackSkip([
                "/order/:id",
                "/order/:id/:taskID",
                "/orderEntry/:id",
                "/task/:id",
                "/taskDetails/:id",
                "/taskEdit/:id",
                "/internalTask/:id",
              ]),
            );
          });
        });
      }
    },
    [
      completedDialogCancelled,
      customerSettings.continuationTaskTargetDate,
      dispatch,
      genericPrimaryTimer.url,
      getTimerStarts,
      holidayCalendars,
      nextCustomerDialogOpen,
      task,
    ],
  );

  const handleCopyButton = useCallback((): void => {
    if (!task) {
      return;
    }
    const today = dateToString(new Date());
    const overrides: Partial<Writable<Task>> = {
      createdBy: currentUserURL,
      order: null,
    };
    if (task.date == null || task.date < today) {
      overrides["date"] = today;
    }
    if (
      !currentRole ||
      !currentRole.manager ||
      customerSettings.taskCopyAlwaysOverridemachineOperator
    ) {
      overrides.machineOperator = currentUserURL;
    }
    const taskCopy = copyTask(
      task,
      customerSettings.taskCopyFields,
      overrides,
      {
        locationLookup: _.constant(undefined), // Not used for internal tasks
        machineLookup,
        priceGroupLookup: _.constant(undefined), // Only used for generic log
        productLookup: _.constant(undefined), // Not used for internal tasks
        projectLookup: _.constant(undefined), // Not used for internal tasks
        reportingSpecificationLookup: _.constant(undefined), // Only used for generic log
        workTypeLookup: _.constant(undefined), // Only used for generic log
      },
      dispatch,
      intl,
      customerSettings,
    );
    dispatch(actions.create(taskCopy));
    window.setTimeout(() => {
      batch(() => {
        dispatch(actions.go("/task/:id", {id: urlToId(taskCopy.url)}, {}, "REPLACE"));
        setCopiedDialogOpen(true);
      });
    }, 0);
  }, [currentRole, currentUserURL, customerSettings, dispatch, intl, machineLookup, task]);

  const handleSaveButton = useCallback((): void => {
    dispatch(actions.startOnlineSaves());
  }, [dispatch]);

  const handleNotesDialogClose = useCallback((): void => {
    setNotesDialogDismissed(true);
  }, []);

  const handleValidatedButton = useCallback((): void => {
    // stop timer, just in case
    timerChange(null);
    setValidatedDialogOpen(true);
  }, [timerChange]);

  const handleValidatedDialogOk = useCallback((): void => {
    setValidatedDialogOpen(false);
    const {recordInternalTasks} = customerSettings;
    const patch: PatchOperation<Task>[] = [
      {member: "reportApproved", value: true},
      {member: "validatedAndRecorded", value: true},
    ];

    const willBeRecorded = recordInternalTasks;
    if (!willBeRecorded) {
      patch.push({member: "archivable", value: true});
    }
    dispatch(actions.update(task.url, patch));
    setTimeout(() => {
      batch(() => {
        dispatch(actions.back());
      });
    });
  }, [customerSettings, dispatch, task.url]);

  const handleValidatedDialogCancel = useCallback((): void => {
    setValidatedDialogOpen(false);
  }, []);

  const handleGoToInternalTaskEdit = useCallback((): void => {
    if (task) {
      dispatch(actions.go("/internalTask/:id", {id: urlToId(task.url)}));
    }
  }, [dispatch, task]);

  const handleTakeButton = useCallback((): void => {
    dispatch(actions.update(task.url, [{member: "machineOperator", value: currentUserURL}]));
  }, [currentUserURL, dispatch, task.url]);

  const handleCopiedDialogClose = useCallback((): void => {
    setCopiedDialogOpen(false);
  }, []);

  const addTimeCorrectionArray = useCallback(
    (
      corrections: readonly (readonly [string, string, TimerUrl | null])[],
      asManager: boolean,
    ): void => {
      let oldIntervals;
      if (asManager) {
        oldIntervals = task.managerTimeCorrectionSet || [];
      } else {
        oldIntervals = task.machineOperatorTimeCorrectionSet || [];
      }
      let newIntervals = oldIntervals;
      corrections.forEach(([inputFromTimestamp, inputToTimestamp, timer]) => {
        let fromTimestamp = inputFromTimestamp;
        let toTimestamp = inputToTimestamp;
        const correctionLengthMilliseconds =
          new Date(toTimestamp).valueOf() - new Date(fromTimestamp).valueOf();
        if (correctionLengthMilliseconds < MINUTE_MILLISECONDS) {
          // ignore corrections of less than a minute
          return;
        }
        const intersectsPredicate = (interval: {
          fromTimestamp: string;
          toTimestamp: string;
        }): boolean => {
          return interval.toTimestamp > fromTimestamp && interval.fromTimestamp < toTimestamp;
        };
        const nonIntersectingIntervals = newIntervals.filter(_.negate(intersectsPredicate));
        const intersectingIntervals = newIntervals.filter(intersectsPredicate);
        newIntervals = nonIntersectingIntervals;
        if (!intersectingIntervals.length) {
          newIntervals = [...newIntervals, {fromTimestamp, timer, toTimestamp}];
        } else {
          const sameWorkTypePredicate = (interval: {timer: string | null}): boolean => {
            return interval.timer === timer;
          };
          const intersectingSameWorktype = intersectingIntervals.filter(sameWorkTypePredicate);
          intersectingSameWorktype.forEach((interval) => {
            const intervalFromTimestamp = interval.fromTimestamp;
            const intervalToTimestamp = interval.toTimestamp;
            if (intervalFromTimestamp < fromTimestamp) {
              fromTimestamp = intervalFromTimestamp;
            }
            if (intervalToTimestamp > toTimestamp) {
              toTimestamp = intervalToTimestamp;
            }
          });
          newIntervals = [...newIntervals, {fromTimestamp, timer, toTimestamp}];
          const intersectingDifferentWorkType = intersectingIntervals.filter(
            _.negate(sameWorkTypePredicate),
          );
          intersectingDifferentWorkType.forEach((interval) => {
            // split into part before new interval and part after interval; both might be empty/have negative length...
            const intervalBeyondStart = {
              ...interval,
              toTimestamp: fromTimestamp,
            };
            const intervalBeyondEnd = {...interval, fromTimestamp: toTimestamp};
            const intervalBeyondStartLength =
              new Date(intervalBeyondStart.toTimestamp).valueOf() -
              new Date(intervalBeyondStart.fromTimestamp).valueOf();
            if (intervalBeyondStartLength > MINUTE_MILLISECONDS) {
              newIntervals = [...newIntervals, intervalBeyondStart];
            }
            const intervalBeyondEndLength =
              new Date(intervalBeyondEnd.toTimestamp).valueOf() -
              new Date(intervalBeyondEnd.fromTimestamp).valueOf();
            if (intervalBeyondEndLength > MINUTE_MILLISECONDS) {
              newIntervals = [...newIntervals, intervalBeyondEnd];
            }
          });
        }
        newIntervals = _.sortBy(newIntervals, (interval) => interval.fromTimestamp);
      });
      // cleanup
      if (newIntervals.length) {
        const cleaned = [];
        let previous = newIntervals[0];
        newIntervals.slice(1).forEach((interval) => {
          if (interval.timer !== previous.timer) {
            cleaned.push(previous);
            previous = interval;
          } else {
            const distance =
              new Date(interval.fromTimestamp).valueOf() - new Date(previous.toTimestamp).valueOf();
            if (distance < MINUTE_MILLISECONDS) {
              previous = {...previous, toTimestamp: interval.toTimestamp};
            } else {
              cleaned.push(previous);
              previous = interval;
            }
          }
        });
        cleaned.push(previous);
        newIntervals = cleaned;
      }
      if (!_.isEqual(newIntervals, oldIntervals)) {
        if (asManager) {
          // perform manager time corrections
          dispatch(
            actions.update(task.url, [{member: "managerTimeCorrectionSet", value: newIntervals}]),
          );
        } else {
          // perform machine operator time corrections
          dispatch(
            actions.update(task.url, [
              {member: "machineOperatorTimeCorrectionSet", value: newIntervals},
            ]),
          );
        }
      }
    },
    [dispatch, task.machineOperatorTimeCorrectionSet, task.managerTimeCorrectionSet, task.url],
  );

  const addTimeCorrection = useCallback(
    (
      fromTimestamp: string,
      toTimestamp: string,
      timer: TimerUrl | null,
      asManager: boolean,
    ): void => {
      addTimeCorrectionArray([[fromTimestamp, toTimestamp, timer]], asManager);
    },
    [addTimeCorrectionArray],
  );

  const handleAddMachineOperatorTimeCorrection = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null): void => {
      addTimeCorrection(fromTimestamp, toTimestamp, timer, false);
    },
    [addTimeCorrection],
  );

  const timerButtonValid = useCallback(
    (timerURL: TimerUrl, inputLastTimerStart?: TimerStart): void => {
      const lastTimerStart =
        inputLastTimerStart ||
        (currentUserURL ? getLastTimerStart(currentUserURL, timerStartArray) : undefined);
      const lastTimerStartTaskURL = lastTimerStart && lastTimerStart.task;
      const computeTimestamp = new Date();
      if (
        lastTimerStart &&
        lastTimerStartTaskURL === task.url &&
        lastTimerStart.timer === timerURL
      ) {
        timerChange(null);
      } else {
        const oldDate = task.date;
        const oldTime = task.time;
        const nowDate = dateToString(computeTimestamp);
        const hoursDigits = 2;
        const minutesDigits = 2;
        const nowTime = `${padZero(computeTimestamp.getHours(), hoursDigits)}:${padZero(
          computeTimestamp.getMinutes(),
          minutesDigits,
        )}`;
        if (!oldDate || oldDate > nowDate) {
          dispatch(
            actions.update(task.url, [
              {member: "date", value: nowDate},
              {member: "time", value: nowTime},
            ]),
          );
        } else if (!oldTime || (oldDate === nowDate && oldTime > nowTime)) {
          dispatch(actions.update(task.url, [{member: "time", value: nowTime}]));
        }
        const timestamp = computeTimestamp.toISOString();
        timerChange(timerURL, timestamp);
        if (lastTimerStartTaskURL !== task.url) {
          const {autoFillUnregisteredMinutesOnStart} = customerSettings;
          if (autoFillUnregisteredMinutesOnStart != null) {
            const oldTask = lastTimerStartTaskURL && taskLookup(lastTimerStartTaskURL);
            if (oldTask) {
              const newComputedIntervals = getComputedTime(oldTask, timerStartArray, timestamp);
              const correctionIntervals = oldTask.machineOperatorTimeCorrectionSet || [];
              const managerCorrectionIntervals = oldTask.managerTimeCorrectionSet || [];
              const newIntervals = mergeIntervals(
                newComputedIntervals,
                correctionIntervals,
                managerCorrectionIntervals,
                timestamp,
              );
              const lastInterval = newIntervals && newIntervals[newIntervals.length - 1];
              const lastToTimestamp = lastInterval && lastInterval.toTimestamp;
              if (lastToTimestamp) {
                const {unregisteredBreakAfterMinutes} = customerSettings;
                const lastToDate = new Date(lastToTimestamp);
                const differenceMilliseconds = computeTimestamp.valueOf() - lastToDate.valueOf();
                const differenceMinutes = differenceMilliseconds / MINUTE_MILLISECONDS;
                // eslint-disable-next-line max-depth
                if (differenceMinutes >= 1 && differenceMinutes < unregisteredBreakAfterMinutes) {
                  // eslint-disable-next-line max-depth
                  if (differenceMinutes <= autoFillUnregisteredMinutesOnStart) {
                    handleAddMachineOperatorTimeCorrection(lastToTimestamp, timestamp, timerURL);
                  } else {
                    setAutoCorrectionDialog({
                      fromTimestamp: lastToTimestamp,
                      timerURL,
                      toTimestamp: timestamp,
                    });
                  }
                }
              }
            }
          }
        }
      }
    },
    [
      currentUserURL,
      customerSettings,
      dispatch,
      handleAddMachineOperatorTimeCorrection,
      task.date,
      task.time,
      task.url,
      taskLookup,
      timerChange,
      timerStartArray,
    ],
  );

  const handleTimerButton = useCallback((timerURL: TimerUrl): void => {
    setPerformOldTaskCheck(true);
    setTimerButtonClickedFor(timerURL);
  }, []);

  const {lastTaskTimerStart, oldTaskTimerStart, oldTimerTimerStart, requestedActiveTimer} =
    useMemo((): {
      lastTaskTimerStart: TimerStart | null;
      oldTaskTimerStart: TimerStart | null;
      oldTimerTimerStart: TimerStart | null;
      requestedActiveTimer: string | null;
    } => {
      if (timerButtonClickedFor) {
        const timerURL = timerButtonClickedFor;
        const taskURL = task.url;
        const lastUserTimerStart = _.last(currentUserSortedTimerStartArray);
        const foundLastTaskTimerStart = _.findLast(
          currentUserSortedTimerStartArray,
          (timerStart) => timerStart.task === taskURL,
        );
        if (
          lastUserTimerStart &&
          lastUserTimerStart !== foundLastTaskTimerStart &&
          lastUserTimerStart.timer &&
          !customerSettings.concurrentTasksAllowed
        ) {
          // possibly conflict with other task...
          const lastTask = taskLookup(lastUserTimerStart.task);
          if (
            !concurrencyAllowedForTask(task, workTypeLookup, customerSettings) ||
            (lastTask && !concurrencyAllowedForTask(lastTask, workTypeLookup, customerSettings))
          ) {
            // Show "Stop other task?"-dialog
            return {
              lastTaskTimerStart: foundLastTaskTimerStart || null,
              oldTaskTimerStart: null,
              oldTimerTimerStart: lastUserTimerStart,
              requestedActiveTimer: timerURL,
            };
          }
        }
        const isFirstTimerStartOnTask = !foundLastTaskTimerStart;
        if (isFirstTimerStartOnTask && task.date && task.date !== dateToString(new Date())) {
          // Show "Wrong date, are you sure?"-dialog
          return {
            lastTaskTimerStart: foundLastTaskTimerStart || null,
            oldTaskTimerStart: null,
            oldTimerTimerStart: null,
            requestedActiveTimer: timerURL,
          };
        }
        const {oldTaskWarningAgeMinutes} = customerSettings;
        if (isFirstTimerStartOnTask && oldTaskWarningAgeMinutes != null && performOldTaskCheck) {
          const openTaskURLSet = new Set<string>();
          taskArray.forEach((otherTask) => {
            if (!otherTask.completed && otherTask.machineOperator === currentUserURL) {
              openTaskURLSet.add(otherTask.url);
            }
          });
          if (openTaskURLSet.size) {
            const foundOldTaskTimerStart = currentUserSortedTimerStartArray.find((timerStart) =>
              openTaskURLSet.has(timerStart.task),
            );
            if (foundOldTaskTimerStart) {
              const oldTaskTimerStartAge =
                new Date().valueOf() - new Date(foundOldTaskTimerStart.deviceTimestamp).valueOf();
              if (oldTaskTimerStartAge > oldTaskWarningAgeMinutes * MINUTE_MILLISECONDS) {
                return {
                  lastTaskTimerStart: foundLastTaskTimerStart || null,
                  oldTaskTimerStart: foundOldTaskTimerStart,
                  oldTimerTimerStart: null,
                  requestedActiveTimer: timerURL,
                };
              }
            }
          }
        }
      }
      return {
        lastTaskTimerStart: null,
        oldTaskTimerStart: null,
        oldTimerTimerStart: null,
        requestedActiveTimer: null,
      };
    }, [
      currentUserSortedTimerStartArray,
      currentUserURL,
      customerSettings,
      performOldTaskCheck,
      task,
      taskArray,
      taskLookup,
      timerButtonClickedFor,
      workTypeLookup,
    ]);

  useEffect(() => {
    if (
      timerButtonClickedFor &&
      !requestedActiveTimer &&
      !oldTimerTimerStart &&
      !oldTaskTimerStart
    ) {
      setTimerButtonClickedFor(null);
      timerButtonValid(timerButtonClickedFor, lastTaskTimerStart || undefined);
    }
  }, [
    lastTaskTimerStart,
    oldTaskTimerStart,
    oldTimerTimerStart,
    requestedActiveTimer,
    timerButtonClickedFor,
    timerButtonValid,
  ]);

  const handleOldTaskOpenOk = useCallback((): void => {
    if (!requestedActiveTimer) {
      return;
    }
    setPerformOldTaskCheck(false);
  }, [requestedActiveTimer]);

  const handleOldTaskOpenCancel = useCallback((): void => {
    if (!oldTaskTimerStart) {
      return;
    }
    setTimerButtonClickedFor(null);
    window.setTimeout(() => {
      batch(() => {
        const taskID = urlToId(oldTaskTimerStart.task);
        dispatch(actions.go("/task/:id", {id: taskID}));
      });
    });
  }, [dispatch, oldTaskTimerStart]);

  const handleStopTimerDialogOk = useCallback((): void => {
    if (!requestedActiveTimer || !oldTimerTimerStart || !currentUserURL) {
      return;
    }
    const stopTaskURL = oldTimerTimerStart.task;
    saveTimerStart(
      boundCreate,
      null,
      stopTaskURL,
      currentUserURL,
      null,
      customerSettings.geolocation.registerPositionOnTimerClick &&
        currentUserURL === task.machineOperator
        ? boundRegisterTimerStartPosition
        : undefined,
    );
  }, [
    boundCreate,
    boundRegisterTimerStartPosition,
    currentUserURL,
    customerSettings.geolocation.registerPositionOnTimerClick,
    oldTimerTimerStart,
    requestedActiveTimer,
    task.machineOperator,
  ]);

  const handleStopTimerDialogCancel = useCallback((): void => {
    setTimerButtonClickedFor(null);
  }, []);

  const handleOtherDateDialogOk = useCallback((): void => {
    if (!requestedActiveTimer) {
      return;
    }
    const computeTimestamp = new Date();
    const nowDate = dateToString(computeTimestamp);
    dispatch(actions.update(task.url, [{member: "date", value: nowDate}]));
  }, [dispatch, requestedActiveTimer, task.url]);

  const handleOtherDateDialogCancel = useCallback((): void => {
    setTimerButtonClickedFor(null);
  }, []);

  const handleRequestAddMachineOperatorTimeCorrection = useCallback((): void => {
    setMachineOperatorTimeCorrectionDialogOpen(true);
  }, []);

  const handleRequestAddManagerTimeCorrection = useCallback((): void => {
    setManagerTimeCorrectionDialogOpen(true);
  }, []);

  const handleCorrectionDialogCancel = useCallback((): void => {
    setMachineOperatorTimeCorrectionDialogOpen(false);
    setManagerTimeCorrectionDialogOpen(false);
  }, []);

  const handleAddManagerTimeCorrection = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null): void => {
      addTimeCorrection(fromTimestamp, toTimestamp, timer, true);
    },
    [addTimeCorrection],
  );

  const handleCorrectionDialogOk = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null): void => {
      handleCorrectionDialogCancel();
      if (machineOperatorTimeCorrectionDialogOpen) {
        handleAddMachineOperatorTimeCorrection(fromTimestamp, toTimestamp, timer);
      }
      if (managerTimeCorrectionDialogOpen) {
        handleAddManagerTimeCorrection(fromTimestamp, toTimestamp, timer);
      }
    },
    [
      handleAddMachineOperatorTimeCorrection,
      handleAddManagerTimeCorrection,
      handleCorrectionDialogCancel,
      machineOperatorTimeCorrectionDialogOpen,
      managerTimeCorrectionDialogOpen,
    ],
  );

  const handleTimelineIntervalClick = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: Timer | null): void => {
      setEditIntervalEnd(toTimestamp);
      setEditIntervalStart(fromTimestamp);
      setEditIntervalTimer(timer);
    },
    [],
  );

  const handleTimelineEditIntervalDialogCancel = useCallback((): void => {
    setEditIntervalEnd(null);
    setEditIntervalStart(null);
    setEditIntervalTimer(null);
  }, []);

  const handleTimelineEditIntervalDialogOk = useCallback(
    (fromTimestamp: string, toTimestamp: string, timerURL: TimerUrl | null): void => {
      if (!editIntervalEnd || !editIntervalStart) {
        return;
      }
      setEditIntervalEnd(null);
      setEditIntervalStart(null);
      setEditIntervalTimer(null);
      if (!task) {
        return;
      }
      const completed = !!(task ? task.completed : true);
      const validated = !!(task ? task.validatedAndRecorded : false);
      const role = currentRole;
      const userIsOnlyMachineOperator = role && !role.manager;
      const userIsOther = !task || task.machineOperator !== currentUserURL;
      let managerCorrections;
      if (!validated && !userIsOnlyMachineOperator) {
        // perform manager time corrections
        managerCorrections = true;
      } else if (!completed && !userIsOther) {
        // perform machine operator time corrections
        managerCorrections = false;
      } else {
        // no edit-permission...
        return;
      }
      const editIntervalTimerURL = editIntervalTimer ? editIntervalTimer.url : null;
      const corrections: [string, string, TimerUrl | null][] = [];

      if (timerURL === editIntervalTimer) {
        if (fromTimestamp < editIntervalStart) {
          corrections.push([fromTimestamp, editIntervalStart, editIntervalTimerURL]);
        }
        if (toTimestamp > editIntervalEnd) {
          corrections.push([editIntervalEnd, toTimestamp, editIntervalTimerURL]);
        }
      } else {
        corrections.push([fromTimestamp, toTimestamp, timerURL]);
      }
      if (fromTimestamp > editIntervalStart) {
        const previousInterval = _.findLast(intervals, (i) => i.toTimestamp <= editIntervalStart);
        let timer: TimerUrl | null = null;
        if (previousInterval) {
          const distance =
            new Date(editIntervalStart).valueOf() -
            new Date(previousInterval.toTimestamp).valueOf();
          // if there's a minute or more of unregistered time in between, then the correction should be to unregistered
          if (distance <= MINUTE_MILLISECONDS) {
            ({timer} = previousInterval);
          }
        }
        corrections.push([editIntervalStart, fromTimestamp, timer]);
      }
      if (toTimestamp < editIntervalEnd) {
        const nextInterval = intervals.find((i) => i.fromTimestamp >= editIntervalEnd);
        let timer = null;
        if (nextInterval) {
          const distance =
            new Date(nextInterval.fromTimestamp).valueOf() - new Date(editIntervalEnd).valueOf();
          // if there's a minute or more of unregistered time in between, then the correction should be to unregistered
          if (distance <= MINUTE_MILLISECONDS) {
            ({timer} = nextInterval);
          }
        }
        corrections.push([toTimestamp, editIntervalEnd, timer]);
      }
      addTimeCorrectionArray(corrections, managerCorrections);
    },
    [
      addTimeCorrectionArray,
      currentRole,
      currentUserURL,
      editIntervalEnd,
      editIntervalStart,
      editIntervalTimer,
      intervals,
      task,
    ],
  );

  const handleAutoCorrectionOk = useCallback((): void => {
    if (!autoCorrectionDialog) {
      return;
    }
    const {fromTimestamp, timerURL, toTimestamp} = autoCorrectionDialog;
    setAutoCorrectionDialog(null);
    handleAddMachineOperatorTimeCorrection(fromTimestamp, toTimestamp, timerURL);
  }, [autoCorrectionDialog, handleAddMachineOperatorTimeCorrection]);

  const handleAutoCorrectionCancel = useCallback((): void => {
    setAutoCorrectionDialog(null);
  }, []);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      dispatch(actions.putQueryKey("tab", value || ""));
    },
    [dispatch],
  );

  const handleConvertToCustomerTask = useCallback((): void => {
    if (taskCreationWizardControl.current) {
      const userURL = task.machineOperator;
      const user = userURL ? userLookup(userURL) : null;
      const userProfile = userURL ? userUserProfileLookup(userURL) : null;
      const employeeGroupURL = userProfile && userProfile.employeeGroup;
      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
      const defaultDepartment =
        (user && computeDepartment(userProfile || null, employeeGroup || null, customerSettings)) ||
        null;
      taskCreationWizardControl.current.start({
        defaultDepartment,
        skipMachineChoice: true,
      });
    }
  }, [
    customerSettings,
    employeeGroupLookup,
    task.machineOperator,
    userLookup,
    userUserProfileLookup,
  ]);

  const handleCustomerTaskCreationWizardOk = useCallback(
    (data: {
      customer: CustomerUrl;
      department: string | null;
      fields: readonly LocationUrl[];
      machines: readonly {
        readonly machine: MachineUrl;
        readonly priceGroup: PriceGroupUrl | null;
      }[];
      priceGroup: PriceGroupUrl | null;
      project: ProjectUrl | null;
      workPlace: LocationUrl | null;
      workType: WorkTypeUrl | null;
    }): void => {
      const {customer, department, fields, machines, priceGroup, project, workPlace, workType} =
        data;
      console.assert(!machines.length, "no machines should be selected on skipMachineChoice");
      const fielduseSet = fields
        .map((fieldURL) => locationLookup(fieldURL))
        .filter(notUndefined)
        .map(
          (field): FieldUse => ({
            fieldAreaHa: field.fieldAreaHa,
            fieldCrop: field.fieldCrop,
            geojson: field.geojson,
            notes: "",
            relatedField: field.url,
          }),
        );
      const breakTimer = getBreakTimer(timerArray) as Timer;
      if (!currentUserURL) {
        return;
      }
      const workPlaceInstance = (workPlace && locationLookup(workPlace)) || undefined;
      const address = customerAddress(workPlaceInstance);
      const newOrder: Writable<Order> = createOrder({
        address,
        created: new Date().toISOString(),
        createdBy: currentUserURL,
        customer,
        date: task.date,
        durationDays: 1,
        relatedWorkplace: workPlace,
      });
      if (
        customerSettings.enableOrderReferenceNumber &&
        customerSettings.autoFillReferenceNumberWithCustomerAccount
      ) {
        const customerInstance = customerLookup(customer);
        const referenceNumber = (customerInstance && customerInstance.c5_account) || "";
        newOrder.referenceNumber = referenceNumber;
      }
      dispatch(actions.create(newOrder));
      dispatch(
        actions.update(task.url, [
          {member: "department", value: department || ""},
          {member: "fielduseSet", value: fielduseSet},
          {
            member: "machineOperatorTimeCorrectionSet",
            value: convertToExternalTime(
              genericPrimaryTimer.url,
              breakTimer.url,
              task.machineOperatorTimeCorrectionSet,
            ),
          },
          {
            member: "managerTimeCorrectionSet",
            value: convertToExternalTime(
              genericPrimaryTimer.url,
              breakTimer.url,
              task.managerTimeCorrectionSet,
            ),
          },
          {member: "order", value: newOrder.url},
          {member: "priceGroup", value: priceGroup},
          {member: "project", value: project},
          {member: "relatedWorkplace", value: workPlace},
          {member: "workType", value: workType},
        ]),
      );
      timerStartArray.forEach((timerStart) => {
        if (
          timerStart.task === task.url &&
          timerStart.timer &&
          timerStart.timer !== breakTimer.url &&
          timerStart.timer !== genericPrimaryTimer.url
        ) {
          dispatch(
            actions.update(timerStart.url, [{member: "timer", value: genericPrimaryTimer.url}]),
          );
        }
      });
    },
    [
      currentUserURL,
      customerLookup,
      customerSettings.autoFillReferenceNumberWithCustomerAccount,
      customerSettings.enableOrderReferenceNumber,
      dispatch,
      genericPrimaryTimer.url,
      locationLookup,
      task.date,
      task.machineOperatorTimeCorrectionSet,
      task.managerTimeCorrectionSet,
      task.url,
      timerArray,
      timerStartArray,
    ],
  );

  const taskCreationWizardControl = useRef<{
    start: (options?: {
      customer?: string;
      defaultDepartment?: string | null;
      skipMachineChoice?: boolean;
    }) => void;
  } | null>(null);

  const handlePhotoDisplay = useCallback((taskPhoto: TaskPhoto | UserPhoto): void => {
    setDisplayedImage(taskPhoto);
  }, []);

  const handleDisplayImageRequestClose = useCallback((): void => {
    window.setTimeout(() => {
      setDisplayedImage(null);
    }, 0);
  }, []);

  const handleRequestFileDelete = useCallback((taskFileURL: string): void => {
    setDeletingFile(taskFileURL);
  }, []);

  const handleFileDeleteDialogCancel = useCallback((): void => {
    setDeletingFile(null);
  }, []);

  const handleFileDeleteDialogOk = useCallback((): void => {
    if (deletingFile) {
      dispatch(actions.remove(deletingFile));
      setDeletingFile(null);
    }
  }, [deletingFile, dispatch]);

  const handleRequestPhotoDelete = useCallback((taskPhotoURL: string): void => {
    setDeletingPhoto(taskPhotoURL);
  }, []);

  const handlePhotoDeleteDialogCancel = useCallback((): void => {
    setDeletingPhoto(null);
  }, []);

  const handlePhotoDeleteDialogOk = useCallback((): void => {
    if (deletingPhoto) {
      dispatch(actions.remove(deletingPhoto));
      setDeletingPhoto(null);
    }
  }, [deletingPhoto, dispatch]);

  let statusBarText;
  let statusBarIcon;
  const role = currentRole;
  const userIsOnlyMachineOperator = !!role && !role.manager;
  const userIsOther = !task || task.machineOperator !== currentUserURL;
  const userIsOtherMachineOperator = userIsOther && userIsOnlyMachineOperator;
  const userIsManager = !!role && role.manager;
  const userIsJobber = !!role && role.jobber;
  const taskAssignedToUser = !!task && task.machineOperator === currentUserURL;

  const completed = !!(task ? task.completed : true);
  const createdBy = task ? task.createdBy : null;
  const createdByUserProfile = createdBy ? userUserProfileLookup(createdBy) : null;
  const userInitials = createdByUserProfile ? createdByUserProfile.alias : null;
  const validated = !!(task ? task.validatedAndRecorded || task.reportApproved : false);

  const machineList = ((task && task.machineuseSet) || [])
    .map((machineUse) => {
      const machineURL = machineUse.machine;
      return machineLookup(machineURL);
    })
    .filter(notUndefined);
  let primaryWorkType;
  const workTypeURL = task ? task.workType : null;
  if (workTypeURL) {
    primaryWorkType = workTypeLookup(workTypeURL);
  }
  let workTypeString = null;
  if (primaryWorkType) {
    workTypeString = getWorkTypeString(primaryWorkType);
  } else if (customerSettings.enableInternalTaskDepartmentField && task.department === "E") {
    workTypeString = intl.formatMessage(messages.contractorWork);
  } else {
    workTypeString = getMachinesWorkTypeString(machineList);
  }

  const secondaryTimersList = getTaskSecondaryTimerList(task, customerSettings, {
    machineLookup,
    priceGroupLookup,
    timerArray,
    workTypeLookup,
  });
  const secondaryTimers = new Set(secondaryTimersList);

  const hasActivity = !!intervals.length;
  const canTakeTask =
    task &&
    !hasActivity &&
    task.machineOperator !== currentUserURL &&
    (!task.machineOperator || customerSettings.allowTakingPendingTasksFromOthers);

  const disableDelete =
    validated ||
    (userIsOnlyMachineOperator &&
      (userIsOther ||
        completed ||
        hasActivity ||
        (task.createdBy === currentUserURL
          ? userIsJobber && !customerSettings.jobberRoleCanCreateInternalTasks
          : userIsJobber || !customerSettings.machineOperatorCanDeleteAssignedTasks)));

  let computedStartTime;
  let computedEndTime;
  if (computedIntervals.length) {
    computedStartTime = computedIntervals[0].fromTimestamp;
    computedEndTime =
      computedIntervals[computedIntervals.length - 1].toTimestamp || now.toISOString();
  }
  let finalStartTime;
  let finalEndTime;
  if (intervals.length) {
    finalStartTime = intervals[0].fromTimestamp;
    finalEndTime = intervals[intervals.length - 1].toTimestamp || now.toISOString();
  }

  const deletableMachineURLSet = new Set(machineList.map((machine) => machine.url));

  const intervalsWithTimers = intervals
    .map((interval) => {
      const intervalTimerURL = interval.timer;
      const timer = intervalTimerURL && timerLookup(intervalTimerURL);
      if (timer) {
        return {...interval, timer};
      } else {
        return undefined;
      }
    })
    .filter(notUndefined);

  const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;

  const hidePrimaryTimer = !!(
    workType &&
    customerSettings.hidePrimaryTimeButtonForInternalWorktypes.includes(workType.identifier)
  );

  const genericPrimaryTimerLabel = getTaskGenericPrimaryTimerLabel(
    task,
    workTypeLookup,
    priceGroupLookup,
    customerSettings,
  );

  const timeTabContent = (
    <TimeCard
      activeTimer={activeTimer}
      completed={completed}
      customerSettings={customerSettings}
      finalEndTime={finalEndTime}
      finalStartTime={finalStartTime}
      genericPrimaryTimer={genericPrimaryTimer}
      genericPrimaryTimerLabel={genericPrimaryTimerLabel}
      hidePrimaryButton={hidePrimaryTimer}
      intervals={intervalsWithTimers}
      now={now.toISOString()}
      secondaryTimers={secondaryTimers}
      task={task}
      timerMinutes={timerMinutesMap}
      update={boundUpdate}
      userIsOnlyMachineOperator={userIsOnlyMachineOperator}
      userIsOther={userIsOther}
      userIsOtherMachineOperator={userIsOtherMachineOperator}
      validated={validated}
      onRequestAddMachineOperatorTimeCorrection={
        completed || validated || userIsOther
          ? undefined
          : handleRequestAddMachineOperatorTimeCorrection
      }
      onRequestAddManagerTimeCorrection={
        validated || userIsOnlyMachineOperator ? undefined : handleRequestAddManagerTimeCorrection
      }
      onTimelineIntervalClick={
        editTimeCorrectionsAllowed({
          taskCompleted: completed,
          taskValidated: validated,
          userIsManager,
          userIsOther,
        })
          ? handleTimelineIntervalClick
          : undefined
      }
      onTimerButton={handleTimerButton}
    />
  );
  const date = task ? task.date : null;
  const hoursColonMinutesStringLength = 5;
  const time = task ? (task.time || "").substr(0, hoursColonMinutesStringLength) : null;
  const machineOperatorURL = task.machineOperator;
  const machineOperatorProfile = machineOperatorURL && userUserProfileLookup(machineOperatorURL);
  const responsibleInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
  let completedText = null;
  if (completed) {
    completedText = (
      <div>
        <h2>Opgaven er markeret fuldført og kan ikke rettes</h2>
      </div>
    );
  }
  const infoTabContent = (
    <div>
      <InternalInfoCard
        completedText={completedText}
        customerSettings={customerSettings}
        date={date || undefined}
        responsibleInitials={responsibleInitials || undefined}
        task={task}
        taskAssignedToUser={taskAssignedToUser}
        time={time || undefined}
        userInitials={userInitials || undefined}
        userIsJobber={userIsJobber}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        onGoToEdit={handleGoToInternalTaskEdit}
      />
    </div>
  );

  const machineUseSet = (task && task.machineuseSet) || [];
  const taskMachines = machineUseSet.map((machineUse) => machineUse.machine);

  const workTypeIdentifier = workType ? workType.identifier : "";
  const enableChecklist =
    workTypeURL &&
    customerSettings.enableWorkshopChecklistsFor.length &&
    customerSettings.enableWorkshopChecklistsFor.includes(workTypeIdentifier);

  const workshopChecklists = enableChecklist
    ? _.sortBy(
        workshopChecklistArray.filter((checklist) => {
          if (!checklist.draft && checklist.active) {
            const checklistMachines = checklist.machines;
            return taskMachines.some((machine) => checklistMachines.includes(machine));
          } else {
            return false;
          }
        }),
        (checklist) => checklist.name,
      )
    : [];

  let mainContent;
  if (currentTab === "checklist") {
    mainContent = (
      <WorkshopChecklist
        task={task}
        unitLookup={unitLookup}
        update={boundUpdate}
        workshopChecklistArray={workshopChecklistArray}
        workshopChecklistItemArray={workshopChecklistItemArray}
      />
    );
  } else if (currentTab === "photos") {
    mainContent = (
      <PhotosTab
        completed={completed}
        task={task}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
        onPhotoDisplay={handlePhotoDisplay}
        onRequestFileDelete={handleRequestFileDelete}
        onRequestPhotoDelete={handleRequestPhotoDelete}
      />
    );
  } else if (currentTab === "geolocation" && !userIsOnlyMachineOperator) {
    mainContent = (
      <div style={{padding: "0.5em 0"}}>
        <GeolocationTab taskURL={task.url} />
      </div>
    );
  } else {
    mainContent = (
      <div>
        <Grid>
          <Cell palm="12/12">{timeTabContent}</Cell>
          <Cell palm="12/12">{infoTabContent}</Cell>
        </Grid>
        {!workType?.disallowMachineUse ? (
          <MachinesCard
            allowMachineChange
            completed={completed}
            currentDepartment={task && task.department}
            customerSettings={customerSettings}
            deletableMachineURLSet={deletableMachineURLSet}
            enableTaskDepartmentField={customerSettings.enableInternalTaskDepartmentField}
            machineList={machineList}
            machineuseSet={machineUseSet}
            priceGroupLookup={priceGroupLookup}
            userIsOnlyMachineOperator={userIsOnlyMachineOperator}
            userIsOtherMachineOperator={userIsOtherMachineOperator}
            validated={validated}
            workType={workType}
            onDepartmentSelectButton={handleDepartmentSelectButton}
            onMachineSelectButton={handleMachineSelectButton}
            onRemoveMachine={handleRemoveMachine}
          />
        ) : null}
      </div>
    );
  }

  let tabBar;

  if (
    enableChecklist ||
    customerSettings.internalTaskPhotos ||
    (customerSettings.enableGPSList && userIsManager)
  ) {
    tabBar = (
      <Tabs
        value={currentTab}
        variant={bowser.mobile ? "fullWidth" : "standard"}
        onChange={handleTabChange}
      >
        <Tab key="time" label={intl.formatMessage(messages.time)} value="time" />
        {enableChecklist ? (
          <Tab key="checklist" label={intl.formatMessage(messages.checklist)} value="checklist" />
        ) : null}
        {customerSettings.internalTaskPhotos ? (
          <Tab key="photos" label={intl.formatMessage(messages.photo)} value="photos" />
        ) : null}
        {customerSettings.enableGPSList && userIsManager ? (
          <Tab
            key="geolocation"
            label={intl.formatMessage(messages.geolocation)}
            value="geolocation"
          />
        ) : null}
      </Tabs>
    );
  }
  let vinSubheader;
  if (primaryWorkType && customerSettings.workshopVehicleIdentificationNumber) {
    if (customerSettings.workshopWorkTypes.includes(primaryWorkType.identifier)) {
      const vinValues = machineList
        .map((m) => m.vehicleIdentificationNumber)
        .filter((n) => n)
        .join(", ");
      if (vinValues) {
        vinSubheader = (
          <h3>
            <FormattedMessage
              defaultMessage="Stelnummer:"
              id="internal-task.label.vehicle-identification-number"
            />
            &nbsp;
            {vinValues}
          </h3>
        );
      }
    }
  }
  const reportApproved = !!(task ? task.reportApproved : true);

  const finalIntervals = intervals.map((interval) => ({
    ...interval,
    timer: (interval.timer && timerLookup(interval.timer)) || null,
  }));
  const breakTimer = getBreakTimer(timerArray);
  const dialogs = (
    <>
      <ConnectedMachineDialog
        open={machineDialogOpen}
        workType={task && task.workType ? workTypeLookup(task.workType) : undefined}
        onCancel={handleMachineDialogCancel}
        onOk={handleMachineDialogOk}
      />
      <TaskWarningDialogs
        oldTaskTimerStart={!!oldTaskTimerStart}
        oldTimerTimerStart={!!oldTimerTimerStart}
        requestedActiveTimer={!!requestedActiveTimer}
        task={task}
        onActiveRouteWarningDialogCancel={handleStopTimerDialogCancel}
        onOldTaskDialogCancel={handleOldTaskOpenCancel}
        onOldTaskDialogOk={handleOldTaskOpenOk}
        onOtherDateDialogCancel={handleOtherDateDialogCancel}
        onOtherDateDialogOk={handleOtherDateDialogOk}
        onStopTimerDialogCancel={handleStopTimerDialogCancel}
        onStopTimerDialogOk={handleStopTimerDialogOk}
      />
      <NotesDialog
        dismissed={currentUserProfile?.showNotePopupOnTask === false || notesDialogDismissed}
        task={task}
        onRequestClose={handleNotesDialogClose}
      />
      <CopiedDialog
        machineList={machineList}
        open={copiedDialogOpen}
        workType={primaryWorkType}
        onRequestClose={handleCopiedDialogClose}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
      >
        {userIsOnlyMachineOperator ? (
          <FormattedMessage defaultMessage="Slet opgave?" id="task-instance.label.do-delete-task" />
        ) : (
          <TaskInformation
            breakTimer={breakTimer}
            computedIntervals={computedIntervals}
            finalIntervals={finalIntervals}
            genericPrimaryTimer={genericPrimaryTimer}
            machineOperatorProfile={machineOperatorProfile || undefined}
            secondaryTimers={secondaryTimers}
            task={task}
            workType={workType}
          />
        )}
      </DeleteDialog>
      <EditTimeCorrectionDialog
        correctionDisabled={
          validated || userIsOtherMachineOperator || (completed && userIsOnlyMachineOperator)
        }
        fromTimestamp={editIntervalStart}
        genericPrimaryTimer={genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryTimer={hidePrimaryTimer}
        legalIntervals={legalIntervals}
        open={!!(editIntervalStart && editIntervalEnd)}
        secondaryTimers={secondaryTimers}
        timer={editIntervalTimer}
        toTimestamp={editIntervalEnd}
        onCancel={handleTimelineEditIntervalDialogCancel}
        onOk={handleTimelineEditIntervalDialogOk}
      />
      <AddTimeCorrectionDialog
        defaultDate={task.date}
        genericPrimaryTimer={genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryTimer={hidePrimaryTimer}
        legalIntervals={legalIntervals}
        open={managerTimeCorrectionDialogOpen || machineOperatorTimeCorrectionDialogOpen}
        secondaryTimers={secondaryTimers}
        onCancel={handleCorrectionDialogCancel}
        onOk={handleCorrectionDialogOk}
      />
      {customerSettings.askRegardingMissingBreakOnInternalTaskCompletion ? (
        <MissingBreakDialog
          open={missingBreakDialogOpen}
          onCancel={handleMissingBreakDialogCancel}
          onOk={handleMissingBreakDialogOk}
        />
      ) : null}
      <InternalCompletedDialog
        computedEndTime={computedEndTime}
        computedIntervals={computedIntervals}
        computedStartTime={computedStartTime}
        continuation={completedDialogContinuation}
        finalEndTime={finalEndTime}
        finalIntervals={finalIntervals}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        open={completedDialogOpen}
        primaryWorkType={primaryWorkType}
        secondaryTimers={secondaryTimers}
        task={task}
        workshopChecklistItemArray={workshopChecklistItemArray}
        workshopChecklists={workshopChecklists}
        onAction={handleCompletedValidatedDialogAction}
        onCancel={handleCompletedDialogCancel}
        onOk={handleCompletedDialogOk}
      />
      <InternalValidatedDialog
        computedEndTime={computedEndTime}
        computedIntervals={computedIntervals}
        computedStartTime={computedStartTime}
        continuation={completedDialogContinuation}
        finalEndTime={finalEndTime}
        finalIntervals={finalIntervals}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        open={validatedDialogOpen}
        primaryWorkType={primaryWorkType}
        secondaryTimers={secondaryTimers}
        task={task}
        onAction={handleCompletedValidatedDialogAction}
        onCancel={handleValidatedDialogCancel}
        onOk={handleValidatedDialogOk}
      />
      <AutoCorrectionDialog
        fromTimestamp={(autoCorrectionDialog && autoCorrectionDialog.fromTimestamp) || undefined}
        open={!!autoCorrectionDialog}
        toTimestamp={(autoCorrectionDialog && autoCorrectionDialog.toTimestamp) || undefined}
        onCancel={handleAutoCorrectionCancel}
        onOk={handleAutoCorrectionOk}
      />
      <ConnectedDepartmentDialog
        open={departmentDialogOpen}
        onCancel={handleDepartmentDialogCancel}
        onOk={handleDepartmentDialogOk}
      />
      <CustomerTaskCreationWrapper
        ref={taskCreationWizardControl}
        onCustomerTaskCreation={handleCustomerTaskCreationWizardOk}
      />
      <PhotoDisplayDialog
        instance={displayedImage || undefined}
        onRequestClose={handleDisplayImageRequestClose}
      />
      <DeleteDialog
        key="delete-photo-dialog"
        open={!!deletingPhoto}
        onCancel={handlePhotoDeleteDialogCancel}
        onOk={handlePhotoDeleteDialogOk}
      >
        <FormattedMessage
          defaultMessage="Slet foto?"
          id="damage-report-instance.label.do-delete-photo"
        />
      </DeleteDialog>
      <DeleteDialog
        key="delete-file-dialog"
        open={!!deletingFile}
        onCancel={handleFileDeleteDialogCancel}
        onOk={handleFileDeleteDialogOk}
      >
        <FormattedMessage
          defaultMessage="Slet fil?"
          id="damage-report-instance.label.do-delete-file"
        />
      </DeleteDialog>
      <MachineRemovalBlockedDialog
        key="machine-removal-blocked-dialog"
        blockedReason={machineRemovalBlockedReason}
        open={machineRemovalBlockedDialogOpen}
        onClose={handleMachineRemovalBlockedDialogClose}
      />
    </>
  );

  return (
    <PageLayout
      withPadding
      dialogs={dialogs}
      tabs={tabBar}
      toolbar={intl.formatMessage(messages.taskTitle)}
    >
      <div style={{margin: "1em 1em 0"}}>
        <h1>{workTypeString}</h1>
        {vinSubheader}
      </div>
      {mainContent}
      <ActionButtons
        canTakeTask={canTakeTask}
        completed={completed}
        customerSettings={customerSettings}
        disableDelete={disableDelete}
        includeCopy={customerSettings.includeTaskCopy}
        reportApproved={reportApproved}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
        onCompletedButton={handleCompletedButton}
        onConvertToCustomerTask={handleConvertToCustomerTask}
        onCopyButton={handleCopyButton}
        onDeleteButton={handleDeleteButton}
        onIncompleteButton={handleIncompleteButton}
        onSaveButton={handleSaveButton}
        onTakeButton={handleTakeButton}
        onValidatedButton={handleValidatedButton}
      />
      <StatusBar status={statusBarIcon} text={statusBarText} />
    </PageLayout>
  );
}
