import {Config} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  PathTemplate,
  actions,
  getCurrentRole,
  getCustomerSettings,
  getPathName,
} from "@co-frontend-libs/redux";
import {PathParameters} from "@co-frontend-libs/routing-sync-history";
import {List, Paper, makeStyles} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {integrationCustomerSettings} from "shared-integration-customer-settings";
import {MenuEntry, MenuItem} from "../types";
import {isMenuGrouping, isMenuItem} from "../utils";
import {LeaveSettingsListItem} from "./leave-settings-list-item";
import {SettingsMenuGroup} from "./settings-menu-group";
import {SettingsMenuItem} from "./settings-menu-item";
import {menuItems} from "./settings-menu-item-data";

function computeMenuContent(intl: IntlShape, settings: Config, role: Role | null): MenuEntry[] {
  const entries = menuItems(intl, settings);

  const {csvImportExportSettings} = integrationCustomerSettings(settings);

  const csvImportEnabled = csvImportExportSettings.canImport(role);
  const csvExportEnabled = csvImportExportSettings.canExport(role);
  return [
    {
      entries: [
        settings.enableWorkTypePriceStructureOverviewSettingsPage ||
        settings.enableMachinePriceStructureOverviewSettingsPage
          ? entries.overview
          : undefined,
        entries.workTypes,
        settings.enableStandardMachinesSettingsPage ? entries.favoriteMachines : undefined,
        settings.enableStandardProductsSettingsPage ? entries.favoriteProducts : undefined,
      ].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Områder"}),
    },
    {
      entries: [
        settings.showProductList ? entries.products : undefined,
        settings.showProductList ? entries.productGroups : undefined,
      ].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Varer"}),
    },
    {
      entries: [entries.machines].filter(notUndefined),
      headerText:
        settings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({defaultMessage: "Maskiner"})
          : intl.formatMessage({defaultMessage: "Køretøjer"}),
    },
    {
      entries: [
        settings.enableWorkshopChecklistsFor.length > 0 ? entries.workshopChecklists : undefined,
      ].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Værksted"}),
    },
    {
      entries: [
        entries.locations,
        entries.locationTypes,
        settings.routesEnabled ? entries.routes : undefined,
      ].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Steder"}),
    },
    {
      entries: [settings.enableFieldsSettingsPage ? entries.fields : undefined].filter(
        notUndefined,
      ),
      headerText: intl.formatMessage({defaultMessage: "Marker"}),
    },
    {
      entries: [settings.enableFieldsSettingsPage ? entries.crops : undefined].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Afgrøder"}),
    },
    {
      entries: [settings.enableUnitSettingsPage ? entries.units : undefined].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Enheder"}),
    },
    {
      entries: [
        settings.enableProjects && settings.displayProjectsPageMenu === "SETTINGS"
          ? entries.projects
          : undefined,
      ].filter(notUndefined),
      headerText:
        settings.projectLabelVariant === "PROJECT"
          ? intl.formatMessage({defaultMessage: "Projekter"})
          : intl.formatMessage({defaultMessage: "Sager"}),
    },
    {
      entries: [settings.fuelSurcharge ? entries.fuelSurcharge : undefined].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Afgift"}),
    },
    {
      entries: [
        settings.anniversariesEnabled ? entries.anniversary : undefined,
        settings.dinnerBookings ? entries.foodBooking : undefined,
      ].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Medarbejdere"}),
    },
    {
      entries: [settings.enableMachineAnalysis ? entries.machineAnalysis : undefined].filter(
        notUndefined,
      ),
      headerText: intl.formatMessage({defaultMessage: "Rapporter"}),
    },
    {
      entries: [
        csvImportEnabled && csvExportEnabled ? entries.csvImportAndExport : undefined,
      ].filter(notUndefined),
      headerText: intl.formatMessage({defaultMessage: "Import og eksport"}),
    },
    {
      entries: [csvImportEnabled && !csvExportEnabled ? entries.csvImport : undefined].filter(
        notUndefined,
      ),
      headerText: intl.formatMessage({defaultMessage: "Import"}),
    },
    {
      entries: [!csvImportEnabled && csvExportEnabled ? entries.csvExport : undefined].filter(
        notUndefined,
      ),
      headerText: intl.formatMessage({defaultMessage: "Eksport"}),
    },
  ].filter(notUndefined);
}

interface SettingsDrawerContentProps {
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflow: "auto",
    width: "100%",
  },
}));

export const SettingsDrawerContent = React.memo(function SettingsDrawerContent(
  props: SettingsDrawerContentProps,
): JSX.Element {
  const {onClose} = props;

  const classes = useStyles();

  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const currentPathname = useSelector(getPathName);
  const currentRole = useSelector(getCurrentRole);

  const dispatch = useDispatch();

  const settingsMenuData = useMemo(
    () => computeMenuContent(intl, customerSettings, currentRole),
    [currentRole, customerSettings, intl],
  );

  const [selectedRoute, setSelectedRoute] = useState<PathTemplate | null>(() => {
    const allMenuEntries: MenuItem[] = settingsMenuData.flatMap((entry: MenuEntry) => {
      if (isMenuItem(entry)) {
        return entry;
      } else {
        return entry.entries.filter(isMenuItem);
      }
    });

    const selectedMenu = (item: MenuItem): boolean => {
      return currentPathname === item.route || currentPathname.startsWith(`${item.route}/`);
    };

    return allMenuEntries.find(selectedMenu)?.route ?? null;
  });

  const handleChange = useCallback(
    (route: PathTemplate, params?: PathParameters): void => {
      onClose();
      if (currentPathname !== route) {
        setSelectedRoute(route);
        dispatch(actions.go(route, params));
      }
    },
    [currentPathname, dispatch, onClose],
  );

  return (
    <>
      <Paper square elevation={4} style={{zIndex: 10}}>
        <LeaveSettingsListItem onRequestChange={handleChange} />
      </Paper>
      <List className={classes.root} data-testid="settings-menu" subheader={<li />}>
        {settingsMenuData.map((entry, index) => {
          if (isMenuItem(entry)) {
            return (
              <SettingsMenuItem
                key={index}
                menuItem={entry}
                selectedRoute={selectedRoute}
                onRequestChange={handleChange}
              />
            );
          } else if (isMenuGrouping(entry)) {
            return (
              <SettingsMenuGroup
                key={index}
                menuGroup={entry}
                selectedRoute={selectedRoute}
                onRequestChange={handleChange}
              />
            );
          } else {
            return null;
          }
        })}
      </List>
    </>
  );
});
