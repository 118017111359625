import {
  CustomerUrl,
  KrPerLiterFuelSurchargeSpecificationUrl,
  MachineUrl,
  Patch,
  PriceItemUse,
  PricePercentFuelSurchargeSpecificationUrl,
  ProjectUrl,
  Task,
  TaskUrl,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  getFuelSurchargeKrPerLiterInvoiceData,
  getFuelSurchargePricePercentInvoiceData,
  getMachineString,
  getProjectString,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {
  dateAndTimeToTimestamp,
  dateToString,
  formatDateTime,
  groupByToMap,
  makeContainsPredicate,
} from "@co-common-libs/utils";
import {DateField, ThrottledTextField} from "@co-frontend-libs/components";
import {
  ConnectedMultipleCustomersDialog,
  ConnectedMultipleExternalWorkTypesDialog,
  ConnectedMultipleMachinesDialog,
  ConnectedMultipleProjectsDialog,
} from "@co-frontend-libs/connected-components";
import {Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  getCustomerLookup,
  getCustomerSettings,
  getKrPerLiterDefaultFuelSurchargeUseArray,
  getKrPerLiterFuelSurchargeBasisArray,
  getKrPerLiterFuelSurchargeSpecificationEntryArray,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterMachineFuelSurchargeUseArray,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getPricePercentDefaultFuelSurchargeUseArray,
  getPricePercentFuelSurchargeBasisArray,
  getPricePercentFuelSurchargeSpecificationEntryArray,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentMachineFuelSurchargeUseArray,
  getPricePercentWorkTypeFuelSurchargeUseArray,
  getProductGroupLookup,
  getProductLookup,
  getProjectLookup,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskResultArray,
  getTaskPhotoArray,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {SetAction, useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  useTheme,
} from "@material-ui/core";
import {useQueryParameter} from "app-utils";
import {instanceURL} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useEconomicSyncTasks} from "../hooks/use-economic-sync-tasks";
import {useEconomicTasksErrors} from "../hooks/use-economic-tasks-errors";
import {EconomicTaskIssuesCard} from "../issues/economic-task-issues-card";
import {KrPerLiterFuelSurchargeIssuesCard} from "../issues/kr-per-liter-fuel-surcharge-issues-card";
import {PricePercentFuelSurchargeIssuesCard} from "../issues/price-percent-fuel-surcharge-issues-card";
import {EconomicDownloadErrorBlock} from "./economic-download-error-block";
import {EconomicTaskTable, TaskTableDataType, buildRowData} from "./economic-task-table";
import {EconomicUploadStatus} from "./economic-upload-status";

export const ENTRIES_BEFORE_ELLIPSIS = 3;

const ClearFilterButton = React.memo(function ClearFilterButton({
  disabled,
  queryKey,
}: {
  disabled: boolean;
  queryKey: string;
}): JSX.Element {
  const dispatch = useDispatch();
  const handleRemoveFilter = useCallback(() => {
    dispatch(actions.deleteQueryKey(queryKey));
  }, [dispatch, queryKey]);
  return (
    <IconButton disabled={disabled} onClick={handleRemoveFilter}>
      <CloseIcon />
    </IconButton>
  );
});

interface InvoicingTabProps {
  dispatchToRecord: (value: SetAction<TaskUrl>) => void;
  downloadError: Error | null;
  downloading: boolean;
  onDownloadFromEconomic: () => void;
  onUploadToEconomic: () => void;
  query: Query | null;
  toRecord: ReadonlySet<TaskUrl>;
  uploadDoneTimestamp: string | null;
  uploadError: Error | null;
  uploading: boolean;
  uploadProgress: number;
  uploadStartTimestamp: string | null;
}

export const EconomicInvoicingTab = React.memo(function EconomicInvoicingTab(
  props: InvoicingTabProps,
): JSX.Element {
  const {
    dispatchToRecord,
    downloadError,
    downloading,
    onDownloadFromEconomic,
    onUploadToEconomic,
    query,
    toRecord,
    uploadDoneTimestamp,
    uploadError,
    uploading,
    uploadProgress,
    uploadStartTimestamp,
  } = props;

  const intl = useIntl();
  const theme = useTheme();

  const [customersDialogOpen, setCustomersDialogOpen] = useState(false);
  const setCustomersDialogOpenTrue = useCallWithTrue(setCustomersDialogOpen);
  const setCustomersDialogOpenFalse = useCallWithFalse(setCustomersDialogOpen);

  const [workTypesDialogOpen, setWorkTypesDialogOpen] = useState(false);
  const setWorkTypesDialogOpenTrue = useCallWithTrue(setWorkTypesDialogOpen);
  const setWorkTypesDialogOpenFalse = useCallWithFalse(setWorkTypesDialogOpen);

  const [machinesDialogOpen, setMachinesDialogOpen] = useState(false);
  const setMachinesDialogOpenTrue = useCallWithTrue(setMachinesDialogOpen);
  const setMachinesDialogOpenFalse = useCallWithFalse(setMachinesDialogOpen);

  const [projectsDialogOpen, setProjectsDialogOpen] = useState(false);
  const setProjectsDialogOpenTrue = useCallWithTrue(setProjectsDialogOpen);
  const setProjectsDialogOpenFalse = useCallWithFalse(setProjectsDialogOpen);

  const dispatch = useDispatch();

  const handleCustomersDialogOk = useCallback(
    (customers: ReadonlySet<CustomerUrl>) => {
      dispatch(actions.putQueryKey("customers", Array.from(customers).map(urlToId).join(",")));
      setCustomersDialogOpen(false);
    },
    [dispatch],
  );

  const handleWorkTypesDialogOk = useCallback(
    (workTypes: ReadonlySet<WorkTypeUrl>) => {
      dispatch(actions.putQueryKey("workTypes", Array.from(workTypes).map(urlToId).join(",")));
      setWorkTypesDialogOpen(false);
    },
    [dispatch],
  );

  const handleMachinesDialogOk = useCallback(
    (machines: ReadonlySet<MachineUrl>) => {
      dispatch(actions.putQueryKey("machines", Array.from(machines).map(urlToId).join(",")));
      setMachinesDialogOpen(false);
    },
    [dispatch],
  );

  const handleProjectsDialogOk = useCallback(
    (projects: ReadonlySet<ProjectUrl>) => {
      dispatch(actions.putQueryKey("projects", Array.from(projects).map(urlToId).join(",")));
      setProjectsDialogOpen(false);
    },
    [dispatch],
  );

  const handleReferenceNumberChange = useCallback(
    (value: string) => {
      dispatch(actions.putQueryKey("referenceNumber", value));
    },
    [dispatch],
  );

  const handleFromDateChange = useCallback(
    (value: string | null) => {
      dispatch(actions.putQueryKey("fromDate", value || ""));
    },
    [dispatch],
  );

  const handleToDateChange = useCallback(
    (value: string | null) => {
      dispatch(actions.putQueryKey("toDate", value || ""));
    },
    [dispatch],
  );

  const fromDate = useQueryParameter("fromDate");
  const toDate = useQueryParameter("toDate");
  const customers = useQueryParameter("customers");
  const machines = useQueryParameter("machines");
  const projects = useQueryParameter("projects");
  const referenceNumber = useQueryParameter("referenceNumber");
  const workTypes = useQueryParameter("workTypes");
  const searchString = useQueryParameter("q");

  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const projectLookup = useSelector(getProjectLookup);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );
  const pricePercentMachineFuelSurchargeUseArray = useSelector(
    getPricePercentMachineFuelSurchargeUseArray,
  );
  const pricePercentWorkTypeFuelSurchargeUseArray = useSelector(
    getPricePercentWorkTypeFuelSurchargeUseArray,
  );
  const pricePercentDefaultFuelSurchargeUseArray = useSelector(
    getPricePercentDefaultFuelSurchargeUseArray,
  );
  const pricePercentFuelSurchargeSpecificationEntryArray = useSelector(
    getPricePercentFuelSurchargeSpecificationEntryArray,
  );
  const pricePercentFuelSurchargeBasisArray = useSelector(getPricePercentFuelSurchargeBasisArray);
  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );
  const krPerLiterMachineFuelSurchargeUseArray = useSelector(
    getKrPerLiterMachineFuelSurchargeUseArray,
  );
  const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
    getKrPerLiterWorkTypeFuelSurchargeUseArray,
  );
  const krPerLiterDefaultFuelSurchargeUseArray = useSelector(
    getKrPerLiterDefaultFuelSurchargeUseArray,
  );
  const krPerLiterFuelSurchargeSpecificationEntryArray = useSelector(
    getKrPerLiterFuelSurchargeSpecificationEntryArray,
  );
  const krPerLiterFuelSurchargeBasisArray = useSelector(getKrPerLiterFuelSurchargeBasisArray);

  const reportApprovedOrValidatedTasks = useEconomicSyncTasks({
    includeReportApproved: true,
  });

  const {
    enableOrderReferenceNumber,
    enableProjects,
    enableTaskReferenceNumber,
    externalTaskCustomer,
    fuelSurcharge,
    machineLabelVariant,
    noExternalTaskWorkType,
    orderReferenceNumberLabel,
    projectLabelVariant,
    recordInternalTasks,
    taskReferenceNumberLabel,
  } = customerSettings;

  const {formatMessage} = useIntl();
  let customersCell;
  const customerArray = useMemo(
    () =>
      externalTaskCustomer && customers
        ? customers.split(",").map((id) => instanceURL("customer", id))
        : [],
    [customers, externalTaskCustomer],
  );
  const customersSet = useMemo(() => new Set(customerArray), [customerArray]);
  if (externalTaskCustomer) {
    customersCell = (
      <Grid item xs>
        <ConnectedMultipleCustomersDialog
          open={customersDialogOpen}
          selected={customersSet}
          onCancel={setCustomersDialogOpenFalse}
          onOk={handleCustomersDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setCustomersDialogOpenTrue}
        >
          {formatMessage({defaultMessage: "Vælg kunder"})}
        </Button>
        <ClearFilterButton disabled={!customerArray.length} queryKey="customers" />
        <div style={{whiteSpace: "pre-line"}}>
          {!customersSet.size
            ? formatMessage({defaultMessage: "Ingen filtrering"})
            : customerArray
                .map((customerURL) => customerLookup(customerURL)?.name)
                .sort()
                .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                .concat(customersSet.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                .join(",\n")}
        </div>
      </Grid>
    );
  }

  let workTypesCell;
  const workTypeArray = useMemo(
    () =>
      !noExternalTaskWorkType && workTypes
        ? workTypes.split(",").map((id) => instanceURL("workType", id))
        : [],
    [noExternalTaskWorkType, workTypes],
  );
  const workTypesSet = useMemo(() => new Set(workTypeArray), [workTypeArray]);
  if (!noExternalTaskWorkType) {
    workTypesCell = (
      <Grid item xs>
        <ConnectedMultipleExternalWorkTypesDialog
          open={workTypesDialogOpen}
          selected={workTypesSet}
          onCancel={setWorkTypesDialogOpenFalse}
          onOk={handleWorkTypesDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setWorkTypesDialogOpenTrue}
        >
          {formatMessage({defaultMessage: "Vælg arbejdsområder"})}
        </Button>
        <ClearFilterButton disabled={!workTypeArray.length} queryKey="workTypes" />
        <div style={{whiteSpace: "pre-line"}}>
          {!workTypesSet.size
            ? formatMessage({defaultMessage: "Ingen filtrering"})
            : workTypeArray
                .map((workTypeURL) => getWorkTypeString(workTypeLookup(workTypeURL)))
                .sort()
                .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                .concat(workTypesSet.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                .join(",\n")}
        </div>
      </Grid>
    );
  }
  const machineArray = useMemo(
    () => (machines ? machines.split(",").map((id) => instanceURL("machine", id)) : []),
    [machines],
  );
  const machinesSet = useMemo(() => new Set(machineArray), [machineArray]);
  const machinesCell = (
    <Grid item xs>
      <ConnectedMultipleMachinesDialog
        open={machinesDialogOpen}
        selected={machinesSet}
        onCancel={setMachinesDialogOpenFalse}
        onOk={handleMachinesDialogOk}
      />
      <Button
        color="secondary"
        style={{marginTop: 2}}
        variant="contained"
        onClick={setMachinesDialogOpenTrue}
      >
        {machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Vælg maskiner"})
          : formatMessage({defaultMessage: "Vælg køretøjer"})}
      </Button>
      <ClearFilterButton disabled={!machineArray.length} queryKey="machines" />
      <div style={{whiteSpace: "pre-line"}}>
        {!machinesSet.size
          ? formatMessage({defaultMessage: "Ingen filtrering"})
          : machineArray
              .map((machineURL) => getMachineString(machineLookup(machineURL)))
              .sort()
              .slice(0, ENTRIES_BEFORE_ELLIPSIS)
              .concat(machinesSet.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
              .join(",\n")}
      </div>
    </Grid>
  );
  let projectsCell;
  const projectArray = useMemo(
    () =>
      enableProjects && projects ? projects.split(",").map((id) => instanceURL("project", id)) : [],
    [enableProjects, projects],
  );
  const projectSet = useMemo(() => new Set(projectArray), [projectArray]);
  if (enableProjects) {
    projectsCell = (
      <Grid item xs>
        <ConnectedMultipleProjectsDialog
          open={projectsDialogOpen}
          suggestRecentlyUsed={false}
          onCancel={setProjectsDialogOpenFalse}
          onOk={handleProjectsDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setProjectsDialogOpenTrue}
        >
          {projectLabelVariant === "PROJECT"
            ? formatMessage({defaultMessage: "Vælg projekt"})
            : formatMessage({defaultMessage: "Vælg sag"})}
        </Button>
        <ClearFilterButton disabled={!projectArray.length} queryKey="projects" />
        <div style={{whiteSpace: "pre-line"}}>
          {!projectSet.size
            ? formatMessage({defaultMessage: "Ingen filtrering"})
            : projectArray
                .map((projectURL) => getProjectString(projectLookup(projectURL)))
                .sort()
                .slice(0, ENTRIES_BEFORE_ELLIPSIS)
                .concat(projectSet.size > ENTRIES_BEFORE_ELLIPSIS ? ["..."] : [])
                .join(",\n")}
        </div>
      </Grid>
    );
  }
  let referenceNumberCell;
  if (enableTaskReferenceNumber || enableOrderReferenceNumber) {
    const referenceNumberLabel =
      taskReferenceNumberLabel ||
      orderReferenceNumberLabel ||
      formatMessage({defaultMessage: "Ref. nr."});
    referenceNumberCell = (
      <Grid item xs>
        <ThrottledTextField
          inputProps={{maxLength: 255}}
          label={referenceNumberLabel}
          margin="dense"
          style={{width: 256}}
          value={referenceNumber}
          variant="outlined"
          onChange={handleReferenceNumberChange}
        />
        <ClearFilterButton disabled={!referenceNumber} queryKey="referenceNumber" />
      </Grid>
    );
  }

  const handleSearchChange = useCallback(
    (value: string | null) => {
      dispatch(actions.putQueryKey("q", value || ""));
    },
    [dispatch],
  );

  let uploadStatusBlock;
  if (uploadStartTimestamp) {
    if (uploadDoneTimestamp) {
      uploadStatusBlock = (
        <FormattedMessage
          defaultMessage="Sidste upload startet: {start}, fuldført: {done}"
          id="economic-sync.label.upload-start-done"
          values={{
            done: formatDateTime(uploadDoneTimestamp),
            start: formatDateTime(uploadStartTimestamp),
          }}
        />
      );
    } else {
      uploadStatusBlock = (
        <FormattedMessage
          defaultMessage="Sidste upload startet: {start}"
          id="economic-sync.label.upload-start"
          tagName="div"
          values={{start: formatDateTime(uploadStartTimestamp)}}
        />
      );
    }
  }

  const potentialTasks = useMemo(() => {
    if (recordInternalTasks) {
      return reportApprovedOrValidatedTasks;
    } else {
      return reportApprovedOrValidatedTasks.filter(
        (task) => !!task.order && !task.cancelled && !task.completedAsInternal,
      );
    }
  }, [recordInternalTasks, reportApprovedOrValidatedTasks]);

  const filteredTasks = useMemo(() => {
    const fromDateTime = fromDate ? dateAndTimeToTimestamp(fromDate, "00:00") : null;
    const toDateTime = toDate ? dateAndTimeToTimestamp(toDate, "24:00") : null;
    return potentialTasks.filter((task) => {
      if (fromDateTime || toDateTime) {
        if (
          !task.workFromTimestamp ||
          (fromDateTime && task.workFromTimestamp < fromDateTime) ||
          (toDateTime && task.workFromTimestamp > toDateTime)
        ) {
          return false;
        }
      }
      if (customerArray.length) {
        if (!task.order) {
          return false;
        }
        const order = orderLookup(task.order);
        if (!order?.customer || !customerArray.includes(order.customer)) {
          return false;
        }
      }
      if (workTypeArray.length && (!task.workType || !workTypeArray.includes(task.workType))) {
        return false;
      }
      if (projectArray.length && (!task.project || !projectArray.includes(task.project))) {
        return false;
      }
      if (
        machineArray.length &&
        (!task.machineuseSet.length ||
          task.machineuseSet.every((machineUse) => !machineArray.includes(machineUse.machine)))
      ) {
        return false;
      }
      return true;
    });
  }, [
    customerArray,
    fromDate,
    machineArray,
    orderLookup,
    potentialTasks,
    projectArray,
    toDate,
    workTypeArray,
  ]);

  const economicTaskErrors = useEconomicTasksErrors(filteredTasks, "bookkeeping");

  const rowData = useMemo(
    () =>
      buildRowData(
        filteredTasks,
        routeTaskArray,
        customerLookup,
        workTypeLookup,
        orderLookup,
        userUserProfileLookup,
        machineLookup,
        priceGroupLookup,
        locationLookup,
        projectLookup,
        uploading,
        taskPhotoArray,
        economicTaskErrors,
        toRecord,
      ),
    [
      filteredTasks,
      routeTaskArray,
      customerLookup,
      workTypeLookup,
      orderLookup,
      userUserProfileLookup,
      machineLookup,
      priceGroupLookup,
      locationLookup,
      projectLookup,
      uploading,
      taskPhotoArray,
      economicTaskErrors,
      toRecord,
    ],
  );

  const trimmedReferenceNumber = referenceNumber.trim();
  const trimmedSearchString = searchString.trim();

  const filteredRowData = useMemo(() => {
    const trimmedLowerReferenceNumber = trimmedReferenceNumber.toLocaleLowerCase();
    const referenceNumberFiltered = trimmedLowerReferenceNumber
      ? rowData.filter(({refNumber}) =>
          refNumber.toLocaleLowerCase().includes(trimmedLowerReferenceNumber),
        )
      : rowData;

    if (trimmedSearchString) {
      const check = makeContainsPredicate(trimmedSearchString, dateToString);
      return referenceNumberFiltered.filter((data) =>
        check(
          `${data.customer} ${data.date} ${data.delivery} ${data.fields} ${data.invoiceNote} ${data.machineOperator} ${data.machines} ${data.notes} ${data.order} ${data.pickup} ${data.priceGroup} ${data.project} ${data.refNumber} ${data.selected} ${data.workType}`,
        ),
      );
    } else {
      return referenceNumberFiltered;
    }
  }, [rowData, trimmedReferenceNumber, trimmedSearchString]);

  const {pricePercentSpecificationProblemDates, pricePercentTaskProblemSpecifications} =
    useMemo(() => {
      if (fuelSurcharge === "PRICE_PERCENT") {
        const routeTasksPerTask = groupByToMap(routeTaskArray, (routeTask) => routeTask.route);
        const routeTaskResultsPerRouteTask = groupByToMap(
          routeTaskResultArray,
          (routeTaskResult) => routeTaskResult.routeTask,
        );

        const specificationProblemDates = new Map<
          PricePercentFuelSurchargeSpecificationUrl,
          Set<string>
        >();
        const taskProblemSpecifications = new Map<
          TaskUrl,
          Set<PricePercentFuelSurchargeSpecificationUrl>
        >();
        for (const {task} of filteredRowData) {
          const order = task.order ? orderLookup(task.order) : null;
          if (!order) {
            continue;
          }
          if (!order.routePlan) {
            const customerUrl = order.customer;
            const data = getFuelSurchargePricePercentInvoiceData(
              intl,
              priceItemLookup,
              productLookup,
              productGroupLookup,
              pricePercentFuelSurchargeSpecificationLookup,
              pricePercentMachineFuelSurchargeUseArray,
              pricePercentWorkTypeFuelSurchargeUseArray,
              pricePercentDefaultFuelSurchargeUseArray,
              pricePercentFuelSurchargeSpecificationEntryArray,
              pricePercentFuelSurchargeBasisArray,
              {
                ...task,
                priceItemUses: task.priceItemUses || {},
                productUses: task.productUses || {},
                workFromTimestamp: task.workFromTimestamp as string,
              },
              customerUrl,
            );
            if (data.issues.size) {
              const taskDate = dateToString(new Date(task.workFromTimestamp as string));
              const problemSpecifications = new Set<PricePercentFuelSurchargeSpecificationUrl>();
              for (const {specification} of data.issues.values()) {
                problemSpecifications.add(specification.url);
                const existingTaskDatesForSpecification = specificationProblemDates.get(
                  specification.url,
                );
                if (existingTaskDatesForSpecification) {
                  existingTaskDatesForSpecification.add(taskDate);
                } else {
                  specificationProblemDates.set(specification.url, new Set([taskDate]));
                }
              }
              taskProblemSpecifications.set(task.url, problemSpecifications);
            }
          } else {
            const routeTasks = routeTasksPerTask.get(task.url);
            if (!routeTasks) {
              continue;
            }
            const problemSpecifications = new Set<PricePercentFuelSurchargeSpecificationUrl>();
            const taskDate = dateToString(new Date(task.workFromTimestamp as string));
            for (const routeTask of routeTasks) {
              if (!routeTask.completed) {
                continue;
              }
              const routeTaskResults = routeTaskResultsPerRouteTask.get(routeTask.url);
              if (!routeTaskResults) {
                continue;
              }
              const selectedRouteTaskResults = routeTaskResults.filter(
                (routeTaskResult) =>
                  routeTaskActivityOptionLookup(routeTaskResult.activityOption)?.activity ===
                  routeTask.activity,
              );
              const taskPriceItemUseEntries = Array.from(selectedRouteTaskResults)
                .sort((a, b) => a.order - b.order)
                .map(
                  (
                    result,
                  ): [
                    string,
                    Pick<
                      PriceItemUse,
                      "dangling" | "machine" | "priceGroup" | "priceItem" | "workType"
                    >,
                  ] => {
                    return [
                      urlToId(result.url),
                      {
                        dangling: false,
                        machine: null,
                        priceGroup: routeTask.activity,
                        priceItem: result.specification,
                        workType: task.workType,
                      },
                    ];
                  },
                );
              const data = getFuelSurchargePricePercentInvoiceData(
                intl,
                priceItemLookup,
                productLookup,
                productGroupLookup,
                pricePercentFuelSurchargeSpecificationLookup,
                pricePercentMachineFuelSurchargeUseArray,
                pricePercentWorkTypeFuelSurchargeUseArray,
                pricePercentDefaultFuelSurchargeUseArray,
                pricePercentFuelSurchargeSpecificationEntryArray,
                pricePercentFuelSurchargeBasisArray,
                {
                  priceGroup: routeTask.activity,
                  priceItemUses: Object.fromEntries(taskPriceItemUseEntries),
                  productUses: {},
                  workFromTimestamp: task.workFromTimestamp as string,
                  workType: task.workType,
                },
                routeTask.customer,
              );
              if (data.issues.size) {
                for (const {specification} of data.issues.values()) {
                  problemSpecifications.add(specification.url);
                  const existingTaskDatesForSpecification = specificationProblemDates.get(
                    specification.url,
                  );
                  if (existingTaskDatesForSpecification) {
                    existingTaskDatesForSpecification.add(taskDate);
                  } else {
                    specificationProblemDates.set(specification.url, new Set([taskDate]));
                  }
                }
              }
            }
            if (problemSpecifications.size) {
              taskProblemSpecifications.set(task.url, problemSpecifications);
            }
          }
        }
        return {
          pricePercentSpecificationProblemDates: specificationProblemDates,
          pricePercentTaskProblemSpecifications: taskProblemSpecifications,
        };
      } else {
        return {
          pricePercentSpecificationProblemDates: null,
          pricePercentTaskProblemSpecifications: null,
        };
      }
    }, [
      filteredRowData,
      fuelSurcharge,
      intl,
      orderLookup,
      priceItemLookup,
      pricePercentDefaultFuelSurchargeUseArray,
      pricePercentFuelSurchargeBasisArray,
      pricePercentFuelSurchargeSpecificationEntryArray,
      pricePercentFuelSurchargeSpecificationLookup,
      pricePercentMachineFuelSurchargeUseArray,
      pricePercentWorkTypeFuelSurchargeUseArray,
      productGroupLookup,
      productLookup,
      routeTaskActivityOptionLookup,
      routeTaskArray,
      routeTaskResultArray,
    ]);

  const {
    krPerLiterMachinesMissingFuelConsumption,
    krPerLiterSpecificationProblemDates,
    krPerLiterTaskProblemMachines,
    krPerLiterTaskProblemSpecifications,
  } = useMemo(() => {
    if (fuelSurcharge === "KR_PER_LITER") {
      const routeTasksPerTask = groupByToMap(routeTaskArray, (routeTask) => routeTask.route);

      const specificationProblemDates = new Map<
        KrPerLiterFuelSurchargeSpecificationUrl,
        Set<string>
      >();
      const taskProblemSpecifications = new Map<
        TaskUrl,
        Set<KrPerLiterFuelSurchargeSpecificationUrl>
      >();
      const machinesMissingFuelConsumption = new Set<MachineUrl>();
      const taskProblemMachines = new Map<TaskUrl, Set<MachineUrl>>();
      for (const {task} of filteredRowData) {
        const order = task.order ? orderLookup(task.order) : null;
        if (!order) {
          continue;
        }
        if (!order.routePlan) {
          const customerUrl = order.customer;
          const data = getFuelSurchargeKrPerLiterInvoiceData(
            intl,
            machineLookup,
            productLookup,
            krPerLiterFuelSurchargeSpecificationLookup,
            krPerLiterMachineFuelSurchargeUseArray,
            krPerLiterWorkTypeFuelSurchargeUseArray,
            krPerLiterDefaultFuelSurchargeUseArray,
            krPerLiterFuelSurchargeSpecificationEntryArray,
            krPerLiterFuelSurchargeBasisArray,
            task as typeof task & {workFromTimestamp: string},
            customerUrl,
          );
          if (data.issues.size) {
            const taskDate = dateToString(new Date(task.workFromTimestamp as string));
            const problemSpecifications = new Set<KrPerLiterFuelSurchargeSpecificationUrl>();
            const problemMachines = new Set<MachineUrl>();
            for (const [
              machineUrl,
              {fuelConsumptionLiterPerHour, specification, specificationEntry},
            ] of data.issues.entries()) {
              if (!specificationEntry) {
                problemSpecifications.add(specification.url);
                const existingTaskDatesForSpecification = specificationProblemDates.get(
                  specification.url,
                );
                if (existingTaskDatesForSpecification) {
                  existingTaskDatesForSpecification.add(taskDate);
                } else {
                  specificationProblemDates.set(specification.url, new Set([taskDate]));
                }
              }
              if (fuelConsumptionLiterPerHour === null) {
                problemMachines.add(machineUrl);
                machinesMissingFuelConsumption.add(machineUrl);
              }
            }
            if (problemSpecifications.size) {
              taskProblemSpecifications.set(task.url, problemSpecifications);
            }
            if (problemMachines.size) {
              taskProblemMachines.set(task.url, problemMachines);
            }
          }
        } else {
          const routeTasks = routeTasksPerTask.get(task.url);
          if (!routeTasks) {
            continue;
          }
          const problemSpecifications = new Set<KrPerLiterFuelSurchargeSpecificationUrl>();
          const taskDate = dateToString(new Date(task.workFromTimestamp as string));
          for (const routeTask of routeTasks) {
            if (!routeTask.completed) {
              continue;
            }
            const data = getFuelSurchargeKrPerLiterInvoiceData(
              intl,
              machineLookup,
              productLookup,
              krPerLiterFuelSurchargeSpecificationLookup,
              krPerLiterMachineFuelSurchargeUseArray,
              krPerLiterWorkTypeFuelSurchargeUseArray,
              krPerLiterDefaultFuelSurchargeUseArray,
              krPerLiterFuelSurchargeSpecificationEntryArray,
              krPerLiterFuelSurchargeBasisArray,
              {
                machineuseSet: task.machineuseSet,
                priceGroup: routeTask.activity,
                workFromTimestamp: task.workFromTimestamp as string,
                workType: task.workType,
              },
              routeTask.customer,
            );
            if (data.issues.size) {
              for (const {specification} of data.issues.values()) {
                problemSpecifications.add(specification.url);
                const existingTaskDatesForSpecification = specificationProblemDates.get(
                  specification.url,
                );
                if (existingTaskDatesForSpecification) {
                  existingTaskDatesForSpecification.add(taskDate);
                } else {
                  specificationProblemDates.set(specification.url, new Set([taskDate]));
                }
              }
            }
          }
          if (problemSpecifications.size) {
            taskProblemSpecifications.set(task.url, problemSpecifications);
          }
        }
      }
      return {
        krPerLiterMachinesMissingFuelConsumption: machinesMissingFuelConsumption,
        krPerLiterSpecificationProblemDates: specificationProblemDates,
        krPerLiterTaskProblemMachines: taskProblemMachines,
        krPerLiterTaskProblemSpecifications: taskProblemSpecifications,
      };
    } else {
      return {
        krPerLiterMachinesMissingFuelConsumption: null,
        krPerLiterSpecificationProblemDates: null,
        krPerLiterTaskProblemMachines: null,
        krPerLiterTaskProblemSpecifications: null,
      };
    }
  }, [
    filteredRowData,
    fuelSurcharge,
    intl,
    krPerLiterDefaultFuelSurchargeUseArray,
    krPerLiterFuelSurchargeBasisArray,
    krPerLiterFuelSurchargeSpecificationEntryArray,
    krPerLiterFuelSurchargeSpecificationLookup,
    krPerLiterMachineFuelSurchargeUseArray,
    krPerLiterWorkTypeFuelSurchargeUseArray,
    machineLookup,
    orderLookup,
    productLookup,
    routeTaskArray,
  ]);

  useEffect(() => {
    if (
      krPerLiterTaskProblemMachines?.size ||
      krPerLiterTaskProblemSpecifications?.size ||
      pricePercentTaskProblemSpecifications?.size ||
      economicTaskErrors.size
    ) {
      for (const {task} of filteredRowData) {
        if (
          task.validatedAndRecorded &&
          (krPerLiterTaskProblemMachines?.has(task.url) ||
            krPerLiterTaskProblemSpecifications?.has(task.url) ||
            pricePercentTaskProblemSpecifications?.has(task.url) ||
            economicTaskErrors.has(task.url))
        ) {
          const patch: Patch<Task> = [
            {member: "reportApproved", value: true},
            {member: "validatedAndRecorded", value: false},
          ];
          dispatch(actions.update(task.url, patch));
        }
      }
    }
  }, [
    dispatch,
    filteredRowData,
    krPerLiterTaskProblemSpecifications,
    pricePercentTaskProblemSpecifications,
    krPerLiterTaskProblemMachines,
    economicTaskErrors,
  ]);

  const filteredRowDataWithFuelSurchargeIssues = useMemo((): readonly (TaskTableDataType & {
    task: Task;
  })[] => {
    return filteredRowData.map((data): TaskTableDataType & {task: Task} => ({
      ...data,
      hasFuelSurchargeIssues: !!(
        krPerLiterTaskProblemMachines?.has(data.key) ||
        krPerLiterTaskProblemSpecifications?.has(data.key) ||
        pricePercentTaskProblemSpecifications?.has(data.key)
      ),
    }));
  }, [
    filteredRowData,
    krPerLiterTaskProblemSpecifications,
    pricePercentTaskProblemSpecifications,
    krPerLiterTaskProblemMachines,
  ]);

  return (
    <>
      <Card>
        <CardHeader
          subheader={uploadStatusBlock}
          title={formatMessage({defaultMessage: "Angiv filtrering"})}
        />
        <CardContent>
          <Grid container spacing={3}>
            {customersCell}
            {workTypesCell}
            {machinesCell}
            {projectsCell}
          </Grid>
          <Grid container spacing={3}>
            {referenceNumberCell}
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <DateField
                autoOk
                label={formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                style={{width: "calc(100% - 48px)"}}
                value={fromDate}
                onChange={handleFromDateChange}
              />
              <ClearFilterButton disabled={!fromDate} queryKey="fromDate" />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <DateField
                autoOk
                label={formatMessage({defaultMessage: "Til"})}
                margin="dense"
                style={{width: "calc(100% - 48px)"}}
                value={toDate}
                onChange={handleToDateChange}
              />
              <ClearFilterButton disabled={!toDate} queryKey="toDate" />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <ThrottledTextField
                label={<FormattedMessage defaultMessage="Fritekst" id="invoicing-tab.search" />}
                margin="dense"
                style={{width: "calc(100% - 48px)"}}
                value={searchString}
                variant="outlined"
                onChange={handleSearchChange}
              />
              <ClearFilterButton disabled={!searchString} queryKey="q" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {pricePercentSpecificationProblemDates?.size ? (
        <PricePercentFuelSurchargeIssuesCard
          specificationProblemDates={pricePercentSpecificationProblemDates}
        />
      ) : null}
      {krPerLiterSpecificationProblemDates?.size ||
      krPerLiterMachinesMissingFuelConsumption?.size ? (
        <KrPerLiterFuelSurchargeIssuesCard
          machinesMissingFuelConsumption={krPerLiterMachinesMissingFuelConsumption}
          specificationProblemDates={krPerLiterSpecificationProblemDates}
        />
      ) : null}
      {economicTaskErrors.size ? (
        <EconomicTaskIssuesCard
          context="bookkeeping"
          downloading={downloading}
          economicTaskIssues={economicTaskErrors}
          onDownloadFromEconomic={onDownloadFromEconomic}
        />
      ) : null}
      {downloadError || uploadError ? (
        <Paper style={{marginTop: 10}}>
          <div style={{color: theme.palette.error.main, textAlign: "center"}}>
            {uploadError ? (
              <EconomicUploadStatus query={query} uploadError={uploadError} />
            ) : downloadError ? (
              <EconomicDownloadErrorBlock downloadError={downloadError} />
            ) : null}
          </div>
        </Paper>
      ) : null}

      <Paper style={{marginTop: 10}}>
        <EconomicTaskTable
          dispatchToRecord={dispatchToRecord}
          filteredRowData={filteredRowDataWithFuelSurchargeIssues}
          krPerLiterTaskProblemSpecifications={krPerLiterTaskProblemSpecifications}
          previousUploadEncounteredError={!!uploadError}
          pricePercentTaskProblemSpecifications={pricePercentTaskProblemSpecifications}
          taskProblemMachines={krPerLiterTaskProblemMachines}
          tasksToTransferCount={toRecord.size}
          uploading={uploading}
          uploadProgress={uploadProgress}
          onUploadToEconomic={onUploadToEconomic}
        />
      </Paper>
    </>
  );
});
