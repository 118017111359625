import {CustomerUrl, Project, ProjectUrl} from "@co-common-libs/resources";
import {ConnectedProjectDialog} from "@co-frontend-libs/connected-components";
import {actions, getCurrentUserCanAddProject, getProjectLookup} from "@co-frontend-libs/redux";
import {instanceURL} from "frontend-global-config";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ProjectCreateEditDialog, type ProjectData} from "../../create-edit-dialogs";
import {CustomerSelectCreateDialog} from "../../customer-select-create-dialog";
import {
  ProjectCustomerSelectionEvent,
  ProjectCustomerSelectionState,
} from "./project-customer-selection-state-machine";

interface ProjectCustomerSelectionDisplayProps {
  send: (event: ProjectCustomerSelectionEvent) => void;
  state: ProjectCustomerSelectionState;
}

export function ProjectCustomerSelectionDisplay(
  props: ProjectCustomerSelectionDisplayProps,
): JSX.Element {
  const {send, state} = props;

  const currentUserCanAddProject = useSelector(getCurrentUserCanAddProject);

  const projectLookup = useSelector(getProjectLookup);
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    send({type: "CANCEL"});
  }, [send]);

  const handleProjectAdd = useCallback(() => {
    send({type: "ADD"});
  }, [send]);

  const handleProjectNone = useCallback(() => {
    send({type: "NONE"});
  }, [send]);

  const handleProjectSelected = useCallback(
    (projectURL: ProjectUrl) => {
      const project = projectLookup(projectURL);
      const customerURL = project?.customer;
      if (projectURL && customerURL) {
        send({
          customer: customerURL,
          project: projectURL,
          type: "PROJECT_SELECTED",
        });
      }
    },
    [projectLookup, send],
  );

  const handleCustomerSelected = useCallback(
    (customerURL: CustomerUrl) => {
      send({customer: customerURL, type: "CUSTOMER_SELECTED"});
    },
    [send],
  );

  const handleProjectCreate = useCallback(
    (data: ProjectData) => {
      const id = uuid();
      const url = instanceURL("project", id);
      const instance: Project = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        c5_recid: 0,
        id,
        remoteUrl: "",
        url,
        ...data,
      };
      dispatch(actions.create(instance));
      send({type: "CREATED", url});
    },
    [dispatch, send],
  );

  return (
    <>
      <ConnectedProjectDialog
        suggestRecentlyUsed
        customerURL={state.context.customer ?? undefined}
        open={state.matches("selectProject") || state.matches("selectCustomerProject")}
        onAdd={currentUserCanAddProject ? handleProjectAdd : undefined}
        onCancel={handleCancel}
        onNone={handleProjectNone}
        onOk={handleProjectSelected}
      />
      <CustomerSelectCreateDialog
        open={state.matches("selectCustomer") || state.matches("selectCustomerForNewProject")}
        onCancel={handleCancel}
        onOk={handleCustomerSelected}
      />
      {state.context.customer ? (
        <ProjectCreateEditDialog
          data={state.context.customer}
          open={state.matches("createNewProject")}
          onCancel={handleCancel}
          onOk={handleProjectCreate}
        />
      ) : null}
    </>
  );
}
