import {ResourceMappingObject} from "./resource-data-map";

export const resourcesConfig: ResourceMappingObject<string> = {
  accomodationAllowance: "accomodationallowances",
  accumulatedCompensatory: "accumulatedcompensatory",
  anniversaryType: "anniversarytypes",
  archiveFile: "archivefiles",
  archiveLink: "archivelinks",
  availability: "availability",
  cachedDailyAccumulatedCompensatory: "cacheddailyaccumulatedcompensatory",
  calendarOrdering: "calendarorderings",
  calendarWorkHours: "calendarworkhours",
  contact: "contacts",
  crop: "crops",
  culture: "cultures",
  customer: "customers",
  customerFile: "customerfiles",
  daysAbsence: "daysabsence",
  delivery: "deliveries",
  deliveryLocation: "deliverylocations",
  dinnerBooking: "dinnerbookings",
  employeeGroup: "employeegroups",
  expectedAmount: "expectedamounts",
  hoursAbsence: "hoursabsence",
  information: "information",
  journal: "journals",
  krPerLiterDefaultFuelSurchargeUse: "krperliterdefaultfuelsurchargeuses",
  krPerLiterFuelSurchargeBasis: "krperliterfuelsurchargebasis",
  krPerLiterFuelSurchargeSpecification: "krperliterfuelsurchargespecifications",
  krPerLiterFuelSurchargeSpecificationEntry: "krperliterfuelsurchargespecificationentries",
  krPerLiterMachineFuelSurchargeUse: "krperlitermachinefuelsurchargeuses",
  krPerLiterWorkTypeFuelSurchargeUse: "krperliterworktypefuelsurchargeuses",
  location: "locations",
  locationStorageAdjustment: "locationstorageadjustments",
  locationStorageChange: "locationstoragechanges",
  locationStorageStatus: "locationstoragestatuses",
  locationType: "locationtypes",
  locationUseLog: "locationuselogs",
  lunchBooking: "lunchbookings",
  machine: "machines",
  machineGroup: "machinegroups",
  machineLastUsed: "machinelastused",
  machineUseLog: "machineuselogs",
  order: "orders",
  orderFile: "orderfiles",
  pickup: "pickups",
  pickupLocation: "pickuplocations",
  position: "positions",
  priceGroup: "pricegroups",
  priceItem: "priceitems",
  pricePercentDefaultFuelSurchargeUse: "pricepercentdefaultfuelsurchargeuses",
  pricePercentFuelSurchargeBasis: "pricepercentfuelsurchargebasis",
  pricePercentFuelSurchargeSpecification: "pricepercentfuelsurchargespecifications",
  pricePercentFuelSurchargeSpecificationEntry: "pricepercentfuelsurchargespecificationentries",
  pricePercentMachineFuelSurchargeUse: "pricepercentmachinefuelsurchargeuses",
  pricePercentWorkTypeFuelSurchargeUse: "pricepercentworktypefuelsurchargeuses",
  product: "products",
  productGroup: "productgroups",
  productUseLog: "productuselogs",
  project: "projects",
  punchInOut: "punchinout",
  remunerationReport: "remunerationreports",
  reportingPrintout: "reportingprintouts",
  reportingSpecification: "reportingspecifications",
  reportSetup: "reportsetups",
  role: "roles",
  routeLogReport: "routelogreports",
  routePlan: "routeplans",
  routePlanTask: "routeplantasks",
  routePlanTaskActivityOption: "routeplantaskactivityoptions",
  routePlanTaskResult: "routeplantaskresults",
  routeTask: "routetasks",
  routeTaskActivityOption: "routetaskactivityoptions",
  routeTaskResult: "routetaskresults",
  settingEntry: "settingentry",
  settings: "settings",
  spray: "sprays",
  sprayLocation: "spraylocations",
  sprayLog: "spraylogs",
  sprayLogReport: "spraylogreports",
  syncLog: "c5_sync",
  task: "tasks",
  taskFile: "taskfiles",
  taskPhoto: "taskphotos",
  tasksPrintout: "tasksprintouts",
  timer: "timers",
  timerStart: "timerstarts",
  transportLog: "transportlogs",
  transportLogReport: "transportlogreports",
  unit: "units",
  user: "users",
  userFile: "userfiles",
  userPhoto: "userphotos",
  userProfile: "userprofiles",
  workshopChecklist: "workshopchecklists",
  workshopChecklistItem: "workshopchecklistitems",
  workType: "worktypes",
  yieldDelivery: "yielddeliveries",
  yieldDeliveryLocation: "yielddeliverylocations",
  yieldLog: "yieldlogs",
  yieldLogReport: "yieldlogreports",
  yieldPickup: "yieldpickups",
  yieldPickupLocation: "yieldpickuplocations",
};
