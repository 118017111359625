import {Config} from "@co-common-libs/config";
import {SimplifiedData} from "@co-common-libs/payroll";
import {
  RemunerationReport,
  RemunerationReportUrl,
  Role,
  User,
  urlToId,
} from "@co-common-libs/resources";
import {formatDate} from "@co-common-libs/utils";
import {
  AppState,
  getCurrentRole,
  getCurrentUser,
  getCustomerSettings,
  getRemunerationReportLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {Button, CircularProgress} from "@material-ui/core";
import {DoLoadInstance, PageLayout, UserSalaryReportCard} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  childsFirstSickDay: {
    defaultMessage: "Barns første sygedag",
    id: "time-overview.label.childs-first-sick-day",
  },
  compensatory: {
    defaultMessage: "Afspadsering",
    id: "time-overview.label.compensatory",
  },
  daysOffWithoutPay: {
    defaultMessage: "Fridage uden løn",
    id: "time-overview.label.days-off-without-pay",
  },
  floatingHoliday: {
    defaultMessage: "Ferie-fridage",
    id: "time-overview.label.floating-holiday",
  },
  salaryVoucher: {
    defaultMessage: "Lønbilag",
    id: "salary-voucher.title.salary-voucher",
  },
  salaryVoucherPeriod: {
    defaultMessage: "Lønbilag: {from}–{to}",
    id: "salary-voucher.title.salary-voucher-format",
  },
  sickLeave: {
    defaultMessage: "Sygefravær",
    id: "time-overview.label.sick-leave",
  },
  timeOff: {defaultMessage: "FRI", id: "time-overview.label.time-off"},
  tryAgain: {
    defaultMessage: "Forsøg igen",
    id: "salary-voucher.label.try-again",
  },
  vacation: {defaultMessage: "Feriedage", id: "time-overview.label.vacation"},
});

type RemunerationReportData = SimplifiedData & {
  customerSettings: object;
};

interface SalaryVoucherStateProps {
  currentRole: Role | null;
  currentUser: User | null;
  customerSettings: Config;
}
interface SalaryVoucherOwnProps {
  instance: RemunerationReport;
}

type SalaryVoucherProps = SalaryVoucherOwnProps & SalaryVoucherStateProps;

interface SalaryVoucherState {
  data: RemunerationReportData | null;
  error: Error | null;
}

class SalaryVoucher extends PureComponent<SalaryVoucherProps, SalaryVoucherState> {
  state: SalaryVoucherState = {
    data: null,
    error: null,
  };
  componentDidMount(): void {
    this.doFetch();
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  doFetch(): void {
    const {dataUrl} = this.props.instance;
    if (dataUrl) {
      jsonFetch(dataUrl)
        .then((response) => {
          const {data} = response;
          this.setState({data});
          return;
        })
        .catch((error) => {
          this.setState({error});
        });
    }
  }
  @bind
  handleTryLoadAgain(): void {
    this.setState({error: null}, this.doFetch);
  }
  render(): JSX.Element {
    const report = this.props.instance;
    const {formatMessage} = this.context;
    const {fromDate} = report;
    const {toDate} = report;
    const title = formatMessage(messages.salaryVoucherPeriod, {
      from: _.capitalize(formatDate(fromDate)),
      to: _.capitalize(formatDate(toDate)),
    });
    const {data} = this.state;
    if (data) {
      const user = this.props.currentUser;
      const userUrl = user?.url || "";
      const userId = urlToId(userUrl);
      const userDataList = data.users;
      const reportCards = userDataList
        .filter((d) => d.userId === userId)
        .map((userData) => {
          const visibleBonusLabels = userData.visibleBonusLabels || data.visibleBonusLabels;
          const visibleRateLabels = userData.visibleRateLabels || data.visibleRateLabels;
          const {visibleLabels} = userData;
          return (
            <UserSalaryReportCard
              key={userData.remunerationGroup}
              calendarDayBonusLabels={data.calendarDayBonusLabels || []}
              countBonusLabels={data.countBonusLabels || []}
              currentRole={this.props.currentRole || undefined}
              customerSettings={this.props.customerSettings}
              intervalBonusLabels={data.intervalBonusLabels || []}
              rateLabels={data.rateLabels || []}
              showBreaks={!userData.paidBreaks || !!userData.forcedUnpaidBreakMinutes}
              startRateLabel={data.startRateLabel || undefined}
              taskBonusLabels={data.taskBonusLabels || []}
              userData={userData}
              visibleBonusLabels={visibleBonusLabels || undefined}
              visibleLabels={visibleLabels}
              visibleRateLabels={visibleRateLabels || undefined}
              workDayBonusLabels={data.workDayBonusLabels || []}
            />
          );
        });
      return (
        <PageLayout withPadding toolbar={title}>
          {reportCards}
        </PageLayout>
      );
    } else if (this.state.error) {
      return (
        <PageLayout withPadding toolbar={title}>
          <div style={{textAlign: "center"}}>
            <Button color="primary" variant="contained" onClick={this.handleTryLoadAgain}>
              {formatMessage(messages.tryAgain)}
            </Button>
          </div>
        </PageLayout>
      );
    } else {
      return (
        <PageLayout withPadding toolbar={title}>
          <div style={{textAlign: "center"}}>
            <CircularProgress />
          </div>
        </PageLayout>
      );
    }
  }
}

const ConnectedSalaryVoucher = connect<
  SalaryVoucherStateProps,
  object,
  SalaryVoucherOwnProps,
  AppState
>(
  createStructuredSelector<AppState, SalaryVoucherStateProps>({
    currentRole: getCurrentRole,
    currentUser: getCurrentUser,
    customerSettings: getCustomerSettings,
  }),
  {},
)(SalaryVoucher);

class LoadSalaryVoucher extends React.Component<undefined> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    return (
      <DoLoadInstance<RemunerationReport, RemunerationReportUrl>
        Component={ConnectedSalaryVoucher}
        loadingTitle={formatMessage(messages.salaryVoucher)}
        lookupSelector={getRemunerationReportLookup}
        resourceName="remunerationReport"
      />
    );
  }
}

export default LoadSalaryVoucher;
