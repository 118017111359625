import {Config} from "@co-common-libs/config";
import {Machine, urlToId} from "@co-common-libs/resources";
import {getMachineString} from "@co-common-libs/resources-utils";
import {DateField, FilePdfIcon} from "@co-frontend-libs/components";
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import {AjaxDownloadButton} from "app-components";
import {PureComponent} from "app-utils";
import {globalConfig} from "frontend-global-config";
import FileChartIcon from "mdi-react/FileChartIcon";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  calculate: {
    defaultMessage: "Beregn",
    id: "calculate-card.label.calculate",
  },
  csv: {
    defaultMessage: "CSV",
  },
  csvFilename: {
    defaultMessage: "Maskine {machine} lønomkostning {fromDate} - {toDate}.csv",
    id: "reports.label.machine-salary-csv-filename",
  },
  machine: {
    defaultMessage: "Vælg maskine",
    id: "calculate-card.label.machines",
  },
  pdf: {
    defaultMessage: "PDF",
  },
  pdfFilename: {
    defaultMessage: "Maskine {machine} lønomkostning {fromDate} - {toDate}.pdf",
    id: "reports.label.machine-salary-pdf-filename",
  },
  periodEnd: {
    defaultMessage: "Til",
    id: "calculate-card.label.period-end",
  },
  periodStart: {
    defaultMessage: "Fra",
    id: "calculate-card.label.period-start",
  },
  vehicle: {
    defaultMessage: "Vælg køretøj",
    id: "calculate-card.label.vehicles",
  },
});

interface CalculateCardProps {
  customerSettings: Config;
  data?:
    | {
        [workTypeID: string]: {
          [initials: string]: [string, number, string, number][];
        };
      }
    | undefined;
  fromDate: string | null;
  onFromDateChanged: (value: string | null) => void;
  onGenerateClick: () => void;
  onRequestMachineDialogOpen: () => void;
  onToDateChanged: (value: string | null) => void;
  selectedMachine?: Machine | undefined;
  toDate: string | null;
  token: string | null;
}

export class CalculateCard extends PureComponent<CalculateCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerSettings,
      data,
      fromDate,
      onFromDateChanged,
      onGenerateClick,
      onToDateChanged,
      selectedMachine,
      toDate,
    } = this.props;

    let periodValid = false;
    if (fromDate && toDate) {
      if (toDate >= fromDate) {
        periodValid = true;
      }
    }

    const {baseURL} = globalConfig.resources;

    const calculateDisabled = !periodValid || !selectedMachine;
    return (
      <Card>
        <CardContent>
          <Grid>
            <Cell>
              <DateField
                autoOk
                fullWidth
                label={formatMessage(messages.periodStart)}
                margin="dense"
                value={fromDate || null}
                onChange={onFromDateChanged}
              />
            </Cell>
            <Cell>
              <DateField
                autoOk
                fullWidth
                label={formatMessage(messages.periodEnd)}
                margin="dense"
                value={toDate || null}
                onChange={onToDateChanged}
              />
            </Cell>
            <Cell>
              <Button
                color="secondary"
                style={{marginTop: 30}}
                variant="contained"
                onClick={this.props.onRequestMachineDialogOpen}
              >
                {customerSettings.machineLabelVariant === "MACHINE"
                  ? formatMessage(messages.machine)
                  : formatMessage(messages.vehicle)}
              </Button>
            </Cell>
            <Cell>
              <div style={{marginTop: 30}}>{getMachineString(selectedMachine)}</div>
            </Cell>
            <Cell>
              <Button
                disabled={calculateDisabled}
                startIcon={<FileChartIcon />}
                style={{float: "right", marginTop: 30}}
                variant="text"
                onClick={onGenerateClick}
              >
                {formatMessage(messages.calculate)}
              </Button>
            </Cell>
          </Grid>
        </CardContent>
        <CardActions>
          <AjaxDownloadButton
            data={data as any}
            disabled={!data || !selectedMachine}
            downloadURL={`${baseURL}machineSalaryCostCSV`}
            filename={formatMessage(messages.csvFilename, {
              fromDate,
              machine: getMachineString(selectedMachine),
              toDate,
            })}
            Icon={FileDelimitedIcon}
            label={formatMessage(messages.csv)}
            token={this.props.token}
          />
          <AjaxDownloadButton
            data={{
              data,
              fromDate,
              machine: selectedMachine ? urlToId(selectedMachine.url) : null,
              toDate,
            }}
            disabled={!data || !selectedMachine}
            downloadURL={`${baseURL}machineSalaryCostPDF`}
            filename={formatMessage(messages.pdfFilename, {
              fromDate,
              machine: getMachineString(selectedMachine),
              toDate,
            })}
            Icon={FilePdfIcon}
            label={formatMessage(messages.pdf)}
            token={this.props.token}
          />
        </CardActions>
      </Card>
    );
  }
}
