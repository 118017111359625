import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Location,
  OrderUrl,
  Role,
  TaskUrl,
} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import {Button, Card, CardActions, CardContent, IconButton} from "@material-ui/core";
import {ButtonStyle, EditCustomerButton, WorkplaceBlock} from "app-components";
import {addDanishCountryPrefix} from "app-utils";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import EmailIcon from "mdi-react/EmailIcon";
import MapIcon from "mdi-react/MapIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import React from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {ChangeProjectButton} from "./change-project-button";

const messages = defineMessages({
  changeCustomer: {
    defaultMessage: "Skift kunde",
    id: "customer-card.label.change-customer",
  },
});

export const getCustomerInfo = (
  customer?: Customer,
  contact?: Contact,
  preferContact: boolean = false,
): {
  cellphone: string | undefined;
  contactName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
} => {
  let contactPhone;
  let contactCellphone;
  let contactEmail;
  let contactName;
  let customerPhone;
  let customerCellphone;
  let customerEmail;
  if (customer) {
    customerPhone = customer.phone;
    customerCellphone = customer.cellphone;
    customerEmail = customer.billingEmail;
  }
  if (contact) {
    contactPhone = contact.phone;
    contactCellphone = contact.cellphone;
    contactEmail = contact.email;
    if (!customer || contact.name !== customer.name) {
      contactName = contact.name;
    }
  }
  let phone;
  let email;
  let cellphone;
  if (preferContact) {
    phone = contactPhone || customerPhone;
    cellphone = contactCellphone || customerCellphone;
    email = contactEmail || customerEmail;
  } else {
    phone = customerPhone || contactPhone;
    cellphone = customerCellphone || contactCellphone;
    email = customerEmail || contactEmail;
  }
  return {cellphone, contactName, email, phone};
};

interface CustomerCardProps {
  address?: string | undefined;
  completed: boolean;
  contact?: Contact | undefined;
  currentRole: Role | null;
  customer?: Customer | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  department?: string;
  onChangeCustomerButton: () => void;
  orderURL: OrderUrl | null;
  pickupLocation?: Location | undefined;
  taskURL: TaskUrl;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  userIsSeniorMachineOperator: boolean;
  validated: boolean;
  workplace?: Location | undefined;
}

export const CustomerCard = React.memo(function CustomerCard(
  props: CustomerCardProps,
): JSX.Element {
  const {formatMessage} = useIntl();
  const {
    completed,
    contact,
    customer,
    customerLookup,
    customerSettings,
    onChangeCustomerButton,
    pickupLocation,
    userIsOnlyMachineOperator,
    userIsOtherMachineOperator,
    userIsSeniorMachineOperator,
    validated,
    workplace,
  } = props;
  let {address} = props;
  const role = props.currentRole;
  const userIsJobber = role && role.jobber;

  const customerName = customer ? customer.name : null;
  const contactInfo = getCustomerInfo(customer, contact, true);
  const contactPhone = contactInfo.phone;
  const contactCellphone = contactInfo.cellphone;
  const contactEmail = contactInfo.email;
  const {contactName} = contactInfo;

  let contactNameBlock;
  if (contactName) {
    contactNameBlock = (
      <FormattedMessage
        defaultMessage="Kontaktperson: {contactName}"
        id="task-instance.header.contact"
        tagName="h4"
        values={{contactName}}
      />
    );
  }
  let phoneButtonAndNumber = null;
  if (customerSettings.showLandlinePhoneNumberOnTaskInfo && contactPhone) {
    phoneButtonAndNumber = (
      <>
        <IconButton color="primary" href={`tel:${addDanishCountryPrefix(contactPhone)}`}>
          <PhoneIcon />
        </IconButton>
        {contactPhone}
      </>
    );
  }
  let cellphoneButton = null;
  if (contactCellphone) {
    cellphoneButton = (
      <IconButton color="primary" href={`tel:${addDanishCountryPrefix(contactCellphone)}`}>
        <CellphoneIcon />
      </IconButton>
    );
  }
  let workplaceBlock;
  if (customerSettings.taskPickupDelivery) {
    const workplaceBlockStyle = {marginBottom: 24};
    workplaceBlock = (
      <div>
        <FormattedMessage
          defaultMessage="Afhentningssted"
          id="task-instance.label.workplace-pickup"
          tagName="h4"
        />
        <WorkplaceBlock
          customerLookup={customerLookup}
          location={pickupLocation}
          style={workplaceBlockStyle}
        />
        <FormattedMessage
          defaultMessage="Arbejds-/leveringssted"
          id="task-instance.label.workplace-delivery"
          tagName="h4"
        />
        <WorkplaceBlock
          customerLookup={customerLookup}
          location={workplace}
          style={workplaceBlockStyle}
        />
      </div>
    );
  } else if (workplace) {
    workplaceBlock = (
      <div>
        <FormattedMessage
          defaultMessage="Arbejdssted"
          id="task-instance.header.workplace"
          tagName="h4"
        />
        <WorkplaceBlock customerLookup={customerLookup} location={workplace} />
      </div>
    );
  }

  let projectManagerBlock;
  if (customerSettings.customerProjectManager && customer) {
    const customerProjectManager = customer.projectManagerName;
    if (customerProjectManager) {
      projectManagerBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Formand"
            id="task-instance.header.project-manager"
            tagName="h4"
          />
          {customerProjectManager}
        </div>
      );
    }
  }
  if (customer && !address) {
    address = customerAddress(customer);
  }
  let mapsURL = null;
  if (address) {
    const query = encodeURIComponent(address);
    mapsURL = `http://maps.google.com/?q=${query}`;
  }
  let mapButton = null;
  if (mapsURL) {
    mapButton = (
      <IconButton color="primary" href={mapsURL} target="_blank">
        <MapIcon />
      </IconButton>
    );
  }
  let emailButton = null;
  if (contactEmail) {
    emailButton = (
      <IconButton color="primary" href={`mailto:${contactEmail}`}>
        <EmailIcon />
      </IconButton>
    );
  }
  let changeCustomerButton;
  const disabled =
    (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
    (userIsOnlyMachineOperator && completed) ||
    validated;
  if (
    customerSettings.enableCustomerSwitch &&
    !userIsJobber &&
    !(userIsOnlyMachineOperator && !customerSettings.machineOperatorCanCreateCustomerTask)
  ) {
    changeCustomerButton = (
      <Button
        color="secondary"
        disabled={disabled}
        variant="contained"
        onClick={onChangeCustomerButton}
      >
        {formatMessage(messages.changeCustomer)}
      </Button>
    );
  }

  let changeProjectButton;
  if (
    customerSettings.enableProjects ||
    (props.department &&
      customerSettings.onlyEnableProjectsForDepartments.includes(props.department) &&
      customerSettings.machineOperatorsCanChooseProject &&
      !userIsJobber)
  ) {
    changeProjectButton = (
      <ChangeProjectButton
        customerURL={customer?.url ?? null}
        disabled={disabled}
        orderURL={props.orderURL}
        taskURL={props.taskURL}
      />
    );
  }
  const cardActions =
    changeCustomerButton || changeProjectButton ? (
      <CardActions>
        {changeCustomerButton} {changeProjectButton}
      </CardActions>
    ) : null;

  if (customerSettings.taskPickupDelivery) {
    return (
      <Card style={{margin: "1em"}}>
        {customer && customerSettings.createCustomer && role?.manager ? (
          <EditCustomerButton
            buttonStyle={ButtonStyle.FAB}
            customer={customer}
            variant="component"
          />
        ) : null}
        <CardContent>
          {workplaceBlock}
          <FormattedMessage defaultMessage="Kunde" id="task-instance.label.customer" tagName="h4" />
          {customerName}
          <br />
          {mapButton} {address}
          <br />
          {contactNameBlock}
          {emailButton} {contactEmail}
          <br />
          {phoneButtonAndNumber} {cellphoneButton} {contactCellphone}
          {projectManagerBlock}
        </CardContent>

        {changeCustomerButton ? <CardActions>{changeCustomerButton}</CardActions> : null}
      </Card>
    );
  } else {
    return (
      <Card style={{margin: "1em"}}>
        {customer && customerSettings.createCustomer && role?.manager ? (
          <EditCustomerButton
            buttonStyle={ButtonStyle.FAB}
            customer={customer}
            variant="component"
          />
        ) : null}
        <CardContent>
          <FormattedMessage defaultMessage="Kunde" id="task-instance.label.customer" />
          <h1>{customerName}</h1>
          {mapButton} {address}
          <br />
          {contactNameBlock}
          {emailButton} {contactEmail}
          <br />
          {phoneButtonAndNumber} {cellphoneButton} {contactCellphone}
          {projectManagerBlock}
          {workplaceBlock}
        </CardContent>
        {cardActions}
      </Card>
    );
  }
});
