import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  ExpectedAmount,
  Journal,
  Machine,
  MachineUrl,
  Order,
  PatchUnion,
  ProjectUrl,
  ReportingSpecification,
  ResourceTypeUnion,
  Role,
  Task,
  Timer,
  Unit,
  UnitUrl,
  User,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {DebouncedAppbarSearchField, WhiteCheckbox} from "@co-frontend-libs/components";
import {Query} from "@co-frontend-libs/db-resources";
import {
  AppState,
  PathTemplate,
  QueryQueryStateStruct,
  actions,
  getContactArray,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getDeviceConfigKey,
  getExpectedAmountArray,
  getJournalArray,
  getMachineLookup,
  getOrderArray,
  getPathName,
  getReportingSpecificationArray,
  getShareToken,
  getSyncedState,
  getTaskArray,
  getTimerArray,
  getToken,
  getUnitLookup,
  getUserLookup,
  getWorkTypeLookup,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {FormControlLabel, Tab, Tabs} from "@material-ui/core";
import {
  BackToolbar,
  DoLoadInstance,
  FieldList,
  LocationList,
  PageLayout,
  ProjectsList,
} from "app-components";
import {LoadInstanceRelated, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages, useIntl} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {CustomerTab} from "./customer-tab";
import {FuelSurchargeTab} from "./fuel-surcharge-tab";
import {LocationStorageTab} from "./location-storage-tab";
import {LogTab} from "./log-tab";
import PeriodLogSumCard from "./period-log-sum-card";
import PeriodOrdersCard from "./period-orders-card";

const messages = defineMessages({
  cancel: {defaultMessage: "Fortryd", id: "dialog.label.cancel"},
  customerTab: {
    defaultMessage: "Detaljer",
    id: "customer-instance.tab-label.customer",
  },
  customerTitle: {
    defaultMessage: "Kunde",
  },
  fileRenameName: {
    defaultMessage: "Navn",
    id: "customer-instance.label.rename-name",
  },
  log: {
    defaultMessage: "Log",
    id: "customer-instance.tab-label.log",
  },
  ok: {defaultMessage: "OK", id: "dialog.label.ok"},
  orderTab: {
    defaultMessage: "Ordreoverblik",
    id: "customer-instance.tab-label.order",
  },
  renameDialogTitle: {
    defaultMessage: "Omnavngiv",
    id: "customer-instance.dialog-title.reanme",
  },
  statisticTab: {
    defaultMessage: "Statistik",
    id: "customer-instance.tab-label.statistic",
  },
  workplacesTab: {
    defaultMessage: "Steder",
    id: "customer-instance.tab-label.locations",
  },
});

/*
@connectQuery({
  tab: "customer",
  fromDate: null,
  toDate: null,
})
*/

interface CustomerInstanceState {
  showInactiveLocations: boolean;
}

class CustomerInstance extends React.Component<CustomerInstanceProps, CustomerInstanceState> {
  state: CustomerInstanceState = {
    showInactiveLocations: false,
  };
  UNSAFE_componentWillMount(): void {
    const {customerInstanceDateFields, fromDate, toDate} = this.props;
    if (!fromDate && !toDate) {
      const date = new Date();
      const newToDate =
        customerInstanceDateFields && customerInstanceDateFields.toDate
          ? customerInstanceDateFields.toDate
          : dateToString(date);
      const HALF_YEAR_MONTHS = 6;
      date.setMonth(date.getMonth() - HALF_YEAR_MONTHS);
      const newFromDate =
        customerInstanceDateFields && customerInstanceDateFields.fromDate
          ? customerInstanceDateFields.fromDate
          : dateToString(date);
      this.props.putQueryKeys({
        fromDate: newFromDate,
        toDate: newToDate,
      });
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleEditCustomerFabButton(): void {
    const id = urlToId(this.props.customer.url);
    this.props.go("/customer/entry/:id", {id});
  }

  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKey("tab", value);
  }

  @bind
  handleFilterStringChange(value: string): void {
    this.props.putQueryKey("q", value);
  }

  @bind
  handleShowInactiveLocationsCheckboxChange(_event: unknown, checked: boolean): void {
    this.setState({showInactiveLocations: checked});
  }

  @bind
  handleProjectClick(projectUrlURL: ProjectUrl): void {
    const id = urlToId(projectUrlURL);
    this.props.go("/projects/:id", {id});
  }

  render(): JSX.Element {
    const {customerSettings} = this.props;
    const {formatMessage} = this.context;
    const {
      customer,
      customerContacts,
      reportingSpecificationArray,
      tab,
      taskArray,
      workTypeLookup,
    } = this.props;
    const {currentRole} = this.props;
    const userIsOnlyMachineOperator = !currentRole || !currentRole.manager;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;

    const showAccessColumn = currentRole && (currentRole.manager || currentRole.projectManager);

    let content;
    const activeTab = tab;
    const active = !!customer.active;
    let action;
    const dialogs: JSX.Element[] = [];
    if (activeTab === "customer") {
      content = <CustomerTab customer={customer} customerContacts={customerContacts} />;
    } else if (activeTab === "log") {
      const {customerInstanceDateFields, fromDate, pathName, taskSyncedState, toDate, token} =
        this.props;
      content = (
        <LogTab
          customer={customer}
          customerInstanceDateFields={customerInstanceDateFields}
          fromDate={fromDate}
          pathName={pathName}
          taskSyncedState={taskSyncedState}
          toDate={toDate}
          token={token}
        />
      );
    } else if (activeTab === "order") {
      const {
        configPut: configPutRequest,
        customerInstanceDateFields,
        fromDate,
        machineLookup,
        orderArray,
        orderSyncedState,
        pathName,
        temporaryQueriesRequestedForPath,
        timerArray,
        toDate,
        userLookup,
        workTypes,
      } = this.props;
      content = (
        <div style={{padding: "1em"}}>
          <PeriodOrdersCard
            configPutRequest={configPutRequest}
            customer={customer}
            customerInstanceDateFields={customerInstanceDateFields}
            customerSettings={this.props.customerSettings}
            fromDate={fromDate}
            go={this.props.go}
            machineLookup={machineLookup}
            orderArray={orderArray}
            orderSyncedState={orderSyncedState}
            pathName={pathName}
            putQueryKey={this.props.putQueryKey}
            taskArray={taskArray}
            temporaryQueriesRequestedForPath={temporaryQueriesRequestedForPath}
            timerArray={timerArray}
            toDate={toDate}
            userLookup={userLookup}
            workTypeLookup={workTypeLookup}
            workTypes={workTypes}
          />
        </div>
      );
    } else if (activeTab === "sum") {
      content = (
        <div style={{padding: "1em"}}>
          <PeriodLogSumCard
            configPutRequest={this.props.configPut}
            customer={this.props.customer}
            customerInstanceDateFields={this.props.customerInstanceDateFields}
            customerSettings={this.props.customerSettings}
            fromDate={this.props.fromDate}
            orderArray={this.props.orderArray}
            pathName={this.props.pathName}
            putQueryKey={this.props.putQueryKey}
            taskArray={this.props.taskArray}
            taskSyncedState={this.props.taskSyncedState}
            temporaryQueriesRequestedForPath={this.props.temporaryQueriesRequestedForPath}
            toDate={this.props.toDate}
            token={this.props.token}
          />
        </div>
      );
    } else if (activeTab === "locations") {
      content = (
        <LocationList customerURL={customer.url} showInactive={this.state.showInactiveLocations} />
      );
    } else if (activeTab === "locationStorage") {
      content = <LocationStorageTab customerURL={customer.url} />;
    } else if (activeTab === "fields") {
      content = <FieldList customer={customer} />;
    } else if (activeTab === "fuelsurcharge" && customerSettings.fuelSurcharge) {
      content = <FuelSurchargeTab customer={customer} />;
    } else if (activeTab === "projects") {
      content = (
        <ProjectsList
          customer={customer}
          showAccessColumn={!!showAccessColumn}
          onClick={this.handleProjectClick}
        />
      );
    }

    const managerOrCustomerStatsVisibleToAll =
      !userIsOnlyMachineOperator || this.props.customerSettings.customerStatsVisibleToAll;
    let tabs;
    const hasLogTab =
      customerSettings.enableTransportLogStatistics ||
      customerSettings.autoAddYieldLogFor ||
      customerSettings.autoAddSprayLogFor ||
      (customerSettings.showGenericPeriodLogView && reportingSpecificationArray.length);
    if (managerOrCustomerStatsVisibleToAll || userIsSeniorMachineOperator) {
      tabs = (
        <Tabs
          scrollButtons="auto"
          value={activeTab}
          variant="scrollable"
          onChange={this.handleTabChange}
        >
          <Tab label={formatMessage(messages.customerTab)} value="customer" />
          {managerOrCustomerStatsVisibleToAll ? (
            <Tab disabled={!active} label={formatMessage(messages.statisticTab)} value="sum" />
          ) : null}
          {managerOrCustomerStatsVisibleToAll &&
          this.props.customerSettings.customerOrderOverview ? (
            <Tab disabled={!active} label={formatMessage(messages.orderTab)} value="order" />
          ) : null}
          {managerOrCustomerStatsVisibleToAll && hasLogTab ? (
            <Tab disabled={!active} label={formatMessage(messages.log)} value="log" />
          ) : null}
          {managerOrCustomerStatsVisibleToAll ? (
            <Tab
              disabled={!active}
              label={formatMessage(messages.workplacesTab)}
              value="locations"
            />
          ) : null}
          {customerSettings.customerFields &&
          (managerOrCustomerStatsVisibleToAll || userIsSeniorMachineOperator) ? (
            <Tab
              disabled={!active}
              label={
                <FormattedMessage defaultMessage="Marker" id="customer-instance.label.fields" />
              }
              value="fields"
            />
          ) : null}
          {!userIsOnlyMachineOperator && customerSettings.enableLocationStorage ? (
            <Tab
              disabled={!active}
              label={
                <FormattedMessage
                  defaultMessage="Lager"
                  id="customer-instance.label.location-storage"
                />
              }
              value="locationStorage"
            />
          ) : null}
          {customerSettings.enableProjects ? (
            <Tab
              disabled={!active}
              label={
                customerSettings.projectLabelVariant === "PROJECT"
                  ? formatMessage({defaultMessage: "Projekter"})
                  : formatMessage({defaultMessage: "Sager"})
              }
              value="projects"
            />
          ) : null}
          {customerSettings.fuelSurcharge ? (
            <Tab
              disabled={!active}
              label={<FormattedMessage defaultMessage="Brændstoftillæg" />}
              value="fuelsurcharge"
            />
          ) : null}{" "}
        </Tabs>
      );
    }
    return (
      <PageLayout
        dialogs={dialogs}
        floatingActionButton={action}
        tabs={tabs}
        toolbar={
          <BackToolbar
            rightElement={
              activeTab === "locations" || activeTab === "fields" || activeTab === "projects" ? (
                <>
                  {activeTab === "locations" ? (
                    <FormControlLabel
                      checked={this.state.showInactiveLocations}
                      control={<WhiteCheckbox />}
                      label={
                        <FormattedMessage
                          defaultMessage="Vis inaktive"
                          id="customer-instance.label.show-inactive-locations"
                        />
                      }
                      onChange={this.handleShowInactiveLocationsCheckboxChange}
                    />
                  ) : null}
                  <DebouncedAppbarSearchField
                    debounceTimeout={200}
                    value={this.props.q}
                    onChange={this.handleFilterStringChange}
                  />
                </>
              ) : undefined
            }
            title={formatMessage(messages.customerTitle)}
          />
        }
      >
        {content}
      </PageLayout>
    );
  }
}

interface CustomerInstanceContainerStateProps {
  contactArray: readonly Contact[];
  currentRole: Role | null;
  customerInstanceDateFields?: {fromDate: string; toDate: string};
  customerSettings: Config;
  expectedAmountArray: readonly ExpectedAmount[];
  fromDate: string;
  journalArray: readonly Journal[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderArray: readonly Order[];
  orderSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  pathName: string;
  q: string;
  reportingSpecificationArray: readonly ReportingSpecification[];
  shareToken: string | null;
  tab: string;
  taskArray: readonly Task[];
  taskSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  timerArray: readonly Timer[];
  toDate: string;
  token: string | null;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userLookup: (url: UserUrl) => User | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  workTypes: string;
}

interface CustomerInstanceContainerDispatchProps {
  configPut: (data: {key: string; value: any}) => void;
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  putQueryKeys: (update: QueryParameters, navigationKind?: PartialNavigationKind) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface CustomerInstanceContainerOwnProps {
  instance: Customer;
}

type CustomerInstanceContainerProps = CustomerInstanceContainerDispatchProps &
  CustomerInstanceContainerOwnProps &
  CustomerInstanceContainerStateProps;

interface CustomerInstanceProps extends CustomerInstanceContainerProps {
  customer: Customer;
  customerContacts: readonly Contact[];
}

class CustomerInstanceContainer extends PureComponent<CustomerInstanceContainerProps> {
  render(): JSX.Element {
    const {contactArray, instance: customer} = this.props;
    const customerURL = customer.url;
    const customerContacts = contactArray.filter(
      (contact) => contact.customer === customerURL && contact.active,
    );
    return (
      <CustomerInstance customer={customer} customerContacts={customerContacts} {...this.props} />
    );
  }
}

const ConnectedCustomerInstanceContainer = connect<
  CustomerInstanceContainerStateProps,
  CustomerInstanceContainerDispatchProps,
  CustomerInstanceContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CustomerInstanceContainerStateProps>({
    contactArray: getContactArray,
    currentRole: getCurrentRole,
    customerInstanceDateFields: getDeviceConfigKey("customerInstanceDateFields"),
    customerSettings: getCustomerSettings,
    expectedAmountArray: getExpectedAmountArray,
    fromDate: makeQueryParameterGetter("fromDate"),
    journalArray: getJournalArray,
    machineLookup: getMachineLookup,
    orderArray: getOrderArray,
    orderSyncedState: getSyncedState.bind(null, "order"),
    pathName: getPathName,
    q: makeQueryParameterGetter("q"),
    reportingSpecificationArray: getReportingSpecificationArray,
    shareToken: getShareToken,
    tab: makeQueryParameterGetter("tab", "customer"),
    taskArray: getTaskArray,
    taskSyncedState: getSyncedState.bind(null, "task"),
    timerArray: getTimerArray,
    toDate: makeQueryParameterGetter("toDate"),
    token: getToken,
    unitLookup: getUnitLookup,
    userLookup: getUserLookup,
    workTypeLookup: getWorkTypeLookup,
    workTypes: makeQueryParameterGetter("workTypes"),
  }),
  {
    configPut: actions.configPut,
    create: actions.create,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    putQueryKeys: actions.putQueryKeys,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    update: actions.update,
  },
)(CustomerInstanceContainer);

const related: LoadInstanceRelated = [
  {
    memberName: "customer",
    resourceType: "customerFile",
    type: "hasForeignKey",
  },
  {
    memberName: "customer",
    resourceType: "contact",
    type: "hasForeignKey",
  },
  {
    memberName: "customer",
    resourceType: "location",
    type: "hasForeignKey",
  },
] as const;

function LoadCustomerInstance(): JSX.Element {
  const {formatMessage} = useIntl();
  return (
    <DoLoadInstance<Customer, CustomerUrl>
      Component={ConnectedCustomerInstanceContainer}
      loadingTitle={formatMessage(messages.customerTitle)}
      lookupSelector={getCustomerLookup}
      related={related}
      resourceName="customer"
    />
  );
}

export default LoadCustomerInstance;
