export const customerAddress = (
  instance?: {
    address: string;
    city: string;
    postalCode: string;
  } | null,
): string => {
  if (!instance) {
    return "";
  }
  const address = (instance.address || "").replace("\n", ", ");
  const {postalCode} = instance;
  const {city} = instance;
  if (address && postalCode && city) {
    return `${address}, ${postalCode} ${city}`;
  } else if (address && postalCode) {
    return `${address}, ${postalCode}`;
  } else if (address && city) {
    return `${address}, ${city}`;
  } else if (address) {
    return address;
  } else if (postalCode && city) {
    return `${postalCode} ${city}`;
  } else if (postalCode) {
    return postalCode;
  } else if (city) {
    return city;
  } else {
    return "";
  }
};
