import {
  PriceGroupUrl,
  Product,
  ProductGroupUrl,
  ProductUrl,
  WorkType,
  urlToId,
} from "@co-common-libs/resources";
import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {DecimalField, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {ConnectedSingleProductGroupDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentlyFetchingChanges,
  getCustomerSettings,
  getPriceGroupLookup,
  getProductArray,
  getProductGroupArray,
  getProductGroupLookup,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
  useResettingState,
} from "@co-frontend-libs/utils";
import {
  Button,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  Switch,
  useTheme,
} from "@material-ui/core";
import {createProduct, diffResourceInstanceProperties} from "app-utils";
import {ImportProductGroupDialog} from "feat-import-resources";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {UnitSelect} from "../unit-select";

const DISPLAY_SPINNER_MIN_MS = 1000;

// TODO(mr): refactor
//            - <ResourceName>CreateDialog
//            - <ResourceName>EditDialog
//            - <ResourceName>CreateWithIntegrationDialog (add to lib in economy-system-integration feature folder)
//            - <ResourceName>EditWithIntegrationDialog (add to lib in economy-system-integration feature folder)
export const ProductCreateEditDialog = React.memo(function ProductCreateEditDialog(props: {
  createForPriceItem?: boolean;
  forPriceGroupUrl?: PriceGroupUrl;
  onCancel: () => void;
  onOk: (productUrl: ProductUrl) => void;
  open: boolean;
  product?: Product | undefined;
}): JSX.Element {
  const {createForPriceItem, forPriceGroupUrl, onOk, open, product} = props;
  const [name, setName] = useResettingState(product?.name || "", open);
  const [unit, setUnit] = useResettingState(product?.relatedUnit || null, open);
  const [active, setActive] = useResettingState(product ? product.active : true, open);

  const [coGroupUrl, setCOGroupUrl] = useResettingState<ProductGroupUrl | null>(
    product?.group ?? null,
    open,
  );
  const [catalogNumber, setCatalogNumber] = useResettingState(product?.catalogNumber || "", open);
  const [price, setPrice] = useResettingState<number | null>(product?.price ?? null, open);
  const [errorMessage, setErrorMessage] = useResettingState<string | null>(null, open);
  const [productGroupWarningMessage, setProductGroupWarningMessage] = useResettingState<
    string | null
  >(null, open);

  const [saving, setSaving] = useState(false);
  const setSavingFalse = useCallWithFalse(setSaving);

  const [fetchingGroupProductCount, setFetchingGroupProductCount] = useState(false);

  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const setGroupDialogOpenTrue = useCallWithTrue(setGroupDialogOpen);
  const setGroupDialogOpenFalse = useCallWithFalse(setGroupDialogOpen);

  const customerSettings = useSelector(getCustomerSettings);

  const productArray = useSelector(getProductArray);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const workTypeArray = useSelector(getWorkTypeArray);

  const coProductGroup = useMemo(
    () => (coGroupUrl ? productGroupLookup(coGroupUrl) : null),
    [coGroupUrl, productGroupLookup],
  );

  const productNumberDuplicate: boolean = useMemo(() => {
    return (
      !!catalogNumber &&
      productArray.some((p) => p.catalogNumber === catalogNumber && p.url !== product?.url)
    );
  }, [catalogNumber, product?.url, productArray]);

  const handleActiveChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      setActive(checked);
    },
    [setActive],
  );

  const selectedWorktypeList = useMemo(() => {
    if (forPriceGroupUrl) {
      const pricegroup = priceGroupLookup(forPriceGroupUrl);
      if (pricegroup) {
        return workTypeArray.filter((workType) => workType.pricegroups.includes(pricegroup.url));
      }
    }
    return null;
  }, [forPriceGroupUrl, priceGroupLookup, workTypeArray]);

  const remoteUrls = useMemo(() => {
    if (selectedWorktypeList) {
      return new Set(selectedWorktypeList.map((selectedWorktype) => selectedWorktype.remoteUrl));
    }
    return new Set<string>();
  }, [selectedWorktypeList]);

  const defaultGroup: WorkType | null = useMemo(() => {
    if (selectedWorktypeList && selectedWorktypeList.length === 1) {
      return selectedWorktypeList[0];
    } else {
      return null;
    }
  }, [selectedWorktypeList]);

  const [group, setGroup] = useResettingState<ImportPreviewProductGroup | null>(defaultGroup, open);

  const intl = useIntl();

  const dispatch = useDispatch();

  const currentlyFetching = useSelector(getCurrentlyFetchingChanges);
  const [savedUrl, setSavedUrl] = useState<ProductUrl | null>(null);

  const [refreshPendingTimeout, setRefreshPendingTimeout] = useState(false);

  const {economicSync} = customerSettings;

  useEffect(() => {
    if (!currentlyFetching && !refreshPendingTimeout && saving && savedUrl) {
      onOk(savedUrl);
      setSaving(false);
      setSavedUrl(null);
    }
  }, [currentlyFetching, onOk, refreshPendingTimeout, savedUrl, saving, setSavingFalse]);

  const handleOk = useCallback(async () => {
    if (economicSync) {
      const {baseURL} = globalConfig.resources;
      if (product) {
        if (
          name !== product.name ||
          active !== product.active ||
          group ||
          coGroupUrl !== product.group ||
          price !== product.price ||
          unit !== product.relatedUnit
        ) {
          setSaving(true);
          try {
            const url = `${baseURL}economic/product/${urlToId(product.url)}`;
            const response = await jsonFetch(url, "POST", {
              active,
              groupIdentifier: group?.identifier || coProductGroup?.identifier,
              name,
              price,
              unit,
            });
            dispatch(actions.addToOffline(response.data));
            setSavedUrl(response.data.url);
            setRefreshPendingTimeout(true);
            window.setTimeout(() => {
              setRefreshPendingTimeout(false);
            }, DISPLAY_SPINNER_MIN_MS);
            dispatch(actions.requestChangesFetch());
          } catch (error) {
            setSaving(false);
            setErrorMessage(translateNetworkError(error, intl));
          }
        } else {
          onOk(product.url);
        }
      } else {
        setSaving(true);
        const url = createForPriceItem
          ? `${baseURL}economic/priceitem-product/create`
          : `${baseURL}economic/product/create`;
        try {
          const response = await jsonFetch(url, "POST", {
            active,
            catalogNumber,
            groupIdentifier: group?.identifier,
            name,
            price,
            unit,
          });
          dispatch(actions.addToOffline(response.data));
          setSavedUrl(response.data.url);
          setRefreshPendingTimeout(true);
          window.setTimeout(() => {
            setRefreshPendingTimeout(false);
          }, DISPLAY_SPINNER_MIN_MS);
          dispatch(actions.requestChangesFetch());
        } catch (error) {
          setSaving(false);
          setErrorMessage(translateNetworkError(error, intl));
        }
      }
    } else {
      if (product) {
        const patch = diffResourceInstanceProperties(
          {active, catalogNumber, group: coGroupUrl, name, relatedUnit: unit},
          product,
        );

        if (patch.length) {
          dispatch(actions.update(product.url, patch));
        }
        onOk(product.url);
      } else {
        const newProduct = createProduct({
          catalogNumber,
          group: coGroupUrl,
          name,
          relatedUnit: unit,
        });

        dispatch(actions.create(newProduct));
        onOk(newProduct.url);
      }
    }
  }, [
    active,
    catalogNumber,
    coGroupUrl,
    coProductGroup?.identifier,
    createForPriceItem,
    dispatch,
    economicSync,
    group,
    intl,
    name,
    onOk,
    price,
    product,
    setErrorMessage,
    unit,
  ]);

  const theme = useTheme();

  const duplicateNumberError = productNumberDuplicate
    ? intl.formatMessage({
        defaultMessage: "Der eksisterer allerede en vare med det ID",
      })
    : undefined;

  const productGroupArray = useSelector(getProductGroupArray);
  const handleEconomicProductGroupDialogOk = useCallback(
    async (productGroup: ImportPreviewProductGroup) => {
      setProductGroupWarningMessage("");

      const existingActiveProductGroup = productGroupArray.find(
        (pg) => pg.active && pg.remoteUrl && pg.identifier === productGroup.identifier,
      );
      if (!createForPriceItem && !existingActiveProductGroup) {
        setFetchingGroupProductCount(true);
        const {baseURL} = globalConfig.resources;

        const url = `${baseURL}economic/productGroup/productcount/${productGroup.identifier}`;
        try {
          const response = await jsonFetch(url);
          const count = response.data as number;
          if (count) {
            setProductGroupWarningMessage(
              intl.formatMessage(
                {
                  defaultMessage:
                    "Hvis du benytter denne varegruppe, så importeres/aktiveres der {newCount} andre varer",
                },
                {newCount: count},
              ),
            );
          }
          setFetchingGroupProductCount(false);
          setGroup(productGroup);
        } catch (error) {
          setErrorMessage(translateNetworkError(error, intl));
        }
      } else {
        setGroup(productGroup);
      }
      setGroupDialogOpen(false);
    },
    [
      createForPriceItem,
      intl,
      productGroupArray,
      setErrorMessage,
      setGroup,
      setProductGroupWarningMessage,
    ],
  );

  const handleProductGroupDialogOk = useCallback(
    (productGroup: ProductGroupUrl) => {
      setCOGroupUrl(productGroup);
      setGroupDialogOpen(false);
    },
    [setCOGroupUrl],
  );

  return (
    <>
      <ResponsiveDialog
        okDisabled={
          !name ||
          !catalogNumber ||
          !unit ||
          saving ||
          productNumberDuplicate ||
          (economicSync && ((!group && !coProductGroup) || price === null)) ||
          fetchingGroupProductCount
        }
        open={open && !groupDialogOpen}
        title={
          props.product ? (
            <FormattedMessage defaultMessage="Redigér vare" />
          ) : (
            <FormattedMessage defaultMessage="Opret vare" />
          )
        }
        onCancel={props.onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          {economicSync ? (
            <>
              {product?.barred ? (
                <div style={{color: theme.palette.warning.main}}>
                  <FormattedMessage defaultMessage="Varen er blevet slettet eller spærret i e-conomic" />
                </div>
              ) : null}
              <TrimTextField
                fullWidth
                autoFocus={!props.product}
                disabled={saving || !!product?.remoteUrl}
                error={productNumberDuplicate}
                helperText={!saving ? duplicateNumberError : null}
                label={intl.formatMessage({
                  defaultMessage: "ID *",
                })}
                margin="dense"
                value={catalogNumber}
                onChange={setCatalogNumber}
              />
            </>
          ) : (
            <TrimTextField
              fullWidth
              autoFocus={!props.product}
              error={productNumberDuplicate}
              helperText={duplicateNumberError}
              label={intl.formatMessage({
                defaultMessage: "ID *",
              })}
              margin="dense"
              value={catalogNumber}
              variant="outlined"
              onChange={setCatalogNumber}
            />
          )}
          <TrimTextField
            fullWidth
            autoFocus={!!props.product}
            disabled={saving}
            label={intl.formatMessage({
              defaultMessage: "Navn *",
            })}
            margin="dense"
            value={name}
            variant="outlined"
            onChange={setName}
          />
          {economicSync ? (
            <DecimalField
              fullWidth
              disabled={saving}
              label={intl.formatMessage({
                defaultMessage: "Pris *",
              })}
              margin="dense"
              maxDigits={9}
              value={price}
              variant="outlined"
              onChange={setPrice}
            />
          ) : null}
          <UnitSelect relatedUnit={unit} onSelectUnitChange={setUnit} />
          <Button
            color="secondary"
            style={{marginTop: 2}}
            variant="contained"
            onClick={setGroupDialogOpenTrue}
          >
            <FormattedMessage defaultMessage="Vælg varegruppe" />
          </Button>
          <div>
            {group
              ? `${group.identifier}: ${group.name}`
              : coProductGroup
                ? `${coProductGroup.identifier}: ${coProductGroup.name}`
                : null}
          </div>
          {fetchingGroupProductCount ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
          {productGroupWarningMessage ? (
            <div
              style={{
                color: theme.palette.warning.main,
                whiteSpace: "pre-line",
              }}
            >
              {productGroupWarningMessage}
            </div>
          ) : null}
          {!economicSync ? (
            <FormControlLabel
              control={<Switch checked={active} onChange={handleActiveChange} />}
              disabled={!!product?.barred}
              label={intl.formatMessage({defaultMessage: "Aktiv"})}
            />
          ) : null}

          <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
          {errorMessage ? (
            <h3
              style={{
                color: theme.palette.error.main,
                whiteSpace: "pre-line",
              }}
            >
              {errorMessage}
            </h3>
          ) : null}
          {saving ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>
      </ResponsiveDialog>
      {economicSync ? (
        <ImportProductGroupDialog
          open={groupDialogOpen}
          remoteUrlFavorites={remoteUrls}
          onCancel={setGroupDialogOpenFalse}
          onOk={handleEconomicProductGroupDialogOk}
        />
      ) : (
        <ConnectedSingleProductGroupDialog
          key="product-group-dialog"
          open={groupDialogOpen}
          onCancel={setGroupDialogOpenFalse}
          onOk={handleProductGroupDialogOk}
        />
      )}
    </>
  );
});
