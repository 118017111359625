import {Config} from "@co-common-libs/config";
import {
  Patch,
  PatchOperation,
  PatchUnion,
  PriceGroup,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Role,
  Unit,
  UnitUrl,
  VolumeDiscount,
} from "@co-common-libs/resources";
import {getUnitString, priceItemIsTime} from "@co-common-libs/resources-utils";
import {
  DecimalField,
  IntegerField,
  SingleTextFieldDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {ConnectedSingleProductDialog} from "@co-frontend-libs/connected-components";
import {
  AppState,
  actions,
  getCurrentRole,
  getCustomerSettings,
  getPriceGroupArray,
  getPriceItemLookup,
  getProductLookup,
  getUnitArray,
  getUnitLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withTheme,
} from "@material-ui/core";
import {PageLayout} from "app-components";
import {PureComponent, checkedModelUpdaterFactoryFactory} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {integrationCustomerSettings} from "shared-integration-customer-settings";
import VolumeDiscountDialog from "./volume-discount-dialog";

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
    id: "price-items-settings.card-title.active",
  },
  billable: {
    defaultMessage: "Overfør til økonomisystem",
    id: "price-items-settings.label.billable",
  },
  changeProduct: {
    defaultMessage: "Skift vare",
    id: "price-items.settings.button.label.change-product",
  },
  contributesToFuelSurchargeSalesPrice: {
    defaultMessage: "Bidrager til salgspris-brændstoftillæg",
  },
  conversionFactor: {
    defaultMessage: "Omregningsfaktor ({baseUnit}/{inputUnit})",
    id: "price-items-settings.label.conversion-factor",
  },
  conversionUnit: {
    defaultMessage: "Indtastningsenhed",
    id: "price-items.settings.label.conversion-unit",
  },
  count: {
    defaultMessage: "Antal",
    id: "price-items-settings.card-title.count",
  },
  discounts: {
    defaultMessage: "Rabatsatser",
    id: "price-items-settings.card-title.discounts",
  },
  editPriceItem: {
    defaultMessage: "Redigér prislinje",
    id: "price-items-settings.header.edit-price-item",
  },
  editPriceItemName: {
    defaultMessage: "Redigér prislinje navn",
    id: "price-items-settings.header.edit-price-item-name",
  },
  fuelSurcharge: {
    defaultMessage: "Brændstoftillæg",
  },
  genericEffectiveTimerTarget: {
    defaultMessage: "Værdi er effektiv tid fra tidtagningsknap",
    id: "price-items-settings.label.generic-effective-timer-target",
  },
  id: {
    defaultMessage: "ID",
    id: "price-items-settings.header.id",
  },
  includeInLogs: {
    defaultMessage: "Kan anvendes i logs",
    id: "price-items-settings.label.include-in-logs",
  },
  minimumCount: {
    defaultMessage: "Minimum (antal)",
    id: "price-items-settings.card-title.minimum-count",
  },
  name: {
    defaultMessage: "Navn",
    id: "price-items-settings.card-title.name",
  },
  noCatalogNumber: {
    defaultMessage: "INTET VARENUMMER",
    id: "price-items.settings.text.product-has-no-catalog-number",
  },
  onlyVisibleOnOverview: {
    defaultMessage: "Kun synlig på oversigtssiden",
    id: "price-items.settings.label.only-visible-on-overview",
  },
  optional: {
    defaultMessage: "Valgfri",
    id: "price-items-settings.label.optional",
  },
  relevantForExecution: {
    defaultMessage: "Synlig ved udførsel",
    id: "price-items-settings.label.relevant-for-execution",
  },
  required: {
    defaultMessage: "Skal udfyldes",
    id: "price-items-settings.label.required",
  },
  requiredGreaterThanZero: {
    defaultMessage: "Skal udfyldes med en værdi større end nul",
    id: "price-items-settings.label.required-larger-than-zero",
  },
  selectProduct: {
    defaultMessage: "Vælg vare",
    id: "price-items.settings.button.label.select-product",
  },
  takesTimeAfterMinutes: {
    defaultMessage:
      "Overtager tiden fra timeren der overstiger givne minutter — kun for ekstraknapper",
    id: "price-item-settings.label.takes-time-after-minutes",
  },
  takesTimeAfterMinutesUnusedError: {
    defaultMessage: "Har kun en effekt når brugt med prisgruppe opsat for feature",
  },
  timeAfterMinutesError: {
    defaultMessage: 'Dette felt må ikke anvendes sammen med "Anvend fordelingstabel"',
    id: "price-items-settings.error.time-after-minutes",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "price-items-settings.card-title.unit",
  },
  useManualDistribution: {
    defaultMessage: "Anvend fordelingstabel",
    id: "price-items-settings.label.use-manual-distribution",
  },
});

interface PriceItemEditBlockProps {
  currentRole: Role | null;
  customerSettings: Config;
  priceGroupArray: readonly PriceGroup[];
  priceItem: PriceItem;
  productLookup: (url: ProductUrl) => Product | undefined;
  theme: Theme;
  unitArray: readonly Unit[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
}

interface PriceItemEditBlockState {
  priceItemDialogOpen: boolean;
  productDialogOpen: boolean;
}

class PriceItemEditBlock extends PureComponent<PriceItemEditBlockProps, PriceItemEditBlockState> {
  state: PriceItemEditBlockState = {
    priceItemDialogOpen: false,
    productDialogOpen: false,
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  makeCheckedModelUpdater = checkedModelUpdaterFactoryFactory<PriceItem>(this.props.update);

  @bind
  handleEditPriceItemName(): void {
    this.setState({priceItemDialogOpen: true});
  }

  @bind
  handlePriceItemDialogOk(name: string): void {
    this.setState({priceItemDialogOpen: false});
    this.props.update(this.props.priceItem.url, [{member: "name", value: name}]);
  }

  @bind
  handlePriceItemDialogCancel(): void {
    this.setState({priceItemDialogOpen: false});
  }

  @bind
  handleTransferAsMinutesChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.props.update(this.props.priceItem.url, [
      {
        member: "transferAsMinutes",
        value: event.target.checked,
      },
    ]);
  }

  @bind
  handleMutuallyExclusiveOptionsChange(_event: React.FormEvent, value: string): void {
    const selected = value as
      | "genericEffectiveTimerTarget"
      | "optional"
      | "required"
      | "requiredGreaterThanZero";

    const patch: PatchOperation<PriceItem>[] = [
      {
        member: "genericEffectiveTimerTarget",
        value: selected === "genericEffectiveTimerTarget",
      },
      {member: "required", value: selected === "required"},
      {
        member: "requiredGreaterThanZero",
        value: selected === "requiredGreaterThanZero",
      },
    ];
    if (selected === "genericEffectiveTimerTarget") {
      patch.push(
        {member: "relevantForExecution", value: false},
        {member: "conversionFactor", value: null},
        {member: "conversionUnit", value: null},
      );
    } else {
      patch.push(
        {member: "relevantForExecution", value: true},
        {member: "timeAfterMinutes", value: null},
        {member: "transferAsMinutes", value: false},
      );
    }
    this.props.update(this.props.priceItem.url, patch);
  }

  @bind
  handleTimeAfterMinutesChange(value: number | null): void {
    this.props.update(this.props.priceItem.url, [{member: "timeAfterMinutes", value}]);
  }

  @bind
  handleSelectUnitChange(
    event: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
    _child: React.ReactNode,
  ): void {
    const {value} = event.target;
    const patch: Patch<PriceItem> = [{member: "relatedUnit", value: value as UnitUrl}];
    this.props.update(this.props.priceItem.url, patch);
  }

  @bind
  handleConversionUnitChange(
    event: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
    _child: React.ReactNode,
  ): void {
    const {value} = event.target;
    const patch: Patch<PriceItem> = [{member: "conversionUnit", value: (value as UnitUrl) || null}];
    this.props.update(this.props.priceItem.url, patch);
  }

  @bind
  handleProductSelectButton(): void {
    this.setState({productDialogOpen: true});
  }

  @bind
  handleProductDialogOk(urlOrURLs: ProductUrl | Set<ProductUrl>): void {
    this.setState({
      productDialogOpen: false,
    });
    const urls = typeof urlOrURLs === "string" ? [urlOrURLs] : Array.from(urlOrURLs);
    console.assert(urls.length === 1);
    const productURL = urls[0];
    if (productURL) {
      const product = this.props.productLookup(productURL);
      const unitURL = product?.relatedUnit || null;
      const patch: Patch<PriceItem> = [
        {member: "billable", value: true},
        {member: "product", value: productURL},
        {member: "relatedUnit", value: unitURL},
      ];
      this.props.update(this.props.priceItem.url, patch);
    }
  }

  @bind
  handleProductDialogCancel(): void {
    this.setState({
      productDialogOpen: false,
    });
  }

  @bind
  handleRemoveProductButton(): void {
    if (this.props.priceItem.product) {
      const patch: Patch<PriceItem> = [
        {member: "billable", value: false},
        {member: "product", value: null},
        {member: "relatedUnit", value: null},
      ];
      this.props.update(this.props.priceItem.url, patch);
    }
  }
  isEditAllowed(priceItemEditSettingName: keyof Config): boolean {
    const {priceItem} = this.props;
    const importedPriceItem = !!(priceItem.c5_recid || priceItem.remoteUrl);
    const anyPriceItemSyncEnabled =
      this.props.customerSettings.c5Sync ||
      this.props.customerSettings.navSync ||
      this.props.customerSettings.economicSync;
    if (anyPriceItemSyncEnabled && priceItemEditSettingName === "canEditPriceItemID") {
      return false;
    }
    if (anyPriceItemSyncEnabled && importedPriceItem) {
      if (
        this.props.customerSettings.c5Sync &&
        priceItemEditSettingName !== "canEditPriceItemRelevantForExecution" &&
        priceItemEditSettingName !== "priceItemConversionSetup" &&
        priceItemEditSettingName !== "canEditPriceItemInputConditions"
      ) {
        return false;
      }
      if (
        priceItemEditSettingName === "canEditPriceItemName" ||
        priceItemEditSettingName === "canEditPriceItemUnit" ||
        priceItemEditSettingName === "canEditPriceItemProduct"
      ) {
        return false;
      }
    } else {
      if (
        priceItemEditSettingName === "enablePriceItemMinimumCount" ||
        priceItemEditSettingName === "enablePriceItemVolumeDiscount"
      ) {
        return false;
      }
    }
    return !!this.props.customerSettings[priceItemEditSettingName];
  }
  @bind
  handleRemoteUrlChange(value: string): void {
    const {priceItem, update} = this.props;
    update(priceItem.url, [{member: "remoteUrl", value}]);
  }
  @bind
  handleDefaultCountChange(value: number | null): void {
    const {priceItem, update} = this.props;
    update(priceItem.url, [{member: "defaultCount", value}]);
  }
  @bind
  handleMinimumCountChange(value: number | null): void {
    const {priceItem, update} = this.props;
    update(priceItem.url, [{member: "minimumCount", value}]);
  }
  @bind
  handleConversionFactorChange(value: number | null): void {
    const {priceItem, update} = this.props;
    update(priceItem.url, [{member: "conversionFactor", value}]);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const intl = this.context;
    const {customerSettings, priceItem, unitArray, unitLookup} = this.props;
    const {minimumCount} = priceItem;
    const relevantForExecution =
      priceItem.relevantForExecution != null ? priceItem.relevantForExecution : true;
    const genericEffectiveTimerTarget = !!priceItem.genericEffectiveTimerTarget;
    const useManualDistribution =
      priceItem.useManualDistribution != null ? priceItem.useManualDistribution : false;
    const required = priceItem.required != null ? priceItem.required : true;
    const requiredGreaterThanZero = !!priceItem.requiredGreaterThanZero;
    let selectedOption:
      | "genericEffectiveTimerTarget"
      | "optional"
      | "required"
      | "requiredGreaterThanZero";
    if (genericEffectiveTimerTarget) {
      selectedOption = "genericEffectiveTimerTarget";
    } else if (requiredGreaterThanZero) {
      selectedOption = "requiredGreaterThanZero";
    } else if (required) {
      selectedOption = "required";
    } else {
      selectedOption = "optional";
    }

    const priceItemUrl = priceItem.url;

    const dropdownUnits = _.sortBy(
      unitArray.filter((u) => u.active),
      (u) => (u.symbol || u.name).toLowerCase(),
    );
    const {relatedUnit} = priceItem;

    let priceItemIDField;
    if (this.isEditAllowed("canEditPriceItemID")) {
      priceItemIDField = (
        <TrimTextField
          fullWidth
          id="price-items-settings-id-field"
          label={formatMessage(messages.id)}
          margin="dense"
          value={priceItem.remoteUrl}
          variant="outlined"
          onChange={this.handleRemoteUrlChange}
        />
      );
    }

    const productURL = this.props.priceItem.product;
    const product = productURL ? this.props.productLookup(productURL) : null;
    const productData = product
      ? {
          catalogNumber: product.catalogNumber || formatMessage(messages.noCatalogNumber),
          name: product.name,
          unit: getUnitString(product, this.props.unitLookup),
        }
      : null;
    let productDialog;
    let productBlock;
    if (this.isEditAllowed("canEditPriceItemProduct")) {
      productDialog = (
        <ConnectedSingleProductDialog
          open={this.state.productDialogOpen}
          onCancel={this.handleProductDialogCancel}
          onOk={this.handleProductDialogOk}
        />
      );
      const productText = productData
        ? `${productData.catalogNumber}: ${productData.name} - ${productData.unit}`
        : "Ingen valgt";
      const productSelectButton = (
        <Button color="secondary" variant="contained" onClick={this.handleProductSelectButton}>
          {formatMessage(productURL ? messages.changeProduct : messages.selectProduct)}
        </Button>
      );
      const removeProductButton = productURL ? (
        <IconButton onClick={this.handleRemoveProductButton}>
          <CloseCircleIcon />
        </IconButton>
      ) : null;
      productBlock = productText ? (
        <div style={{marginTop: 24}}>
          <h3>
            <FormattedMessage
              defaultMessage="Tilknyttet vare"
              id="price-items.settings.header.product-relation"
            />
          </h3>
          <div>
            {productText} {removeProductButton}
          </div>
          {productSelectButton}
        </div>
      ) : null;
    }

    const unitString = getUnitString(priceItem, this.props.unitLookup);
    let unitBlock;
    if (this.isEditAllowed("canEditPriceItemUnit")) {
      unitBlock = (
        <div>
          <FormControl style={{width: 140}}>
            <InputLabel id="product-unit-select-label">{formatMessage(messages.unit)}</InputLabel>
            <Select
              fullWidth
              id="product-unit-select"
              labelId="product-unit-select-label"
              value={relatedUnit || ""}
              onChange={this.handleSelectUnitChange}
            >
              {dropdownUnits.map((unit) => (
                <MenuItem key={unit.url} value={unit.url}>
                  {unit.symbol || unit.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    } else {
      unitBlock = unitString ? (
        <div style={{marginTop: 24}}>
          <h3>
            <FormattedMessage defaultMessage="Enhed" id="worktypes.label.unit" />
          </h3>
          {unitString}
        </div>
      ) : null;
    }

    const priceItemName = priceItem.name;

    let editNameButton;
    if (this.isEditAllowed("canEditPriceItemName")) {
      editNameButton = (
        <IconButton onClick={this.handleEditPriceItemName}>
          <PencilIcon />
        </IconButton>
      );
    }
    let activeToggle;
    if (this.isEditAllowed("canEditPriceItemActive")) {
      activeToggle = (
        <FormControlLabel
          control={
            <Switch
              checked={!!priceItem.active}
              onChange={this.makeCheckedModelUpdater(priceItemUrl, "active")}
            />
          }
          label={formatMessage(messages.active)}
        />
      );
    }

    const editPriceItemFields = (
      <div>
        {priceItemIDField}
        {unitBlock}
        {activeToggle}
      </div>
    );

    let relevantForExecutionToggle;
    if (this.isEditAllowed("canEditPriceItemRelevantForExecution")) {
      relevantForExecutionToggle = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={relevantForExecution}
                disabled={genericEffectiveTimerTarget}
                onChange={this.makeCheckedModelUpdater(priceItemUrl, "relevantForExecution")}
              />
            }
            label={formatMessage(messages.relevantForExecution)}
            labelPlacement="end"
          />
        </div>
      );
    }
    let billableToggle;
    if (this.isEditAllowed("canEditPriceItemBillable")) {
      billableToggle = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={!!priceItem.billable}
                disabled={
                  this.props.customerSettings.navSync
                    ? !priceItem.product && !priceItem.remoteUrl
                    : false
                }
                onChange={this.makeCheckedModelUpdater(priceItemUrl, "billable")}
              />
            }
            label={formatMessage(messages.billable)}
            labelPlacement="end"
          />
        </div>
      );
    }

    let onlyVisibleOnOverviewToggle;
    if (this.isEditAllowed("canEditPriceItemOnlyVisibleOnOverview")) {
      const onlyVisibleOnOverview =
        priceItem.onlyVisibleOnOverview != null ? priceItem.onlyVisibleOnOverview : false;
      onlyVisibleOnOverviewToggle = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={onlyVisibleOnOverview}
                onChange={this.makeCheckedModelUpdater(priceItemUrl, "onlyVisibleOnOverview")}
              />
            }
            label={formatMessage(messages.onlyVisibleOnOverview)}
            labelPlacement="end"
          />
        </div>
      );
    }

    let useManualDistributionToggle;
    if (this.isEditAllowed("canEditPriceItemUseManualDistribution")) {
      useManualDistributionToggle = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={useManualDistribution}
                disabled={!genericEffectiveTimerTarget}
                onChange={this.makeCheckedModelUpdater(priceItemUrl, "useManualDistribution")}
              />
            }
            label={formatMessage(messages.useManualDistribution)}
            labelPlacement="end"
          />
        </div>
      );
    }

    const {price} = priceItem;

    let minimumCountField;
    if (this.isEditAllowed("enablePriceItemMinimumCount")) {
      minimumCountField = (
        <DecimalField
          fullWidth
          label={formatMessage(messages.minimumCount)}
          margin="dense"
          value={minimumCount}
          onChange={this.handleMinimumCountChange}
        />
      );
    }

    let transferOptions: JSX.Element | undefined;
    if (this.props.customerSettings.brugerdataSync) {
      transferOptions = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={priceItem.transferWhenCountZero ?? false}
                disabled={!priceItem.billable}
                onChange={this.makeCheckedModelUpdater(priceItemUrl, "transferWhenCountZero")}
              />
            }
            label={
              <FormattedMessage
                defaultMessage="Overfør til økonomisystem også hvis antal 0"
                id="price-item-settings.label.transfer-when-count-zero"
              />
            }
            labelPlacement="end"
          />
        </div>
      );
    }
    if (this.props.customerSettings.economicSync) {
      transferOptions = (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={priceItem.transferWhenNetPriceZero ?? true}
                disabled={!priceItem.billable}
                onChange={this.makeCheckedModelUpdater(priceItemUrl, "transferWhenNetPriceZero")}
              />
            }
            label={
              <FormattedMessage
                defaultMessage="Overfør til økonomisystem også hvis pris 0"
                id="price-item-settings.label.transfer-when-price-zero"
              />
            }
            labelPlacement="end"
          />
        </div>
      );
    }

    let inputConditionsChoice: JSX.Element | undefined;
    if (this.isEditAllowed("canEditPriceItemInputConditions")) {
      inputConditionsChoice = (
        <RadioGroup
          name="mutuallyExclusiveOptions"
          style={{marginTop: 24}}
          value={selectedOption}
          onChange={this.handleMutuallyExclusiveOptionsChange}
        >
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.optional)}
            value="optional"
          />
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.genericEffectiveTimerTarget)}
            value="genericEffectiveTimerTarget"
          />
          {customerSettings.enablePriceItemTransferAsMinutes ? (
            <div style={{paddingLeft: "1em"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!priceItem.transferAsMinutes}
                    id="transferAsMinutes-checkbox"
                    name="transferAsMinutes"
                    onChange={this.handleTransferAsMinutesChange}
                  />
                }
                disabled={selectedOption !== "genericEffectiveTimerTarget" || !!priceItem.remoteUrl}
                label={intl.formatMessage({
                  defaultMessage: "Overfør som minutter i stedet for timer (afrundes ikke)",
                })}
              />
            </div>
          ) : null}
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.required)}
            value="required"
          />
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.requiredGreaterThanZero)}
            value="requiredGreaterThanZero"
          />
        </RadioGroup>
      );
    }
    let timeAfterMinutesSetup: JSX.Element | undefined;

    const {priceItemSettings} = integrationCustomerSettings(customerSettings);

    if (priceItemSettings.addTimeAfterMinutesSetup(this.props.currentRole)) {
      const timeAfterMinutesWillBeUsed = this.props.priceGroupArray.some(
        (priceGroup) =>
          (priceGroup.timeAfterMinutesEffect === "per_interval" ||
            priceGroup.timeAfterMinutesEffect === "on_sum") &&
          priceGroup.priceGroupItemSet.some((pgi) => pgi.priceItem === priceItemUrl),
      );
      timeAfterMinutesSetup = (
        <div style={{backgroundColor: this.props.theme.palette.consultant.main}}>
          <IntegerField
            fullWidth
            disabled={selectedOption !== "genericEffectiveTimerTarget"}
            errorText={
              priceItem.useManualDistribution && priceItem.timeAfterMinutes != null
                ? formatMessage(messages.timeAfterMinutesError)
                : !timeAfterMinutesWillBeUsed && priceItem.timeAfterMinutes != null
                  ? formatMessage(messages.takesTimeAfterMinutesUnusedError)
                  : undefined
            }
            label={formatMessage(messages.takesTimeAfterMinutes)}
            margin="dense"
            value={priceItem.timeAfterMinutes ?? null}
            onChange={this.handleTimeAfterMinutesChange}
          />
        </div>
      );
    }

    let conversionSetup;
    if (this.isEditAllowed("priceItemConversionSetup")) {
      const conversionUnitURL = priceItem.conversionUnit;
      const conversionUnit = conversionUnitURL ? unitLookup(conversionUnitURL) : null;
      const baseUnit = unitString || "";
      const inputUnit = conversionUnit ? conversionUnit.symbol || conversionUnit.name : "";
      conversionSetup = (
        <div>
          <FormattedMessage
            defaultMessage="Indtastes med omregning:"
            id="price-items-settings.header.entered-with-conversion"
            tagName="h3"
          />
          <DecimalField
            fullWidth
            decimalPlaces={3}
            disabled={selectedOption === "genericEffectiveTimerTarget"}
            label={formatMessage(messages.conversionFactor, {
              baseUnit,
              inputUnit,
            })}
            margin="dense"
            maxDigits={10}
            value={priceItem.conversionFactor}
            onChange={this.handleConversionFactorChange}
          />
          <FormControl disabled={selectedOption === "genericEffectiveTimerTarget"}>
            <InputLabel id="conversion-unit-select-label">
              {formatMessage(messages.conversionUnit)}
            </InputLabel>
            <Select
              fullWidth
              id="conversion-unit-select"
              labelId="conversion-unit-select-label"
              value={priceItem.conversionUnit || ""}
              onChange={this.handleConversionUnitChange}
            >
              <MenuItem value="">
                <FormattedMessage
                  defaultMessage="(Ingen omregning)"
                  id="price-items-settings.label.no-conversion"
                />
              </MenuItem>
              {dropdownUnits.map((u) => (
                <MenuItem key={u.url} value={u.url}>
                  {u.symbol || u.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    }

    const priceBlock =
      customerSettings.overviewShowPrices && !!(priceItem.c5_recid || priceItem.remoteUrl) ? (
        <div style={{marginTop: 24}}>
          <h3>
            <FormattedMessage defaultMessage="Pris" id="worktypes.label.price" />
          </h3>
          {price ? price : "-"} kr
        </div>
      ) : null;

    return (
      <div>
        <Card>
          <CardContent>
            <div>
              <div>
                <h3>
                  <FormattedMessage defaultMessage="Navn" id="worktypes.label.name" />
                </h3>
                {priceItemName} {editNameButton}
              </div>
            </div>
            {priceBlock}
            {editPriceItemFields}
            {productBlock}
            {minimumCountField}
            {relevantForExecutionToggle}
            {useManualDistributionToggle}
            {onlyVisibleOnOverviewToggle}
            {billableToggle}
            {transferOptions}
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      !priceItemIsTime(unitLookup, priceItem) && priceItem.includeInLogs !== false
                    }
                    disabled={priceItemIsTime(unitLookup, priceItem)}
                    onChange={this.makeCheckedModelUpdater(priceItemUrl, "includeInLogs")}
                  />
                }
                label={formatMessage(messages.includeInLogs)}
                labelPlacement="end"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!priceItem.booleanSelection}
                    onChange={this.makeCheckedModelUpdater(priceItemUrl, "booleanSelection")}
                  />
                }
                label={
                  <FormattedMessage
                    defaultMessage="Besvares med ja/nej"
                    id="price-items-settings.label.booleanSelection"
                  />
                }
                labelPlacement="end"
              />
            </div>
            {inputConditionsChoice}
            {timeAfterMinutesSetup}
            {conversionSetup}
          </CardContent>
        </Card>
        <SingleTextFieldDialog
          label={<FormattedMessage defaultMessage="Navn" id="settings-overview.label.name" />}
          open={this.state.priceItemDialogOpen}
          title={formatMessage(messages.editPriceItemName)}
          value={priceItemName}
          onCancel={this.handlePriceItemDialogCancel}
          onOk={this.handlePriceItemDialogOk}
        />
        {productDialog}
      </div>
    );
  }
}

const PriceItemEditBlockWithTheme = withTheme(PriceItemEditBlock);

interface DiscountRowProps {
  discount: VolumeDiscount;
  index: number;
  onEntryClick: (event: React.MouseEvent, index: number) => void;
  priceItem: PriceItem;
  update: (url: string, patch: PatchUnion) => void;
}

class DiscountRow extends PureComponent<DiscountRowProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleDeleteClick(event: React.MouseEvent): void {
    event.stopPropagation();
    const {discount, index, priceItem, update} = this.props;
    const oldDiscountList = priceItem.volumeDiscountSet || [];
    if (_.isEqual(oldDiscountList[index], discount)) {
      const newDiscountList = oldDiscountList.slice();
      newDiscountList.splice(index, 1);
      update(priceItem.url, [{member: "volumeDiscountSet", value: newDiscountList}]);
    }
  }
  @bind
  handleClick(event: React.MouseEvent): void {
    this.props.onEntryClick(event, this.props.index);
  }
  render(): JSX.Element {
    const {formatNumber} = this.context;
    const {discount, priceItem} = this.props;
    const {threshold} = discount;
    const {discountPercentage} = discount;
    const {price} = priceItem;
    const percent = 100;
    return (
      <TableRow onClick={this.handleClick}>
        <TableCell>{threshold != null ? formatNumber(threshold) : ""}</TableCell>
        <TableCell>{discountPercentage != null ? formatNumber(discountPercentage) : ""}%</TableCell>
        <TableCell>
          {price ? formatNumber((price * (percent - (discountPercentage || 0))) / percent) : "-"} kr
        </TableCell>
        <TableCell style={{padding: 0, width: 48}}>
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

interface VolumeDiscountBlockProps {
  onRequestAddPriceItemVolumeDiscountEntry: () => void;
  onRequestEditPriceItemVolumeDiscountEntry: (index: number) => void;
  priceItem: PriceItem;
  update: (url: string, patch: PatchUnion) => void;
}

class VolumeDiscountBlock extends PureComponent<VolumeDiscountBlockProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleEntryClick(_event: React.MouseEvent, index: number): void {
    const {onRequestEditPriceItemVolumeDiscountEntry} = this.props;
    onRequestEditPriceItemVolumeDiscountEntry(index);
  }
  @bind
  handleFabButton(): void {
    const {onRequestAddPriceItemVolumeDiscountEntry} = this.props;
    onRequestAddPriceItemVolumeDiscountEntry();
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {priceItem, update} = this.props;
    const rows = (priceItem.volumeDiscountSet || []).map((discount, index) => {
      return (
        <DiscountRow
          key={index}
          discount={discount}
          index={index}
          priceItem={priceItem}
          update={update}
          onEntryClick={this.handleEntryClick}
        />
      );
    });
    const addButtonStyle: React.CSSProperties = {
      position: "absolute",
      right: 23,
      top: 23,
    };
    const addButton = (
      <Fab size="small" style={addButtonStyle} onClick={this.handleFabButton}>
        <SvgIcon>
          <PlusIcon color="#fff" />
        </SvgIcon>
      </Fab>
    );
    return (
      <Card>
        <div style={{position: "relative"}}>{addButton}</div>
        <CardHeader title={formatMessage(messages.discounts)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Grænse"
                  id="price-items-settings.table-header.threshold"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Rabatsats"
                  id="price-items-settings.table-header.discount"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Pris"
                  id="price-items-settings.table-header.price"
                />
              </TableCell>
              <TableCell style={{width: 48}} />
            </TableRow>
          </TableHead>
          <TableBody style={{cursor: "pointer"}}>{rows}</TableBody>
        </Table>
      </Card>
    );
  }
}

interface EditPriceItemStateProps {
  currentRole: Role | null;
  customerSettings: Config;
  id: string;
  priceGroupArray: readonly PriceGroup[];
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  unitArray: readonly Unit[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface EditPriceItemDispatchProps {
  update: (url: string, patch: PatchUnion) => void;
}

type EditPriceItemProps = EditPriceItemDispatchProps & EditPriceItemStateProps;

interface EditPriceItemState {
  volumeDiscountDialogCallback:
    | ((data: {discountPercentage: number; threshold: number}) => void)
    | null;
  volumeDiscountDialogInstance: VolumeDiscount | null;
}

class EditPriceItem extends PureComponent<EditPriceItemProps, EditPriceItemState> {
  state: EditPriceItemState = {
    volumeDiscountDialogCallback: null,
    volumeDiscountDialogInstance: null,
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  makeCheckedModelUpdater = checkedModelUpdaterFactoryFactory<PriceItem>(this.props.update);

  @bind
  handleRequestPriceItemVolumeDiscountDialog(
    callback: (data: {discountPercentage: number; threshold: number}) => void,
    instance: VolumeDiscount | null,
  ): void {
    this.setState({
      volumeDiscountDialogCallback: callback,
      volumeDiscountDialogInstance: instance,
    });
  }
  @bind
  handleVolumeDiscountDialogCancel(): void {
    this.setState({
      volumeDiscountDialogCallback: null,
      volumeDiscountDialogInstance: null,
    });
  }
  @bind
  handleVolumeDiscountDialogOk(data: {discountPercentage: number; threshold: number}): void {
    const callback = this.state.volumeDiscountDialogCallback;
    this.setState({
      volumeDiscountDialogCallback: null,
      volumeDiscountDialogInstance: null,
    });
    if (callback) {
      callback(data);
    }
  }

  @bind
  handlePriceItemVolumeDiscountDialogOk(
    index: number | null,
    data: {discountPercentage: number; threshold: number},
  ): void {
    const priceItemURL = instanceURL("priceItem", this.props.id);
    const selectedPriceItem = this.props.priceItemLookup(priceItemURL);
    const oldDiscountList = (selectedPriceItem && selectedPriceItem.volumeDiscountSet) || [];
    const newInstance = data;
    let newDiscountList;
    if (index === null) {
      newDiscountList = [...oldDiscountList, newInstance];
    } else {
      newDiscountList = oldDiscountList.slice();
      newDiscountList[index] = newInstance;
    }
    newDiscountList = _.sortBy(newDiscountList, [(d) => d.threshold, (d) => d.discountPercentage]);
    this.props.update(priceItemURL, [{member: "volumeDiscountSet", value: newDiscountList}]);
  }
  @bind
  handleRequestAddPriceItemVolumeDiscountEntry(): void {
    const callback = this.handlePriceItemVolumeDiscountDialogOk.bind(this, null);
    const instance = null;
    this.handleRequestPriceItemVolumeDiscountDialog(callback, instance);
  }
  @bind
  handleRequestEditPriceItemVolumeDiscountEntry(index: number): void {
    const priceItemURL = instanceURL("priceItem", this.props.id);

    const selectedPriceItem = this.props.priceItemLookup(priceItemURL);
    if (selectedPriceItem) {
      const callback = this.handlePriceItemVolumeDiscountDialogOk.bind(this, index);
      const instance = selectedPriceItem.volumeDiscountSet[index];
      this.handleRequestPriceItemVolumeDiscountDialog(callback, instance);
    }
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;

    const priceItemURL = instanceURL("priceItem", this.props.id);
    const selectedPriceItem = this.props.priceItemLookup(priceItemURL);
    let volumeDiscountBlock;
    let priceItemEditBlock;
    if (selectedPriceItem) {
      priceItemEditBlock = (
        <PriceItemEditBlockWithTheme
          currentRole={this.props.currentRole}
          customerSettings={this.props.customerSettings}
          priceGroupArray={this.props.priceGroupArray}
          priceItem={selectedPriceItem}
          productLookup={this.props.productLookup}
          unitArray={this.props.unitArray}
          unitLookup={this.props.unitLookup}
          update={this.props.update}
        />
      );
      if (this.props.customerSettings.enablePriceItemVolumeDiscount) {
        volumeDiscountBlock = (
          <VolumeDiscountBlock
            priceItem={selectedPriceItem}
            update={this.props.update}
            onRequestAddPriceItemVolumeDiscountEntry={
              this.handleRequestAddPriceItemVolumeDiscountEntry
            }
            onRequestEditPriceItemVolumeDiscountEntry={
              this.handleRequestEditPriceItemVolumeDiscountEntry
            }
          />
        );
      }
    }

    let fuelSurchargeSetupCard: JSX.Element | undefined;
    if (selectedPriceItem && this.props.customerSettings.fuelSurcharge === "PRICE_PERCENT") {
      fuelSurchargeSetupCard = (
        <Card style={{marginBottom: 16}}>
          <CardHeader title={formatMessage(messages.fuelSurcharge)} />
          <CardContent>
            <FormControlLabel
              control={
                <Switch
                  checked={selectedPriceItem.contributesToFuelSurchargeSalesPrice ?? true}
                  onChange={this.makeCheckedModelUpdater(
                    selectedPriceItem.url,
                    "contributesToFuelSurchargeSalesPrice",
                  )}
                />
              }
              label={formatMessage(messages.contributesToFuelSurchargeSalesPrice)}
              labelPlacement="end"
            />
          </CardContent>
        </Card>
      );
    }

    const mobileWidth = 360;

    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;
    return (
      <PageLayout toolbar={formatMessage(messages.editPriceItem)}>
        <GridNoType
          style={
            {
              height: bowser.mobile ? "auto" : "calc(100% - 10px)",
              margin: 5,
            } as React.CSSProperties
          }
        >
          <CellNoType
            palm="12/12"
            style={{
              height: bowser.mobile ? mobileWidth : "100%",
              paddingBottom: 11,
              paddingTop: 11,
            }}
          >
            {priceItemEditBlock}
          </CellNoType>
          <CellNoType
            palm="12/12"
            style={{
              height: bowser.mobile ? mobileWidth : "100%",
              paddingBottom: 11,
              paddingTop: 11,
            }}
          >
            {fuelSurchargeSetupCard}
            {volumeDiscountBlock}
          </CellNoType>
        </GridNoType>
        <VolumeDiscountDialog
          key="volume-discount-dialog"
          instance={this.state.volumeDiscountDialogInstance || undefined}
          open={!!this.state.volumeDiscountDialogCallback}
          onCancel={this.handleVolumeDiscountDialogCancel}
          onOk={this.handleVolumeDiscountDialogOk}
        />
      </PageLayout>
    );
  }
}

const ConnectedEditPriceItem = connect<
  EditPriceItemStateProps,
  EditPriceItemDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, EditPriceItemStateProps>({
    currentRole: getCurrentRole,
    customerSettings: getCustomerSettings,
    id: makePathParameterGetter("id"),
    priceGroupArray: getPriceGroupArray,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    unitArray: getUnitArray,
    unitLookup: getUnitLookup,
  }),
  {
    update: actions.update,
  },
)(EditPriceItem);

export {ConnectedEditPriceItem as EditPriceItemPage};
