import {Config} from "@co-common-libs/config";
import {
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItemUsesDict,
  ProductUsesDict,
  Project,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Role,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  UserProfile,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator, notUndefined} from "@co-common-libs/utils";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentRole,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getReportingSpecificationArray,
  getReportingSpecificationLookup,
  getTaskArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {InvoiceLineTable, Linkify} from "app-components";
import {PureComponent, WorkTypeChangeBlockedReason, getReferenceNumberLabel} from "app-utils";
import React from "react";
import {FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {allowMachineUseForWorktype} from "../order-instance/task-instance";
import TaskEntry from "./task-entry";

export interface TaskAndData {
  readonly machineList: readonly Machine[];
  readonly machineOperatorProfile: UserProfile | undefined;
  readonly machinePriceGroups: readonly PriceGroup[];
  readonly priceGroup: PriceGroup | undefined;
  readonly project: Project | undefined;
  readonly task: Task;
  readonly workType: WorkType | undefined;
}

interface TaskTableStateProps {
  currentRole: Role | null;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  reportingSpecificationArray: readonly ReportingSpecification[];
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskTableDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface TaskTableOwnProps {
  onRequestEstimatedTimeEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestMachineAdd: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestMachineOperatorEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestProjectEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestWorkplaceEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onTaskCopy: (taskURL: TaskUrl) => void;
  onWorkTypeChangeBlocked: (reason: WorkTypeChangeBlockedReason) => void;
  order: Order;
  taskAndDataArray: readonly TaskAndData[];
  tasksWhereMachineOperatorHasMultipleTasks: ReadonlySet<TaskUrl>;
  tasksWithAbsence: ReadonlySet<TaskUrl>;
  usesOrderValidation: boolean;
}

type TaskTableProps = TaskTableDispatchProps & TaskTableOwnProps & TaskTableStateProps;

class TaskTable extends PureComponent<TaskTableProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {
      taskAndDataArray,
      tasksWhereMachineOperatorHasMultipleTasks,
      timerLookup,
      usesOrderValidation,
    } = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;
    let taskTableHeaders: JSX.Element[];
    const workTypeCell = (
      <TableCell key="workType">
        <FormattedMessage defaultMessage="Område" />
      </TableCell>
    );
    let projectCell;
    if (customerSettings.enableProjects) {
      projectCell = (
        <TableCell key="project">
          {customerSettings.projectLabelVariant === "PROJECT" ? (
            <FormattedMessage defaultMessage="Projekt" />
          ) : (
            <FormattedMessage defaultMessage="Sag" />
          )}
        </TableCell>
      );
    }
    let priceGroupCell;
    if (customerSettings.orderEntryShowPriceGroup) {
      priceGroupCell = (
        <TableCell key="pricegroup">
          <FormattedMessage defaultMessage="Variant" />
        </TableCell>
      );
    }
    let workplaceCell;
    if (customerSettings.orderEntryShowWorkPlace) {
      workplaceCell = (
        <TableCell key="workplace">
          <FormattedMessage defaultMessage="Arbejdssted" />
        </TableCell>
      );
    }
    let pickupDeliveryCells;
    if (customerSettings.showLogPickupAndDeliveryColumnsOnOrderEntryTaskList) {
      pickupDeliveryCells = (
        <React.Fragment key="pickup-delivery">
          <TableCell>
            <FormattedMessage defaultMessage="Afh., log" />
          </TableCell>
          <TableCell key="workplace">
            <FormattedMessage defaultMessage="Lev., log" />
          </TableCell>
        </React.Fragment>
      );
    }
    let timeCell;
    if (customerSettings.showTimeColumnOnOrderEntryTaskList) {
      timeCell = (
        <TableCell key="time">
          <FormattedMessage defaultMessage="Klokkeslæt" />
        </TableCell>
      );
    }
    let refNumberCell;
    if (customerSettings.showTaskRefColumnOnOrderEntryTaskList) {
      refNumberCell = (
        <TableCell key="refnumber">
          {getReferenceNumberLabel(
            customerSettings.enableTaskReferenceNumber,
            customerSettings.taskReferenceNumberLabel,
            false,
            null,
            formatMessage,
          )}
        </TableCell>
      );
    }
    let invoiceNoteCell;
    if (customerSettings.showTaskInvoiceNoteColumnOnOrderEntryTaskList) {
      invoiceNoteCell = (
        <TableCell key="refnumber">
          <FormattedMessage defaultMessage="Int. fak. note" />
        </TableCell>
      );
    }
    let fieldsCell;
    if (customerSettings.showFieldsColumnOnOrderEntryTaskList) {
      fieldsCell = (
        <TableCell key="fields">
          <FormattedMessage defaultMessage="Marker" />
        </TableCell>
      );
    }
    const machineOperatorCell = (
      <TableCell key="machineoperator">
        {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
          <FormattedMessage defaultMessage="Maskinfører" />
        ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
          <FormattedMessage defaultMessage="Medarbejder" />
        ) : (
          <FormattedMessage defaultMessage="Chauffør" />
        )}
      </TableCell>
    );
    let estimatedTimeCell;
    if (customerSettings.enableTaskEstimation) {
      estimatedTimeCell = (
        <TableCell key="estimatedtime">
          <FormattedMessage defaultMessage="Estimeret tid" />
        </TableCell>
      );
    }
    const machinesCell = (
      <TableCell key="machines">
        {customerSettings.machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage="Maskiner" />
        ) : (
          <FormattedMessage defaultMessage="Køretøjer" />
        )}
      </TableCell>
    );
    const dateCell = (
      <TableCell key="date">
        <FormattedMessage defaultMessage="Dato" />
      </TableCell>
    );
    const detailsCell = (
      <TableCell
        key="details"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 48,
        }}
      />
    );
    let taskCopyCell;
    if (customerSettings.adminCanCreateCustomerTask) {
      taskCopyCell = (
        <TableCell
          key="copy"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            width: 48,
          }}
        />
      );
    }
    let taskMoveCell;
    if (customerSettings.adminCanCreateCustomerTask) {
      taskMoveCell = (
        <TableCell
          key="taskMove"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            width: 48,
          }}
        />
      );
    }
    const goToTaskCell = (
      <TableCell
        key="go-to-task"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 48,
        }}
      />
    );
    let statusCell;
    if (isManager) {
      statusCell = (
        <TableCell
          key="status"
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            width: 48,
          }}
        />
      );
    }
    if (customerSettings.noExternalTaskWorkType) {
      taskTableHeaders = [
        machinesCell,
        priceGroupCell,
        workplaceCell,
        pickupDeliveryCells,
        machineOperatorCell,
        projectCell,
        refNumberCell,
        invoiceNoteCell,
        fieldsCell,
        estimatedTimeCell,
        dateCell,
        timeCell,
        statusCell,
        goToTaskCell,
        taskCopyCell,
        taskMoveCell,
        detailsCell,
      ].filter(notUndefined);
    } else {
      // has task worktype, does not have order worktype
      taskTableHeaders = [
        workTypeCell,
        priceGroupCell,
        workplaceCell,
        pickupDeliveryCells,
        machineOperatorCell,
        projectCell,
        refNumberCell,
        invoiceNoteCell,
        fieldsCell,
        estimatedTimeCell,
        machinesCell,
        dateCell,
        timeCell,
        statusCell,
        goToTaskCell,
        taskCopyCell,
        taskMoveCell,
        detailsCell,
      ].filter(notUndefined);
    }
    const taskEntryArray: JSX.Element[] = [];
    let colCount = 0;
    for (let i = 0; i < taskTableHeaders.length; i += 1) {
      if (taskTableHeaders[i]) {
        colCount += 1;
      }
    }

    taskAndDataArray.forEach((taskAndData, index) => {
      const {
        machineList,
        machineOperatorProfile,
        machinePriceGroups,
        priceGroup,
        project,
        workType,
      } = taskAndData;

      const striped = customerSettings.orderValidation && index % 2 !== 0;
      const taskURL = taskAndData.task.url;
      const machineOperatorAbsent = this.props.tasksWithAbsence.has(taskURL);
      const allowMachineUse = allowMachineUseForWorktype(workType);

      const machineOperatorHasMultipleTasks =
        tasksWhereMachineOperatorHasMultipleTasks.has(taskURL);

      taskEntryArray.push(
        <TaskEntry
          key={taskURL}
          allowMachineUse={allowMachineUse}
          allowProjectChange={!!this.props.order.customer}
          currentRole={this.props.currentRole}
          customerSettings={customerSettings}
          customerURL={this.props.order.customer}
          go={this.props.go}
          locationLookup={this.props.locationLookup}
          machineList={machineList}
          machineLookup={this.props.machineLookup}
          machineOperatorAbsent={machineOperatorAbsent}
          machineOperatorHasMultipleTasks={machineOperatorHasMultipleTasks}
          machineOperatorProfile={machineOperatorProfile}
          machinePriceGroups={machinePriceGroups}
          order={this.props.order}
          priceGroup={priceGroup}
          priceGroupLookup={this.props.priceGroupLookup}
          project={project}
          reportingSpecificationArray={this.props.reportingSpecificationArray}
          reportingSpecificationLookup={this.props.reportingSpecificationLookup}
          striped={striped}
          task={taskAndData.task}
          taskArray={this.props.taskArray}
          timerArray={this.props.timerArray}
          timerStartArray={this.props.timerStartArray}
          update={this.props.update}
          workType={workType}
          workTypeLookup={this.props.workTypeLookup}
          onRequestEstimatedTimeEdit={this.props.onRequestEstimatedTimeEdit}
          onRequestMachineAdd={this.props.onRequestMachineAdd}
          onRequestMachineOperatorEdit={this.props.onRequestMachineOperatorEdit}
          onRequestProjectEdit={this.props.onRequestProjectEdit}
          onRequestWorkplaceEdit={this.props.onRequestWorkplaceEdit}
          onTaskCopy={this.props.onTaskCopy}
          onWorkTypeChangeBlocked={this.props.onWorkTypeChangeBlocked}
        />,
      );
      const {invoiceNote, notesFromMachineOperator, notesFromManager} = taskAndData.task;

      const timerNotes = taskAndData.task.timernotesSet
        .map((timerNote) => {
          const timer = timerLookup(timerNote.timer);
          if (timer) {
            return `${timer.label}: ${timerNote.notes}`;
          } else {
            return undefined;
          }
        })
        .filter(notUndefined)
        .sort(caseAccentInsensitiveCollator.compare);

      const cellStyle: React.CSSProperties = {
        paddingLeft: 48,
      };
      if (striped) {
        cellStyle.backgroundColor = "rgba(129, 212, 249, 0.4)";
      }

      if (
        customerSettings.showTaskNotesOnOrderEntryPage &&
        (invoiceNote || notesFromMachineOperator || notesFromManager || timerNotes.length)
      ) {
        taskEntryArray.push(
          <tr key={`${taskURL}-notes`}>
            <td colSpan={colCount} style={cellStyle}>
              <Linkify>
                {notesFromMachineOperator || timerNotes.length ? (
                  <p style={{whiteSpace: "pre-line"}}>
                    <strong>
                      {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                        <FormattedMessage defaultMessage="Maskinfører:" />
                      ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                        <FormattedMessage defaultMessage="Medarbejder:" />
                      ) : (
                        <FormattedMessage defaultMessage="Chauffør:" />
                      )}
                    </strong>
                    {notesFromMachineOperator ? <br /> : null}
                    {notesFromMachineOperator}
                    {timerNotes.map((timernote, i) => (
                      <span key={i} style={{whiteSpace: "pre-line"}}>
                        <br />
                        {timernote}
                      </span>
                    ))}
                  </p>
                ) : null}
                {notesFromManager ? (
                  <p style={{whiteSpace: "pre-line"}}>
                    <strong>
                      <FormattedMessage defaultMessage="Administration:" />
                    </strong>
                    <br />
                    {notesFromManager}
                  </p>
                ) : null}
                {invoiceNote ? (
                  <p style={{whiteSpace: "pre-line"}}>
                    <strong>
                      <FormattedMessage defaultMessage="Fakturering:" />
                    </strong>
                    <br />
                    {invoiceNote}
                  </p>
                ) : null}
              </Linkify>
            </td>
          </tr>,
        );
      }

      if (usesOrderValidation && taskAndData.task.completed) {
        const editDisabled = this.props.order.validatedAndRecorded || taskAndData.task.recordedInC5;
        taskEntryArray.push(
          <tr key={`${taskAndData.task.url}-values`}>
            <td colSpan={colCount} style={cellStyle}>
              <InvoiceLineTable
                editDisabled={!!editDisabled}
                striped={false}
                task={
                  taskAndData.task as Task & {
                    readonly priceItemUses: PriceItemUsesDict;
                    readonly productUses: ProductUsesDict;
                  }
                }
              />
            </td>
          </tr>,
        );
      } else if (customerSettings.enableInvoiceCorrections && taskAndData.task.completed) {
        const editDisabled =
          taskAndData.task.validatedAndRecorded || taskAndData.task.reportApproved;
        taskEntryArray.push(
          <tr key={`${taskAndData.task.url}-values`}>
            <td colSpan={colCount} style={cellStyle}>
              <InvoiceLineTable
                editDisabled={!!editDisabled}
                striped={false}
                task={
                  taskAndData.task as Task & {
                    readonly priceItemUses: PriceItemUsesDict;
                    readonly productUses: ProductUsesDict;
                  }
                }
              />
            </td>
          </tr>,
        );
      }
    });
    return (
      <Table>
        <TableHead>
          <TableRow>{taskTableHeaders}</TableRow>
        </TableHead>
        <TableBody>{taskEntryArray}</TableBody>
      </Table>
    );
  }
}

const ConnectedTaskTable: React.ComponentType<TaskTableOwnProps> = connect<
  TaskTableStateProps,
  TaskTableDispatchProps,
  TaskTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskTableStateProps>({
    currentRole: getCurrentRole,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    priceGroupLookup: getPriceGroupLookup,
    reportingSpecificationArray: getReportingSpecificationArray,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    taskArray: getTaskArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    update: actions.update,
  },
)(TaskTable);

export {ConnectedTaskTable as TaskTable};
