import {Config} from "@co-common-libs/config";
import {
  ComputedTime,
  Customer,
  ProductUrl,
  Task,
  Timer,
  TransportLog,
  UserProfile,
  WorkType,
} from "@co-common-libs/resources";
import {getProductsWithLogData} from "@co-common-libs/resources-utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  getContactLookup,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getDeliveryArray,
  getDeliveryLocationArray,
  getLocationArray,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeLookup,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getReportingSpecificationLookup,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getTaskFileArray,
  getTaskPhotoArray,
  getTimerArray,
  getTimerLookup,
  getUnitLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {colorMap, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {
  ErrorAction,
  ErrorEntry,
  InlinedTask,
  PureComponent,
  getBreakTimer,
  getErrors,
  getReadonlyProductsFromTask,
  getWarnings,
  inlineTaskData,
  inlineTransportLogData,
  isErrorEntryObjectWithAction,
} from "app-utils";
import {SendTask} from "feature/economy-system-integration/frontend/feat-send-task-data";
import _ from "lodash";
import React, {useMemo} from "react";
import {FormattedMessage, IntlContext, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {AccommodationAllowanceCheckbox} from "./accommodation-allowance-checkbox";
import {ErrorList} from "./error-list";
import {TaskInformation} from "./task-info";

interface TaskCheckDialogContentProps {
  accommodationAllowanceRegistratedOnTaskDate?: boolean | undefined;
  accommodationRemunerationGroup?: string;
  address?: string;
  breakTimer?: Timer | undefined;
  cancelled?: boolean | undefined;
  canRegisterAccommodation?: boolean | undefined;
  computedEndTime?: string | undefined;
  computedIntervals: readonly ComputedTime[];
  computedStartTime?: string | undefined;
  customer?: Customer;
  customerSettings: Config;
  date?: string | undefined;
  department?: string;
  errorHeaderText?: string | undefined;
  errors: readonly ErrorEntry[];
  finalEndTime?: string | undefined;
  finalIntervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer?: Timer | undefined;
  headerText?: string | undefined;
  machineOperatorProfile?: UserProfile | undefined;
  okHeaderText?: string | undefined;
  onAction?: ((action: ErrorAction) => void) | undefined;
  onCancel: () => void;
  onlyCompletedChecks?: boolean | undefined;
  onOk: () => void;
  onRegisterAccommodationChanged?:
    | ((registerAccommodation: boolean, remunerationGroup: string) => void)
    | undefined;
  registerAccommodation?: boolean | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  taskWithData?: InlinedTask | undefined;
  title: string;
  warnings: readonly ErrorEntry[];
  workType?: WorkType;
}

class TaskCheckDialogContent extends PureComponent<TaskCheckDialogContentProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element | null {
    const {
      accommodationAllowanceRegistratedOnTaskDate,
      accommodationRemunerationGroup,
      address,
      breakTimer,
      cancelled,
      canRegisterAccommodation,
      computedIntervals,
      date,
      department,
      errors,
      finalEndTime,
      finalIntervals,
      finalStartTime,
      genericPrimaryTimer,
      headerText,
      machineOperatorProfile,
      onlyCompletedChecks,
      onRegisterAccommodationChanged,
      registerAccommodation,
      secondaryTimers,
      task,
      taskWithData,
      warnings,
    } = this.props;

    if (!taskWithData) {
      return null;
    }

    const autofocusWarningAction = warnings.some(isErrorEntryObjectWithAction);
    let warningBlock: JSX.Element | undefined;
    if (warnings.length) {
      warningBlock = (
        <div style={{color: colorMap.WARNING}}>
          <FormattedMessage
            defaultMessage="Advarsler:"
            id="task-check-dialog.header.warnings"
            tagName="h4"
          />
          <ErrorList
            errors={warnings}
            onAction={autofocusWarningAction ? this.props.onAction : undefined}
          />
        </div>
      );
    }
    let errorBlock: JSX.Element | undefined;
    const autofocusErrorAction =
      !autofocusWarningAction && errors.some(isErrorEntryObjectWithAction);
    if (errors.length) {
      errorBlock = (
        <div style={{color: colorMap.ERROR}}>
          <FormattedMessage
            defaultMessage="Fejl:"
            id="task-check-dialog.header.errors"
            tagName="h4"
          />
          <ErrorList
            errors={errors}
            onAction={autofocusErrorAction ? this.props.onAction : undefined}
          />
        </div>
      );
    }

    let headerBlock;
    if (headerText) {
      if (cancelled) {
        headerBlock = <h3 style={{color: colorMap.ERROR}}>{headerText}</h3>;
      } else {
        headerBlock = <h3>{headerText}</h3>;
      }
    }

    const info = (
      <div style={{marginBottom: 10, marginTop: 10}}>
        <TaskInformation
          address={address}
          breakTimer={breakTimer}
          cancelled={cancelled}
          computedIntervals={computedIntervals}
          customer={(taskWithData.order && taskWithData.order.customer) || undefined}
          date={date}
          department={department}
          finalEndTime={finalEndTime}
          finalIntervals={finalIntervals}
          finalStartTime={finalStartTime}
          genericPrimaryTimer={genericPrimaryTimer}
          machineOperatorProfile={machineOperatorProfile}
          onlyCompletedChecks={onlyCompletedChecks}
          project={taskWithData.project || undefined}
          secondaryTimers={secondaryTimers}
          task={task}
          workType={taskWithData.workType || undefined}
        />
      </div>
    );

    let registerAccommodationBlock;
    if (canRegisterAccommodation && !!onRegisterAccommodationChanged) {
      registerAccommodationBlock = (
        <div style={{paddingBottom: "1em", paddingTop: "1em"}}>
          <AccommodationAllowanceCheckbox
            accommodationAllowanceRegistratedOnTaskDate={
              accommodationAllowanceRegistratedOnTaskDate || false
            }
            registerAccommodation={registerAccommodation || false}
            remunerationGroup={accommodationRemunerationGroup || ""}
            onRegisterAccommodationChanged={onRegisterAccommodationChanged}
          />
        </div>
      );
    }

    return (
      <div>
        {headerBlock}
        {errorBlock}
        {warningBlock}
        {registerAccommodationBlock}
        {info}
      </div>
    );
  }
}

interface TaskCheckDialogProps {
  accommodationAllowanceRegistratedOnTaskDate?: boolean;
  cancelled?: boolean;
  canRegisterAccommodation?: boolean;
  completedAsInternal?: boolean;
  computedEndTime?: string | undefined;
  computedIntervals: readonly ComputedTime[];
  computedStartTime?: string | undefined;
  date?: string;
  errorHeaderText?: string;
  finalEndTime?: string | undefined;
  finalIntervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer?: Timer | undefined;
  headerText?: string;
  okHeaderText?: string | undefined;
  onAction?: (action: ErrorAction) => void;
  onCancel: () => void;
  onlyCompletedChecks?: boolean;
  onOk: () => void;
  onRegisterAccommodationChanged?: (
    registerAccommodation: boolean,
    remunerationGroup: string,
  ) => void;
  open: boolean;
  // primaryWorkType?: WorkType;
  registerAccommodation?: boolean;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  title: string;
  transportLog?: TransportLog | undefined;
}

export const TaskCheckDialog = React.memo(function TaskCheckDialog(
  props: TaskCheckDialogProps,
): JSX.Element {
  const {
    accommodationAllowanceRegistratedOnTaskDate,
    cancelled,
    canRegisterAccommodation,
    completedAsInternal,
    computedEndTime,
    computedIntervals,
    computedStartTime,
    date,
    errorHeaderText,
    finalEndTime,
    finalIntervals,
    finalStartTime,
    genericPrimaryTimer,
    headerText,
    okHeaderText,
    onAction,
    onCancel,
    onlyCompletedChecks,
    onOk,
    onRegisterAccommodationChanged,
    open,
    registerAccommodation,
    secondaryTimers,
    task,
    title,
    transportLog,
  } = props;

  const contactLookup = useSelector(getContactLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const deliveryArray = useSelector(getDeliveryArray);
  const deliveryLocationArray = useSelector(getDeliveryLocationArray);
  const locationArray = useSelector(getLocationArray);
  const locationLookup = useSelector(getLocationLookup);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const machineArray = useSelector(getMachineArray);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const pickupArray = useSelector(getPickupArray);
  const pickupLocationArray = useSelector(getPickupLocationArray);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemArray = useSelector(getPriceItemArray);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const projectArray = useSelector(getProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const routeTaskLookup = useSelector(getRouteTaskLookup);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const taskFileArray = useSelector(getTaskFileArray);
  const timerLookup = useSelector(getTimerLookup);
  const timerArray = useSelector(getTimerArray);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);

  const currentRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);

  const intl = useIntl();

  let warnings: ErrorEntry[] = [];
  let errors: ErrorEntry[] = [];
  let autofocusWarningAction = false;
  let autofocusErrorAction = false;
  let taskWithData: InlinedTask | undefined;

  const productsWithLogData = useMemo(
    () => (!task.logSkipped ? getProductsWithLogData(task) : undefined),
    [task],
  );

  const readonlyProducts = useMemo(
    (): Set<ProductUrl> =>
      getReadonlyProductsFromTask(task, productLookup, unitLookup, reportingSpecificationLookup),
    [productLookup, reportingSpecificationLookup, task, unitLookup],
  );

  if (open) {
    taskWithData = task
      ? inlineTaskData(task, {
          contactLookup,
          customerLookup,
          machineLookup,
          orderLookup,
          priceGroupLookup,
          priceItemLookup,
          productLookup,
          projectLookup,
          reportingSpecificationLookup,
          timerLookup,
          workTypeLookup,
        })
      : undefined;
    const transportLogWithData = transportLog
      ? inlineTransportLogData(transportLog, {
          deliveryArray,
          deliveryLocationArray,
          pickupArray,
          pickupLocationArray,
        })
      : undefined;
    if (taskWithData && finalIntervals && finalIntervals.length) {
      taskWithData = {...taskWithData, _intervals: finalIntervals};
    }
    const role = currentRole;
    const userIsManager = !!(role && role.manager);
    if (taskWithData) {
      warnings = getWarnings(
        {
          genericPrimaryTimer,
          locationArray,
          locationLookup,
          locationStorageAdjustmentArray,
          locationStorageChangeArray,
          locationStorageStatusArray,
          locationTypeLookup,
          machineArray,
          machineLookup,
          priceGroupLookup,
          priceItemArray,
          priceItemLookup,
          productGroupLookup,
          productLookup,
          productsWithLogData,
          projectArray,
          readonlyProducts,
          routeTaskArray,
          secondaryTimers,
          task: taskWithData,
          timerArray,
          transportLog: transportLogWithData,
          unitLookup,
          userIsManager,
          workTypeLookup,
        },
        customerSettings,
        intl,
      );
      autofocusWarningAction = warnings.some(isErrorEntryObjectWithAction);
      errors = getErrors(
        {
          genericPrimaryTimer,
          locationArray,
          machineArray,
          priceGroupLookup,
          priceItemArray,
          priceItemLookup,
          productGroupLookup,
          productLookup,
          productsWithLogData,
          projectArray,
          readonlyProducts,
          routeTaskActivityOptionLookup,
          routeTaskArray,
          routeTaskLookup,
          routeTaskResultArray,
          secondaryTimers,
          task: taskWithData,
          taskFileArray,
          taskPhotoArray,
          transportLog: transportLogWithData,
          unitLookup,
          userIsManager,
          workTypeLookup,
        },
        customerSettings,
        intl,
        onlyCompletedChecks || false,
        completedAsInternal || false,
      );
      autofocusErrorAction = !autofocusWarningAction && errors.some(isErrorEntryObjectWithAction);
    }
  }
  const specificHeaderText = !errors.length ? okHeaderText : errorHeaderText;
  const realHeaderText = specificHeaderText || headerText;
  const machineOperatorURL = task ? task.machineOperator : null;
  const machineOperatorProfile = machineOperatorURL
    ? userUserProfileLookup(machineOperatorURL)
    : undefined;
  const breakTimer = getBreakTimer(timerArray);

  const dialogContent = (
    <TaskCheckDialogContent
      accommodationAllowanceRegistratedOnTaskDate={accommodationAllowanceRegistratedOnTaskDate}
      breakTimer={breakTimer}
      cancelled={cancelled}
      canRegisterAccommodation={canRegisterAccommodation}
      computedEndTime={computedEndTime}
      computedIntervals={computedIntervals}
      computedStartTime={computedStartTime}
      customerSettings={customerSettings}
      date={date}
      errorHeaderText={errorHeaderText}
      errors={errors}
      finalEndTime={finalEndTime}
      finalIntervals={finalIntervals}
      finalStartTime={finalStartTime}
      genericPrimaryTimer={genericPrimaryTimer}
      headerText={realHeaderText}
      machineOperatorProfile={machineOperatorProfile}
      okHeaderText={okHeaderText}
      onlyCompletedChecks={onlyCompletedChecks}
      registerAccommodation={registerAccommodation}
      secondaryTimers={secondaryTimers}
      task={task}
      taskWithData={taskWithData}
      title={title}
      warnings={warnings}
      onAction={onAction}
      onCancel={onCancel}
      onOk={onOk}
      onRegisterAccommodationChanged={onRegisterAccommodationChanged}
    />
  );

  const [sendTask, setSendTask] = useResettingState(false, open);
  const setSendTaskTrue = useCallWithTrue(setSendTask);

  return (
    <>
      <SendTask
        execute={sendTask}
        tasks={useMemo(() => [task], [task])}
        onErrorDialogDismiss={onCancel}
        onSuccess={onOk}
      />

      <ResponsiveDialog
        autoFocusOk={!autofocusWarningAction && !autofocusErrorAction}
        okDisabled={!!errors.length}
        open={open}
        title={title}
        onCancel={onCancel}
        onOk={setSendTaskTrue}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    </>
  );
}, _.isEqual);
