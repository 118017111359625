import {type BookkeepingArchiveData, customerAddress, formatDate} from "@co-common-libs/utils";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {ConnectedTableWithPagination, PaginationPageSize} from "app-components";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {
  BookkeepingTableDataType,
  buildColumnSpecifications,
  computeVisibleColumns,
} from "./bookkeeping-table";

interface BookkeepingArchiveTableProps {
  data: BookkeepingArchiveData[];
  filteringData: Record<string, unknown>;
}

export function BookkeepingArchiveTable(props: BookkeepingArchiveTableProps): JSX.Element {
  const {data, filteringData} = props;

  const customerSettings = useSelector(getCustomerSettings);

  const intl = useIntl();

  const dispatch = useDispatch();

  const handleTableClick = useCallback(
    (dateAndMachineOperator: string): void => {
      const [date, machineOperatorId] = dateAndMachineOperator.split(":");
      if (date && machineOperatorId) {
        dispatch(
          actions.go("/bookkeepingDay/:date/:machineOperatorId", {
            date,
            machineOperatorId,
          }),
        );
      }
    },
    [dispatch],
  );

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(intl.formatMessage, handleTableClick, customerSettings),
    [customerSettings, handleTableClick, intl.formatMessage],
  );

  const visibleColumns = useMemo(
    () =>
      computeVisibleColumns(
        !!bowser.mobile,
        !!bowser.tablet,
        customerSettings.bookkeepingListColumns,
      ),
    [customerSettings.bookkeepingListColumns],
  );

  const entries = useMemo((): BookkeepingTableDataType[] => {
    const {departments, useApproveReport} = customerSettings;

    const getDepartmentLabel = (departmentID: string): string =>
      departments[departmentID] || departmentID;

    const buildCustomerCultureProjectWorkplace = (
      taskData: BookkeepingArchiveData["taskData"][number],
    ): string => {
      let projectString: string | undefined;
      if (taskData.project) {
        const {name: projectName, projectNumber} = taskData.project;
        if (projectNumber && projectName) {
          projectString = `${projectNumber}: ${projectName}`;
        } else {
          projectString = projectName || projectNumber;
        }
      }
      let workplaceString: string | undefined;
      if (customerSettings.bookkeepingWorkplaceAddressReplaceName && taskData.workplace) {
        workplaceString = customerAddress(taskData.workplace);
      } else if (taskData.workplace) {
        workplaceString = taskData.workplace.name || customerAddress(taskData.workplace);
        if (taskData.workplace.customerName) {
          workplaceString = `(${taskData.workplace.customerName}) ${workplaceString}`;
        }
      }
      let pickupLocationString: string | undefined;
      if (taskData.pickupLocation) {
        pickupLocationString = taskData.pickupLocation.name || taskData.pickupLocation.address;
      }
      let logLocationsString;
      if (customerSettings.showLogLocationsOnBookkeeping && taskData.logLocations) {
        logLocationsString = taskData.logLocations
          .map((l) => l.name || l.address)
          .sort()
          .join(", ");
      }
      return [
        taskData.customerName,
        taskData.cultureName,
        projectString,
        workplaceString,
        pickupLocationString,
        taskData.fieldNumbers.join(" "),
        logLocationsString,
      ]
        .filter(Boolean)
        .join(", ");
    };

    return _.sortBy(
      data.map((entryData) => {
        const entry: BookkeepingTableDataType = {
          approved: useApproveReport ? entryData.validatedOrReportApproved : entryData.validated,
          completed: entryData.completed,
          customerCultureProjectWorkplace: entryData.taskData
            .map(buildCustomerCultureProjectWorkplace)
            .join("\n"),
          date: formatDate(entryData.date),
          dateRaw: entryData.date,
          departments: entryData.departments.map(getDepartmentLabel).join(", "),
          key: `${entryData.date}:${entryData.employee.id}`,
          machineOperatorInitials: entryData.employee.alias,
          machineOperatorName: entryData.employee.name,
          machineOperatorURL: instanceURL("user", entryData.employee.id),
          open: entryData.open,
          posted: entryData.posted,
          referenceNumbers: _.sortBy(
            entryData.referenceNumbers.concat(entryData.orderReferenceNumbers),
          ).join("\n"),
          total: entryData.total,
        };
        return entry;
      }),
      [(e) => e.machineOperatorInitials, (e) => e.machineOperatorURL, (e) => e.dateRaw],
    );
  }, [customerSettings, data]);

  return (
    <ConnectedTableWithPagination
      columns={columnSpecifications}
      defaultRowsPerPage={PaginationPageSize.SMALL}
      defaultSortDirection="ASC"
      defaultSortKey="date"
      entries={entries}
      filteringData={filteringData}
      savePaginationIdentifier="BookkeepingArchiveTable"
      saveSortingIdentifier="BookkeepingArchiveTable"
      visibleColumns={visibleColumns}
    />
  );
}
