import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  RoutePlan,
  RoutePlanTask,
  RoutePlanTaskUrl,
  urlToId,
} from "@co-common-libs/resources";
import {customerAddress, formatTime} from "@co-common-libs/utils";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {
  Card,
  CardHeader,
  CardMedia,
  Fab,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import {PureComponent} from "app-utils";
import DragHorizontalVariantIcon from "mdi-react/DragHorizontalVariantIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  cardTitle: {
    defaultMessage: "Rutepunkter",
    id: "route-plan.card-title.route-points",
  },
});

const DRAGHANDLE_COLUMN_STYLE = {
  width: 80,
};

const DEADLINE_COLUMN_STYLE = {
  width: 120,
};

interface RoutePlanTaskRowContentProps {
  customer?: Customer | undefined;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  onDrop: (item: {url: RoutePlanTaskUrl}, routePlanTask: RoutePlanTask) => void;
  routePlanTask: RoutePlanTask;
  workplace?: Location | undefined;
}

const RoutePlanTaskRowContent = React.memo(function RoutePlanTaskRowContent(
  props: RoutePlanTaskRowContentProps,
): JSX.Element {
  const {customer, go, onDrop, routePlanTask, workplace} = props;

  const [dropCollectedProps, drop] = useDrop({
    accept: "routePlanTask",
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: any, _monitor: DropTargetMonitor) => {
      onDrop(item, routePlanTask);
    },
  });
  const {isOver} = dropCollectedProps;

  const [dragCollectedProps, drag] = useDrag({
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      url: routePlanTask.url,
    },
    type: "routePlanTask",
  });
  const {isDragging} = dragCollectedProps;

  const handleClick = useCallback(
    (_event: unknown): void => {
      const routePlanID = urlToId(routePlanTask.routePlan);
      const taskID = urlToId(routePlanTask.url);
      go("/settings/route/:planID/:id", {id: taskID, planID: routePlanID});
    },
    [go, routePlanTask.routePlan, routePlanTask.url],
  );

  const customerName = customer ? customer.name : "";
  const address = customerAddress(workplace);
  const {description} = routePlanTask;
  const notes = routePlanTask.notesFromManager;
  const style: React.CSSProperties = {cursor: "pointer"};
  if (isDragging) {
    style.opacity = 0.2;
  }
  if (isOver) {
    style.backgroundColor = green[100];
  }
  const {deadline} = routePlanTask;

  const multiRef = useMergedRef<HTMLTableRowElement>(drop, drag);

  return (
    <TableRow ref={multiRef} style={style} onClick={handleClick}>
      <TableCell style={DRAGHANDLE_COLUMN_STYLE}>
        <DragHorizontalVariantIcon style={{marginTop: 8}} />
      </TableCell>
      <TableCell style={DEADLINE_COLUMN_STYLE}>{deadline ? formatTime(deadline) : ""}</TableCell>
      <TableCell>
        {description}
        {description ? <br /> : null}
        {address}
      </TableCell>
      <TableCell>{notes}</TableCell>
      <TableCell>{customerName}</TableCell>
    </TableRow>
  );
});

interface RoutePlanTaskRowContainerProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  locationLookup: (url: LocationUrl) => Location | undefined;
  onDrop: (item: {url: RoutePlanTaskUrl}, routePlanTask: RoutePlanTask) => void;
  routePlanTask: RoutePlanTask;
}

class RoutePlanTaskRowContainer extends PureComponent<RoutePlanTaskRowContainerProps> {
  render(): JSX.Element {
    const {customerLookup, locationLookup, onDrop, routePlanTask} = this.props;
    const customerURL = routePlanTask.customer;
    const customer = customerLookup(customerURL);
    const workplaceURL = routePlanTask.relatedLocation;
    const workplace = locationLookup(workplaceURL);
    return (
      <RoutePlanTaskRowContent
        customer={customer}
        go={this.props.go}
        routePlanTask={routePlanTask}
        workplace={workplace}
        onDrop={onDrop}
      />
    );
  }
}

interface RoutePlanTaskTableProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  locationLookup: (url: LocationUrl) => Location | undefined;
  onAdd?: (() => void) | undefined;
  onMove: (item: {url: RoutePlanTaskUrl}, routePlanTask: RoutePlanTask) => void;
  routePlan: RoutePlan;
  routePlanTaskList: readonly RoutePlanTask[];
}

export class RoutePlanTaskTable extends PureComponent<RoutePlanTaskTableProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerLookup, locationLookup, onAdd, onMove, routePlanTaskList} = this.props;
    const addButtonStyle: React.CSSProperties = {
      position: "absolute",
      right: 23,
      top: 23,
    };
    const rows = routePlanTaskList.map((routePlanTask) => {
      return (
        <RoutePlanTaskRowContainer
          key={routePlanTask.url}
          customerLookup={customerLookup}
          go={this.props.go}
          locationLookup={locationLookup}
          routePlanTask={routePlanTask}
          onDrop={onMove}
        />
      );
    });
    return (
      <Card style={{margin: "1em"}}>
        <div style={{position: "relative"}}>
          {onAdd ? (
            <Fab size="small" style={addButtonStyle} onClick={onAdd}>
              <SvgIcon>
                <PlusIcon color="white" />
              </SvgIcon>
            </Fab>
          ) : (
            <Fab disabled size="small" style={addButtonStyle}>
              <SvgIcon>
                <PlusIcon color="white" />
              </SvgIcon>
            </Fab>
          )}
        </div>
        <CardHeader title={formatMessage(messages.cardTitle)} />
        <CardMedia>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={DRAGHANDLE_COLUMN_STYLE} />
                <TableCell style={DEADLINE_COLUMN_STYLE}>
                  <FormattedMessage
                    defaultMessage="Inden kl."
                    id="route-plan.table-header.before-time"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Beskrivelse og adresse"
                    id="route-plan.table-header.description-address"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Noter fra adm."
                    id="route-plan.table-header.notes"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Kunde" id="route-plan.table-header.customer" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </CardMedia>
      </Card>
    );
  }
}
