import {
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductUrl,
  ProductUse,
  ReportingInputSpecification,
  ReportingLocations,
  ReportingLogEntry,
  ReportingSpecification,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {
  getInputSpecificationsMap,
  getNormalisedDeviceTimestamp,
  getUnitString,
  getValue,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {
  formatDate,
  formatTime,
  identifierComparator,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {
  getCustomerSettings,
  getLocationLookup,
  getPriceItemLookup,
  getProductLookup,
  getShareToken,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {ReportingValue, RotatedTableCell, getDataColumns} from "app-components";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React, {useMemo} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  downloadPDF: {
    defaultMessage: "Download PDF",
  },
  filename: {
    defaultMessage: "{name}_{fromDate}---{toDate}_{customerName}.pdf",
  },
  unknown: {
    defaultMessage: "Ukendt",
  },
});

const TIME_COLUMN_STYLE = {width: 86};

interface EntryData extends ReportingLogEntry {
  readonly reportingLocations: ReportingLocations;
}

interface SimpleLogTableEntryProps {
  data: EntryData;
  dataColumnPositions: ReadonlyMap<string, number>;
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>;
  materialColumnPositions: ReadonlyMap<string, number>;
}

const SimpleLogTableEntry = React.memo(function SimpleLogTableEntry(
  props: SimpleLogTableEntryProps,
): JSX.Element | null {
  const intl = useIntl();
  const locationLookup = useSelector(getLocationLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const {data, dataColumnPositions, inputSpecificationsMap, materialColumnPositions} = props;
  const locationID = data.location;
  const {values} = data;
  const locationData = data.reportingLocations?.[locationID];
  const dataColumns = [];
  const materialColumns: JSX.Element[] = [];
  if (!locationData) {
    return null;
  }
  const locationURL = locationData.location;
  const location = locationURL && locationLookup(locationURL);
  const locationLabel = location?.name || location?.address || "";
  dataColumnPositions.forEach((position, identifier) => {
    const locationValues = locationData.values;
    const valueMaps = locationValues ? [values, locationValues] : [values];
    const value = getValue(customerSettings, valueMaps, inputSpecificationsMap, identifier);
    const inputSpecification = inputSpecificationsMap.get(identifier);
    if (value != null) {
      dataColumns[position] = (
        <TableCell key={`${position}`}>
          {inputSpecification ? (
            <ReportingValue
              formatSpecification={inputSpecification.format}
              value={value as string[] | boolean | number | string}
            />
          ) : null}
        </TableCell>
      );
    }
  });
  for (let i = 0; i < dataColumnPositions.size; i += 1) {
    if (!dataColumns[i]) {
      dataColumns[i] = <TableCell key={`${i}`} />;
    }
  }
  if (data.priceItemUses) {
    for (const entry of Object.values(data.priceItemUses)) {
      const {count, priceItem: priceItemURL} = entry;
      if (count != null) {
        const position = materialColumnPositions.get(priceItemURL) as number;
        materialColumns[position] = (
          <TableCell key={`${position}`}>{intl.formatNumber(count)}</TableCell>
        );
      }
    }
  }

  if (data.productUses) {
    for (const entry of Object.values(data.productUses)) {
      const {count, product: productURL} = entry;
      if (count != null) {
        const position = materialColumnPositions.get(productURL) as number;
        materialColumns[position] = (
          <TableCell key={`${position}`}>{intl.formatNumber(count)}</TableCell>
        );
      }
    }
  }

  for (let i = 0; i < materialColumnPositions.size; i += 1) {
    if (!materialColumns[i]) {
      materialColumns[i] = <TableCell key={`${i}`} />;
    }
  }
  const {deviceTimestamp} = data;
  return (
    <TableRow key={deviceTimestamp}>
      <TableCell>{formatDate(deviceTimestamp)}</TableCell>
      <TableCell style={TIME_COLUMN_STYLE}>{formatTime(deviceTimestamp)}</TableCell>
      {dataColumns}
      {materialColumns}
      <TableCell>{locationLabel}</TableCell>
    </TableRow>
  );
});

interface TransportationTableEntryProps {
  data: {
    locationURL: string | undefined;
    pos: number;
    priceItemUseList: {
      [priceItem: string]: {
        count: number;
        priceItem: string;
      };
    };
    productUseList: {
      [product: string]: {
        count: number;
        product: string;
      };
    };
    values: {
      [identifier: string]: number;
    };
  };
  dataColumnPositions: ReadonlyMap<string, number>;
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>;
  locationName: string;
  materialColumnPositions: ReadonlyMap<string, number>;
}

const TransportationTableEntry = React.memo(function TransportationTableEntry(
  props: TransportationTableEntryProps,
): JSX.Element {
  const intl = useIntl();
  const {data, dataColumnPositions, inputSpecificationsMap, locationName, materialColumnPositions} =
    props;
  const dataColumns = [];
  const materialColumns: JSX.Element[] = [];
  const {values} = data;
  dataColumnPositions.forEach((position, identifier) => {
    const inputSpecification = inputSpecificationsMap.get(identifier);
    if (!inputSpecification) {
      return;
    }
    const formatSpecification = inputSpecification.format;
    const value = values[identifier];
    if (value != null || formatSpecification.type === "boolean") {
      if (position != null) {
        dataColumns[position] = (
          <TableCell key={`${position}`}>
            <ReportingValue formatSpecification={formatSpecification} value={value} />
          </TableCell>
        );
      }
    }
  });
  for (let i = 0; i < dataColumnPositions.size; i += 1) {
    if (!dataColumns[i]) {
      dataColumns[i] = <TableCell key={`${i}`} />;
    }
  }
  const {priceItemUseList, productUseList} = data;
  if (priceItemUseList) {
    Object.keys(priceItemUseList).forEach((entry) => {
      const priceItemURL = priceItemUseList[entry].priceItem;
      const {count} = priceItemUseList[entry];
      if (count != null) {
        const position = materialColumnPositions.get(priceItemURL) as number;
        materialColumns[position] = (
          <TableCell key={`${position}`}>{intl.formatNumber(count)}</TableCell>
        );
      }
    });
  }
  if (productUseList) {
    Object.keys(productUseList).forEach((entry) => {
      const productURL = productUseList[entry].product;
      const {count} = productUseList[entry];
      if (count != null) {
        const position = materialColumnPositions.get(productURL) as number;
        materialColumns[position] = (
          <TableCell key={`${position}`}>{intl.formatNumber(count)}</TableCell>
        );
      }
    });
  }
  for (let i = 0; i < materialColumnPositions.size; i += 1) {
    if (!materialColumns[i]) {
      materialColumns[i] = <TableCell key={`${i}`} />;
    }
  }
  return (
    <TableRow>
      <TableCell>{locationName}</TableCell>
      {dataColumns}
      {materialColumns}
    </TableRow>
  );
});

const updateMaterialHeaders = (
  priceItemUseList: readonly PriceItemUse[],
  productUseList: readonly ProductUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  materialHeaderColumns: JSX.Element[],
  materialColumnPositions: Map<string, number>,
): void => {
  priceItemUseList.forEach((priceItemUse) => {
    const priceItemURL = priceItemUse.priceItem;
    const priceItem = priceItemLookup(priceItemURL);
    if (
      !priceItem ||
      !priceItemIsVisible(priceItem, false, priceItemUseList, unitLookup, priceItemLookup) ||
      materialColumnPositions.has(priceItemURL)
    ) {
      return;
    }

    const {name} = priceItem;
    const unit = getUnitString(priceItem, unitLookup);
    const position = materialHeaderColumns.length;
    materialColumnPositions.set(priceItemURL, position);
    materialHeaderColumns.push(
      <RotatedTableCell key={priceItemURL}>
        {name}, {unit}
      </RotatedTableCell>,
    );
  });
  productUseList.forEach((productUse) => {
    const productURL = productUse.product;
    const product = productLookup(productURL);
    if (materialColumnPositions.has(productURL)) {
      return;
    }

    const name = product?.name || "";
    const unit = getUnitString(product, unitLookup);
    const position = materialHeaderColumns.length;
    materialColumnPositions.set(productURL, position);
    materialHeaderColumns.push(
      <RotatedTableCell key={productURL}>
        {name}, {unit}
      </RotatedTableCell>,
    );
  });
};

interface GenericTransportationLogSumListProps {
  reportingSpecification: ReportingSpecification;
  taskList: readonly Task[];
}

const GenericTransportationLogSumList = React.memo(function GenericTransportationLogSumList(
  props: GenericTransportationLogSumListProps,
): JSX.Element {
  const {reportingSpecification, taskList} = props;
  const intl = useIntl();
  const locationLookup = useSelector(getLocationLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const transportSum: {
    delivery: {
      [locationName: string]: {
        locationURL: string | undefined;
        pos: number;
        priceItemUseList: {
          [priceItem: string]: {
            count: number;
            priceItem: string;
          };
        };
        productUseList: {
          [product: string]: {
            count: number;
            product: string;
          };
        };
        values: {
          [identifier: string]: number;
        };
      };
    };
    pickup: {
      [locationName: string]: {
        locationURL: string;
        pos: number;
        priceItemUseList: {
          [priceItem: string]: {
            count: number;
            priceItem: string;
          };
        };
        productUseList: {
          [product: string]: {
            count: number;
            product: string;
          };
        };
        values: {
          [identifier: string]: number;
        };
      };
    };
  } = {delivery: {}, pickup: {}};
  const materialHeaderColumns: JSX.Element[] = [];
  const materialColumnPositions = new Map<string, number>();

  const reportingSpecificationURL = reportingSpecification.url;
  const [dataHeaderColumns, dataColumnPositions] = useMemo(
    () => getDataColumns(reportingSpecification),
    [reportingSpecification],
  );

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(reportingSpecification),
    [reportingSpecification],
  );

  const [includePriceItems, includeProducts] = useMemo(
    () => includeMaterials(reportingSpecification),
    [reportingSpecification],
  );

  taskList.forEach((task) => {
    if (task.reportingSpecification !== reportingSpecificationURL) {
      return;
    }

    if (task.reportingLog) {
      updateMaterialHeaders(
        includePriceItems && task.priceItemUses
          ? sortByOrderMember(Object.values(task.priceItemUses || {}))
          : [],
        includeProducts && task.productUses
          ? sortByOrderMember(Object.values(task.productUses || {}))
          : [],
        priceItemLookup,
        productLookup,
        unitLookup,
        materialHeaderColumns,
        materialColumnPositions,
      );
      const indexMapping = new Map(
        _.sortBy(
          Object.entries(task.reportingLocations || {}),
          ([_identifier, value]) => value.order,
        ).map(([identifier, _value], index) => [identifier, index]),
      );
      for (const data of sortByOrderMember(Object.values(task.reportingLog))) {
        const logType = data.type as "delivery" | "pickup";
        const locationID = data.location;
        const locationData = task.reportingLocations?.[locationID];
        if (!locationData) {
          continue;
        }
        const index = indexMapping.get(locationID) as number;
        const locationURL = locationData.location;
        const customerLocation = locationURL && locationLookup(locationURL);
        let locationName: string;
        if (customerLocation) {
          locationName = customerLocation.address || customerLocation.name;
        } else {
          locationName = intl.formatMessage(messages.unknown);
        }
        if (transportSum[logType][locationName] === undefined) {
          transportSum[logType][locationName] = {
            locationURL,
            pos: index + 1,
            priceItemUseList: {},
            productUseList: {},
            values: {},
          };
        }
        if (data.priceItemUses) {
          for (const priceItemUse of Object.values(data.priceItemUses)) {
            const {priceItem} = priceItemUse;
            const count = priceItemUse.count || 0;
            if (transportSum[logType][locationName].priceItemUseList[priceItem] === undefined) {
              transportSum[logType][locationName].priceItemUseList[priceItem] = {
                count,
                priceItem,
              };
            } else {
              transportSum[logType][locationName].priceItemUseList[priceItem].count += count;
            }
          }
        }
        if (data.productUses) {
          for (const productUse of Object.values(data.productUses)) {
            const {product} = productUse;
            const count = productUse.count || 0;
            if (transportSum[logType][locationName].productUseList[product] === undefined) {
              transportSum[logType][locationName].productUseList[product] = {
                count,
                product,
              };
            } else {
              transportSum[logType][locationName].productUseList[product].count += count;
            }
          }
        }
        const {values} = data;
        dataColumnPositions.forEach((_position, identifier) => {
          const locationValues = locationData.values;
          const valueMaps = locationValues ? [values, locationValues] : [values];
          const value = getValue(customerSettings, valueMaps, inputSpecificationsMap, identifier);
          if (typeof value === "number") {
            if (transportSum[logType][locationName].values[identifier] === undefined) {
              transportSum[logType][locationName].values[identifier] = value;
            } else {
              transportSum[logType][locationName].values[identifier] += value;
            }
          }
        });
      }
    }
  });

  const rows: JSX.Element[] = [];
  const {delivery, pickup} = transportSum;

  const pickupAddresses = Object.keys(pickup).sort(identifierComparator);
  pickupAddresses.forEach((address) => {
    rows.push(
      <TransportationTableEntry
        key={`pickup-${pickup[address].locationURL}`}
        data={pickup[address]}
        dataColumnPositions={dataColumnPositions}
        inputSpecificationsMap={inputSpecificationsMap}
        locationName={`A: ${address}`}
        materialColumnPositions={materialColumnPositions}
      />,
    );
  });
  const deliveryAddresses = Object.keys(delivery).sort();
  deliveryAddresses.forEach((address) => {
    rows.push(
      <TransportationTableEntry
        key={`delivery-${delivery[address].locationURL}`}
        data={delivery[address]}
        dataColumnPositions={dataColumnPositions}
        inputSpecificationsMap={inputSpecificationsMap}
        locationName={`L: ${address}`}
        materialColumnPositions={materialColumnPositions}
      />,
    );
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <RotatedTableCell>
            <FormattedMessage defaultMessage="Sted" id="task-instance.table-header.place" />
          </RotatedTableCell>
          {dataHeaderColumns}
          {materialHeaderColumns}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
});

const includeMaterials = (logSpecification: ReportingSpecification): [boolean, boolean] => {
  let includePriceItems = false;
  let includeProducts = false;
  (["workplace", "pickup", "delivery"] as const).forEach(
    (type: "delivery" | "pickup" | "workplace") => {
      const workplaceData = logSpecification.workplaceData && logSpecification.workplaceData[type];

      includePriceItems = includePriceItems || !!workplaceData?.logPriceItems;
      includeProducts = includeProducts || !!workplaceData?.logProducts;
    },
  );
  return [includePriceItems, includeProducts];
};

interface GenericTransportationLogListProps {
  reportingSpecification: ReportingSpecification;
  taskList: readonly Task[];
}

const GenericTransportationLogList = React.memo(function GenericTransportationLogList(
  props: GenericTransportationLogListProps,
): JSX.Element {
  const {reportingSpecification, taskList} = props;
  const materialHeaderColumns: JSX.Element[] = [];
  const materialColumnPositions = new Map<string, number>();

  const reportingSpecificationURL = reportingSpecification.url;
  const [dataHeaderColumns, dataColumnPositions] = useMemo(
    () => getDataColumns(reportingSpecification),
    [reportingSpecification],
  );

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(reportingSpecification),
    [reportingSpecification],
  );

  const [includePriceItems, includeProducts] = useMemo(
    () => includeMaterials(reportingSpecification),
    [reportingSpecification],
  );

  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);

  const entriesArray: EntryData[] = [];

  taskList.forEach((task) => {
    if (task.reportingSpecification !== reportingSpecificationURL) {
      return;
    }

    if (task.reportingLog) {
      updateMaterialHeaders(
        includePriceItems && task.priceItemUses
          ? sortByOrderMember(Object.values(task.priceItemUses || {}))
          : [],
        includeProducts && task.productUses
          ? sortByOrderMember(Object.values(task.productUses || {}))
          : [],
        priceItemLookup,
        productLookup,
        unitLookup,
        materialHeaderColumns,
        materialColumnPositions,
      );
      const reportingLocations = task.reportingLocations || {};
      entriesArray.push(
        ...Object.values(task.reportingLog).map((x) => ({
          ...x,
          reportingLocations,
        })),
      );
    }
  });

  const entries = _.sortBy(entriesArray, getNormalisedDeviceTimestamp).map((data, index) => {
    return (
      <SimpleLogTableEntry
        key={index}
        data={data}
        dataColumnPositions={dataColumnPositions}
        inputSpecificationsMap={inputSpecificationsMap}
        materialColumnPositions={materialColumnPositions}
      />
    );
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <RotatedTableCell>
            <FormattedMessage defaultMessage="Dato." id="customer-instance.table-header.date" />
          </RotatedTableCell>

          <RotatedTableCell>
            <FormattedMessage defaultMessage="Kl." id="customer-instance.table-header.time" />
          </RotatedTableCell>
          {dataHeaderColumns}
          {materialHeaderColumns}
          <RotatedTableCell>
            <FormattedMessage defaultMessage="Sted" id="customer-instance.table-header.place" />
          </RotatedTableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{cursor: "pointer"}}>{entries}</TableBody>
    </Table>
  );
});

interface GenericWorkplaceLogListProps {
  reportingSpecification: ReportingSpecification;
  taskList: readonly Task[];
}

const GenericWorkplaceLogList = React.memo(function GenericWorkplaceLogList(
  props: GenericWorkplaceLogListProps,
): JSX.Element {
  const {reportingSpecification, taskList} = props;
  const materialHeaderColumns: JSX.Element[] = [];
  const materialColumnPositions = new Map<string, number>();

  const reportingSpecificationURL = reportingSpecification.url;
  const [dataHeaderColumns, dataColumnPositions] = useMemo(
    () => getDataColumns(reportingSpecification),
    [reportingSpecification],
  );

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(reportingSpecification),
    [reportingSpecification],
  );

  const [includePriceItems, includeProducts] = useMemo(
    () => includeMaterials(reportingSpecification),
    [reportingSpecification],
  );

  const entriesArray: EntryData[] = [];

  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);

  taskList.forEach((task) => {
    if (task.reportingSpecification !== reportingSpecificationURL) {
      return;
    }
    if (task.reportingLog) {
      updateMaterialHeaders(
        includePriceItems && task.priceItemUses
          ? sortByOrderMember(Object.values(task.priceItemUses || {}))
          : [],
        includeProducts && task.productUses
          ? sortByOrderMember(Object.values(task.productUses || {}))
          : [],
        priceItemLookup,
        productLookup,
        unitLookup,
        materialHeaderColumns,
        materialColumnPositions,
      );
      const reportingLocations = task.reportingLocations || {};
      entriesArray.push(
        ...Object.values(task.reportingLog).map((x) => ({
          ...x,
          reportingLocations,
        })),
      );
    }
  });

  const entries = _.sortBy(entriesArray, getNormalisedDeviceTimestamp).map((data, index) => {
    return (
      <SimpleLogTableEntry
        key={index}
        data={data}
        dataColumnPositions={dataColumnPositions}
        inputSpecificationsMap={inputSpecificationsMap}
        materialColumnPositions={materialColumnPositions}
      />
    );
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Dato." id="customer-instance.table-header.date" />
          </TableCell>

          <TableCell style={TIME_COLUMN_STYLE}>
            <FormattedMessage defaultMessage="Kl." id="customer-instance.table-header.time" />
          </TableCell>
          {dataHeaderColumns}
          {materialHeaderColumns}
          <TableCell>
            <FormattedMessage defaultMessage="Sted" id="customer-instance.table-header.place" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{cursor: "pointer"}}>{entries}</TableBody>
    </Table>
  );
});

const TYPE_WORKPLACE = 1;
const TYPE_TRANSPORTATION = 2;

interface GenericLogListProps {
  customerID: string;
  customerName: string;
  fromDate: string;
  reportingSpecification: ReportingSpecification;
  taskList: readonly Task[];
  toDate: string;
}

export const GenericLogList = React.memo(function GenericLogList(
  props: GenericLogListProps,
): JSX.Element {
  const {customerID, customerName, fromDate, reportingSpecification, taskList, toDate} = props;

  const shareToken = useSelector(getShareToken);
  const intl = useIntl();

  const {baseURL} = globalConfig;
  const reportingSpecificationID = reportingSpecification.id;
  const filename = intl.formatMessage(messages.filename, {
    customerName,
    fromDate,
    name: reportingSpecification.name,
    toDate,
  });
  const pdfURL = `${baseURL}/download/periodic_report/${reportingSpecificationID}/pdf/${filename}?token=${shareToken}&customer=${customerID}&fromDate=${fromDate}&toDate=${toDate}`;

  const type = reportingSpecification.workplaceRegistration;
  console.assert(type === TYPE_TRANSPORTATION || type === TYPE_WORKPLACE);
  let table;
  if (type === TYPE_TRANSPORTATION) {
    if (reportingSpecification.customerLogSums !== false) {
      table = (
        <GenericTransportationLogSumList
          reportingSpecification={reportingSpecification}
          taskList={taskList}
        />
      );
    } else {
      table = (
        <GenericTransportationLogList
          reportingSpecification={reportingSpecification}
          taskList={taskList}
        />
      );
    }
  } else {
    table = (
      <GenericWorkplaceLogList
        reportingSpecification={reportingSpecification}
        taskList={taskList}
      />
    );
  }
  return (
    <>
      <CardActions>
        <Button
          color="secondary"
          href={pdfURL}
          startIcon={<FilePdfIcon />}
          target="_blank"
          variant="text"
        >
          {intl.formatMessage(messages.downloadPDF)}
        </Button>
      </CardActions>
      {table}
    </>
  );
});
