import {Config} from "@co-common-libs/config";
import {
  Contact,
  CultureUrl,
  Customer,
  CustomerUrl,
  Order,
  Patch,
  PatchOperation,
  PatchUnion,
  Task,
  Unit,
  UnitUrl,
  emptyOrder,
} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import _ from "lodash";

export function changeCustomerCulture(
  order: Order,
  customerUrl: CustomerUrl | null,
  cultureUrl: CultureUrl | null,
  data: {
    contactArray: readonly Contact[];
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    taskList: readonly Task[];
    unitLookup: (url: UnitUrl) => Unit | undefined;
  },
  customerSettings: Config,
  update: (url: string, patch: PatchUnion) => void,
): void {
  const {contactArray, customerLookup, taskList} = data;

  const anyValidatedAndRecorded = taskList.some((t) => t.validatedAndRecorded);
  if (anyValidatedAndRecorded) {
    return;
  }

  // TODO: if contacts are not loaded yet, but get loaded later;
  // set to default for customer if customer is selected and contact
  // is not selected then...?
  const defaultContact = customerUrl
    ? contactArray.find(
        (instance) =>
          instance.customer === customerUrl && instance.active && instance.defaultContact,
      )
    : null;
  const newContactUrl = defaultContact?.url || null;
  const customer = customerUrl ? customerLookup(customerUrl) : null;

  const orderPatch: PatchOperation<Order>[] = [
    {member: "contact", value: newContactUrl},
    {member: "culture", value: cultureUrl},
    {member: "customer", value: customerUrl},
    {member: "relatedWorkplace", value: null},
  ];
  if (
    customerSettings.enableOrderReferenceNumber &&
    customerSettings.autoFillReferenceNumberWithCustomerAccount
  ) {
    const referenceNumber = (customer && customer.c5_account) || "";
    orderPatch.push({member: "referenceNumber", value: referenceNumber});
  }
  update(order.url, orderPatch);

  taskList.forEach((task) => {
    const taskPatch: Patch<Task> = [
      {member: "address", value: customerAddress(customer)},
      {member: "project", value: null},
      // TODO: Clearing relatedPickupLocation and relatedWorkplace might not be
      // the right thing to do, depending on whether or not settings
      // locationFavoritesEnabled and/or locationCrossCustomerSelectionEnabled
      // are enabled.
      {member: "relatedPickupLocation", value: null},
      {member: "relatedWorkplace", value: null},
    ];
    update(task.url, taskPatch);
  });
}

export const isOrderEmpty = (
  customerSettings: Config,
  order?: Readonly<Partial<Order>>,
): boolean => {
  if (!order) {
    return false;
  }
  const {created, date, priority, ...other} = emptyOrder;
  const emptyOrderToCheck: Omit<Order, "created" | "createdBy" | "date" | "priority" | "url"> =
    other;

  const different: string[] = [];
  Object.entries(emptyOrderToCheck).forEach(([key, emptyValue]) => {
    const orderValue = order[key as keyof Order];
    if (orderValue !== undefined && !_.isEqual(emptyValue, orderValue)) {
      different.push(key);
    }
  });
  if (different.length === 0) {
    return true;
  }
  if (
    customerSettings.customerTaskCreationWizard &&
    different.every((key) => key === "customer" || key === "contact")
  ) {
    return true;
  }
  return false;
};
