import {Config} from "@co-common-libs/config";
import {MachineUrl} from "@co-common-libs/resources";
import {Button} from "@material-ui/core";
import {MachineChip} from "app-components";
import {InlinedMachineUse, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  selectMachine: {
    defaultMessage: "Vælg",
    id: "task-details.label.select-machine",
  },
});

interface MachineBlockProps {
  allowMachineUse: boolean;
  allowMaxOneMachine: boolean;
  customerSettings: Config;
  deletableMachineURLSet: ReadonlySet<string>;
  editable: boolean;
  machineUseList: readonly InlinedMachineUse[];
  onRequestMachineAdd: () => void;
  onRequestMachineRemove: (url: MachineUrl) => void;
}

class MachineBlock extends PureComponent<MachineBlockProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleMachineAddClick(): void {
    this.props.onRequestMachineAdd();
  }
  @bind
  handleMachineRemoveClick(url: MachineUrl): void {
    this.props.onRequestMachineRemove(url);
  }
  selectButton = React.createRef<HTMLButtonElement>();
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      allowMachineUse,
      allowMaxOneMachine,
      customerSettings,
      deletableMachineURLSet,
      editable,
      machineUseList,
    } = this.props;
    const machineChips = machineUseList.map((machineUse) => {
      const machineURL = machineUse.machine.url;
      return (
        <MachineChip
          key={machineURL}
          deletable={editable && deletableMachineURLSet.has(machineURL)}
          machineUse={machineUse}
          onDelete={this.handleMachineRemoveClick}
        />
      );
    });
    return (
      <Cell palm="12/12">
        {customerSettings.machineLabelVariant === "MACHINE" ? (
          <FormattedMessage
            defaultMessage="Maskine"
            id="task-details.header.machine"
            tagName="h4"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Køretøj"
            id="task-details.header.vehicle"
            tagName="h4"
          />
        )}
        <Button
          ref={this.selectButton}
          color="secondary"
          disabled={
            !allowMachineUse || (allowMaxOneMachine && machineUseList.length > 0) || !editable
          }
          variant="contained"
          onClick={this.handleMachineAddClick}
        >
          {formatMessage(messages.selectMachine)}
        </Button>
        {machineChips}
      </Cell>
    );
  }
}

export default MachineBlock;
