import {urlToId} from "@co-common-libs/resources";
import {OrderArchiveData} from "@co-common-libs/resources-utils";
import {DateField, SortDirection, TrimTextField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {PaginationPageSize, TableDefaults, useTableWithPagination} from "app-components";
import {
  useBooleanQueryParameterState,
  useFetchGet,
  useNullableQueryParameterState,
  useQueryParameterState,
} from "app-utils";
import bowser from "bowser";
import clsx from "clsx";
import {resourceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {OrderTableColumnID} from "../order-table";
import {ArchiveOrderTable} from "./archive-order-table";

const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    mobilePadding: {padding: "1em 11px 1em 11px"},
    pcPadding: {padding: "1em"},
  });
});

const mobile = bowser.tablet || bowser.mobile;

function getSortOption(
  sortDirection: "ASC" | "DESC",
  sortKey: OrderTableColumnID | null,
): string[] {
  if (sortKey) {
    const desc = sortDirection === "DESC";
    switch (sortKey as OrderTableColumnID) {
      case "address":
        return [
          `${desc ? "-" : ""}related_workplace__name`,
          `${desc ? "-" : ""}related_workplace__address`,
          `${desc ? "-" : ""}address`,
        ];
      case "createdDate":
      case "createdDateShort":
        return [`${desc ? "-" : ""}version_birth_date`];
      case "customer":
      case "customerShort":
        return [`${desc ? "-" : ""}customer__name`];
      case "customerAddress":
        return [
          `${desc ? "-" : ""}customer__address`,
          `${desc ? "-" : ""}customer__postal_code`,
          `${desc ? "-" : ""}customer__city`,
        ];
      case "customerAddressShort":
        return [`${desc ? "-" : ""}customer__address`];
      case "date":
      case "dateShort":
        return [`${desc ? "-" : ""}date`];
      case "department":
        return [`${desc ? "-" : ""}department`, `${desc ? "-" : ""}task__department`];
      case "employees":
        return [`${desc ? "-" : ""}task__machine_operator__login_identifier`];
      case "managerInternalNotes":
        return [`${desc ? "-" : ""}manager_internal_notes`];
      case "note":
        return [`${desc ? "-" : ""}notes`];
      case "projectNumbers":
        return [`${desc ? "-" : ""}task__project__project_number`];
      case "referenceNumber":
        return [`${desc ? "-" : ""}reference_number`];
      case "workType":
      case "workTypeShort":
      case "workTypes":
        return [
          `${desc ? "-" : ""}work_type__identifier`,
          `${desc ? "-" : ""}work_type__name`,
          `${desc ? "-" : ""}task__work_type__identifier`,
          `${desc ? "-" : ""}task__work_type__name`,
        ];
      default:
        // eslint-disable-next-line no-console
        console.warn(`specific backend sort not specified for key: ${sortKey}`);
        return [`${desc ? "-" : ""}created`];
    }
  } else {
    return ["-date", "customer__name"];
  }
}

type SortingState = {
  readonly sortDirection: SortDirection;
  readonly sortKey: OrderTableColumnID | null;
};

const orderArchiveTableDefaults: TableDefaults<OrderTableColumnID> = {
  defaultRowsPerPage: PaginationPageSize.SMALL,
  defaultSortDirection: "ASC",
  defaultSortKey: "status",
};

const orderArchiveTableIdentifier = "OrderArchiveTable";

export function OrderArchive({onDataLoaded}: {onDataLoaded: () => void}): JSX.Element {
  const classes = useStyles();

  const intl = useIntl();

  const [fromDate, setFromDate] = useNullableQueryParameterState("archiveFromDate", null);
  const [toDate, setToDate] = useNullableQueryParameterState("archiveToDate", null);
  const [textSearch, setTextSearch] = useQueryParameterState<string>("search", "");

  const [searchPerformedWithCurrentCriteria, setSearchPerformedWithCurrentCriteria] =
    useBooleanQueryParameterState("searchPerformedWithCurrentCriteria");

  const [fetchData, fetchDataCancel, data, fetching, fetchError] = useFetchGet<{
    data: OrderArchiveData[];
    total: number;
  }>();

  const [page, rowsPerPage, sortBy, sortDirection, setPage, setRowsPerPage, toggleSortingKey] =
    useTableWithPagination(
      orderArchiveTableIdentifier,
      orderArchiveTableIdentifier,
      orderArchiveTableDefaults.defaultSortKey,
      orderArchiveTableDefaults.defaultSortDirection,
      orderArchiveTableDefaults.defaultRowsPerPage,
      null,
    );

  const sortingState: SortingState = useMemo(() => {
    return {sortDirection, sortKey: sortBy || null};
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (data) {
      setSearchPerformedWithCurrentCriteria(true);
      onDataLoaded();
    }
  }, [data, onDataLoaded, setSearchPerformedWithCurrentCriteria]);

  useEffect(() => {
    fetchDataCancel();
    setSearchPerformedWithCurrentCriteria(false);
  }, [fromDate, toDate, textSearch, fetchDataCancel, setSearchPerformedWithCurrentCriteria]);

  const dispatch = useDispatch();

  const doFetch = useCallback(
    (
      fetchFromDate: string | null,
      fetchToDate: string | null,
      fetchSortingState: SortingState,
      fetchPage: number,
      fetchRowsPerPage: number,
      fetchTextSearch: string,
    ) => {
      if (!fetchFromDate || !fetchToDate || fetchFromDate <= fetchToDate) {
        const orderListURL = resourceURL("order");
        const sortOptions = getSortOption(
          fetchSortingState.sortDirection,
          fetchSortingState.sortKey,
        );
        const params: string[] = [
          `limit=${fetchRowsPerPage}`,
          `offset=${fetchPage * fetchRowsPerPage}`,
          ...sortOptions.map((sortOption) => `sortBy[]=${encodeURIComponent(sortOption)}`),
        ];
        if (fetchFromDate) {
          params.push(`archiveFromDate=${fetchFromDate}`);
        }
        if (fetchToDate) {
          params.push(`archiveToDate=${fetchToDate}`);
        }
        if (fetchTextSearch) {
          params.push(`search=${encodeURIComponent(fetchTextSearch)}`);
        }
        const orderArchiveSearchURL = `${orderListURL}archive_search/?${params.join("&")}`;
        fetchData(orderArchiveSearchURL);
      }
    },
    [fetchData],
  );

  const handleFetchClick = useCallback(() => {
    doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch);
  }, [doFetch, fromDate, toDate, sortingState, page, rowsPerPage, textSearch]);

  const handleSubmitOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleFetchClick();
      }
    },
    [handleFetchClick],
  );

  useEffect(() => {
    if (searchPerformedWithCurrentCriteria) {
      doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch);
    }
    // trigger on deep linking and table header click
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingState]);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage);
      doFetch(fromDate, toDate, sortingState, newPage, rowsPerPage, textSearch);
    },
    [doFetch, fromDate, rowsPerPage, setPage, sortingState, textSearch, toDate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const rows = parseInt(event.target.value);
      setPage(0);
      setRowsPerPage(rows);
      doFetch(fromDate, toDate, sortingState, 0, rows, textSearch);
    },
    [doFetch, fromDate, setPage, setRowsPerPage, sortingState, textSearch, toDate],
  );

  const handleOrderTableClick = useCallback(
    (orderURL: string): void => {
      const id = urlToId(orderURL);
      dispatch(actions.go("/orderEntry/:id", {id}));
    },
    [dispatch],
  );

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate, setToDate, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          setFromDate(date);
          setToDate(date);
        } else {
          setFromDate(date);
        }
      }
    },
    [setFromDate, setToDate, toDate],
  );

  const theme = useTheme();

  let errorText: string | undefined;

  if (fetchError) {
    if (fetchError instanceof NetworkError) {
      errorText = intl.formatMessage({
        defaultMessage: "Kunne ikke kontakte server",
      });
    } else if (fetchError instanceof StatusError) {
      errorText = intl.formatMessage({defaultMessage: "Serverfejl"});
    } else {
      errorText = intl.formatMessage({defaultMessage: "Ukendt fejl"});
    }
  }

  return (
    <div
      className={clsx({
        [classes.mobilePadding]: mobile,
        [classes.pcPadding]: !mobile,
      })}
    >
      <Card>
        <CardContent>
          <FormattedMessage defaultMessage="Periode" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                autoFocus={!bowser.ios}
                label={intl.formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                referenceDate={toDate || undefined}
                value={fromDate || null}
                yearSuggestions="DATE_BEFORE"
                onBlur={handleFromDateBlur}
                onChange={setFromDate}
                onKeyDown={handleSubmitOnEnter}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Til"})}
                margin="dense"
                referenceDate={fromDate || undefined}
                value={toDate || null}
                yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
                onChange={setToDate}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
            <Grid item xs={12}>
              <TrimTextField
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fritekst"})}
                margin="dense"
                value={textSearch}
                variant="outlined"
                onChange={setTextSearch}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={(fromDate && toDate && fromDate > toDate) || fetching}
            variant="contained"
            onClick={handleFetchClick}
          >
            <FormattedMessage defaultMessage="Søg" />
          </Button>
        </CardActions>
      </Card>
      {fetching ? (
        <div style={{marginTop: "1em", textAlign: "center"}}>
          <CircularProgress />
        </div>
      ) : null}
      {data ? (
        <div style={{marginTop: "1em"}}>
          <ArchiveOrderTable
            count={data.total}
            data={data.data}
            page={page}
            rowsPerPage={rowsPerPage}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onClick={handleOrderTableClick}
            onHeaderClick={toggleSortingKey}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
      {errorText ? (
        <Card style={{marginTop: "1em"}}>
          <CardContent>
            <div style={{color: theme.palette.error.main}}>{errorText}</div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}
