import {Config} from "@co-common-libs/config";
import {WorkType, WorkTypeUrl, urlToId} from "@co-common-libs/resources";
import {
  identifierComparator,
  identifierPartsComparator,
  identifierToComparisonParts,
  notNull,
} from "@co-common-libs/utils";
import {
  ColorBox,
  ColorPickerDialog,
  ColumnSpecifications,
  ErrorDialog,
  GenericTable,
  RowData,
  VerticalStackingFloatingActionButton,
  WorkTypeTypeChoices,
  WorkTypeTypeSelectionDialog,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerSettings,
  getTableSortingState,
  getTimerArray,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {WorkTypeCreateEditDialog, WorkTypeCreateEditInternalDialog} from "app-components";
import {getDepartmentName, getVerticalStackingFabstyle, useQueryParameter} from "app-utils";
import {ImportWorkTypesFab} from "feat-import-resources";
import CheckIcon from "mdi-react/CheckIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {integrationCustomerSettings} from "shared-integration-customer-settings";

const TABLE_SORTING_IDENTIFIER = "WorkTypeTable";

type WorkTypeTableColumnID =
  | "active"
  | "allowMaxOneMachine"
  | "autoDepartment"
  | "color"
  | "disallowMachine"
  | "identifier"
  | "internal"
  | "name"
  | "onlyForExtraTimers"
  | "requireAttachment"
  | "requireMachine"
  | "requirePhoto"
  | "requireWorkplace"
  | "showInList";

type WorkTypeTableFieldID =
  | "active"
  | "allowMaxOneMachine"
  | "autoDepartment"
  | "color"
  | "disallowMachine"
  | "identifier"
  | "internal"
  | "name"
  | "onlyForExtraTimers"
  | "requireAttachment"
  | "requireMachine"
  | "requirePhoto"
  | "requireWorkplace"
  | "showInList";

interface WorkTypeTableDataType extends RowData<WorkTypeTableFieldID, WorkTypeUrl> {
  active: boolean;
  allowMaxOneMachine: boolean;
  color: string;
  identifier: string;
  internal: boolean;
  name: string;
  requireAttachment: boolean;
  showInList: boolean;
}

function renderColor(data: WorkTypeTableDataType): JSX.Element {
  return <ColorBox showSlashOnEmpty color={data.color} />;
}

function renderActive(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function renderShowInList(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.showInList ? <CheckIcon /> : <></>;
}

function renderRequireMachine(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.requireMachine ? <CheckIcon /> : <></>;
}

function renderRequirePhoto(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.requirePhoto ? <CheckIcon /> : <></>;
}

function renderAllowMaxOneMachine(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.allowMaxOneMachine ? <CheckIcon /> : <></>;
}

function renderDisallowMachine(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.disallowMachine ? <CheckIcon /> : <></>;
}

function renderOnlyForExtraTimers(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.onlyForExtraTimers ? <CheckIcon /> : <></>;
}

function renderRequireWorkplace(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.requireWorkplace ? <CheckIcon /> : <></>;
}

function renderInternal(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.internal ? <CheckIcon /> : <></>;
}
function renderRequireAttachment(data: WorkTypeTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.requireAttachment ? <CheckIcon /> : <></>;
}

function compareID(a: WorkTypeTableDataType, b: WorkTypeTableDataType): number {
  return identifierComparator(a.identifier, b.identifier);
}

function buildColumnSpecifications(
  onClick: ((workTypeURL: WorkTypeUrl) => void) | undefined,
  onChangeColorClick: (url: WorkTypeUrl) => void,
  customerSettings: Config,
): ColumnSpecifications<
  WorkTypeTableFieldID,
  WorkTypeTableColumnID,
  WorkTypeUrl,
  WorkTypeTableDataType
> {
  return {
    active: {
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" id="work-type-list.table-header.active" />,
      onClick,
      render: renderActive,
    },
    allowMaxOneMachine: {
      field: "allowMaxOneMachine",
      label:
        customerSettings.machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage="Maks. 1 mask." />
        ) : (
          <FormattedMessage defaultMessage="Maks. 1 køret." />
        ),
      onClick,
      render: renderAllowMaxOneMachine,
    },
    autoDepartment: {
      field: "autoDepartment",
      label: <FormattedMessage defaultMessage="Auto afdeling" />,
      onClick,
    },
    color: {
      field: "color",
      label: "",
      onClick: onChangeColorClick,
      render: renderColor,
      style: {paddingLeft: 8, paddingRight: 0, paddingTop: 8} as const,
      width: 32,
    },
    disallowMachine: {
      field: "disallowMachine",
      label:
        customerSettings.machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage="Tillader ikke maskiner" />
        ) : (
          <FormattedMessage defaultMessage="Tillader ikke køretøjer" />
        ),
      onClick,
      render: renderDisallowMachine,
    },
    identifier: {
      comparator: compareID,
      field: "identifier",
      label: <FormattedMessage defaultMessage="ID" id="internal.table-header.id" />,
      onClick,
    },
    internal: {
      field: "internal",
      label: <FormattedMessage defaultMessage="Intern" id="work-type-list.table-header.internal" />,
      onClick,
      render: renderInternal,
    },
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Navn" id="internal.table-header.name" />,
      onClick,
    },
    onlyForExtraTimers: {
      field: "onlyForExtraTimers",
      label: (
        <FormattedMessage
          defaultMessage="Anvendes kun til ekstra-tidsknapper"
          id="work-type-list.table-header.only-for-extra-timers"
        />
      ),
      onClick,
      render: renderOnlyForExtraTimers,
    },
    requireAttachment: {
      field: "requireAttachment",
      label: <FormattedMessage defaultMessage="Kræver foto eller PDF" />,
      onClick,
      render: renderRequireAttachment,
    },
    requireMachine: {
      field: "requireMachine",
      label:
        customerSettings.machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage="Kræver maskine" />
        ) : (
          <FormattedMessage defaultMessage="Kræver køretøj" />
        ),
      onClick,
      render: renderRequireMachine,
    },
    requirePhoto: {
      field: "requirePhoto",
      label: (
        <FormattedMessage
          defaultMessage="Kræver foto"
          id="work-type-list.table-header.require-photo"
        />
      ),
      onClick,
      render: renderRequirePhoto,
    },
    requireWorkplace: {
      field: "requireWorkplace",
      label: (
        <FormattedMessage
          defaultMessage="Kræver Arbejdssted"
          id="work-type-list.table-header.require-workplace"
        />
      ),
      onClick,
      render: renderRequireWorkplace,
    },
    showInList: {
      field: "showInList",
      label: (
        <FormattedMessage defaultMessage="Vis i liste" id="internal.table-header.show-in-list" />
      ),
      onClick,
      render: renderShowInList,
    },
  };
}

function buildRowData(
  workTypeArray: readonly WorkType[],
  customerSettings: Config,
): WorkTypeTableDataType[] {
  return workTypeArray.map((workType) => {
    const {
      active,
      allowMaxOneMachine,
      color,
      department,
      disallowMachineUse,
      identifier,
      name,
      onlyForExtraTimers,
      requireAttachment,
      requireMachineUse,
      requirePhotoOnTaskCompletion,
      requireWorkplace,
      url,
    } = workType;
    const internal =
      !workType.externalTaskPrimary &&
      !(workType.externalTaskSecondary && workType.internalTaskSecondary);
    const showInList = !!workType.internalTaskPrimary || !!workType.externalTaskPrimary;
    return {
      active,
      allowMaxOneMachine: !!allowMaxOneMachine,
      autoDepartment: getDepartmentName(department, customerSettings),
      color,
      disallowMachine: !!disallowMachineUse,
      identifier,
      internal,
      key: url,
      name,
      onlyForExtraTimers: !!onlyForExtraTimers,
      requireAttachment: !!requireAttachment,
      requireMachine: !!requireMachineUse,
      requirePhoto: !!requirePhotoOnTaskCompletion,
      requireWorkplace: !!requireWorkplace,
      showInList,
    };
  });
}

function workTypeCompare(a: WorkType, b: WorkType): number {
  const aActive = a.active;
  const bActive = b.active;
  if (aActive === bActive) {
    return identifierPartsComparator(
      identifierToComparisonParts(a.identifier),
      identifierToComparisonParts(b.identifier),
    );
  } else if (aActive) {
    return -1;
  } else {
    return 1;
  }
}

interface WorkTypeTableProps {
  filterString: string;
  onChangeColorClick: (url: WorkTypeUrl) => void;
  showInactive: boolean;
}

function WorkTypeTable(props: WorkTypeTableProps): JSX.Element {
  const {filterString, onChangeColorClick, showInactive} = props;

  const workTypeArray = useSelector(getWorkTypeArray);
  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);

  const {canEditExternalWorkType, canEditInternalWorkTypes} = customerSettings;

  const dispatch = useDispatch();

  const {disabledWorkTypes} = customerSettings;

  const handleClick = useCallback(
    (url: WorkTypeUrl): void => {
      dispatch(
        actions.go("/settings/worktype/:id", {
          id: urlToId(url),
        }),
      );
    },
    [dispatch],
  );

  const timerArray = useSelector(getTimerArray);

  const sortedFilteredWorkTypes = useMemo(() => {
    const breakWorkTypeURL = timerArray.find((timer) => timer.isBreak)?.workType;

    return workTypeArray
      .filter(
        ({
          active,
          externalTaskPrimary,
          externalTaskSecondary,
          identifier,
          internalTaskPrimary,
          internalTaskSecondary,
          url,
        }) => {
          const isEffectiveWorkType = !(
            externalTaskPrimary ||
            externalTaskSecondary ||
            internalTaskPrimary ||
            internalTaskSecondary
          );

          return (
            (!identifier || (identifier && !disabledWorkTypes.includes(identifier))) &&
            url !== breakWorkTypeURL &&
            (active || showInactive) &&
            !isEffectiveWorkType
          );
        },
      )
      .sort(workTypeCompare);
  }, [disabledWorkTypes, showInactive, timerArray, workTypeArray]);

  const columnSpecifications = useMemo(
    () =>
      buildColumnSpecifications(
        canEditInternalWorkTypes || canEditExternalWorkType || currentRole?.consultant
          ? handleClick
          : undefined,
        onChangeColorClick,
        customerSettings,
      ),
    [
      canEditExternalWorkType,
      canEditInternalWorkTypes,
      currentRole?.consultant,
      customerSettings,
      handleClick,
      onChangeColorClick,
    ],
  );

  const rowData = useMemo(
    () => buildRowData(sortedFilteredWorkTypes, customerSettings),
    [customerSettings, sortedFilteredWorkTypes],
  );

  const visibleColumns = useMemo((): readonly WorkTypeTableColumnID[] => {
    return [
      "identifier",
      "color",
      "name",
      "requireMachine",
      "requirePhoto",
      "requireAttachment",
      "disallowMachine",
      "allowMaxOneMachine",
      "onlyForExtraTimers",
      "requireWorkplace",
      "showInList",
      customerSettings.enableExternalTaskDepartmentField ? "autoDepartment" : null,
      "internal",
      showInactive ? "active" : null,
    ].filter(notNull) as WorkTypeTableColumnID[];
  }, [customerSettings.enableExternalTaskDepartmentField, showInactive]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "identifier", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: WorkTypeTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
    />
  );
}

interface WorkTypeListProps {
  showInactive: boolean;
}

export const WorkTypeList = React.memo(function WorkTypeList(props: WorkTypeListProps) {
  const {showInactive} = props;

  const [colorPickerDialogOpenedFor, setColorPickerDialogOpenedFor] = useState<WorkTypeUrl | null>(
    null,
  );

  const [workTypeCreateSelectionDialogOpen, setWorkTypeCreateSelectionDialogOpen] = useState(false);
  const setWorkTypeCreateSelectionDialogOpenFalse = useCallWithFalse(
    setWorkTypeCreateSelectionDialogOpen,
  );

  const [externalWorkTypeDialogOpen, setExternalWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setExternalWorkTypeDialogOpen);

  const [internalWorkTypeDialogOpen, setInternalWorkTypeDialogOpen] = useState(false);
  const setInternalWorkTypeDialogOpenFalse = useCallWithFalse(setInternalWorkTypeDialogOpen);

  const customerSettings = useSelector(getCustomerSettings);
  const {canCreateWorkTypes, canImportWorkTypes} = integrationCustomerSettings(customerSettings);

  const dispatch = useDispatch();

  const filterString = useQueryParameter("q", "");

  const handleAddWorkType = useCallback(() => {
    if (
      canCreateWorkTypes ||
      (customerSettings.canEditInternalWorkTypes && customerSettings.canCreateExternalWorkType)
    ) {
      setWorkTypeCreateSelectionDialogOpen(true);
    } else if (customerSettings.canEditInternalWorkTypes) {
      setInternalWorkTypeDialogOpen(true);
    } else if (customerSettings.canCreateExternalWorkType) {
      setExternalWorkTypeDialogOpen(true);
    }
  }, [
    customerSettings.canCreateExternalWorkType,
    customerSettings.canEditInternalWorkTypes,
    canCreateWorkTypes,
  ]);

  const handleWorkTypeCreateSelectionDialogOk = useCallback((workTypeType: WorkTypeTypeChoices) => {
    setWorkTypeCreateSelectionDialogOpen(false);
    if (workTypeType === "external") {
      setExternalWorkTypeDialogOpen(true);
    } else {
      setInternalWorkTypeDialogOpen(true);
    }
  }, []);

  const handleChangeColorClick = useCallback((url: WorkTypeUrl): void => {
    setColorPickerDialogOpenedFor(url);
  }, []);

  const handleColorPickerDialogCancel = useCallback((): void => {
    setColorPickerDialogOpenedFor(null);
  }, []);

  const handleColorSelected = useCallback(
    (color: string): void => {
      if (colorPickerDialogOpenedFor) {
        dispatch(actions.update(colorPickerDialogOpenedFor, [{member: "color", value: color}]));
      }
      setColorPickerDialogOpenedFor(null);
    },
    [colorPickerDialogOpenedFor, dispatch],
  );

  const workTypeLookup = useSelector(getWorkTypeLookup);

  const fabs: JSX.Element[] = [];

  if (
    canCreateWorkTypes ||
    customerSettings.canEditInternalWorkTypes ||
    customerSettings.canCreateExternalWorkType
  ) {
    fabs.push(
      <VerticalStackingFloatingActionButton
        key="add-fab"
        stackIndex={fabs.length}
        onClick={handleAddWorkType}
      >
        <PlusIcon />
      </VerticalStackingFloatingActionButton>,
    );
  }

  const colorPickerDialogInitialColor = colorPickerDialogOpenedFor
    ? workTypeLookup(colorPickerDialogOpenedFor)?.color
    : undefined;

  if (canImportWorkTypes) {
    fabs.push(
      <ImportWorkTypesFab
        key="import-fab"
        buttonStyle={getVerticalStackingFabstyle(fabs.length)}
      />,
    );
  }

  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState("");

  const handleErrorMessageOk = useCallback(() => setErrorMessage(""), []);

  return (
    <div>
      <WorkTypeTable
        filterString={filterString}
        showInactive={showInactive}
        onChangeColorClick={handleChangeColorClick}
      />
      <WorkTypeCreateEditDialog
        open={externalWorkTypeDialogOpen}
        onCancel={setWorkTypeDialogOpenFalse}
        onOk={setWorkTypeDialogOpenFalse}
      />
      <WorkTypeCreateEditInternalDialog
        open={internalWorkTypeDialogOpen}
        onCancel={setInternalWorkTypeDialogOpenFalse}
        onOk={setInternalWorkTypeDialogOpenFalse}
      />
      {fabs}
      <ColorPickerDialog
        // Change in key, remounts the component and sets new initial value
        key={colorPickerDialogInitialColor || ""}
        initialSelected={colorPickerDialogInitialColor}
        open={!!colorPickerDialogOpenedFor}
        onCancel={handleColorPickerDialogCancel}
        onOk={handleColorSelected}
      />
      <WorkTypeTypeSelectionDialog
        open={workTypeCreateSelectionDialogOpen}
        title={intl.formatMessage({defaultMessage: "Hvilken type område vil du oprette?"})}
        onCancel={setWorkTypeCreateSelectionDialogOpenFalse}
        onOk={handleWorkTypeCreateSelectionDialogOk}
      />
      <ErrorDialog
        message={errorMessage}
        open={!!errorMessage}
        title={intl.formatMessage({defaultMessage: "Import fejlede"})}
        onOk={handleErrorMessageOk}
      />
    </div>
  );
});
