import {Config} from "@co-common-libs/config";
import {
  AnniversaryType,
  DaysAbsence,
  DaysAbsenceUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Role,
  Task,
  Unit,
  UnitUrl,
  UserUrl,
} from "@co-common-libs/resources";
import {DAY_HOURS, dateToString} from "@co-common-libs/utils";
import {PathTemplate, PunchWorkPeriod, actions, getCurrentRole} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import useMergedRef from "@react-hook/merged-ref";
import {dateFromDateAndTime, diffResourceInstanceProperties} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import bowser from "bowser";
import {addMinutes, differenceInMinutes, format, startOfDay} from "date-fns";
import _ from "lodash";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {DropTargetMonitor, useDrop} from "react-dnd";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AbsenceBlocks} from "./absence-blocks";
import {CalendarGridBlock} from "./calendar-grid-block";
import {
  HALF_HOUR_ROW_HEIGHT,
  HOUR_ROW_HEIGHT,
  NORMAL_HOURS_OVERLAY_Z_INDEX,
  QUARTER_HOUR_ROW_HEIGHT,
  TASK_WIDTH,
  TIME_TRACKER_LEFT,
  TIME_TRACKER_WIDTH,
  USER_COLUMN_WIDTH,
} from "./constants";
import {HolidayBlocks} from "./holiday-blocks";
import {PlannedTasks} from "./planned-tasks";
import {TasksFromOverflow} from "./tasks-from-overflow";
import {TimeTracker} from "./time-tracker";
import {TrackedTasks} from "./tracked-tasks";
import {TrackingIssues} from "./tracking-issues";
import {useLongPress} from "./use-long-press";
import {TaskWithRelations, addHour, calculateYPosition, getAnniversaryBackground} from "./utils";

const IS_DESKTOP = !bowser.mobile && !bowser.tablet;
const SCROLL_DELAY_MS = 20;

const SCROLL_PIXELS_ADJUSTER = 5;
const SCROLL_ZONE_HEIGHT = 50;

const HALF_HOUR_MINUTES = 30;
const MINUTES_PER_PIXEL = HALF_HOUR_MINUTES / HALF_HOUR_ROW_HEIGHT;
const LONG_PRESS_DELAY_MS = 300;

interface UserColumnProps {
  anniversaries?: readonly AnniversaryType[] | undefined;
  completedTasks: readonly TaskWithRelations[];
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  dateTransitionMark?: boolean;
  daysAbsenceList: readonly DaysAbsence[];
  fromTimestamp: ImmutableDate;
  getUserHalfHoliday?: ((date: string) => string | undefined) | undefined;
  getUserHoliday?: ((date: string) => string | undefined) | undefined;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  holidaysVisible: boolean;
  hoursAbsenceList: readonly HoursAbsence[];
  incompleteTasks: readonly TaskWithRelations[];
  normalFromTimestamp?: ImmutableDate | undefined;
  normalToTimestamp?: ImmutableDate | undefined;
  now?: string;
  onAbsenceClick?: ((url: DaysAbsenceUrl | HoursAbsenceUrl) => void) | undefined;
  onRequestAddTask?: (
    userURL: UserUrl,
    fromTime: Date,
    duration: number | null,
    mousePosition: {left: number; top: number},
    clearDragStartPointEndPoint: () => void,
  ) => void;
  onRequestCloseTaskInfo?: (() => void) | undefined;
  onRequestTaskInfo?: ((position: {x: number; y: number}, task: Task) => void) | undefined;
  overlapCheckUserCompletedTasks: readonly TaskWithRelations[];
  overlapCheckUserIncompleteTasks: readonly TaskWithRelations[];
  plannedTasks: readonly TaskWithRelations[];
  punchInOutPeriods?: readonly PunchWorkPeriod[] | undefined;
  singleDateFocus?: boolean | undefined;
  style?: React.CSSProperties;
  tasksFromOverflow?:
    | readonly {
        readonly overflowMinutes: number;
        readonly task: TaskWithRelations;
      }[]
    | undefined;
  toTimestamp: ImmutableDate;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userURL: UserUrl;
}

export const UserColumn = React.memo(function UserColumn(props: UserColumnProps): JSX.Element {
  const {
    anniversaries,
    completedTasks,
    dateTransitionMark,
    daysAbsenceList,
    fromTimestamp,
    getUserHalfHoliday,
    getUserHoliday,
    holidaysVisible,
    hoursAbsenceList,
    incompleteTasks,
    normalFromTimestamp,
    normalToTimestamp,
    onRequestAddTask,
    onRequestCloseTaskInfo,
    onRequestTaskInfo,
    overlapCheckUserCompletedTasks,
    overlapCheckUserIncompleteTasks,
    plannedTasks,
    punchInOutPeriods,
    singleDateFocus,
    tasksFromOverflow,
    toTimestamp,
    unitLookup,
    userURL,
  } = props;
  const now = props.now || new Date().toISOString();
  const emptyBlocks = [];
  const dispatch = useDispatch();

  const handleDrop = useCallback(
    (timestamp: ImmutableDate, task: Task) => {
      const plainDate = new Date(timestamp.getTime());
      const time = format(plainDate, "HH:mm:ss");
      const date = dateToString(plainDate);

      const patch = diffResourceInstanceProperties({date, machineOperator: userURL, time}, task);

      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
    },
    [dispatch, userURL],
  );

  for (
    let currentTimestamp = fromTimestamp;
    currentTimestamp < toTimestamp;
    currentTimestamp = addHour(currentTimestamp)
  ) {
    const hour = currentTimestamp.getHours();
    let transitionMark = false;
    if (dateTransitionMark && hour === 0) {
      transitionMark = true;
    }
    emptyBlocks.push(
      <CalendarGridBlock key={currentTimestamp.toISOString()} transitionMark={transitionMark} />,
    );
  }
  const style: React.CSSProperties = {
    borderColor: "rgb(247, 247, 247)",
    borderStyle: "solid",
    borderWidth: "0px 0px 1px 0px",
    display: "inline-block",
    position: "relative",
    verticalAlign: "top",
    width: USER_COLUMN_WIDTH,
    ...props.style,
  };
  if (anniversaries) {
    style.backgroundImage = getAnniversaryBackground(anniversaries[0].icon);
    style.backgroundRepeat = "repeat";
    style.backgroundSize = `${HALF_HOUR_ROW_HEIGHT}px ${HALF_HOUR_ROW_HEIGHT}px`;
  }

  let beforeNormalHoursOverlay;
  let afterNormalHoursOverlay;
  if (normalFromTimestamp && normalToTimestamp) {
    const beforeStartY = 0;
    const beforeEndY = calculateYPosition(fromTimestamp, toTimestamp, normalFromTimestamp);
    const afterStartY = calculateYPosition(fromTimestamp, toTimestamp, normalToTimestamp);

    const afterEndY = DAY_HOURS * HOUR_ROW_HEIGHT;
    const normalHoursOvelayStyle: React.CSSProperties = {
      backgroundColor: "#000",
      opacity: 0.1,
      position: "absolute",
      width: "100%",
      zIndex: NORMAL_HOURS_OVERLAY_Z_INDEX,
    };
    if (beforeEndY > beforeStartY) {
      beforeNormalHoursOverlay = (
        <div
          style={{
            ...normalHoursOvelayStyle,
            height: beforeEndY - beforeStartY,
            top: beforeStartY,
          }}
        />
      );
    }
    if (afterEndY > afterStartY) {
      afterNormalHoursOverlay = (
        <div
          style={{
            ...normalHoursOvelayStyle,
            height: afterEndY - afterStartY,
            top: afterStartY,
          }}
        />
      );
    }
  }
  let punchInOutBackground;
  if (punchInOutPeriods) {
    const punchInOutBackgroundStyle: React.CSSProperties = {
      backgroundColor: "#0f0",
      opacity: 0.1,
      position: "absolute",
      width: "100%",
      zIndex: NORMAL_HOURS_OVERLAY_Z_INDEX,
    };
    punchInOutBackground = punchInOutPeriods.map(({punchIn, punchOut}) => {
      const startY = calculateYPosition(fromTimestamp, toTimestamp, new Date(punchIn));
      const endY = calculateYPosition(fromTimestamp, toTimestamp, new Date(punchOut || now));
      return (
        <div
          key={`${punchIn}-${punchOut}`}
          style={{
            ...punchInOutBackgroundStyle,
            height: endY - startY,
            top: startY,
          }}
        />
      );
    });
  }
  let overflowBlock;
  if (tasksFromOverflow && normalFromTimestamp && normalToTimestamp) {
    overflowBlock = (
      <TasksFromOverflow
        calendarFromTimestamp={fromTimestamp}
        calendarToTimestamp={toTimestamp}
        customerSettings={props.customerSettings}
        go={props.go}
        normalFromTimestamp={normalFromTimestamp}
        normalToTimestamp={normalToTimestamp}
        now={now}
        overflowTasks={tasksFromOverflow}
        singleDateFocus={singleDateFocus}
        unitLookup={unitLookup}
        onRequestTaskInfo={onRequestTaskInfo}
      />
    );
  }

  const [startPoint, setStartPoint] = useState<number | null>(null);
  const [endPoint, setEndPoint] = useState<number | null>(null);
  const [sizing, setSizing] = useState(false);
  const [mousePosition, setMousePosition] = useState<[number, number] | null>(null);

  const [resizingState, setResizingState] = useState<{
    initialHeight: number;
    initialY: number;
    task: Task;
    taskBlock: HTMLDivElement;
  } | null>(null);

  const scrollTimeout = useRef<number | undefined>(undefined);

  const handleTaskMouseDown = useCallback(
    (e: React.MouseEvent, task: Task, taskBlock: HTMLDivElement) => {
      setResizingState({
        initialHeight: taskBlock.getBoundingClientRect().height,
        initialY: e.clientY,
        task,
        taskBlock,
      });
    },
    [setResizingState],
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (onRequestCloseTaskInfo) {
        onRequestCloseTaskInfo();
        return;
      }
      if (!onRequestAddTask || resizingState || e.button !== 0) {
        return;
      }
      const rect = e.currentTarget.getBoundingClientRect();
      const y = e.clientY - rect.top;
      setStartPoint(Math.floor(y / QUARTER_HOUR_ROW_HEIGHT) * QUARTER_HOUR_ROW_HEIGHT);
      setMousePosition([e.clientX, e.clientY]);
      setEndPoint(null);
      setSizing(true);
    },
    [onRequestAddTask, onRequestCloseTaskInfo, resizingState],
  );

  const onLongTouch = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (
        !onRequestAddTask ||
        document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY)?.className !==
          "gridBlock"
      ) {
        return;
      }

      const scrollableElement = e.touches[0].target as HTMLElement;
      if (scrollableElement) {
        // Hack to enable scrolling while dragging eventhough the touched element is gridBlock
        scrollableElement.className = "gridBlock enableDragScroll";
      }

      const rect = (e.touches[0].target as HTMLElement)
        .closest(".hourColumn")
        ?.getBoundingClientRect();
      if (!rect) {
        return;
      }
      const y = e.touches[0].clientY - rect.top;
      setStartPoint(Math.floor(y / QUARTER_HOUR_ROW_HEIGHT) * QUARTER_HOUR_ROW_HEIGHT);
      setMousePosition([e.touches[0].clientX, e.touches[0].clientY]);
      setEndPoint(null);
      setSizing(true);
    },
    [onRequestAddTask],
  );

  const {clearLongPressTimer, onTouchStart: handleTouchLongPressStart} =
    useLongPress<HTMLDivElement>(onLongTouch, LONG_PRESS_DELAY_MS);

  const handleTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (onRequestCloseTaskInfo) {
        onRequestCloseTaskInfo();
        return;
      }
      handleTouchLongPressStart(event);
    },
    [handleTouchLongPressStart, onRequestCloseTaskInfo],
  );

  const handleTaskLongPress = useCallback(
    (event: React.TouchEvent<HTMLDivElement>, task: Task, taskBlock: HTMLDivElement) => {
      clearLongPressTimer();

      setResizingState({
        initialHeight: taskBlock.getBoundingClientRect().height,
        initialY: event.touches[0].clientY,
        task,
        taskBlock,
      });
    },
    [clearLongPressTimer],
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (sizing) {
        const rect = e.currentTarget.getBoundingClientRect();
        const y = e.clientY - rect.top;
        setMousePosition([e.clientX, e.clientY]);
        setEndPoint(Math.ceil(y / QUARTER_HOUR_ROW_HEIGHT) * QUARTER_HOUR_ROW_HEIGHT);
      } else if (resizingState) {
        const taskBlockRect = resizingState.taskBlock.getBoundingClientRect();
        const rect = e.currentTarget.getBoundingClientRect();
        const y = e.clientY - rect.top;

        const newHeight = y - -(rect.top - taskBlockRect.top);

        const ajustedHeight =
          newHeight < QUARTER_HOUR_ROW_HEIGHT ? QUARTER_HOUR_ROW_HEIGHT : newHeight;

        resizingState.taskBlock.style.height = `${
          Math.ceil(ajustedHeight / QUARTER_HOUR_ROW_HEIGHT) * QUARTER_HOUR_ROW_HEIGHT
        }px`;
      }

      if (sizing || resizingState) {
        window.clearTimeout(scrollTimeout.current);
        const scrollableElement = e.currentTarget.closest(".scrollable");
        if (scrollableElement) {
          const doScroll = (step: number): void => {
            scrollableElement.scrollTop = scrollableElement.scrollTop + step;
            scrollTimeout.current = window.setTimeout(doScroll.bind(null, step), SCROLL_DELAY_MS);
          };

          const scrollableElementRects = scrollableElement.getClientRects();
          if (e.clientY < scrollableElementRects[0].top + SCROLL_ZONE_HEIGHT) {
            const scrollPixels = _.round(
              (e.clientY - (scrollableElementRects[0].top + SCROLL_ZONE_HEIGHT)) /
                SCROLL_PIXELS_ADJUSTER,
            );
            doScroll(scrollPixels);
          }
          if (e.clientY > scrollableElementRects[0].bottom - SCROLL_ZONE_HEIGHT) {
            const scrollPixels = _.round(
              (SCROLL_ZONE_HEIGHT - (scrollableElementRects[0].bottom - e.clientY)) /
                SCROLL_PIXELS_ADJUSTER,
            );
            doScroll(scrollPixels);
          }
        }
      }
    },

    [resizingState, sizing],
  );

  const clearPlanning = useCallback(() => {
    setStartPoint(null);
    setEndPoint(null);
  }, []);

  const planningRef = useRef<HTMLDivElement>(null);

  const onMouseUp = useCallback(
    (_e: React.MouseEvent<HTMLDivElement>) => {
      window.clearTimeout(scrollTimeout.current);
      if (sizing) {
        setSizing(false);

        if (!onRequestAddTask || startPoint === null || !mousePosition) {
          return;
        }

        const calendarDate = new Date(fromTimestamp.getTime());
        const startTime = addMinutes(calendarDate, startPoint * MINUTES_PER_PIXEL);
        const duration = endPoint !== null ? (endPoint - startPoint) * MINUTES_PER_PIXEL : null;

        onRequestAddTask(
          userURL,
          startTime,
          duration,
          {left: mousePosition[0], top: mousePosition[1]},
          clearPlanning,
        );
      } else if (resizingState) {
        setResizingState(null);
        const {task} = resizingState;
        let duration = resizingState.taskBlock.getBoundingClientRect().height * MINUTES_PER_PIXEL;

        if (task.date && task.time && task.date < dateToString(fromTimestamp)) {
          const taskStartDate = dateFromDateAndTime(task.date, task.time);
          const pastDaysDuration = differenceInMinutes(
            startOfDay(new Date(fromTimestamp.getTime())),
            taskStartDate,
          );
          duration += pastDaysDuration;
        }
        const roundedDuration = _.round(duration);
        if (resizingState.task.minutesExpectedTotalTaskDuration !== roundedDuration) {
          dispatch(
            actions.update(resizingState.task.url, [
              {
                member: "minutesExpectedTotalTaskDuration",
                value: roundedDuration,
              },
            ]),
          );
        }
      }
    },
    [
      clearPlanning,
      dispatch,
      endPoint,
      fromTimestamp,
      mousePosition,
      onRequestAddTask,
      resizingState,
      sizing,
      startPoint,
      userURL,
    ],
  );

  const onTouchEnd = useCallback(
    (_e: React.TouchEvent<HTMLDivElement>) => {
      clearLongPressTimer();
      const gridBlocks = Array.from(document.getElementsByClassName("gridBlock enableDragScroll"));
      for (const gridBlock of gridBlocks) {
        gridBlock.className = "gridBlock";
      }
      if (sizing) {
        setSizing(false);
        if (!onRequestAddTask || !startPoint || !mousePosition) {
          return;
        }
        const calendarDate = new Date(fromTimestamp.getTime());
        const startTime = addMinutes(calendarDate, startPoint * MINUTES_PER_PIXEL);
        const duration = endPoint !== null ? (endPoint - startPoint) * MINUTES_PER_PIXEL : null;

        onRequestAddTask(
          userURL,
          startTime,
          duration,
          {left: mousePosition[0], top: mousePosition[1]},
          clearPlanning,
        );
      } else if (resizingState) {
        setResizingState(null);
        const {task} = resizingState;
        let duration = resizingState.taskBlock.getBoundingClientRect().height * MINUTES_PER_PIXEL;

        if (task.date && task.time && task.date < dateToString(fromTimestamp)) {
          const taskStartDate = dateFromDateAndTime(task.date, task.time);
          const pastDaysDuration = differenceInMinutes(
            startOfDay(new Date(fromTimestamp.getTime())),
            taskStartDate,
          );
          duration += pastDaysDuration;
        }
        const roundedDuration = _.round(duration);
        if (resizingState.task.minutesExpectedTotalTaskDuration !== roundedDuration) {
          dispatch(
            actions.update(resizingState.task.url, [
              {
                member: "minutesExpectedTotalTaskDuration",
                value: roundedDuration,
              },
            ]),
          );
        }
      }
    },
    [
      clearLongPressTimer,
      clearPlanning,
      dispatch,
      endPoint,
      fromTimestamp,
      mousePosition,
      onRequestAddTask,
      resizingState,
      sizing,
      startPoint,
      userURL,
    ],
  );

  let planningBlock: JSX.Element | null = null;
  if (startPoint !== null) {
    const height = endPoint ? endPoint - startPoint : !sizing ? HOUR_ROW_HEIGHT : 0;
    planningBlock = (
      <div
        ref={planningRef}
        style={{
          backgroundColor: "#039ae5",
          cursor: "s-resize",
          fontSize: 12,
          fontWeight: 500,
          height: height < QUARTER_HOUR_ROW_HEIGHT ? QUARTER_HOUR_ROW_HEIGHT : height,
          lineHeight: "14px",
          opacity: 0.75,
          paddingRight: 3,
          position: "absolute",
          top: startPoint,
          userSelect: "none",
          WebkitUserSelect: "none",
          width: TASK_WIDTH,
          zIndex: 1000,
        }}
      >
        <FormattedMessage defaultMessage="Ny opgave" />
      </div>
    );
  }
  const currentRole = useSelector(getCurrentRole);
  const allowClickToAdd =
    props.customerSettings.calendarPlanningFunctions &&
    !!currentRole &&
    (currentRole.manager || currentRole.seniorMachineOperator);

  const columnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!columnRef.current) {
      return undefined;
    }
    const column = columnRef.current;
    const handleTouchMove = (event: TouchEvent): void => {
      clearLongPressTimer();
      if (sizing && startPoint) {
        event.preventDefault();

        const rect = (event.touches[0].target as HTMLElement)
          .closest(".hourColumn")
          ?.getBoundingClientRect();
        if (!rect) {
          return;
        }
        const y = event.touches[0].clientY - rect.top;
        setMousePosition([event.touches[0].clientX, event.touches[0].clientY]);
        setEndPoint(Math.ceil(y / QUARTER_HOUR_ROW_HEIGHT) * QUARTER_HOUR_ROW_HEIGHT);
      } else if (resizingState) {
        event.preventDefault();
        const taskBlockRect = resizingState.taskBlock.getBoundingClientRect();
        const rect = (event.touches[0].target as HTMLElement)
          .closest(".hourColumn")
          ?.getBoundingClientRect();
        if (!rect) {
          return;
        }
        const y = event.touches[0].clientY - rect.top;

        const newHeight = y - -(rect.top - taskBlockRect.top);

        const ajustedHeight =
          newHeight < QUARTER_HOUR_ROW_HEIGHT ? QUARTER_HOUR_ROW_HEIGHT : newHeight;

        resizingState.taskBlock.style.height = `${
          Math.ceil(ajustedHeight / QUARTER_HOUR_ROW_HEIGHT) * QUARTER_HOUR_ROW_HEIGHT
        }px`;
      }
    };
    column.addEventListener("touchmove", handleTouchMove, false);
    return () => {
      column?.removeEventListener("touchmove", handleTouchMove, false);
    };
  }, [allowClickToAdd, clearLongPressTimer, resizingState, sizing, startPoint]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_dropCollectedProps, drop] = useDrop({
    accept: "task",
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (task: Task, monitor: DropTargetMonitor) => {
      const rect = columnRef.current?.getBoundingClientRect();
      const sourceClientOffset = monitor.getSourceClientOffset();
      if (!rect || !sourceClientOffset) {
        return;
      }
      const pxFromTop = sourceClientOffset.y - rect.top;
      const minutesFromMidnight =
        Math.floor(pxFromTop / QUARTER_HOUR_ROW_HEIGHT) *
        QUARTER_HOUR_ROW_HEIGHT *
        MINUTES_PER_PIXEL;

      handleDrop(
        fromTimestamp.setUTCMinutes(fromTimestamp.getUTCMinutes() + minutesFromMidnight),
        task,
      );
    },
  });

  const multiRef = useMergedRef<HTMLDivElement>(drop, columnRef);

  const hourColumnEventHandlerProps: React.HTMLAttributes<HTMLDivElement> = {};
  if (allowClickToAdd && IS_DESKTOP) {
    hourColumnEventHandlerProps.onMouseDown = onMouseDown;
    hourColumnEventHandlerProps.onMouseMove = onMouseMove;
  }
  if (allowClickToAdd) {
    hourColumnEventHandlerProps.onMouseUp = onMouseUp;
    hourColumnEventHandlerProps.onTouchCancel = clearLongPressTimer;
    hourColumnEventHandlerProps.onTouchEnd = onTouchEnd;
    hourColumnEventHandlerProps.onTouchStart = handleTouchStart;
  }

  return (
    <div ref={multiRef} className="hourColumn" style={style} {...hourColumnEventHandlerProps}>
      {emptyBlocks}
      {beforeNormalHoursOverlay}
      {afterNormalHoursOverlay}
      {punchInOutBackground}
      <TimeTracker
        calendarFromTimestamp={fromTimestamp}
        calendarToTimestamp={toTimestamp}
        completedTasks={completedTasks}
        incompleteTasks={incompleteTasks}
        left={TIME_TRACKER_LEFT}
        now={now}
        singleDateFocus={singleDateFocus}
        width={TIME_TRACKER_WIDTH}
      />
      <TrackedTasks
        calendarFromTimestamp={fromTimestamp}
        calendarToTimestamp={toTimestamp}
        completedTasks={completedTasks}
        customerSettings={props.customerSettings}
        go={props.go}
        incompleteTasks={incompleteTasks}
        now={now}
        singleDateFocus={singleDateFocus}
        unitLookup={unitLookup}
        onRequestTaskInfo={onRequestTaskInfo}
      />
      <PlannedTasks
        calendarFromTimestamp={fromTimestamp}
        calendarToTimestamp={toTimestamp}
        completedTasks={completedTasks}
        customerSettings={props.customerSettings}
        go={props.go}
        incompleteTasks={incompleteTasks}
        normalFromTimestamp={normalFromTimestamp}
        normalToTimestamp={normalToTimestamp}
        now={now}
        plannedTasks={plannedTasks}
        singleDateFocus={singleDateFocus}
        unitLookup={unitLookup}
        onLongPress={handleTaskLongPress}
        onMouseDown={handleTaskMouseDown}
        onRequestTaskInfo={onRequestTaskInfo}
      />
      {overflowBlock}
      <TrackingIssues
        calendarFromTimestamp={fromTimestamp}
        calendarToTimestamp={toTimestamp}
        completedTasks={overlapCheckUserCompletedTasks}
        customerSettings={props.customerSettings}
        incompleteTasks={overlapCheckUserIncompleteTasks}
        now={now}
      />
      <AbsenceBlocks
        calendarFromTimestamp={fromTimestamp}
        calendarToTimestamp={toTimestamp}
        currentRole={props.currentRole}
        currentUserURL={props.currentUserURL}
        customerSettings={props.customerSettings}
        daysAbsenceList={daysAbsenceList}
        hoursAbsenceList={hoursAbsenceList}
        onAbsenceClick={props.onAbsenceClick}
      />
      {holidaysVisible ? (
        <HolidayBlocks
          calendarFromTimestamp={fromTimestamp}
          calendarToTimestamp={toTimestamp}
          currentUserURL={props.currentUserURL}
          getUserHalfHoliday={getUserHalfHoliday}
          getUserHoliday={getUserHoliday}
          userUrl={userURL}
        />
      ) : null}
      {planningBlock}
    </div>
  );
});
