import {Config} from "@co-common-libs/config";
import {
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PriceGroup,
  PriceGroupUrl,
  Project,
  ProjectUrl,
  ReportingLocation,
  Role,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TaskPhoto,
  TaskUrl,
  TimerStart,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  getMachinesWorkTypeString,
  getTaskDate,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {
  WorkStatus,
  dateFromString,
  dateToString,
  formatDate,
  formatDateShort,
  formatDateTime,
  formatDateTimeShort,
  formatDateWeekdayShort,
  formatTime,
  identifierComparator,
  initialComparator,
  notUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  ColorBox,
  ColumnSpecifications,
  RowData,
  WorkStatusIcon,
  colorBoxColumnSpecification,
  iconButtonColumnSpecification,
} from "@co-frontend-libs/components";
import {
  AppState,
  getCultureLookup,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getProjectLookup,
  getRoutePlanLookup,
  getSortedTimerStartArray,
  getTaskArray,
  getTaskPhotoArray,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {IconButton} from "@material-ui/core";
import {
  ConnectedTableWithPagination,
  GoToPathButton,
  Linkify,
  PaginationPageSize,
  TaskEditButton,
} from "app-components";
import {
  computeTaskStatus,
  getLocationLongString,
  getLocationPostalCode,
  getReferenceNumberLabel,
  getWorkplaceString,
  machineOperatorCanSeeFutureTasksUntil,
  resourceInstanceDateTimeComparator,
} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import ImageIcon from "mdi-react/ImageIcon";
import memoize from "memoize-one";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import type {PickProperties} from "ts-essentials";
import {COLUMN_WIDTHS} from "./constants";

export type TaskTableFieldID =
  | "batchOperationCheckbox"
  | "completed"
  | "createdBy"
  | "createdDate"
  | "createdDateRaw"
  | "createdDateShort"
  | "createdDateTime"
  | "createdDateTimeShort"
  | "culture"
  | "customer"
  | "customerCultureMachine"
  | "customerWithAccount"
  | "date"
  | "dateMedium"
  | "dateRaw"
  | "dateShort"
  | "deliveryLocation"
  | "deliveryLocationLong"
  | "deliveryPostalCode"
  | "department"
  | "fields"
  | "invoiceNote"
  | "isRouteTask"
  | "key"
  | "machineIDs"
  | "machineOperator"
  | "machineOperatorName"
  | "machines"
  | "managerInternalNotes"
  | "notes"
  | "numericStatus"
  | "orderUrl"
  | "photoIcon"
  | "pickupLocation"
  | "pickupLocationLong"
  | "pickupPostalCode"
  | "priceGroup"
  | "project"
  | "projectWithAlias"
  | "referenceNumber"
  | "status"
  | "time"
  | "workplace"
  | "workplaceLong"
  | "workplacePostalCode"
  | "workType"
  | "workTypeColor"
  | "workTypeName"
  | "workTypeShort";

export type TaskTableColumnID =
  | "batchOperationCheckbox"
  | "created"
  | "createdBy"
  | "createdByShort"
  | "createdDate"
  | "createdDateShort"
  | "createdShort"
  | "culture"
  | "cultureMachine"
  | "customer"
  | "customerCultureMachine"
  | "customerMachine"
  | "customerRoute"
  | "customerRouteCultureMachine"
  | "customerRouteMachine"
  | "date"
  | "dateShort"
  | "deliveryLocation"
  | "deliveryLocationLong"
  | "deliveryPostalCode"
  | "department"
  | "editTask"
  | "fields"
  | "invoiceNote"
  | "machine"
  | "machineOperator"
  | "machineOperatorName"
  | "machineOperatorShort"
  | "machines"
  | "machineShort"
  | "managerInternalNotes"
  | "notes"
  | "photo"
  | "pickupLocation"
  | "pickupLocationLong"
  | "pickupPostalCode"
  | "priceGroup"
  | "project"
  | "projectWithAlias"
  | "referenceNumber"
  | "status"
  | "time"
  | "timeShort"
  | "workplace"
  | "workplaceLong"
  | "workplacePostalCode"
  | "workType"
  | "workTypeColor"
  | "workTypeName"
  | "workTypeShort";

export interface TaskTableDataType extends RowData<TaskTableFieldID, TaskUrl> {
  createdBy: string;
  createdDate: string;
  createdDateShort: string;
  createdDateTime: string;
  createdDateTimeShort: string;
  culture: string;
  customer: string;
  customerCultureMachine: string;
  customerId: string | null;
  customerWithAccount: string;
  date: string;
  deliveryPostalCode: string;
  fields: string;
  invoiceNote: string;
  isRouteTask: boolean;
  machineIDs: string;
  machineOperator: string;
  machineOperatorName: string;
  machines: string;
  managerInternalNotes: string;
  notes: string;
  numericStatus: number;
  orderUrl: string | null;
  photoIcon: boolean;
  pickupLocation: string;
  pickupPostalCode: string;
  priceGroup: string;
  project: string;
  projectWithAlias: string;
  referenceNumber: string;
  status: WorkStatus;
  time: string;
  validatedAndRecorded: boolean;
  workplace: string;
  workplacePostalCode: string;
  workType: string;
  workTypeColor: string | null;
  workTypeShort: string;
}

function filterTaskArrayOnDate(
  taskArray: readonly Task[],
  hideTasksAfterDate: string,
  alwaysVisibleCreatedBy: string | null,
): readonly Task[] {
  return taskArray.filter((task) => {
    const taskDate = task.date;
    return !taskDate || taskDate <= hideTasksAfterDate || task.createdBy === alwaysVisibleCreatedBy;
  });
}

function sameDay(a: Date, b: Date): boolean {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
}

function filterTaskArrayOnCompleted(
  taskArray: readonly Task[],
  hideCompleted: boolean,
  hideCompletedExceptToday: boolean,
  today: string,
): readonly Task[] {
  if (hideCompleted) {
    return taskArray.filter((task) => !task.completed);
  } else if (hideCompletedExceptToday) {
    const todayDate = dateFromString(today) as Date;
    return taskArray.filter(
      (task) =>
        !task.completed ||
        !task.workFromTimestamp ||
        sameDay(new Date(task.workFromTimestamp), todayDate),
    );
  } else {
    return taskArray;
  }
}

function filterTaskArrayDraftOrders(
  taskArray: readonly Task[],
  orderLookup: (url: OrderUrl) => Order | undefined,
): readonly Task[] {
  return taskArray.filter((task) => {
    const orderURL = task.order;
    const order = orderURL && orderLookup(orderURL);
    return !orderURL || (order && !order.draft);
  });
}

function filterTaskActiveOrOwn(
  taskArray: readonly Task[],
  currentUserURL: string | null,
): readonly Task[] {
  return taskArray.filter(
    (task) =>
      (task.machineOperator && task.machineOperator === currentUserURL) ||
      (task.workFromTimestamp && !task.completed),
  );
}

function filterTaskArray(
  taskArray: readonly Task[],
  currentUserURL: string | null,
  userIsManager: boolean,
  userIsSeniorMachineOperator: boolean,
  externalTaskCulture: boolean,
  externalTaskCustomer: boolean,
  hideInternalTaskListWorkTypes: readonly string[],
  machineOperatorHideTasksAfterDate: string,
  machineOperatorCanSeeTheirOwnTasksAndActiveTasks: boolean,
  navSync: boolean,
  orderDrafts: boolean,
  taskListsMachineOperatorHideCompleted: boolean,
  taskListsMachineOperatorHideCompletedExceptToday: boolean,
  workTypeArray: readonly WorkType[],
  orderLookup: (url: OrderUrl) => Order | undefined,
  tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling",
  today: string,
  selectedWorkTypeURLSet: ReadonlySet<string>,
  selectedDepartmentIdentifierSet: ReadonlySet<string>,
  filterByProject?: Pick<Project, "url">,
): readonly Task[] {
  const workTypeFilteredTasks = selectedWorkTypeURLSet.size
    ? taskArray.filter((task) => task.workType && selectedWorkTypeURLSet.has(task.workType))
    : taskArray;
  const workTypeDepartmentFilteredTasks = selectedDepartmentIdentifierSet.size
    ? workTypeFilteredTasks.filter(
        (task) => task.department && selectedDepartmentIdentifierSet.has(task.department),
      )
    : workTypeFilteredTasks;
  if (tab === "mine") {
    let filteredTasks: readonly Task[] = workTypeDepartmentFilteredTasks.filter(
      (task) =>
        task.machineOperator === currentUserURL &&
        !task.reportApproved &&
        !task.validatedAndRecorded,
    );
    if (!userIsManager && !userIsSeniorMachineOperator) {
      filteredTasks = filterTaskArrayOnDate(
        filteredTasks,
        machineOperatorHideTasksAfterDate,
        currentUserURL,
      );

      filteredTasks = filterTaskArrayOnCompleted(
        filteredTasks,
        taskListsMachineOperatorHideCompleted,
        taskListsMachineOperatorHideCompletedExceptToday,
        today,
      );
    }
    if (orderDrafts) {
      filteredTasks = filterTaskArrayDraftOrders(filteredTasks, orderLookup);
    }
    return filteredTasks;
  } else if (tab === "internal") {
    let filteredTasks: readonly Task[] = workTypeDepartmentFilteredTasks.filter(
      (task) => !task.order && !task.validatedAndRecorded && !task.reportApproved,
    );
    if (hideInternalTaskListWorkTypes.length) {
      const hiddenWorkTypeURLSet = new Set(
        workTypeArray
          .filter((w) => hideInternalTaskListWorkTypes.includes(w.identifier))
          .map((w) => w.url),
      );
      filteredTasks = filteredTasks.filter(
        (task) => !task.workType || !hiddenWorkTypeURLSet.has(task.workType),
      );
    }
    if (!userIsManager && !userIsSeniorMachineOperator) {
      filteredTasks = filterTaskArrayOnCompleted(
        filteredTasks,
        taskListsMachineOperatorHideCompleted,
        taskListsMachineOperatorHideCompletedExceptToday,
        today,
      );
      if (machineOperatorCanSeeTheirOwnTasksAndActiveTasks) {
        filteredTasks = filterTaskActiveOrOwn(filteredTasks, currentUserURL);
      }
    }
    return filteredTasks;
  } else if (tab === "customers" || tab === "cultures") {
    let filteredTasks: readonly Task[] = workTypeDepartmentFilteredTasks.filter(
      (task) => !!task.order && !task.validatedAndRecorded && !task.reportApproved,
    );
    if (!userIsManager && !userIsSeniorMachineOperator) {
      filteredTasks = filterTaskArrayOnDate(
        filteredTasks,
        machineOperatorHideTasksAfterDate,
        currentUserURL,
      );
    }
    if (externalTaskCustomer && externalTaskCulture) {
      if (tab === "customers") {
        // those without culture; i.e. those with customers or neither.
        filteredTasks = filteredTasks.filter((task) => {
          const order = task.order ? orderLookup(task.order) : null;
          return !(order && order.culture);
        });
      } else {
        console.assert(tab === "cultures");
        // those without customer; i.e. those with cultures or neither.
        filteredTasks = filteredTasks.filter((task) => {
          const order = task.order ? orderLookup(task.order) : null;
          return !(order && (order.customer || order.routePlan));
        });
      }
    }
    if (!userIsManager && !userIsSeniorMachineOperator) {
      filteredTasks = filterTaskArrayOnCompleted(
        filteredTasks,
        taskListsMachineOperatorHideCompleted,
        taskListsMachineOperatorHideCompletedExceptToday,
        today,
      );
      if (machineOperatorCanSeeTheirOwnTasksAndActiveTasks) {
        filteredTasks = filterTaskActiveOrOwn(filteredTasks, currentUserURL);
      }
    }
    if (orderDrafts) {
      filteredTasks = filterTaskArrayDraftOrders(filteredTasks, orderLookup);
    }
    return filteredTasks;
  } else if (tab === "readyForBilling") {
    let filteredTasks: readonly Task[] = workTypeDepartmentFilteredTasks.filter(
      (task) =>
        !task.reportApproved &&
        task.validatedAndRecorded &&
        !task.recordedInC5 &&
        (navSync || !!task.order),
    );
    if (!userIsManager && !userIsSeniorMachineOperator) {
      filteredTasks = filterTaskArrayOnCompleted(
        filteredTasks,
        taskListsMachineOperatorHideCompleted,
        taskListsMachineOperatorHideCompletedExceptToday,
        today,
      );
      if (machineOperatorCanSeeTheirOwnTasksAndActiveTasks) {
        filteredTasks = filterTaskActiveOrOwn(filteredTasks, currentUserURL);
      }
    }
    return filteredTasks;
  } else {
    console.assert(tab === "archive");
    if (filterByProject) {
      const filteredTasks: readonly Task[] = workTypeDepartmentFilteredTasks.filter(
        (task) => !!task.order,
      );

      return filteredTasks.filter((task) => task.project === filterByProject.url);
    } else {
      let filteredTasks = workTypeDepartmentFilteredTasks;
      if (!userIsManager && !userIsSeniorMachineOperator) {
        filteredTasks = filterTaskArrayOnDate(
          filteredTasks,
          machineOperatorHideTasksAfterDate,
          currentUserURL,
        );
        if (machineOperatorCanSeeTheirOwnTasksAndActiveTasks) {
          filteredTasks = filterTaskActiveOrOwn(filteredTasks, currentUserURL);
        }
      }
      if (orderDrafts) {
        filteredTasks = filterTaskArrayDraftOrders(filteredTasks, orderLookup);
      }
      return filteredTasks;
    }
  }
}

function taskComparator(a: Task, b: Task): number {
  if (a.completed !== b.completed) {
    if (a.completed) {
      return 1;
    } else if (b.completed) {
      return -1;
    }
  }
  return resourceInstanceDateTimeComparator(a, b);
}

function sortTaskArray(taskArray: readonly Task[]): readonly Task[] {
  return taskArray.slice().sort(taskComparator);
}

// TODO: extra styling for fadeCompleted

function renderPhotoButton(
  onPhotoClick: (taskURL: string) => void,
): (data: TaskTableDataType) => JSX.Element {
  // eslint-disable-next-line react/display-name
  return (data) => {
    return data.photoIcon ? (
      <IconButton
        color="primary"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          onPhotoClick(data.key);
        }}
      >
        <ImageIcon />
      </IconButton>
    ) : (
      <span />
    );
  };
}

function renderWorkStatus(data: TaskTableDataType): JSX.Element {
  return (
    <GoToPathButton parameters={{id: urlToId(data.key)}} path="/task/:id">
      <WorkStatusIcon status={data.status} />
    </GoToPathButton>
  );
}

function renderEditTaskButton(data: TaskTableDataType): JSX.Element {
  return <TaskEditButton taskUrl={data.key} />;
}

function makeNotesRender(taskListeNoteLines: number): (data: TaskTableDataType) => JSX.Element {
  const style: React.CSSProperties = {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
  };
  if (taskListeNoteLines) {
    style.WebkitLineClamp = taskListeNoteLines;
  }
  return function renderNotes(data: TaskTableDataType): JSX.Element {
    return (
      <div style={style}>
        <Linkify>{data.notes}</Linkify>
      </div>
    );
  };
}

function renderColor(data: TaskTableDataType): JSX.Element {
  return <ColorBox color={data.workTypeColor} />;
}

type TaskTableStringFieldID = keyof PickProperties<TaskTableDataType, string>;

function getIdentifierFieldComparator(
  field: TaskTableStringFieldID,
): (a: TaskTableDataType, b: TaskTableDataType) => number {
  return (a: TaskTableDataType, b: TaskTableDataType) => identifierComparator(a[field], b[field]);
}

function getInitialsComparator(
  field: TaskTableStringFieldID,
): (a: TaskTableDataType, b: TaskTableDataType) => number {
  return (a: TaskTableDataType, b: TaskTableDataType) => initialComparator(a[field], b[field]);
}

export function buildColumnSpecifications(
  customerSettings: Config,
  formatMessage: IntlShape["formatMessage"],
  onClick: (taskURL: string) => void,
  onPhotoClick: (taskURL: string) => void,
  enableOrderReferenceNumber: boolean,
  enableTaskReferenceNumber: boolean,
  orderReferenceNumberLabel: string | null,
  taskReferenceNumberLabel: string | null,
  taskListeNoteLines: number,
  batchOperationRenderer?: (data: TaskTableDataType) => JSX.Element | null,
): ColumnSpecifications<TaskTableFieldID, TaskTableColumnID, TaskUrl, TaskTableDataType> {
  const {machineLabelVariant, projectLabelVariant} = customerSettings;
  return {
    batchOperationCheckbox: {
      field: "batchOperationCheckbox",
      label: "",
      render: batchOperationRenderer,
      width: 82,
    },
    created: {
      field: "createdDateTime",
      label: formatMessage({defaultMessage: "Oprettet"}),
      onClick,
      sortField: "createdDateRaw",
      style: {textAlign: "right"},
      width: COLUMN_WIDTHS.date,
    },
    createdBy: {
      comparator: getInitialsComparator("createdBy"),
      field: "createdBy",
      label: formatMessage({defaultMessage: "Oprettet af"}),
      onClick,
      width: COLUMN_WIDTHS.initials,
    },
    createdByShort: {
      comparator: getInitialsComparator("createdBy"),
      field: "createdBy",
      label: formatMessage({defaultMessage: "Oprettet af"}),
      onClick,
      width: COLUMN_WIDTHS.initialsShort,
    },
    createdDate: {
      field: "createdDate",
      label: formatMessage({defaultMessage: "Oprettelsestidspunkt"}),
      onClick,
      sortField: "createdDateRaw",
      style: {textAlign: "right"},
      width: COLUMN_WIDTHS.dateTime,
    },
    createdDateShort: {
      field: "createdDateShort",
      label: formatMessage({defaultMessage: "Oprettet"}),
      onClick,
      sortField: "createdDateRaw",
      style: {textAlign: "right"},
      width: COLUMN_WIDTHS.dateShort,
    },
    createdShort: {
      field: "createdDateTimeShort",
      label: formatMessage({defaultMessage: "Oprettet"}),
      onClick,
      sortField: "createdDateRaw",
      style: {textAlign: "right"},
      width: COLUMN_WIDTHS.dateShort,
    },
    culture: {
      field: "culture",
      label: formatMessage({defaultMessage: "Kultur"}),
      onClick,
    },
    cultureMachine: {
      field: "customerCultureMachine",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Kultur/mask."})
          : formatMessage({defaultMessage: "Kultur/køretøj"}),
      onClick,
    },
    customer: {
      field: "customer",
      label: formatMessage({defaultMessage: "Kunde"}),
      onClick,
    },
    customerCultureMachine: {
      field: "customerCultureMachine",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Kunde/kultur/mask."})
          : formatMessage({defaultMessage: "Kunde/kultur/køretøj"}),
      onClick,
    },
    customerMachine: {
      field: "customerCultureMachine",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Kunde/mask."})
          : formatMessage({defaultMessage: "Kunde/køretøj"}),
      onClick,
    },
    customerRoute: {
      // NTS: customerRouteCultureMachine had customerRoute header for
      // "customers" and "readyForBilling" tabs, customer header for "cultures" tab (WTF?)
      // and finally customerRouteCultureMachine header otherwise...?
      //
      // NTS: customerRouteMachine use header customerRoute for "customers"
      // and "readyForBilling" tabs...
      field: "customerCultureMachine",
      label: formatMessage({defaultMessage: "Kunde/rute"}),
      onClick,
    },
    customerRouteCultureMachine: {
      field: "customerCultureMachine",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Kunde/rute/kultur/mask."})
          : formatMessage({defaultMessage: "Kunde/rute/kultur/køretøj"}),
      onClick,
    },
    customerRouteMachine: {
      field: "customerCultureMachine",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Kunde/rute/mask."})
          : formatMessage({defaultMessage: "Kunde/rute/køretøj"}),
      onClick,
    },
    date: {
      field: "date",
      headerStyle: {textAlign: "inherit"},
      label: formatMessage({defaultMessage: "Dato"}),
      onClick,
      sortField: "dateRaw",
      style: {textAlign: "right"},
      width: COLUMN_WIDTHS.date,
    },
    dateShort: {
      field: "dateShort",
      headerStyle: {textAlign: "inherit"},
      label: formatMessage({defaultMessage: "Dato"}),
      onClick,
      sortField: "dateRaw",
      style: {textAlign: "right"},
      width: COLUMN_WIDTHS.dateShort,
    },
    deliveryLocation: {
      field: "deliveryLocation",
      label: formatMessage({defaultMessage: "Levering"}),
      onClick,
    },
    deliveryLocationLong: {
      field: "deliveryLocationLong",
      label: formatMessage({defaultMessage: "Levering"}),
      onClick,
    },
    deliveryPostalCode: {
      field: "deliveryPostalCode",
      label: formatMessage({defaultMessage: "Postnr."}),
      onClick,
    },
    department: {
      field: "department",
      label: formatMessage({defaultMessage: "Afd."}),
      onClick,
    },
    editTask: iconButtonColumnSpecification({
      disableSorting: true,
      field: "date",
      render: renderEditTaskButton,
      width: 60,
    }),
    fields: {
      field: "fields",
      label: formatMessage({defaultMessage: "Marknr."}),
      onClick,
    },
    invoiceNote: {
      field: "invoiceNote",
      label: formatMessage({defaultMessage: "Fak. noter"}),
      onClick,
    },
    machine: {
      comparator: getIdentifierFieldComparator("machineIDs"),
      field: "machineIDs",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Mask."})
          : formatMessage({defaultMessage: "Køretøj"}),
      onClick,
      width: 80,
    },
    machineOperator: {
      comparator: getInitialsComparator("machineOperator"),
      field: "machineOperator",
      label: formatMessage({defaultMessage: "Medarb."}),
      onClick,
      width: COLUMN_WIDTHS.initials,
    },
    machineOperatorName: {
      field: "machineOperatorName",
      label: formatMessage({defaultMessage: "Medarb."}),
      onClick,
    },
    machineOperatorShort: {
      comparator: getInitialsComparator("machineOperator"),
      field: "machineOperator",
      label: formatMessage({defaultMessage: "Medarb."}),
      onClick,
      width: COLUMN_WIDTHS.initialsShort,
    },
    machines: {
      field: "machines",
      label:
        customerSettings.machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Mask."})
          : formatMessage({defaultMessage: "Køretøj"}),
      onClick,
    },
    machineShort: {
      comparator: getIdentifierFieldComparator("machineIDs"),
      field: "machineIDs",
      label:
        customerSettings.machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Mask."})
          : formatMessage({defaultMessage: "Køretøj"}),
      onClick,
      width: 60,
    },
    managerInternalNotes: {
      field: "managerInternalNotes",
      label: formatMessage({defaultMessage: "Interne noter"}),
      onClick,
    },
    notes: {
      field: "notes",
      label: formatMessage({defaultMessage: "Noter"}),
      onClick,
      render: makeNotesRender(taskListeNoteLines),
    },
    photo: iconButtonColumnSpecification({
      disableSorting: false,
      field: "photoIcon",
      render: renderPhotoButton(onPhotoClick),
    }),
    pickupLocation: {
      field: "pickupLocation",
      label: formatMessage({defaultMessage: "Afhentning"}),
      onClick,
    },
    pickupLocationLong: {
      field: "pickupLocationLong",
      label: formatMessage({defaultMessage: "Afhentning"}),
      onClick,
    },
    pickupPostalCode: {
      field: "pickupPostalCode",
      label: formatMessage({defaultMessage: "Postnr."}),
      onClick,
    },
    priceGroup: {
      field: "priceGroup",
      label: formatMessage({defaultMessage: "Variant"}),
      onClick,
    },
    project: {
      field: "project",
      label:
        projectLabelVariant === "PROJECT"
          ? formatMessage({defaultMessage: "Projekt"})
          : formatMessage({defaultMessage: "Sag"}),
      onClick,
    },
    projectWithAlias: {
      field: "projectWithAlias",
      label:
        projectLabelVariant === "PROJECT"
          ? formatMessage({defaultMessage: "Projekt"})
          : formatMessage({defaultMessage: "Sag"}),
      onClick,
    },
    referenceNumber: {
      comparator: getIdentifierFieldComparator("referenceNumber"),
      field: "referenceNumber",
      label: getReferenceNumberLabel(
        enableTaskReferenceNumber,
        taskReferenceNumberLabel,
        enableOrderReferenceNumber,
        orderReferenceNumberLabel,
        formatMessage,
      ),
      onClick,
    },
    status: iconButtonColumnSpecification({
      disableSorting: false,
      field: "status",
      render: renderWorkStatus,
      sortField: "numericStatus",
    }),
    time: {
      field: "time",
      label: formatMessage({defaultMessage: "Start"}),
      onClick,
      width: COLUMN_WIDTHS.time,
    },
    timeShort: {
      field: "time",
      label: formatMessage({defaultMessage: "Start"}),
      onClick,
      width: COLUMN_WIDTHS.timeShort,
    },
    workplace: {
      field: "workplace",
      label: formatMessage({defaultMessage: "Arbejdssted"}),
      onClick,
    },
    workplaceLong: {
      field: "workplaceLong",
      label: formatMessage({defaultMessage: "Arbejdssted"}),
      onClick,
    },
    workplacePostalCode: {
      field: "workplacePostalCode",
      label: formatMessage({defaultMessage: "Postnr."}),
      onClick,
    },
    workType: {
      field: "workType",
      label: formatMessage({defaultMessage: "Arbejdsområde"}),
      onClick,
    },
    workTypeColor: colorBoxColumnSpecification({
      field: "workTypeColor",
      render: renderColor,
    }),
    workTypeName: {
      field: "workTypeName",
      label: formatMessage({defaultMessage: "Arbejdsområde"}),
      onClick,
    },
    workTypeShort: {
      comparator: getIdentifierFieldComparator("workTypeShort"),
      field: "workTypeShort",
      label: formatMessage({defaultMessage: "Omr."}),
      onClick,
      width: 60,
    },
  };
}

const getTaskTimeString = (task: Task): string => {
  if (task.workFromTimestamp) {
    return formatTime(task.workFromTimestamp);
  } else if (task.date && task.time) {
    const timestamp = dateFromString(task.date) as Date;
    const [hours, minutes] = task.time.split(":");
    timestamp.setHours(parseInt(hours), parseInt(minutes));
    return formatTime(timestamp);
  }
  return "";
};

function getLocationPostalCodes(
  locationLookup: (url: LocationUrl) => Location | undefined,
  reportingLocations: readonly ReportingLocation[] | undefined,
  location: LocationUrl | null,
): string {
  if (reportingLocations?.length) {
    const locationStrings = reportingLocations.map((entry) =>
      getLocationPostalCode(entry.location, locationLookup),
    );
    if (locationStrings.length) {
      return locationStrings.filter(Boolean).join(", ");
    }
  } else if (location) {
    return getLocationPostalCode(location, locationLookup);
  }
  return "";
}

function getShortLocationString(
  locationLookup: (url: LocationUrl) => Location | undefined,
  relatedLocation: LocationUrl | null,
  reportingLocations: readonly ReportingLocation[] | undefined,
): string {
  if (reportingLocations?.length) {
    const worklaceStrings = reportingLocations.map((entry) => {
      return getWorkplaceString(entry.location, locationLookup);
    });
    if (worklaceStrings?.length) {
      return worklaceStrings.filter(Boolean).join(", ");
    }
  } else if (relatedLocation) {
    return getWorkplaceString(relatedLocation, locationLookup);
  }
  return "";
}

function getLongLocationString(
  locationLookup: (url: LocationUrl) => Location | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  relatedLocation: LocationUrl | null,
  reportingLocations: readonly ReportingLocation[] | undefined,
): string {
  if (reportingLocations?.length) {
    const worklaceStrings = reportingLocations.map((entry) => {
      return getLocationLongString(entry.location, locationLookup, customerLookup);
    });
    if (worklaceStrings?.length) {
      return worklaceStrings.filter(Boolean).join(", ");
    }
  } else if (relatedLocation) {
    return getLocationLongString(relatedLocation, locationLookup, customerLookup) || "";
  }
  return "";
}

export const numericStatusMapping = {
  active: 0,
  completed: 3,
  error: 6,
  paused: 1,
  pending: 2,
  recorded: 5,
  validated: 4,
} as const;

function buildRowData(
  filteredTaskArray: readonly Task[],
  cultureLookup: (url: CultureUrl) => Culture | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
  orderLookup: (url: OrderUrl) => Order | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  projectLookup: (url: ProjectUrl) => Project | undefined,
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined,
  taskPhotoArray: readonly TaskPhoto[],
  sortedTimerStartArray: readonly TimerStart[],
  formatMessage: IntlShape["formatMessage"],
  showMachineAsWorktypeForContratorTasks: boolean,
  showOrderNoteOnTaskList: boolean,
  departments: {
    readonly [x: string]: string | undefined;
  },
  economicSync: boolean,
): readonly TaskTableDataType[] {
  const getDepartmentLabel = (departmentID: string): string =>
    departments[departmentID] || departmentID;
  const tasksWithPhotos = new Set(taskPhotoArray.map((instance) => instance.task));
  const taskLatestTimerStartMap = new Map<string, TimerStart>();
  const taskHasStartTimerStartSet = new Set<string>();
  sortedTimerStartArray.forEach((timerStart) => {
    const taskURL = timerStart.task;
    taskLatestTimerStartMap.set(taskURL, timerStart);
    if (timerStart.timer) {
      taskHasStartTimerStartSet.add(taskURL);
    }
  });
  const getTaskStatus = (task: Task): WorkStatus => {
    return computeTaskStatus(
      task,
      sortedTimerStartArray,
      orderLookup,
      customerLookup,
      economicSync,
    );
  };

  return filteredTaskArray.map((task) => {
    const orderURL = task.order;
    const order = orderURL ? orderLookup(orderURL) : null;
    const cultureURL = order ? order.culture : null;
    const culture = cultureURL ? cultureLookup(cultureURL) : null;
    const customerURL = order ? order.customer : null;
    const customer = customerURL ? customerLookup(customerURL) : null;

    const routePlanURL = order ? order.routePlan : null;
    const route = routePlanURL ? routePlanLookup(routePlanURL) : null;
    const workTypeURL = task.workType;
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : null;
    const machineOperatorURL = task.machineOperator;
    const machineOperatorProfile = machineOperatorURL
      ? userUserProfileLookup(machineOperatorURL)
      : null;
    const machineList = (task.machineuseSet || [])
      .map((machineUse) => machineLookup(machineUse.machine))
      .filter(notUndefined);
    const hasPhoto = tasksWithPhotos.has(task.url);
    const machinesString = machineList.map((m) => `${m.c5_machine}: ${m.name}`).join(", ");
    const machineIDsString = machineList.map((m) => m.c5_machine).join(", ");
    const {notesFromMachineOperator, notesFromManager} = task;
    let notesString =
      notesFromManager && notesFromMachineOperator
        ? `${notesFromMachineOperator}\n${notesFromManager}`.trim()
        : notesFromManager
          ? notesFromManager.trim()
          : notesFromMachineOperator
            ? notesFromMachineOperator.trim()
            : "";
    if (showOrderNoteOnTaskList && order?.notes) {
      notesString = `${notesString}\n${order.notes}`.trim();
    }
    const cultureString = culture ? culture.name : "";
    const priceGroupURL = task.priceGroup;
    const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : null;
    const priceGroupString = priceGroup?.name || "";
    const customerName = customer?.name || "";
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const customerAccount = customer?.c5_account || "";
    const customerString = customerName;
    const customerWithAccountString = `${customerName} - ${customerAccount}`;
    const customerCultureMachineString = !order
      ? machinesString
      : route
        ? route.name
        : customer
          ? customer.name
          : culture
            ? culture.name
            : "";
    const machineOperatorString = machineOperatorProfile?.alias || "";
    const projectURL = task.project;
    const project = projectURL ? projectLookup(projectURL) : null;
    let projectString = "";
    let projectWithAliasString = "";
    if (project) {
      const {alias, name, projectNumber} = project;
      const nameAndAlias = name && alias ? `${name}, ${alias}` : name || alias;
      const nameOrAlias = name || alias;
      projectString = `${projectNumber}: ${nameOrAlias}`;
      projectWithAliasString = `${projectNumber}: ${nameAndAlias}`;
    }
    const timeString = getTaskTimeString(task);
    const taskDate = getTaskDate(task);
    const dateString = formatDate(taskDate);
    const dateShortString = formatDateShort(taskDate);
    const dateMediumString = formatDateWeekdayShort(taskDate);
    const createdByProfile = task.createdBy ? userUserProfileLookup(task.createdBy) : null;
    const createdByString = createdByProfile?.alias || "";
    const createdDateString = formatDate(task.created);
    const createdDateShortString = formatDateShort(task.created);
    const createdDateTimeString = formatDateTime(task.created);
    const createdDateTimeShortString = formatDateTimeShort(task.created);
    const workTypeShortString = workType?.identifier || "";
    let workTypeString = "";
    let workTypeName = "";
    if (workType) {
      workTypeString = getWorkTypeString(workType);
      workTypeName = workType.name;
    } else if (task.department === "E") {
      if (showMachineAsWorktypeForContratorTasks) {
        workTypeString =
          getMachinesWorkTypeString(machineList) ||
          formatMessage({defaultMessage: "Entreprenørarbejde"});
      } else {
        workTypeString = formatMessage({defaultMessage: "Entreprenørarbejde"});
      }
    } else {
      workTypeString = getMachinesWorkTypeString(machineList);
    }
    const machineOperatorName = machineOperatorProfile?.name || "";

    const sortedReportingLocations = task.reportingLocations
      ? sortByOrderMember(Object.values(task.reportingLocations))
      : undefined;
    const workplaceReportingLocations = sortedReportingLocations?.filter(
      (r) => r.type === "workplace",
    );
    const pickupReportingLocations = sortedReportingLocations?.filter((r) => r.type === "pickup");
    const deliveryReportingLocations = sortedReportingLocations?.filter(
      (r) => r.type === "delivery",
    );

    const workplace = getShortLocationString(
      locationLookup,
      task.relatedWorkplace,
      workplaceReportingLocations,
    );
    const workplaceLong = getLongLocationString(
      locationLookup,
      customerLookup,
      task.relatedWorkplace,
      workplaceReportingLocations,
    );
    const deliveryLocation = getShortLocationString(
      locationLookup,
      task.relatedWorkplace,
      deliveryReportingLocations,
    );
    const deliveryLocationLong = getLongLocationString(
      locationLookup,
      customerLookup,
      task.relatedWorkplace,
      deliveryReportingLocations,
    );
    const pickupLocation = getShortLocationString(
      locationLookup,
      task.relatedPickupLocation,
      pickupReportingLocations,
    );
    const pickupLocationLong = getLongLocationString(
      locationLookup,
      customerLookup,
      task.relatedPickupLocation,
      pickupReportingLocations,
    );

    const workplacePostalCode = getLocationPostalCodes(
      locationLookup,
      workplaceReportingLocations,
      task.relatedWorkplace,
    );
    const deliveryPostalCode = getLocationPostalCodes(
      locationLookup,
      deliveryReportingLocations,
      task.relatedWorkplace,
    );
    const pickupPostalCode = getLocationPostalCodes(
      locationLookup,
      pickupReportingLocations,
      task.relatedPickupLocation,
    );

    const referenceNumber = task.referenceNumber || (order ? order.referenceNumber : "");
    let fields = "";
    const fieldUseSet = task.fielduseSet;
    fields = fieldUseSet
      ? fieldUseSet
          .map((fieldUse) => {
            const field = locationLookup(fieldUse.relatedField);
            return field ? field.name : "";
          })
          .sort()
          .join(", ")
      : "";

    const status = getTaskStatus(task);
    const numericStatus = numericStatusMapping[status];

    const result: TaskTableDataType = {
      batchOperationCheckbox: false,
      completed: task.completed,
      createdBy: createdByString,
      createdDate: createdDateString,
      createdDateRaw: task.created || "",
      createdDateShort: createdDateShortString,
      createdDateTime: createdDateTimeString,
      createdDateTimeShort: createdDateTimeShortString,
      culture: cultureString,
      customer: customerString,
      customerCultureMachine: customerCultureMachineString,
      customerId: customerURL ? urlToId(customerURL) : null,
      customerWithAccount: customerWithAccountString,
      date: dateString,
      dateMedium: dateMediumString,
      dateRaw: taskDate || "",
      dateShort: dateShortString,
      deliveryLocation,
      deliveryLocationLong,
      deliveryPostalCode,
      department: getDepartmentLabel(task.department),
      fields,
      invoiceNote: task.invoiceNote,
      isRouteTask: !!routePlanURL,
      key: task.url,
      machineIDs: machineIDsString,
      machineOperator: machineOperatorString,
      machineOperatorName,
      machines: machinesString,
      managerInternalNotes: task.managerInternalNotes,
      notes: notesString,
      numericStatus,
      orderUrl: task.order,
      photoIcon: hasPhoto,
      pickupLocation,
      pickupLocationLong,
      pickupPostalCode,
      priceGroup: priceGroupString,
      project: projectString,
      projectWithAlias: projectWithAliasString,
      referenceNumber,
      status,
      time: timeString,
      validatedAndRecorded: task.validatedAndRecorded,
      workplace,
      workplaceLong,
      workplacePostalCode,
      workType: workTypeString,
      workTypeColor: workType?.color || null,
      workTypeName,
      workTypeShort: workTypeShortString,
    };
    return result;
  });
}

function filteringData(
  filterString: string,
  tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling",
  selectedWorkTypeURLSet: ReadonlySet<string>,
  selectedDepartmentIdentifierSet: ReadonlySet<string>,
): Record<string, unknown> {
  return {
    filterString,
    selectedDepartmentIdentifierSet,
    selectedWorkTypeURLSet,
    tab,
  };
}

export function computeVisibleColumns(
  tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling",
  mobile: boolean,
  tablet: boolean,
  userIsManager: boolean,
  taskListColumns: {
    readonly archive: {
      readonly employee: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
    };
    readonly culture: {
      readonly employee: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
    };
    readonly customer: {
      readonly employee: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
    };
    readonly internal: {
      readonly employee: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
    };
    readonly own: {
      readonly employee: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
    };
    readonly readyForBilling: {
      readonly employee: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly TaskTableColumnID[];
        readonly mobile: readonly TaskTableColumnID[];
        readonly tablet: readonly TaskTableColumnID[];
      };
    };
  },
): readonly TaskTableColumnID[] {
  const deviceType = mobile ? "mobile" : tablet ? "tablet" : "desktop";
  const userType = userIsManager ? "manager" : "employee";
  const tabNameToListType = {
    archive: "archive",
    cultures: "culture",
    customers: "customer",
    internal: "internal",
    mine: "own",
    project: "project",
    readyForBilling: "readyForBilling",
  } as const;
  const listType = tabNameToListType[tab];
  return taskListColumns[listType][userType][deviceType];
}

const FADED_STYLE: Readonly<React.CSSProperties> = {color: "#9e9e9e"};
const NOOP_STYLE: Readonly<React.CSSProperties> = {};

export function fadeCompleted(data: TaskTableDataType): React.CSSProperties {
  if (data.completed) {
    return FADED_STYLE;
  } else {
    return NOOP_STYLE;
  }
}

interface TaskTableStateProps {
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  sortedTimerStartArray: readonly TimerStart[];
  taskArray: readonly Task[];
  taskPhotoArray: readonly TaskPhoto[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskTableOwnProps {
  batchCheckboxRenderer?: (data: TaskTableDataType) => JSX.Element | null;
  batchOperationActive: boolean;
  filterByProject?: Pick<Project, "url">;
  filterString: string;
  onClick: (taskURL: string) => void;
  onPhotoClick: (taskURL: string) => void;
  selectedDepartmentIdentifierSet?: ReadonlySet<string>;
  selectedWorkTypeURLSet?: ReadonlySet<string>;
  tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling";
}

type TaskTableProps = TaskTableOwnProps & TaskTableStateProps;

class TaskTable extends React.Component<TaskTableProps> {
  constructor(props: TaskTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildColumnSpecifications);
    this.computeVisibleColumns = memoize(computeVisibleColumns);
    this.filterTaskArray = memoize(filterTaskArray);
    this.sortTaskArray = memoize(sortTaskArray);
    this.buildRowData = memoize(buildRowData);
    this.filteringData = memoize(filteringData);
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private buildColumnSpecifications: (
    customerSettings: Config,
    formatMessage: IntlShape["formatMessage"],
    onClick: (taskURL: string) => void,
    onPhotoClick: (taskURL: string) => void,
    enableOrderReferenceNumber: boolean,
    enableTaskReferenceNumber: boolean,
    orderReferenceNumberLabel: string | null,
    taskReferenceNumberLabel: string | null,
    taskListeNoteLines: number,
    batchCheckboxRenderer?: (data: TaskTableDataType) => JSX.Element | null,
  ) => ColumnSpecifications<TaskTableFieldID, TaskTableColumnID, TaskUrl, TaskTableDataType>;
  private filterTaskArray: (
    taskArray: readonly Task[],
    currentUserURL: string | null,
    userIsManager: boolean,
    userIsSeniorMachineOperator: boolean,
    externalTaskCulture: boolean,
    externalTaskCustomer: boolean,
    hideInternalTaskListWorkTypes: readonly string[],
    machineOperatorHideTasksAfterDate: string,
    machineOperatorsCanSeeTheirOwnTasksAndActiveTasks: boolean,
    navSync: boolean,
    orderDrafts: boolean,
    taskListsMachineOperatorHideCompleted: boolean,
    taskListsMachineOperatorHideCompletedExceptToday: boolean,
    workTypeArray: readonly WorkType[],
    orderLookup: (url: OrderUrl) => Order | undefined,
    tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling",
    today: string,
    selectedDepartmentIdentifierSet: ReadonlySet<string>,
    selectedWorkTypeURLSet: ReadonlySet<string>,
    filterByProject?: Pick<Project, "url">,
  ) => readonly Task[];
  private sortTaskArray: (taskArray: readonly Task[]) => readonly Task[];
  private buildRowData: (
    filteredTaskArray: readonly Task[],
    cultureLookup: (url: CultureUrl) => Culture | undefined,
    locationLookup: (url: LocationUrl) => Location | undefined,
    orderLookup: (url: OrderUrl) => Order | undefined,
    customerLookup: (url: CustomerUrl) => Customer | undefined,
    routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined,
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
    machineLookup: (url: MachineUrl) => Machine | undefined,
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
    projectLookup: (url: ProjectUrl) => Project | undefined,
    userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined,
    taskPhotoArray: readonly TaskPhoto[],
    sortedTimerStartArray: readonly TimerStart[],
    formatMessage: IntlShape["formatMessage"],
    showMachineAsWorktypeForContratorTasks: boolean,
    showOrderNoteOnTaskList: boolean,
    departments: {
      readonly [x: string]: string | undefined;
    },
    economicSync: boolean,
  ) => readonly TaskTableDataType[];
  private filteringData: (
    filterString: string,
    tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling",
    selectedWorkTypeURLSet: ReadonlySet<string>,
    selectedDepartmentIdentifierSet: ReadonlySet<string>,
  ) => Record<string, unknown>;
  private computeVisibleColumns: (
    tab: "archive" | "cultures" | "customers" | "internal" | "mine" | "readyForBilling",
    mobile: boolean,
    tablet: boolean,
    userIsManager: boolean,
    taskListColumns: {
      readonly archive: {
        readonly employee: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
      };
      readonly culture: {
        readonly employee: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
      };
      readonly customer: {
        readonly employee: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
      };
      readonly internal: {
        readonly employee: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
      };
      readonly own: {
        readonly employee: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
      };
      readonly readyForBilling: {
        readonly employee: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly TaskTableColumnID[];
          readonly mobile: readonly TaskTableColumnID[];
          readonly tablet: readonly TaskTableColumnID[];
        };
      };
    },
  ) => readonly TaskTableColumnID[];

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      cultureLookup,
      currentRole,
      currentUserURL,
      customerLookup,
      customerSettings,
      filterByProject,
      filterString,
      locationLookup,
      machineLookup,
      onClick,
      onPhotoClick,
      orderLookup,
      priceGroupLookup,
      projectLookup,
      routePlanLookup,
      selectedDepartmentIdentifierSet = new Set(),
      selectedWorkTypeURLSet = new Set(),
      sortedTimerStartArray,
      tab,
      taskArray,
      taskPhotoArray,
      userUserProfileLookup,
      workTypeArray,
      workTypeLookup,
    } = this.props;

    if (filterByProject && tab !== "archive") {
      // eslint-disable-next-line no-console
      console.warn(
        "filterByProjectUrl currently only meant to be used together with 'archive' tab",
      );
    }

    const {
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      externalTaskCulture,
      externalTaskCustomer,
      hideInternalTaskListWorkTypes,
      machineOperatorsCanSeeTheirOwnTasksAndActiveTasks,
      navSync,
      orderDrafts,
      orderReferenceNumberLabel,
      showOrderNoteOnTaskList,
      taskListColumns,
      taskListeNoteLines,
      taskListsMachineOperatorHideCompleted,
      taskListsMachineOperatorHideCompletedExceptToday,
      taskReferenceNumberLabel,
    } = customerSettings;
    const columnSpecifications = this.buildColumnSpecifications(
      customerSettings,
      formatMessage,
      onClick,
      onPhotoClick,
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      orderReferenceNumberLabel,
      taskReferenceNumberLabel,
      taskListeNoteLines,
      this.props.batchCheckboxRenderer,
    );
    const userIsManager = !!currentRole?.manager;
    const userIsSeniorMachineOperator = !!currentRole?.seniorMachineOperator;
    const machineOperatorHideTasksAfterDate =
      machineOperatorCanSeeFutureTasksUntil(customerSettings);
    const today = dateToString(new Date());
    const filteredTaskArray = this.filterTaskArray(
      taskArray,
      currentUserURL,
      userIsManager,
      userIsSeniorMachineOperator,
      externalTaskCulture,
      externalTaskCustomer,
      hideInternalTaskListWorkTypes,
      machineOperatorHideTasksAfterDate,
      machineOperatorsCanSeeTheirOwnTasksAndActiveTasks,
      navSync,
      orderDrafts,
      taskListsMachineOperatorHideCompleted,
      taskListsMachineOperatorHideCompletedExceptToday,
      workTypeArray,
      orderLookup,
      tab,
      today,
      selectedWorkTypeURLSet,
      selectedDepartmentIdentifierSet,
      filterByProject,
    );
    const filteredSortedTaskArray = this.sortTaskArray(filteredTaskArray);

    let visibleColumns = this.computeVisibleColumns(
      tab,
      !!bowser.mobile,
      !!bowser.tablet,
      userIsManager,
      taskListColumns,
    );

    if (this.props.batchOperationActive) {
      visibleColumns = ["batchOperationCheckbox", ...visibleColumns];
    }

    const data = this.buildRowData(
      filteredSortedTaskArray,
      cultureLookup,
      locationLookup,
      orderLookup,
      customerLookup,
      routePlanLookup,
      workTypeLookup,
      machineLookup,
      priceGroupLookup,
      projectLookup,
      userUserProfileLookup,
      taskPhotoArray,
      sortedTimerStartArray,
      formatMessage,
      customerSettings.showMachineAsWorktypeForContratorTasks,
      showOrderNoteOnTaskList,
      customerSettings.departments,
      customerSettings.economicSync,
    );
    return (
      <ConnectedTableWithPagination
        columns={columnSpecifications}
        defaultRowsPerPage={PaginationPageSize.SMALL}
        defaultSortDirection="ASC"
        defaultSortKey="status"
        entries={data}
        filteringData={this.filteringData(
          filterString,
          tab,
          selectedWorkTypeURLSet,
          selectedDepartmentIdentifierSet,
        )}
        filterString={filterString}
        groupBy={customerSettings.showDateGroupsOnTaskLists ? "date" : undefined}
        overrideSortBy={tab === "mine" ? "status" : undefined}
        rowStyle={userIsManager ? undefined : fadeCompleted}
        savePaginationIdentifier="TaskTable"
        saveSortingIdentifier={`TaskTable${_.upperFirst(tab)}`}
        visibleColumns={visibleColumns}
      />
    );
  }
}

const ConnectedTaskTable: React.ComponentType<TaskTableOwnProps> = connect<
  TaskTableStateProps,
  object,
  TaskTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskTableStateProps>({
    cultureLookup: getCultureLookup,
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    priceGroupLookup: getPriceGroupLookup,
    projectLookup: getProjectLookup,
    routePlanLookup: getRoutePlanLookup,
    sortedTimerStartArray: getSortedTimerStartArray,
    taskArray: getTaskArray,
    taskPhotoArray: getTaskPhotoArray,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
)(TaskTable);

export {ConnectedTaskTable as TaskTable};
