import {Grid, TextField, Typography} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import {useEventTargetValueCallback} from "app-utils";
import parse from "autosuggest-highlight/parse";
import _ from "lodash";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";

const LOOKUP_THROTTLE_TIMEOUT = 200;

const BOLD_WEIGHT = 700;
const NORMAL_WEIGHT = 400;

interface AddressAutocompleteFieldProps {
  autocompleteService?: google.maps.places.AutocompleteService | undefined;
  autoFocus: boolean;
  countryRestrictions: string | readonly string[];
  onChange: (value: string) => void;
  onPlaceSelected: (placeId: google.maps.places.AutocompletePrediction) => void;
  openOnFocus: boolean;
  value: string;
}

export function AddressAutocompleteField(props: AddressAutocompleteFieldProps): JSX.Element {
  const {
    autocompleteService,
    autoFocus,
    countryRestrictions,
    onChange,
    onPlaceSelected,
    openOnFocus,
    value,
  } = props;

  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const intl = useIntl();

  const fetch = useMemo(
    () =>
      _.throttle(
        async (
          request: google.maps.places.AutocompletionRequest,
          callback: (
            result: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus,
          ) => void,
        ) => {
          if (autocompleteService) {
            await autocompleteService.getPlacePredictions(request, callback);
          }
        },
        LOOKUP_THROTTLE_TIMEOUT,
      ),
    [autocompleteService],
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService) {
      return undefined;
    }
    if (value === "") {
      setOptions([]);
      return undefined;
    }
    fetch(
      {
        componentRestrictions: {
          country: countryRestrictions as string[] | string,
        },
        input: value,
        types: [],
      },
      (results: google.maps.places.AutocompletePrediction[] | null) => {
        if (active) {
          setOptions(results || []);
        }
      },
    )?.catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Google getPlacePredictions failed:", error);
      throw error;
    });
    return () => {
      active = false;
    };
  }, [value, fetch, autocompleteService, countryRestrictions]);

  const getOptionLabel = useCallback(
    (option: google.maps.places.AutocompletePrediction): string => option.description || "",
    [],
  );

  const handleTextFieldChange = useEventTargetValueCallback(onChange, [onChange]);

  const handleTextFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        if (options.length) {
          event.preventDefault();
          onPlaceSelected(options[0]);
        }
      }
    },
    [onPlaceSelected, options],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField
        {...(params.size ? params : _.omit(params, ["size"]))}
        fullWidth
        autoFocus={autoFocus}
        label={intl.formatMessage({
          defaultMessage: "Søg efter firmanavn, adresse el.lign.",
        })}
        margin="dense"
        variant="outlined"
        onChange={handleTextFieldChange}
        onKeyDown={handleTextFieldKeyDown}
      />
    ),
    [autoFocus, handleTextFieldChange, handleTextFieldKeyDown, intl],
  );

  const renderOption = useCallback(
    (option: google.maps.places.AutocompletePrediction): JSX.Element => {
      const matches = option.structured_formatting.main_text_matched_substrings || [];
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match: any) => [match.offset, match.offset + match.length]),
      );

      return (
        <Grid container alignItems="center">
          <Grid item style={{width: 44}}>
            <MapMarkerIcon />
          </Grid>
          <Grid item style={{width: "calc(100% - 44px)", wordWrap: "break-word"}}>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? BOLD_WEIGHT : NORMAL_WEIGHT,
                }}
              >
                {part.text}
              </span>
            ))}
            <Typography color="textSecondary" variant="body2">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      );
    },
    [],
  );

  const handleChange = useCallback(
    (
      _event: React.ChangeEvent<unknown>,
      selectedValue: google.maps.places.AutocompletePrediction | string | null,
      reason: AutocompleteChangeReason,
    ): void => {
      if (reason === "clear") {
        onChange("");
      } else if (reason === "select-option") {
        if (selectedValue && typeof selectedValue !== "string") {
          onPlaceSelected(selectedValue);
        }
      }
    },
    [onChange, onPlaceSelected],
  );

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction, false, undefined, true>
      freeSolo
      includeInputInList
      filterOptions={_.identity}
      getOptionLabel={getOptionLabel}
      inputValue={value}
      multiple={false}
      openOnFocus={openOnFocus}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      style={{width: "100%"}}
      onChange={handleChange}
    />
  );
}
