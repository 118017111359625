import {Config} from "@co-common-libs/config";
import {Machine, MachineUrl, urlToId} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {ConnectedMachineDialog} from "@co-frontend-libs/connected-components";
import {AppState, getCustomerSettings, getMachineLookup, getToken} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {CircularProgress} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {CalculateCard} from "./calculate-card";
import {DisplayCards} from "./display-cards";

const fetchIntervalSeconds = 15;

interface MachineCostStateProps {
  customerSettings: Config;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  token: string | null;
}

type MachineCostProps = MachineCostStateProps;

interface MachineCostState {
  data: {
    [workTypeID: string]: {
      [initials: string]: [string, number, string, number][];
    };
  } | null;
  fetching: boolean;
  fromDate: string | null;
  machineDialogOpen: boolean;
  selectedMachine: MachineUrl | undefined;
  toDate: string | null;
}

class MachineCost extends PureComponent<MachineCostProps, MachineCostState> {
  state: MachineCostState = {
    data: null,
    fetching: false,
    fromDate: null,
    machineDialogOpen: false,
    selectedMachine: undefined,
    toDate: null,
  };

  componentWillUnmount(): void {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  private abortController: AbortController | undefined;
  private fetchDataTimeout: number | undefined;
  fetch(): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}machineSalaryCost`;
    const {fromDate, selectedMachine, toDate} = this.state;
    this.setState({data: null, fetching: true});
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    if (this.fetchDataTimeout) {
      window.clearTimeout(this.fetchDataTimeout);
      this.fetchDataTimeout = undefined;
    }
    if (window.AbortController) {
      this.abortController = new AbortController();
    }
    jsonFetch(
      url,
      "POST",
      {
        fromDate,
        machineId: urlToId(selectedMachine as string),
        toDate,
      },
      this.abortController?.signal,
    )
      .then((response) => {
        if (
          fromDate === this.state.fromDate &&
          toDate === this.state.toDate &&
          selectedMachine === this.state.selectedMachine
        ) {
          const reportIdentifier = response.data.identifier;
          const timeoutMilliseconds = fetchIntervalSeconds * SECOND_MILLISECONDS;
          const callback = (): void => {
            if (
              fromDate === this.state.fromDate &&
              toDate === this.state.toDate &&
              selectedMachine === this.state.selectedMachine
            ) {
              if (window.AbortController) {
                this.abortController = new AbortController();
              }
              jsonFetch(
                `${url}?identifier=${reportIdentifier}`,
                "GET",
                null,
                this.abortController?.signal,
              )
                // eslint-disable-next-line promise/no-nesting
                .then((fetchResponse) => {
                  if (
                    fromDate === this.state.fromDate &&
                    toDate === this.state.toDate &&
                    selectedMachine === this.state.selectedMachine
                  ) {
                    if (fetchResponse.data) {
                      this.setState({
                        data: fetchResponse.data,
                        fetching: false,
                      });
                    } else {
                      this.fetchDataTimeout = window.setTimeout(callback, timeoutMilliseconds);
                    }
                  }
                  return;
                })
                // eslint-disable-next-line promise/no-nesting
                .catch((error) => {
                  if (error.cause?.name === "AbortError") {
                    return;
                  }
                  if (
                    fromDate === this.state.fromDate &&
                    toDate === this.state.toDate &&
                    selectedMachine === this.state.selectedMachine
                  ) {
                    this.setState({
                      fetching: false,
                    });
                  }
                });
            }
          };
          this.fetchDataTimeout = window.setTimeout(callback, timeoutMilliseconds);
        }
        return;
      })
      .catch((error) => {
        if (error.cause?.name === "AbortError") {
          return;
        }
        if (
          fromDate === this.state.fromDate &&
          toDate === this.state.toDate &&
          selectedMachine === this.state.selectedMachine
        ) {
          this.setState({
            fetching: false,
          });
        }
      });
  }

  @bind
  handleFromDateChanged(fromDate: string | null): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    if (this.fetchDataTimeout) {
      window.clearTimeout(this.fetchDataTimeout);
      this.fetchDataTimeout = undefined;
    }
    this.setState({
      data: null,
      fetching: false,
      fromDate,
    });
  }
  @bind
  handleGenerateClick(): void {
    this.fetch();
  }
  @bind
  handleToDateChanged(toDate: string | null): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    if (this.fetchDataTimeout) {
      window.clearTimeout(this.fetchDataTimeout);
      this.fetchDataTimeout = undefined;
    }
    this.setState({
      data: null,
      fetching: false,
      toDate,
    });
  }

  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
  }

  @bind
  handleMachineDialogOk(machineURL: MachineUrl): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = undefined;
    }
    if (this.fetchDataTimeout) {
      window.clearTimeout(this.fetchDataTimeout);
      this.fetchDataTimeout = undefined;
    }
    this.setState({
      data: null,
      fetching: false,
      machineDialogOpen: false,
      selectedMachine: machineURL,
    });
  }

  @bind
  handleRequestMachineDialogOpen(): void {
    this.setState({machineDialogOpen: true});
  }

  render(): JSX.Element {
    const {data, fetching, fromDate, selectedMachine, toDate} = this.state;
    const {customerSettings, machineLookup} = this.props;
    let content: JSX.Element | undefined;
    if (fetching) {
      content = (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1em",
            width: 50,
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (fromDate && toDate && data) {
      content = (
        <DisplayCards
          data={data}
          fromDate={fromDate}
          machine={selectedMachine ? machineLookup(selectedMachine) : undefined}
          toDate={toDate}
        />
      );
    }
    return (
      <div style={{padding: "1em"}}>
        <CalculateCard
          customerSettings={customerSettings}
          data={data || undefined}
          fromDate={fromDate}
          selectedMachine={selectedMachine ? machineLookup(selectedMachine) : undefined}
          toDate={toDate}
          token={this.props.token}
          onFromDateChanged={this.handleFromDateChanged}
          onGenerateClick={this.handleGenerateClick}
          onRequestMachineDialogOpen={this.handleRequestMachineDialogOpen}
          onToDateChanged={this.handleToDateChanged}
        />
        {content}
        <ConnectedMachineDialog
          open={this.state.machineDialogOpen}
          onCancel={this.handleMachineDialogCancel}
          onOk={this.handleMachineDialogOk}
        />
      </div>
    );
  }
}

const ConnectedMachineCost = connect<MachineCostStateProps, object, object, AppState>(
  createStructuredSelector<AppState, MachineCostStateProps>({
    customerSettings: getCustomerSettings,
    machineLookup: getMachineLookup,
    token: getToken,
  }),
  {},
)(MachineCost);

export {ConnectedMachineCost as MachineCost};
