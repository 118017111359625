import {notUndefined} from "@co-common-libs/utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import bowser from "bowser";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {MachineAnalysis} from "./machine-analysis";
import {MachineCost} from "./machine-salary-cost";
import {RouteJournal} from "./route-journal";
import {StorageJournal} from "./storage-journal";
import {TaskStatistics} from "./task-statistics";
import {TransportReport} from "./transport-report";

interface ReportPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

enum TabOption {
  STATISTICS = "statistics",
  TRANSPORT = "transport",
  STORAGE_JOURNAL = "storageJournal",
  MACHINE_SALARY_COST = "machineSalaryCost",
  MACHINE_ANALYSIS = "machineAnalysis",
  ROUTE_JOURNAL = "routeJournal",
}

export const ReportPage = React.memo(function ReportPage(props: ReportPageProps): JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  const [activeTab, setActiveTab] = useQueryParameterState<TabOption>("tab", TabOption.STATISTICS);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: any) => {
      if (Object.values(TabOption).includes(value)) {
        setActiveTab(value);
      }
    },
    [setActiveTab],
  );

  const tabEntries = useMemo(() => {
    return [
      <Tab
        key="statistics"
        label={intl.formatMessage({defaultMessage: "Opgavestatistik"})}
        value={TabOption.STATISTICS}
      />,
      customerSettings.enableTransportReport ? (
        <Tab
          key="transport"
          label={intl.formatMessage({defaultMessage: "Transport"})}
          value={TabOption.TRANSPORT}
        />
      ) : undefined,
      customerSettings.enableLocationStorage ? (
        <Tab
          key="storageJournal"
          label={
            <FormattedMessage
              defaultMessage="Lagerjournal"
              id="reports.tab-label.storage-journal"
            />
          }
          value={TabOption.STORAGE_JOURNAL}
        />
      ) : undefined,
      customerSettings.showMachineSalaryCostReport ? (
        <Tab
          key="machineSalaryCost"
          label={intl.formatMessage({
            defaultMessage: "Lønomkostninger for maskine",
          })}
          value={TabOption.MACHINE_SALARY_COST}
        />
      ) : undefined,
      customerSettings.enableMachineAnalysis ? (
        <Tab
          key="machineAnalysis"
          label={
            <FormattedMessage defaultMessage="Maskinanalyse" id="reports.tab.machine-analysis" />
          }
          value={TabOption.MACHINE_ANALYSIS}
        />
      ) : undefined,
      customerSettings.enableRouteJournal ? (
        <Tab
          key="routeJournal"
          label={<FormattedMessage defaultMessage="Rutejournal" id="reports.tab.route-journal" />}
          value={TabOption.ROUTE_JOURNAL}
        />
      ) : undefined,
    ].filter(notUndefined);
  }, [
    customerSettings.enableLocationStorage,
    customerSettings.enableMachineAnalysis,
    customerSettings.enableRouteJournal,
    customerSettings.enableTransportReport,
    customerSettings.showMachineSalaryCostReport,
    intl,
  ]);

  // leave it up to the TypeScript compiler to ensure that we do not fall-through
  // the switch statement. If a switch-case is missing, TS will detect that
  // we potentially return undefined, which is not covered by the return type.
  // eslint-disable-next-line consistent-return
  const content = useMemo((): JSX.Element | null => {
    switch (activeTab) {
      case TabOption.STORAGE_JOURNAL:
        return customerSettings.enableLocationStorage ? <StorageJournal /> : null;
      case TabOption.MACHINE_ANALYSIS:
        return customerSettings.enableMachineAnalysis ? <MachineAnalysis /> : null;
      case TabOption.MACHINE_SALARY_COST:
        return customerSettings.showMachineSalaryCostReport ? <MachineCost /> : null;
      case TabOption.ROUTE_JOURNAL:
        return customerSettings.enableRouteJournal ? <RouteJournal /> : null;
      case TabOption.STATISTICS:
        return <TaskStatistics />;
      case TabOption.TRANSPORT:
        return <TransportReport />;
    }
  }, [
    activeTab,
    customerSettings.enableLocationStorage,
    customerSettings.enableMachineAnalysis,
    customerSettings.enableRouteJournal,
    customerSettings.showMachineSalaryCostReport,
  ]);

  return (
    <PageLayout
      withBottomScrollPadding
      tabs={
        tabEntries.length > 1 ? (
          <Tabs
            value={activeTab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
            onChange={handleTabChange}
          >
            {tabEntries}
          </Tabs>
        ) : undefined
      }
      toolbar={
        <MenuToolbar
          title={intl.formatMessage({defaultMessage: "Rapporter"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      {content}
    </PageLayout>
  );
});
