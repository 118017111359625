import {Config} from "@co-common-libs/config";
import {Customer, Order, Task} from "@co-common-libs/resources";
import {WEEK_DAYS, dateFromString, dateToString, formatDateNumeric} from "@co-common-libs/utils";
import {DateField} from "@co-frontend-libs/components";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {QueryQueryStateStruct} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {Card, CardContent, CardHeader, CircularProgress} from "@material-ui/core";
import {WorkTypeSumList} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {endOfDay, startOfDay, subDays} from "date-fns";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {moveDate, setDate} from "./log-tab";

const messages = defineMessages({
  consumptionForPeriod: {
    defaultMessage: "Forbrug i periode",
    id: "customer-instance.card-title.orders-for-period",
  },
  fromDate: {defaultMessage: "Fra", id: "customer-instance.label.from-date"},
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "customer-instance.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "customer-instance.label.archive-waiting",
  },
  toDate: {defaultMessage: "Til", id: "customer-instance.label.to-date"},
});

interface PeriodLogSumCardProps {
  configPutRequest: (data: {key: string; value: any}) => void;
  customer: Customer;
  customerInstanceDateFields?: {fromDate: string; toDate: string} | undefined;
  customerSettings: Config;
  fromDate?: string;
  orderArray: readonly Order[];
  pathName: string;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  taskArray: readonly Task[];
  taskSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  toDate?: string;
  token: string | null;
}

interface PeriodLogSumCardState {
  fromDateMoved: boolean;
  toDateMoved: boolean;
}

const TEMPORARY_QUERIES_KEY = "PeriodLogSumCard";

class PeriodLogSumCard extends PureComponent<PeriodLogSumCardProps, PeriodLogSumCardState> {
  state: PeriodLogSumCardState = {
    fromDateMoved: false,
    toDateMoved: false,
  };
  UNSAFE_componentWillMount(): void {
    const {customerSettings, fromDate, toDate} = this.props;
    const {reportDataIsValidFrom} = customerSettings;
    const [fromDateMoved, newFromDate] = moveDate(reportDataIsValidFrom, fromDate);
    const [toDateMoved, newToDate] = moveDate(reportDataIsValidFrom, toDate);
    this.fetchFromArchive(newFromDate, newToDate);
    this.setState({fromDateMoved, toDateMoved});
  }
  UNSAFE_componentWillReceiveProps(nextProps: PeriodLogSumCardProps): void {
    if (nextProps.fromDate !== this.props.fromDate || nextProps.toDate !== this.props.toDate) {
      const {customerSettings, fromDate, toDate} = nextProps;
      const {reportDataIsValidFrom} = customerSettings;
      const [fromDateMoved, newFromDate] = moveDate(reportDataIsValidFrom, fromDate);
      const [toDateMoved, newToDate] = moveDate(reportDataIsValidFrom, toDate);
      this.fetchFromArchive(newFromDate, newToDate);
      this.setState({fromDateMoved, toDateMoved});
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  buildQueries(fromDate?: string | null, toDate?: string | null): Query[] {
    const {customer} = this.props;
    if (fromDate && toDate && toDate >= fromDate) {
      const rangeStartString = startOfDay(dateFromString(fromDate) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(toDate) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                memberName: "workToTimestamp",
                type: "memberGt",
                value: rangeStartString,
              },
              {
                memberName: "workFromTimestamp",
                type: "memberLt",
                value: rangeEndString,
              },
              {memberName: "completed", type: "memberTruthy"},
            ],
            type: "and",
          },
          {
            checks: [
              {
                memberName: "date",
                type: "memberGte",
                value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
              },
              {
                memberName: "date",
                type: "memberLte",
                value: toDate,
              },
            ],
            type: "and",
          },
          {memberName: "completed", type: "memberFalsy"},
        ],
        type: "or",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            customerID: customer.id as string,
            fromDate,
            toDate,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
      ];
      return queries;
    } else {
      return [];
    }
  }
  fetchFromArchive(fromDate?: string | null, toDate?: string | null): void {
    const queries = this.buildQueries(fromDate, toDate);
    if (queries.length) {
      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY);
    }
  }
  @bind
  handleFromFieldChange(value: string | null): void {
    const {configPutRequest, customerInstanceDateFields, customerSettings, putQueryKey} =
      this.props;
    this.setState({
      fromDateMoved: setDate(
        {
          configPutRequest,
          customerInstanceDateFields,
          customerSettings,
          dateField: "fromDate",
          putQueryKey,
        },
        value,
      ),
    });
  }
  @bind
  handleToFieldChange(value: string | null): void {
    const {configPutRequest, customerInstanceDateFields, customerSettings, putQueryKey} =
      this.props;
    this.setState({
      toDateMoved: setDate(
        {
          configPutRequest,
          customerInstanceDateFields,
          customerSettings,
          dateField: "toDate",
          putQueryKey,
        },
        value,
      ),
    });
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customer, fromDate, orderArray, taskSyncedState, toDate} = this.props;
    let message;
    let showSpinner = false;
    {
      const queries = this.buildQueries(fromDate, toDate);
      if (queries.length) {
        const query = queries[0];
        const querySyncedState = taskSyncedState.get(query.keyString);
        if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
          message = formatMessage(messages.onlineArchiveWaiting);
          showSpinner = true;
        } else {
          const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp;
          const {lastErrorTimestamp} = querySyncedState.queryState;
          if (lastErrorTimestamp && lastTimestamp && lastErrorTimestamp > lastTimestamp) {
            message = formatMessage(messages.onlineArchiveError);
          }
        }
      }
    }
    let content;
    if (message) {
      let spinner;
      if (showSpinner) {
        spinner = <CircularProgress />;
      }
      content = (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>
      );
    } else if (fromDate && toDate && toDate >= fromDate) {
      const customerURL = customer.url;
      const customerOrderURLSet = new Set(
        orderArray.filter((order) => order.customer === customerURL).map((order) => order.url),
      );
      const taskList = this.props.taskArray.filter((task) => {
        if (!task.completed || !task.order || !customerOrderURLSet.has(task.order)) {
          return false;
        }
        const {date} = task;
        return date && fromDate <= date && date <= toDate;
      });
      if (!taskList.length) {
        content = (
          <div style={{padding: 8, textAlign: "center"}}>
            <FormattedMessage
              defaultMessage="Kunden har ingen opgaver i den valgte periode."
              id="customer-instance.text.no-tasks-found"
            />
          </div>
        );
      } else {
        content = (
          <WorkTypeSumList
            customerName={customer.name}
            fromDate={fromDate}
            taskList={taskList}
            toDate={toDate}
          />
        );
      }
    }
    return (
      <Card>
        <CardHeader title={formatMessage(messages.consumptionForPeriod)} />
        <CardContent>
          <Grid>
            <Cell palm="12/12">
              <DateField
                autoFocus
                autoOk
                fullWidth
                label={formatMessage(messages.fromDate)}
                margin="dense"
                value={this.props.fromDate || null}
                onChange={this.handleFromFieldChange}
              />
            </Cell>
            <Cell palm="12/12">
              <DateField
                autoOk
                fullWidth
                label={formatMessage(messages.toDate)}
                margin="dense"
                value={this.props.toDate || null}
                onChange={this.handleToFieldChange}
              />
            </Cell>
          </Grid>
          <div style={{color: "red"}}>
            {this.state.fromDateMoved ? (
              <div>
                <FormattedMessage
                  defaultMessage="Den valgte fra dato lå før den første valide start dato: {date}, så datoen er flyttet frem"
                  id="customer-instance.label.from-date-error"
                  values={{date: formatDateNumeric(this.props.fromDate)}}
                />
              </div>
            ) : null}
            {this.state.toDateMoved ? (
              <div>
                <FormattedMessage
                  defaultMessage="Den valgte til dato lå før den første valide start dato: {date}, så datoen er flyttet frem"
                  id="customer-instance.label.to-date-error"
                  values={{date: formatDateNumeric(this.props.fromDate)}}
                />
              </div>
            ) : null}
          </div>
        </CardContent>
        {content}
      </Card>
    );
  }
}

export default PeriodLogSumCard;
