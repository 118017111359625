import {RelevantConfigPart} from "./relevant-config-part";

export enum ActiveIntegrationKind {
  NONE,
  LEGACY,
  IMPORT_ONLY,
  LIVE_SYNC,
}

export function getActiveIntegrationKind(config: RelevantConfigPart): ActiveIntegrationKind {
  const {brugerdataSync, c5Sync, economicSync, navSync, unicontaSync} = config;

  const legacy = c5Sync || navSync || brugerdataSync;
  const importOnly = unicontaSync;
  // noinspection UnnecessaryLocalVariableJS
  const liveSync = economicSync;

  if (legacy) {
    return ActiveIntegrationKind.LEGACY;
  } else if (importOnly) {
    return ActiveIntegrationKind.IMPORT_ONLY;
  } else if (liveSync) {
    return ActiveIntegrationKind.LIVE_SYNC;
  } else {
    return ActiveIntegrationKind.NONE;
  }
}
