import {Config} from "@co-common-libs/config";
import {Customer, CustomerUrl, Location, LocationUrl} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import {ErrorColorButton, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {LocationSelectCreateDialog} from "./location-dialog";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "yield-location-dialog.label.task-address",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  chooseDeliveryLocation: {
    defaultMessage: "Vælg eller tilføj leveringsted",
    id: "customer-location-dialog.title.choose-delivery-location",
  },
  choosePickupLocation: {
    defaultMessage: "Vælg eller tilføj afhentningsted",
    id: "customer-location-dialog.title.choose-pickup-location",
  },
  delete: {
    defaultMessage: "Slet",
    id: "yield-location-dialog.label.delete",
  },
  editDeliveryLocation: {
    defaultMessage: "Ret leveringsted",
    id: "yield-location-dialog.title.edit-delivery-location",
  },
  editPickupLocation: {
    defaultMessage: "Ret afhentningssted",
    id: "yield-location-dialog.title.edit-pickup-location",
  },
  newDeliveryLocation: {
    defaultMessage: "Nyt leveringsted",
    id: "yield-location-dialog.title.new-delivery-location",
  },
  newPickupLocation: {
    defaultMessage: "Nyt afhentningssted",
    id: "yield-location-dialog.title.new-pickup-location",
  },
  note: {
    defaultMessage: "Note",
    id: "yield-location-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  selectCustomer: {
    defaultMessage: "Vælg",
    id: "yield-location-dialog.label.select-customer",
  },
  selectDeliveryLocation: {
    defaultMessage: "Leveringsted",
    id: "yield-location-dialog.title.select-delivery-location",
  },
  selectPickupLocation: {
    defaultMessage: "Afhentningssted",
    id: "yield-location-dialog.title.select-pickup-location",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "yield-location-dialog.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed",
    id: "yield-location-dialog.label.unit-choice",
  },
});

interface YieldLocationDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
}

interface YieldLocationDialogOwnProps {
  address?: string;
  canDelete?: boolean;
  customer?: Customer | undefined;
  isNew: boolean;
  isPickup: boolean;
  location?: Location | undefined;
  note?: string;
  onCancel: () => void;
  onOk: (data: {
    address: string;
    customer: Customer | null;
    location: Location | null;
    note: string;
  }) => void;
  onRequestCustomerDialog: (callback: (customer: Customer) => void) => void;
  onRequestDelete?: () => void;
  open: boolean;
}

interface YieldLocationDialogState {
  address: string | undefined;
  customer: Customer | null | undefined;
  location: Location | null | undefined;
  locationDialogOpen: boolean;
  note: string | undefined;
}

type YieldLocationDialogProps = YieldLocationDialogOwnProps & YieldLocationDialogStateProps;

class YieldLocationDialog extends PureComponent<
  YieldLocationDialogProps,
  YieldLocationDialogState
> {
  state: YieldLocationDialogState = {
    address: this.props.address,
    customer: this.props.customer,
    location: this.props.location,
    locationDialogOpen: false,
    note: this.props.note,
  };
  UNSAFE_componentWillReceiveProps(nextProps: YieldLocationDialogProps): void {
    const members = ["address", "customer", "note", "location"] as const;
    if (nextProps.open && !this.props.open) {
      const update: Partial<YieldLocationDialogState> = {};
      members.forEach((member) => {
        update[member] = nextProps[member] as any;
      });
      this.setState(update as any);
    } else {
      let changed = false;
      const update: Partial<YieldLocationDialogState> = {};
      members.forEach((member) => {
        if (nextProps[member] !== this.props[member]) {
          update[member] = nextProps[member] as any;
          changed = true;
        }
      });
      if (changed) {
        this.setState(update as any);
      }
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleCustomerSelectButton(): void {
    this.props.onRequestCustomerDialog(this.handleCustomerSelected);
  }
  @bind
  handleCustomerSelected(customer: Customer): void {
    this.setState({customer});
  }

  @bind
  handleOk(): void {
    const {address, customer, location, note} = this.state;
    this.props.onOk({
      address: address || "",
      customer: customer || null,
      location: location || null,
      note: note || "",
    });
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleLocationSelectButton(): void {
    this.setState({locationDialogOpen: true});
  }
  @bind
  handleLocationDialogCancel(): void {
    this.setState({locationDialogOpen: false});
  }
  @bind
  handleLocationDialogOk(url: LocationUrl): void {
    const location = this.props.locationLookup(url);
    const customer = location?.customer ? this.props.customerLookup(location.customer) : null;
    this.setState({
      customer: customer || null,
      location,
      locationDialogOpen: false,
    });
  }

  getTitle(): string {
    const {formatMessage} = this.context;
    const {isNew, isPickup} = this.props;
    if (isPickup) {
      return isNew
        ? formatMessage(messages.newPickupLocation)
        : formatMessage(messages.editPickupLocation);
    } else {
      return isNew
        ? formatMessage(messages.newDeliveryLocation)
        : formatMessage(messages.editDeliveryLocation);
    }
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {canDelete, isNew, open} = this.props;
    let customerBlock;
    if (this.state.customer) {
      const {customer} = this.state;
      customerBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Navn: {name}"
            id="yield-location-dialog.label.customer-name"
            tagName="div"
            values={{name: customer.name}}
          />
          <FormattedMessage
            defaultMessage="Adresse: {address}"
            id="yield-location-dialog.label.customer-address"
            tagName="div"
            values={{address: customerAddress(customer)}}
          />
        </div>
      );
    }
    let deleteButton;
    if (!isNew && this.props.onRequestDelete) {
      deleteButton = (
        <div>
          <ErrorColorButton disabled={!canDelete} onClick={this.props.onRequestDelete}>
            {formatMessage(messages.delete)}
          </ErrorColorButton>
        </div>
      );
    }
    let locationAddress;
    if (this.state.location) {
      locationAddress = this.state.location.address || this.state.location.name;
    }
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>
              <FormattedMessage
                defaultMessage="Kunde"
                id="yield-location-dialog.label.customer"
                tagName="h4"
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleCustomerSelectButton}
              >
                {formatMessage(messages.selectCustomer)}
              </Button>
              {customerBlock}
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Arbejdssted"
                id="order-instance.label.workplace"
                tagName="h4"
              />
              <div>
                <Button
                  color="secondary"
                  disabled={!this.state.customer}
                  variant="contained"
                  onClick={this.handleLocationSelectButton}
                >
                  {this.props.isPickup
                    ? formatMessage(messages.selectPickupLocation)
                    : formatMessage(messages.selectDeliveryLocation)}
                </Button>
                <div>{locationAddress}</div>
              </div>
            </div>
            {deleteButton}
          </Cell>
          <Cell palm="12/12">
            <TrimTextField
              fullWidth
              multiline
              label={formatMessage(messages.note)}
              margin="dense"
              maxRows={30}
              minRows={2}
              value={this.state.note}
              variant="outlined"
              onChange={this.handleNoteChange}
            />
          </Cell>
        </Grid>
      </div>
    );
    const dialog = (
      <ResponsiveDialog
        open={open}
        title={this.getTitle()}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
    return (
      <>
        {dialog}
        <LocationSelectCreateDialog
          customerURL={this.state.customer?.url || null}
          includeWorkplaceOnlyLocations={false}
          logOnlyLocation={this.props.customerSettings.setLogOnlyLocationOnCreate}
          open={this.state.locationDialogOpen}
          titleVariant="WORKPLACE"
          onCancel={this.handleLocationDialogCancel}
          onOk={this.handleLocationDialogOk}
        />
      </>
    );
  }
}

const ConnectedYieldLocationDialog: React.ComponentType<YieldLocationDialogOwnProps> = connect<
  YieldLocationDialogStateProps,
  object,
  YieldLocationDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, YieldLocationDialogStateProps>({
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
  }),
  {},
)(YieldLocationDialog);

export {ConnectedYieldLocationDialog as YieldLocationDialog};
