import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {customerAddress} from "@co-common-libs/utils";
import {
  DecimalField,
  ErrorColorButton,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {LocationSelectCreateDialog} from "./location-dialog";
import {UnitSelect} from "./unit-select";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "pickup-location-dialog.label.task-address",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  delete: {
    defaultMessage: "Slet",
    id: "pickup-location-dialog.label.delete",
  },
  editPickupLocation: {
    defaultMessage: "Ret afhentningssted",
    id: "pickup-location-dialog.title.edit-pickup-location",
  },
  newPickupLocation: {
    defaultMessage: "Nyt afhentningssted",
    id: "pickup-location-dialog.title.new-pickup-location",
  },
  note: {
    defaultMessage: "Note",
    id: "pickup-location-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  selectCustomer: {
    defaultMessage: "Vælg",
    id: "pickup-location-dialog.label.select-customer",
  },
  selectPickupLocation: {
    defaultMessage: "Afhentningssted",
    id: "pickup-location-dialog.title.select-pickup-location",
  },
  selectWorkplace: {
    defaultMessage: "Vælg",
    id: "pickup-location-dialog.label.select-workplace",
  },
  totalAmount: {
    defaultMessage: "Total mængde ({unit})",
    id: "pickup-location-dialog.label.total-amount",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "pickup-location-dialog.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed",
    id: "pickup-location-dialog.label.unit-choice",
  },
});

interface PickupLocationDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface PickupLocationDialogOwnProps {
  amount?: number | undefined;
  canDelete?: boolean;
  customer?: Customer | undefined;
  customerSettings: Config;
  isNew: boolean;
  location?: Location | undefined;
  note?: string;
  onCancel: () => void;
  onOk: (data: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }) => void;
  onRequestCustomerDialog: (callback: (customer: Customer) => void) => void;
  onRequestDelete?: () => void;
  open: boolean;
  relatedUnit?: Unit | undefined;
  unit?: string | undefined;
}

interface PickupLocationDialogState {
  // address: string | undefined;
  amount: number | null | undefined;
  customer: Customer | null | undefined;
  customerLocationDialogOpen: boolean;
  location: Location | null | undefined;
  note: string | undefined;
  relatedUnit: Unit | null | undefined;
  unit: string | undefined;
}

type PickupLocationDialogProps = PickupLocationDialogOwnProps & PickupLocationDialogStateProps;

class PickupLocationDialogImpl extends PureComponent<
  PickupLocationDialogProps,
  PickupLocationDialogState
> {
  state: PickupLocationDialogState = {
    amount: this.props.amount,
    customer: this.props.customer,
    customerLocationDialogOpen: false,
    location: this.props.location,
    note: this.props.note,
    relatedUnit: this.props.relatedUnit,
    unit: this.props.unit,
  };
  UNSAFE_componentWillReceiveProps(nextProps: PickupLocationDialogProps): void {
    const members = ["amount", "customer", "note", "unit", "location", "relatedUnit"] as const;
    if (nextProps.open && !this.props.open) {
      const update: Partial<PickupLocationDialogState> = {};
      members.forEach((member) => {
        update[member] = nextProps[member] as any;
      });
      this.setState(update as any);
    } else {
      let changed = false;
      const update: Partial<PickupLocationDialogState> = {};
      members.forEach((member) => {
        if (nextProps[member] !== this.props[member]) {
          update[member] = nextProps[member] as any;
          changed = true;
        }
      });
      if (changed) {
        this.setState(update as any);
      }
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleCustomerSelectButton(): void {
    this.props.onRequestCustomerDialog(this.handleCustomerSelected);
  }
  @bind
  handleCustomerSelected(customer: Customer): void {
    this.setState({customer, location: null});
  }

  @bind
  handleOk(): void {
    const {amount, customer, location, note, relatedUnit, unit} = this.state;
    this.props.onOk({
      amount: amount != null ? amount : null,
      customer: customer || null,
      location: location || null,
      note: note || "",
      relatedUnit: relatedUnit || null,
      unit: unit || "",
    });
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleAmountChange(newValue: number | null): void {
    this.setState({amount: newValue});
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleSelectUnitChange(unitURL: UnitUrl): void {
    const unit = this.props.unitLookup(unitURL);
    if (!unit) {
      return;
    }
    this.setState({
      relatedUnit: unit,
      unit: unit.name || unit.symbol,
    });
  }
  @bind
  handleLocationSelectButton(_event: unknown): void {
    this.setState({customerLocationDialogOpen: true});
  }
  @bind
  handleWorkPlaceDialogCancel(): void {
    this.setState({customerLocationDialogOpen: false});
  }
  @bind
  handleWorkPlaceDialogOk(url: LocationUrl): void {
    const location = this.props.locationLookup(url);
    const customer = location?.customer ? this.props.customerLookup(location.customer) : null;
    this.setState({
      customer: customer || null,
      customerLocationDialogOpen: false,
      location,
    });
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {canDelete, isNew, open} = this.props;
    const okDisabled =
      this.props.customerSettings.sharedTransportLog &&
      this.props.customerSettings.transportLogUnitPerLocation &&
      !this.state.relatedUnit;
    let customerBlock;
    if (this.state.customer) {
      const {customer} = this.state;
      customerBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Navn: {name}"
            id="pickup-location-dialog.label.customer-name"
            tagName="div"
            values={{name: customer.name}}
          />
          <FormattedMessage
            defaultMessage="Adresse: {address}"
            id="pickup-location-dialog.label.customer-address"
            tagName="div"
            values={{address: customerAddress(customer)}}
          />
        </div>
      );
    }
    let deleteButton;
    if (!isNew && this.props.onRequestDelete) {
      deleteButton = (
        <div>
          <ErrorColorButton
            disabled={!canDelete}
            variant="contained"
            onClick={this.props.onRequestDelete}
          >
            {formatMessage(messages.delete)}
          </ErrorColorButton>
        </div>
      );
    }
    let locationAddress;
    if (this.state.location) {
      locationAddress = this.state.location.address || this.state.location.name;
    }
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>
              <FormattedMessage
                defaultMessage="Kunde"
                id="pickup-location-dialog.label.customer"
                tagName="h4"
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleCustomerSelectButton}
              >
                {formatMessage(messages.selectCustomer)}
              </Button>
              {customerBlock}
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Arbejdssted"
                id="order-instance.label.workplace"
                tagName="h4"
              />
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleLocationSelectButton}
                >
                  {formatMessage(messages.selectPickupLocation)}
                </Button>
                <div>{locationAddress}</div>
              </div>
            </div>
            {deleteButton}
          </Cell>
          <Cell palm="12/12">
            {this.props.customerSettings.transportLogUnitPerLocation ? (
              <UnitSelect
                relatedUnit={this.state.relatedUnit?.url}
                onSelectUnitChange={this.handleSelectUnitChange}
              />
            ) : null}
            <DecimalField
              fullWidth
              decimalPlaces={this.props.customerSettings.transportLogDecimals}
              label={formatMessage(messages.totalAmount, {
                unit: this.state.relatedUnit
                  ? this.state.relatedUnit.symbol || this.state.relatedUnit.name
                  : this.state.unit,
              })}
              margin="dense"
              maxDigits={9}
              value={this.state.amount != null ? this.state.amount : null}
              onChange={this.handleAmountChange}
            />
            <TrimTextField
              fullWidth
              multiline
              label={formatMessage(messages.note)}
              margin="dense"
              maxRows={30}
              minRows={2}
              value={this.state.note}
              variant="outlined"
              onChange={this.handleNoteChange}
            />
          </Cell>
        </Grid>
        {this.props.customerSettings.sharedTransportLog &&
        this.props.customerSettings.transportLogUnitPerLocation ? (
          <FormattedMessage defaultMessage="* Krævet" id="pickup-location-dialog.label.required" />
        ) : null}
      </div>
    );
    return (
      <>
        <ResponsiveDialog
          okDisabled={okDisabled}
          open={open}
          title={
            isNew
              ? formatMessage(messages.newPickupLocation)
              : formatMessage(messages.editPickupLocation)
          }
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        >
          <DialogContent>{dialogContent}</DialogContent>
        </ResponsiveDialog>
        <LocationSelectCreateDialog
          customerURL={this.state.customer?.url || null}
          includeWorkplaceOnlyLocations={false}
          logOnlyLocation={this.props.customerSettings.setLogOnlyLocationOnCreate}
          open={this.state.customerLocationDialogOpen}
          titleVariant="PICKUP"
          onCancel={this.handleWorkPlaceDialogCancel}
          onOk={this.handleWorkPlaceDialogOk}
        />
      </>
    );
  }
}

export const PickupLocationDialog: React.ComponentType<PickupLocationDialogOwnProps> = connect<
  PickupLocationDialogStateProps,
  object,
  PickupLocationDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, PickupLocationDialogStateProps>({
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    unitLookup: getUnitLookup,
  }),
  {},
)(PickupLocationDialogImpl);
