import {
  LocationType,
  LocationTypeIcon,
  LocationTypeUrl,
  ProductUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {
  DeleteDialog,
  ErrorColorButton,
  MultipleProductDialog,
  SelectColor,
  SelectIcon,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getLocationTypeArray,
  getLocationTypeLookup,
  getPriceItemArray,
  getProductArray,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, Fab, IconButton} from "@material-ui/core";
import {DoLoadInstance, PageLayout} from "app-components";
import {useFieldUpdater} from "app-utils";
import _ from "lodash";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {LocationTypeIdentifierDialog} from "./location-types/location-type-identifier-dialog";

const messages = defineMessages({
  loadingTitle: {
    defaultMessage: "Locationstype",
    id: "location-type-edit.title.loading",
  },
  title: {
    defaultMessage: "Stedtype: {identifier}",
    id: "location-type-edit.title.normal",
  },
});

interface RemoveIconButtonProps {
  onClick: (url: string) => void;
  url: string;
}

function RemoveIconButton(props: RemoveIconButtonProps): JSX.Element {
  const {onClick, url} = props;
  const handleClick = useCallback(() => {
    onClick(url);
  }, [onClick, url]);
  return (
    <IconButton color="inherit" size="small" onClick={handleClick}>
      <CloseCircleIcon />
    </IconButton>
  );
}

interface LocationTypeEditProps {
  instance: LocationType;
}

function LocationTypeEditContent(props: LocationTypeEditProps): JSX.Element {
  const {instance} = props;
  const instanceURL = instance.url;
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const locationTypeArray = useSelector(getLocationTypeArray);
  const unitLookup = useSelector(getUnitLookup);
  const usedIdentifiers = useMemo(
    () =>
      new Set(
        locationTypeArray
          .map((locationType) => locationType.identifier)
          .filter((identifier) => identifier !== instance.identifier),
      ),
    [locationTypeArray, instance],
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditDialogCancel = useCallback((): void => {
    setEditDialogOpen(false);
  }, []);
  const handleEditDialogOk = useCallback(
    (identifier: string): void => {
      if (!editDialogOpen) {
        return;
      }
      setEditDialogOpen(false);
      dispatch(actions.update(instanceURL, [{member: "identifier", value: identifier}]));
    },
    [dispatch, instanceURL, editDialogOpen],
  );
  const handleEditFabClick = useCallback((): void => {
    setEditDialogOpen(true);
  }, []);
  const customerSettings = useSelector(getCustomerSettings);
  const editFabDisabled =
    !!customerSettings.fieldDefaultLocationType &&
    instance.identifier === customerSettings.fieldDefaultLocationType;
  const productLookup = useSelector(getProductLookup);
  const products = _.sortBy(
    instance.products.map(productLookup).filter(notUndefined),
    (product) => product.catalogNumber,
  );
  const handleRemoveProductButtonClick = useCallback(
    (url: string) => {
      const newProducts = instance.products.filter((productURL) => productURL !== url);
      dispatch(actions.update(instanceURL, [{member: "products", value: newProducts}]));
    },
    [dispatch, instance, instanceURL],
  );
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const handleAddProductsFabClick = useCallback((): void => {
    setProductDialogOpen(true);
  }, []);
  const handleProductDialogOk = useCallback(
    (urls: ReadonlySet<ProductUrl>): void => {
      setProductDialogOpen(false);
      dispatch(actions.update(instanceURL, [{member: "products", value: Array.from(urls)}]));
    },
    [dispatch, instanceURL],
  );
  const handleProductDialogCancel = useCallback((): void => {
    setProductDialogOpen(false);
  }, []);
  const productURLSet = useMemo(() => new Set(instance.products), [instance.products]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogCancel = useCallback((): void => {
    setDeleteDialogOpen(false);
  }, []);
  const handleDeleteDialogOk = useCallback((): void => {
    setDeleteDialogOpen(false);
    dispatch(actions.remove(instance.url));
    dispatch(actions.back());
  }, [dispatch, instance]);
  const handleDeleteButtonClick = useCallback((): void => {
    setDeleteDialogOpen(true);
  }, []);

  const productArray = useSelector(getProductArray);
  const priceItemArray = useSelector(getPriceItemArray);
  const activePriceItemProducts = useMemo(
    () =>
      new Set(
        priceItemArray
          .filter((priceItem) => priceItem.active && priceItem.product)
          .map((priceItem) => priceItem.product),
      ),
    [priceItemArray],
  );
  const filteredProductArray = useMemo(
    () =>
      productArray.filter((product) => product.active || activePriceItemProducts.has(product.url)),
    [activePriceItemProducts, productArray],
  );
  return (
    <PageLayout
      withPadding
      dialogs={[
        <MultipleProductDialog
          key="product-dialog"
          materialUseAlternativeText={customerSettings.materialUseAlternativeText}
          open={productDialogOpen}
          productArray={filteredProductArray}
          selected={productURLSet}
          unitLookup={unitLookup}
          onCancel={handleProductDialogCancel}
          onOk={handleProductDialogOk}
        />,
        <LocationTypeIdentifierDialog
          key="location-type-dialog"
          identifier={instance.identifier}
          illegalIdentifiers={editDialogOpen ? usedIdentifiers : undefined}
          open={editDialogOpen}
          onCancel={handleEditDialogCancel}
          onOk={handleEditDialogOk}
        />,
        <DeleteDialog
          key="delete-dialog"
          open={deleteDialogOpen}
          onCancel={handleDeleteDialogCancel}
          onOk={handleDeleteDialogOk}
        />,
      ]}
      toolbar={formatMessage(messages.title, {
        identifier: instance.identifier,
      })}
    >
      <Card>
        <div style={{position: "relative"}}>
          <Fab
            disabled={editFabDisabled}
            size="small"
            style={{position: "absolute", right: 23, top: 23}}
            onClick={handleEditFabClick}
          >
            <PencilIcon />
          </Fab>
        </div>
        <CardContent>
          <div>
            <FormattedMessage
              defaultMessage="ID"
              id="location-type-edit.label.identifier"
              tagName="h4"
            />
            {instance.identifier}
          </div>
          <TrimTextField
            label={<FormattedMessage defaultMessage="Navn" id="location-type-edit.label.name" />}
            margin="dense"
            value={instance.name}
            variant="outlined"
            onChange={useFieldUpdater<LocationType, string>(instanceURL, "name")}
          />
          <div style={{marginTop: 10}}>
            <SelectColor
              color={instance.color}
              onColorSelected={useFieldUpdater<LocationType, string>(instanceURL, "color")}
            />
          </div>
          <div style={{marginTop: 10}}>
            <SelectIcon
              icon={instance.icon}
              onIconSelected={useFieldUpdater<LocationType, LocationTypeIcon>(instanceURL, "icon")}
            />
          </div>
        </CardContent>
      </Card>
      {customerSettings.enableLocationStorage ? (
        <Card style={{marginTop: 16}}>
          <div style={{position: "relative"}}>
            <Fab
              size="small"
              style={{position: "absolute", right: 23, top: 23}}
              onClick={handleAddProductsFabClick}
            >
              <PlusIcon />
            </Fab>
          </div>
          <CardHeader
            title={
              <FormattedMessage
                defaultMessage="Varer"
                id="location-type-edit.card.title.products"
              />
            }
          />
          <CardContent>
            <ul>
              {products.map((product) => (
                <li key={product.url}>
                  {product.catalogNumber}: {product.name}, {getUnitString(product, unitLookup)}
                  <RemoveIconButton url={product.url} onClick={handleRemoveProductButtonClick} />
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      ) : null}
      <div style={{marginTop: 16}}>
        <ErrorColorButton
          disabled={editFabDisabled}
          variant="contained"
          onClick={handleDeleteButtonClick}
        >
          <FormattedMessage defaultMessage="Slet" id="location-type-edit.label.delete" />
        </ErrorColorButton>
      </div>
    </PageLayout>
  );
}

export function LocationTypeEditPage(): JSX.Element {
  const {formatMessage} = useIntl();
  return (
    <DoLoadInstance<LocationType, LocationTypeUrl>
      Component={LocationTypeEditContent}
      loadingTitle={formatMessage(messages.loadingTitle)}
      lookupSelector={getLocationTypeLookup}
      resourceName="locationType"
    />
  );
}
